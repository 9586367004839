<div class="content" *ngIf="loaded">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina" *ngFor="let pagina of paginasArrayItems">
            <table style="font-weight: normal; width: 85%;" [ngStyle]="{'height': pagina.totalHeightPage}">
                <thead>
                    <th style="font-weight: normal;" class="flex-4" scope="row">NºOferta</th>
                    <th style="font-weight: normal;" class="flex-6" scope="row">Fecha Oferta</th>
                    <th style="font-weight: normal;" class="flex-14" scope="row">Dirección Obra</th>
                    <th style="font-weight: normal;" class="flex-8" scope="row">Ciudad</th>
                    <th style="font-weight: normal;" class="flex-8" scope="row">Provincia</th>
                    <th *ngIf="!pagina.datos[0] || !pagina.datos[0].DataAcceptacio" style="font-weight: normal;"
                        class="flex-26" scope="row">Clientes</th>
                    <th *ngIf="pagina.datos[0].DataAcceptacio" style="font-weight: normal;" class="flex-26" scope="row">
                        Cliente asignado</th>
                    <th style="font-weight: normal;" class="flex-8" scope="row">Comercial</th>
                    <th style="font-weight: normal;" class="flex-8" scope="row">Técnico</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">Importe Kits</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">Importe Otros</th>
                    <!--
                    <th style="font-weight: normal;" class="flex-5" scope="row">Peso Kits</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">Peso Otros</th>
                    -->
                    <th style="font-weight: normal;" class="flex-8" scope="row">Fecha Cierre</th>
                </thead>
                <tbody>
                    <tr class="celda" *ngFor="let producto of pagina.datos; let i = index">
                        <th style="font-weight: normal;" class="text-center flex-4" scope="row">{{producto.nOferta}}
                        </th>
                        <th style="font-weight: normal;" class="text-center flex-6" scope="row">{{producto.fechaOferta |
                            date: 'dd/MM/yy'}}</th>
                        <th style="font-weight: normal;" class="text-left flex-14" scope="row">
                            {{producto.direccionObra}}</th>
                        <th style="font-weight: normal;" class="text-left flex-8" scope="row">{{producto.ciudad}}</th>
                        <th style="font-weight: normal;" class="text-left flex-8" scope="row">{{producto.provincia}}
                        </th>
                        <th *ngIf="!producto.DataAcceptacio" style="font-weight: normal;" class="text-left flex-26"
                            scope="row">
                            <p *ngFor="let cli of producto.clientesArray">
                                {{cli.Client}} <span *ngIf="cli.Telefon">({{cli.Telefon}})</span>
                                <br>
                            </p>
                        </th>
                        <th *ngIf="producto.DataAcceptacio" style="font-weight: normal;" class="text-left flex-26"
                            scope="row">{{producto.cliente}}</th>
                        <th style="font-weight: normal;" class="text-left flex-8" scope="row">{{producto.comercial}}
                        </th>
                        <th style="font-weight: normal;" class="text-left flex-8" scope="row">{{producto.tecnico}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.importeKits}}
                        </th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.importeOtros}}
                        </th>
                        <!--
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.pesoKits}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.pesoOtros}}</th>
                        -->
                        <th style="font-weight: normal;" class="text-center flex-8" scope="row">{{producto.fechaCierre |
                            date: 'dd/MM/yyyy'}}</th>
                    </tr>
                </tbody>

            </table>

            <div class="fecha">
                Página {{pagina.nPagina}} de {{paginasArrayItems.length}}
            </div>

        </div>

    </div>

</div>