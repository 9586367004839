import { Injectable } from '@angular/core';
//import { JwtHelperService } from '@auth0/angular-jwt';

export interface UserRoles {
authMantenimiento: number;
authOfertas:       number;
authStock:         number;
authCompra:        number;
authFacturacion:   number;
authFabricacion:   number;
authFabricar:      number;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {


  public userRoles: UserRoles | null = null;

  public arrayOfertas = [];
  
  constructor() {}

  public isAuthenticated(): boolean {

    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
       };
    
    // Check whether the token is expired and return
    // true or false
  }

  public isAdmin(): boolean {
    if (this.userRoles && this.userRoles.authFabricar > 0 && this.userRoles.authFabricacion > 0
      && this.userRoles.authCompra > 0 && this.userRoles.authFacturacion > 0
      && this.userRoles.authOfertas > 0 && this.userRoles.authStock > 0
      && this.userRoles.authMantenimiento > 0) return true;
    else return false;
  }
  public isFabricante(): boolean {
    if (this.userRoles && this.userRoles.authFabricar > 0 && this.userRoles.authFabricacion === 0 
      && this.userRoles.authCompra === 0 && this.userRoles.authFacturacion === 0 
      && this.userRoles.authOfertas === 0 && this.userRoles.authStock === 0 
      && this.userRoles.authMantenimiento === 0 ) return true;
    else return false;
  }
  
}