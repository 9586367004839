import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DateToString, StringToDate } from 'src/app/services/dataFunction';
import { DialogMantenimientoPrecios } from 'src/app/types/global';
import { DialogPopUpComponent } from '../../dialog-pop-up/dialog-pop-up.component';

@Component({
  selector: 'app-dialog-precios-prod',
  templateUrl: './dialog-precios-prod.component.html',
  styleUrls: ['./dialog-precios-prod.component.css']
})
export class DialogPreciosProdComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPreciosProdComponent>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog, 
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimientoPrecios
  ) { }

  items: any[]=[];
  fechaIniOriginal: any;
  fechaIniNext: any;
  fechaFinPrev: any;
  hoy = new Date();
  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2]];
  
  itemSelected : any = {
    idProducto: null,
    fechaInicio: null,
    fechaFin: null,
    precio: null,
    editable: false
  }

  unaFechaNull = false;

  ngOnInit(): void {
    this.obtenerPrecioProd();
  }
  
  private obtenerPrecioProd(){
    this.apiService.obtenerPrecioProducto(this.data.idProd).subscribe(data =>{
      console.log("data",data);
      this.items = data.response;
      this.unaFechaNull = false;
      for(var i = 0; i< data.response.length; ++i){
        console.log(data.response[i]);
        console.log(data.response[i]['fechaFin']);
        if(data.response[i]['fechaFin'] == null) this.unaFechaNull = true;

      }
      console.log(this.unaFechaNull);
    })
  } 


  dialogEliminarUnCampo(event: any, item: any){
    this.openDialog("ELIMINAR","¿Quiere eliminar "+item.fechaInicio +" con precio " +item.precio  +"€?", true,"eliminar");
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo =="eliminar") this.eliminarItem();
    });
  }

  eliminarItem(){
    var pvp = {
      fechaInicio: this.itemSelected.fechaInicio,
      idProd: this.data.idProd
    }
    this.apiService.eliminarPrecioProducto(pvp).subscribe(data =>{
      console.log("borrar");
      this.obtenerPrecioProd();
      this.resetItem();
    });
  }

  muestraUnItem(item : any){
    this.itemSelected = {... item};
    this.fechaIniOriginal = this.itemSelected.fechaInicio;
    console.log(this.itemSelected);
    var posItem =0;
    for(var i=0; i<this.items.length; ++i){
      if(this.items[i]['fechaInicio']== item.fechaInicio){
        posItem = i;
      }
    }
    if(posItem+1 < this.items.length ){
      this.fechaIniNext = this.items[posItem+1]['fechaInicio'];
      this.fechaIniNext= new Date(this.fechaIniNext);
      this.fechaIniNext.setDate(this.fechaIniNext.getDate()-1)
    }
    else this.fechaIniNext = null;
    console.log(posItem);
    if (posItem >0){
      this.fechaFinPrev = this.items[posItem-1]['fechaFin'];
      console.log(this.fechaFinPrev);
      this.fechaFinPrev= new Date(this.fechaFinPrev);
      this.fechaFinPrev.setDate(this.fechaFinPrev.getDate()+1)
    }
    else this.fechaFinPrev = null;

    if(! this.itemSelected.editable){
      this.hoy = new Date(this.itemSelected.fechaInicio); 
    }
    else this.hoy = new Date();
  }


  guardarItem(formulario: NgForm){
    console.log("guardar; ", this.itemSelected);
    console.log(this.fechaIniOriginal);
   /* if(this.solapament()){
      this.openDialog("AVISO","Hay solapamineto de fechas, No se puede guardar", true,"aviso");
    }
    */
   var fechaFin = null;
   if (this.itemSelected.fechaFin!=null) fechaFin = DateToString(new Date(this.itemSelected.fechaFin));
    var pvp ={
      idProd: this.data.idProd, 
      fechaIni : DateToString(new Date(this.itemSelected.fechaInicio)),
      fechaFin : fechaFin,
      precio: this.itemSelected.precio,
      fechaIniOriginal: this.fechaIniOriginal};
    console.log(pvp);   
    this.apiService.modificarPrecioProductoCompleto(pvp).subscribe(dades => {
      this.obtenerPrecioProd();
      this.fechaIniOriginal = DateToString(this.itemSelected.fechaInicio);
    });  
  }
/*
  solapament(){
    var fechaMolona =this.itemSelected.fechaInicio;
    for(var i= 0; i<this.items.length; ++i){
      var fecha2 = StringToDate(this.items[i].fechaFin); 
      if(fecha2){
        console.log(fecha2, fechaMolona);
        if(fecha2 > fechaMolona) return true;
      }
    }
    console.log("hola");
    if(this.itemSelected.fechaFin == null) return false;
    var fechaMolona =this.itemSelected.fechaFin;
    for(var i= 0; i<this.items.length; ++i){
      var fecha2 = StringToDate(this.items[i].fechaInicio); 
      if(fecha2){
        if(fecha2 > fechaMolona) return true;
      }
    }
    return false;
  }
*/
  resetItem(){
    this.itemSelected = {
      fechaInicio: null,
      fechaFin : null,
      precio: null, 
      editable : false,
    }
    this.fechaIniOriginal = null;

  }

}
