<div *ngIf="tieneLineas === 1 || tieneLineas === 0 && nOferta < 1 " class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div *ngIf="nOferta > 0" class="container-report" id="factura">

        <div class="pagina" *ngFor="let listaAlbaranesPagina of paginas">

            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idIdioma" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-factura [factura]="nFactura" [esAbono]="esAbono" class="header-cliente"></app-cabecera-factura>
            </div>
            <div class="header-pie">
                <app-pie-cabecera [idIdioma]="idIdioma" [fechaFactura]="fFactura" [formaPago]="cPago" [nCuenta]="nCuenta"></app-pie-cabecera>
            </div>

            <div class="body">

                <div *ngIf="idIdioma != 1" class="cabecera-lineas-albaran">
                    <p style="width: 7%;" class="text-left">N ALB.</p>
                    <p style="width: 46%;" class="text-left">DESCRIPCIÓN</p>
                    <P style="width: 6%;" class="text-left">UNI.</P>
                    <p style="width: 15%; text-align: center;" class="text-left">PRE/UNI</p>
                    <p *ngIf="tieneDto" style="width: 17%;" class="text-left"> DESCUENTO</p>
                    <p *ngIf="!tieneDto" style="width: 17%;" class="text-left"></p>
                    <p style="width: 11%;" class="text-right">T O T A L</p>
                </div>
                <div *ngIf="idIdioma == 1" class="cabecera-lineas-albaran">
                    <p style="width: 7%;" class="text-left">N ALB.</p>
                    <p style="width: 46%;" class="text-left">DESCRIPCIÓ</p>
                    <P style="width: 6%;" class="text-left">UNI.</P>
                    <p style="width: 15%; text-align: center;" class="text-left">PRE/UNI</p>
                    <p *ngIf="tieneDto" style="width: 17%;" class="text-left"> DESCOMPTE</p>
                    <p *ngIf="!tieneDto" style="width: 17%;" class="text-left"></p>
                    <p style="width: 11%;" class="text-right">T O T A L</p>
                </div>

                <div class="albaranes" *ngFor="let albaran of listaAlbaranesPagina.albaranes">
                    <div class="nAlbaran">{{albaran.nAlbaran}}</div>

                    <table style="width: 95%;">
                        <tbody class="flex-row-wrap">
                            <tr style="display: flex; width: 100%;" *ngFor="let linea of albaran.datosAlbaran.lineasAlbaran">
                                <th style="width: 50%; padding-left: 75px;" class="text-left" scope="row">{{linea.desc}}</th>
                                <th style="width: 4%" scope="row" class="text-right pr20">{{linea.cantidad}}</th>
                                <th style="width: 15%" scope="row" class="text-right pr40">{{linea.precioUni | number:
                                    '1.2-2'}} €
                                </th>
                                <th *ngIf="tieneDto && linea.dto != 0 && linea.esKit && !linea.ProdOpc" style="width: 17%" scope="row"
                                    class="text-right pr20">
                                    ({{linea.dtoP}}%) {{linea.dto |
                                    number:
                                    '1.2-2'}} €</th>
                                    <th *ngIf="tieneDto && linea.dto != 0 && !linea.esKit && !linea.ProdOpc" style="width: 17%" scope="row"
                                        class="text-right pr20">
                                        ({{linea.dtoP}}%) {{linea.dto |
                                        number:
                                        '1.2-2'}} €</th>
                                <th *ngIf="tieneDto && linea.dto !=0  && !linea.esKit && linea.ProdOpc" style="width: 17%" scope="row"
                                    class="text-right pr20">
                                    {{linea.dto |
                                    number:
                                    '1.2-2'}} €</th>
                                <th *ngIf="!tieneDto || (tieneDto && linea.dto == 0)" style="width: 17%;" scope="row" class="text-right pr20"></th>
                                
                                <th style="width: 14%;" class="text-right" scope="row">{{linea.cantidad * linea.precioUni -
                                    linea.dto |
                                    number: '1.2-2'}} €</th>
                            </tr>
                            <tr style="display: flex; width: 100%;" *ngIf="albaran.datosAlbaran.transporte" class="transporte">
                                <th style="padding-left: 75px;" class="text-left flex-86" scope="row">Coste Transporte</th>
                                <th class="text-right flex-14" scope="row">{{albaran.datosAlbaran.transporte |
                                    number:
                                    '1.2-2'}} €
                                </th>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
            <div class="footer">

                <div *ngIf="!listaAlbaranesPagina.ultimaPagina" class="notas-subtotal">
                    <br><br>
                    <div class="notas flex-70">
                    </div>
                    <div class="flex-30">
                        <div class="subtotal">
                            <div class="text-left flex-60">SUBTOTAL:</div>
                            <div class="text-right flex-40">{{listaAlbaranesPagina.subtotal -
                                listaAlbaranesPagina.dto | number: '1.2-2'}} €</div>
                        </div>
                    </div>
                </div>

                <div *ngIf="listaAlbaranesPagina.ultimaPagina" class="notas-subtotal">
                    <div class="notas flex-70">
                        {{notas}}
                    </div>
                    <div class="flex-30">
                        <div class="subtotal">
                            <div class="text-left flex-60">SUBTOTAL:</div>
                            <div class="text-right flex-40">{{subtotal -
                                listaAlbaranesPagina.dto | number: '1.2-2'}} €</div>
                        </div>

                        <div *ngIf="dtoPP > 0 && listaAlbaranesPagina.ultimaPagina" class="subtotal">
                            <div *ngIf="idIdioma != 1" class="text-left flex-60">DTO.COMERCIAL:</div>
                            <div *ngIf="idIdioma == 1" class="text-left flex-60">DTE.COMERCIAL:</div>
                            <div class="text-right flex-40">{{dtoPP | number: '1.2-2'}} €</div>
                        </div>
                        <div *ngIf="!dtoPP && listaAlbaranesPagina.ultimaPagina" style="height: 20px;" class="subtotal">
                            <div class="text-left flex-60"></div>
                            <div class="text-right flex-40"></div>
                        </div>

                        <!--
                        <div *ngIf="adelanto && listaAlbaranesPagina.ultimaPagina" class="subtotal">
                            <div class="flex-60"></div>
                            <div class="text-left flex-27">ADELANTO:</div>
                            <div class="text-right flex-11">{{adelanto | number: '1.2-2'}} €</div>
                        </div>
                        -->
                        <div *ngIf="listaAlbaranesPagina.ultimaPagina" class="subtotal">
                            <div class="text-left flex-60">I.V.A. {{tipoIva}} %:</div>
                            <div class="text-right flex-40">{{(subtotal -
                                listaAlbaranesPagina.dto - dtoPP) * tipoIva / 100 | number: '1.2-2'}} €</div>
                        </div>

                    </div>
                </div>

                <div *ngIf="listaAlbaranesPagina.ultimaPagina" class="linea-delgada mt10"></div>
                <br>
                <div *ngIf="listaAlbaranesPagina.ultimaPagina" class="subtotal">
                    <div class="flex-2"></div>
                    <div *ngIf="!esAbono" class="text-left flex-85">TOTAL FACTURA:</div>
                    <div *ngIf="!esAbono" class="text-right flex-11">{{ ((subtotal -
                        listaAlbaranesPagina.dto - dtoPP) * (1+(tipoIva / 100))) |
                        number: '1.2-2'}} €</div>
                    <div *ngIf="esAbono && idIdioma == 1" class="text-left flex-85">TOTAL A TORNAR:</div>
                    <div *ngIf="esAbono && idIdioma != 1" class="text-left flex-85">TOTAL A DEVOLVER:</div>
                    <div *ngIf="esAbono" class="text-right flex-11">{{(((subtotal -
                        listaAlbaranesPagina.dto - dtoPP) * (1+(tipoIva / 100))) ) * -1|
                        number: '1.2-2'}} €</div>
                </div>
                <br>
                <div *ngIf="listaAlbaranesPagina.ultimaPagina" class="linea-delgada"></div>

                <div class="registro">
                    <div *ngIf="idIdioma != 1">Registro Mercantil de Barcelona, Tomo 26.200, Folio 143, Hoja B-99649, Inscripción
                        2ª
                    </div>
                    <div *ngIf="idIdioma == 1">Registre Mercantil de Barcelona, Tom 26.200, Foli 143, Fulla B-99649, Inscripció
                        2ª
                    </div>
                    <div *ngIf="idIdioma != 1" class="pie-pagina">Página {{listaAlbaranesPagina.npagina}} de {{paginas.length}}
                    </div>
                    <div *ngIf="idIdioma == 1" class="pie-pagina">Pàgina {{listaAlbaranesPagina.npagina}} de {{paginas.length}}
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="nOferta < 1" class="container-report" id="factura" >

        <div class="pagina" style="height: 1965px;">

            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idIdioma" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-factura [factura]="nFactura" class="header-cliente"></app-cabecera-factura>
            </div>
            <div class="header-pie">
                <app-pie-cabecera [idIdioma]="idIdioma" [fechaFactura]="fFactura" [formaPago]="cPago" [nCuenta]="nCuenta"></app-pie-cabecera>
            </div>
            <div class="descripcion">
                <textarea *ngIf="!descripcionParaImprimir" class="bodyVacia monospace"
                    [(ngModel)]="datosFactura.descripcion" name="descripcion">{{datosFactura.descripcion}}</textarea>
                <pre *ngIf="descripcionParaImprimir" class="bodyVacia monospace">{{datosFactura.descripcion}}</pre>
            </div>
        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>

<div class="noTiene" *ngIf="tieneLineas === 0 && nOferta > 0">
    Esta factura no tiene albaranes entrados para facturar
</div>