import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DialogMantenimiento } from 'src/app/types/global';
import { item } from 'src/app/types/mantenimiento';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { IdLabelObject } from 'src/app/types/global';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-dialog-mantenimiento',
  templateUrl: './dialog-mantenimiento.component.html',
  styleUrls: ['./dialog-mantenimiento.component.css'],
})

export class DialogMantenimientoComponent implements OnInit {

  public anioActual = new Date().getFullYear();
  public anioMax: number = this.anioActual;

  tipoProducto: IdLabelObject[] = [];
  public tipoProductoElegido: any = "";

  itemSelected: any = {
    id: null,
    label: "",
    editable: null
  };
  items: item[] = [];
  itemsTot: item[] = [];

  itemTrobat: item = {
    id: 0,
    label: "",
    editable: false
  };
  nOferta: number = 0;

  displayedColumns: string[] = ['buttons', this.data.mantenimiento];

  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public dialogRef: MatDialogRef<DialogMantenimientoComponent>,
    public dialog: MatDialog,
    public dialogVerificar: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento
  ) { }


  ngOnInit() {
    this.obtenerTipoProducto();
    this.displayedColumns = ['buttons', this.data.mantenimiento];
    switch (this.data.mantenimiento) {
      case 'situacion':
        this.obtenerSituaciones(this.data.parametros);
        break;
      case 'condicion':
        this.obtenerCondPago();
        break;
      case 'validez':
        this.obtenerValidez();
        break;
      case 'forma de pago':
        this.obtenerFormaPago();
        break;
      case 'patologia':
        this.obtenerPatologia();
        break;
      case 'idioma':
        this.obtenerIdioma();
        break;
      case 'pais':
        this.obtenerPais();
        break;
      case 'envio':
        this.obtenerEnvio();
        break;
      case 'tipo Producto':
        this.obtenerTipoProd();
        break;
      case 'tipo Soporte':
        this.obtenerTipoSoporte();
        break;
      case 'transportista':
        this.obtenerTransportista();
        break;
      case 'seleccion':
        this.obtenerAnioSeleccion();
        break;
      case 'cuentas':
        this.obtenerCuenta();
        break;
      default:
        break;
    }
  }

  private obtenerTipoProducto() {
    this.apiService.obtenerTipoProducto().subscribe(data => {
      console.log("tipo prod", data);
      this.tipoProducto = data.response.map(prod => ({ id: prod.idTipo.toString(), label: prod.nombre }));
      console.log(this.tipoProducto);
    });
  }

  protected obtenerCondPago() {
    this.apiService.obtenerCondicionesDePago().subscribe(dades => {
      this.items = dades.response.map(condPago => ({ id: condPago.nCondicion, label: condPago.condicion, editable: condPago.estaAssignada }));
    })
  }
  protected obtenerValidez() {
    this.apiService.obtenerValidezOferta().subscribe(dades => {
      this.items = dades.response.map(validez => ({ id: validez.nValidez, label: validez.validez, editable: validez.estaAssignada }));
    })
  }
  protected obtenerFormaPago() {
    this.apiService.obtenerFormaPago().subscribe(data => {
      this.items = data.response.map(formaP => ({ id: formaP.nForma, label: formaP.FormaPagament, editable: false }));
    })
  }
  protected obtenerPatologia() {
    this.apiService.obtenerPatologia().subscribe(data => {
      this.items = data.response.map(patologia => ({ id: patologia.idPatologia, label: patologia.patologia, editable: false }));
    })
  }
  protected obtenerSituaciones(params: any) {
    this.apiService.obtenerSituacionComanda(params).subscribe(dades => {
      this.items = dades.response.map(situacion => ({ id: situacion.idSituacion, label: situacion.situacion, editable: situacion.estaAssignada }));
      this.data.parametros = parseInt(this.data.parametros);
    });
  }
  protected obtenerIdioma() {
    this.apiService.obtenerIdioma().subscribe(data => {
      this.items = data.response.map(idioma => ({ id: idioma.idIdioma, label: idioma.nombre, editable: idioma.estaAssignada }));
    })
  }
  protected obtenerPais() {
    this.apiService.obtenerPais().subscribe(data => {
      this.items = data.response.map(pais => ({ id: pais.idPais, label: pais.nombrePais, editable: pais.estaAssignada }));
    })
  }
  protected obtenerEnvio() {
    this.apiService.obtenerEnvio().subscribe(data => {
      this.items = data.response.map(envio => ({ id: envio.idEnvio, label: envio.nombre, editable: envio.estaAssignada }));
    })
  }
  protected obtenerTipoProd() {
    this.apiService.obtenerTipoProducto().subscribe(data => {
      this.items = data.response.map(tProd => ({ id: tProd.idTipo, label: tProd.nombre, editable: tProd.estaAssignada }));
    })
  }
  protected obtenerTipoSoporte() {
    this.apiService.obtenerTipoSoporte().subscribe(data => {
      this.items = data.response.map(tSoporte => ({ id: tSoporte.idTipo, label: tSoporte.nombre, editable: tSoporte.estaAssignada }));
    })
  }
  protected obtenerTransportista() {
    this.apiService.obtenerTransportistas().subscribe(data => {
      this.items = data.response.map(transportista => ({ id: transportista.idTransportista, label: transportista.transportista, editable: transportista.estaAssignada }));
    })
  }
  protected obtenerCuenta() {
    this.apiService.obtenerCuenta().subscribe(data => {
      this.items = data.response.map(cuenta => ({ id: cuenta.idCuenta, label: cuenta.cuenta, editable: cuenta.estaAssignada }));
    })
  }


  protected obtenerAnioSeleccion() {
    console.log("Estoy en el dialog de selecionar anio", this.data.mantenimiento);
  }


  dialogEliminarUnCampo(event: any, item: any) {
    this.itemSelected = item;
    this.openDialog("ELIMINAR", "¿Quiere eliminar " + item.label + "?", true, "eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem() {
    switch (this.data.mantenimiento) {
      case 'situacion':
        this.eliminarSituacion(this.itemSelected);
        break;
      case 'condicion':
        this.eliminarCondPago(this.itemSelected);
        break;
      case 'validez':
        this.eliminarValidezOferta(this.itemSelected);
        break;
      case 'forma de pago':
        this.eliminarFormaPago(this.itemSelected);
        break;
      case 'patologia':
        this.eliminarPatologia(this.itemSelected);
        break;
      case 'idioma':
        this.eliminarIdioma(this.itemSelected);
        break;
      case 'pais':
        this.eliminarPais(this.itemSelected);
        break;
      case 'envio':
        this.eliminarEnvio(this.itemSelected);
        break;
      case 'tipo Producto':
        this.eliminarTipoProducto(this.itemSelected);
        break;
      case 'tipo Soporte':
        this.eliminarTipoSoporte(this.itemSelected);
        break;
      case 'transportista':
        this.eliminarTransportista(this.itemSelected);
        break;
      case 'cuentas':
        this.eliminarCuenta(this.itemSelected);
        break;
      default:
        break;
    }
  }
  protected eliminarSituacion(situacion: any) {
    this.apiService.borrarSituacion(situacion.id).subscribe(dades => {
      this.modificarTabla();
      this.resetItem();
    });
  }

  protected eliminarCondPago(condicion: any) {
    this.apiService.borrarCondicionDePago(condicion.id.toString()).subscribe(dades => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarValidezOferta(validez: any) {
    this.apiService.borrarValidezOferta(validez.id.toString()).subscribe(dades => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarFormaPago(formaPago: any) {
    this.apiService.borrarFormaPago(formaPago.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarPatologia(patologia: any) {
    this.apiService.borrarPatologia(patologia.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarIdioma(idioma: any) {
    this.apiService.borrarIdioma(idioma.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarPais(pais: any) {
    this.apiService.borrarPais(pais.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarEnvio(envio: any) {
    this.apiService.borrarEnvio(envio.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarTipoProducto(prod: any) {
    this.apiService.borrarTipoProducto(prod.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarTipoSoporte(soporte: any) {
    this.apiService.borrarTipoSoporte(soporte.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarTransportista(transportista: any) {
    this.apiService.borrarTransportista(transportista.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }
  protected eliminarCuenta(cuenta: any) {
    this.apiService.borrarCuenta(cuenta.id.toString()).subscribe(data => {
      this.modificarTabla();
      this.resetItem()
    });
  }




  public guardarItem() {
    switch (this.data.mantenimiento) {
      case 'situacion':
        this.guardarSituacion();
        break;
      case 'condicion':
        this.guardarCondPago();
        break;
      case 'validez':
        this.guardarValidezOferta();
        break;
      case 'forma de pago':
        this.guardarFormaPago();
        break;
      case 'patologia':
        this.guardarPatologia();
        break;
      case 'idioma':
        this.guardarIdioma();
        break;
      case 'pais':
        this.guardarPais();
        break;
      case 'envio':
        this.guardarEnvio();
        break;
      case 'tipo Producto':
        this.guardarTipoProducto();
        break;
      case 'tipo Soporte':
        this.guardarTipoSoporte();
        break;
      case 'transportista':
        this.guardarTransportista();
        break;
      case 'cuentas':
        this.guardarCuenta();
        break;
      default:
        break;
    }
  }

  private guardarSituacion() {
    if (this.itemSelected.id) {
      var situacion = { idSituacion: this.itemSelected.id, situacion: this.itemSelected.label };
      this.apiService.modificarSituacion(situacion).subscribe(dades => {
        this.modificarTabla();
      });
    }
    else {
      var situacionCompleta = { nOferta: this.data.parametros, situacion: this.itemSelected.label };
      this.apiService.anadirSituacion(situacionCompleta).subscribe(dades => {
        this.modificarTabla();

      });
    }
  }

  private guardarCondPago() {
    if (this.itemSelected.id) {
      this.apiService.modificarCondicionDePago(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirCondicionDePago(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarValidezOferta() {
    if (this.itemSelected.id) {
      this.apiService.modificarValidezOferta(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirValidezOferta(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarFormaPago() {
    if (this.itemSelected.id) {
      this.apiService.modificarFormaPago(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirFormaPago(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarPatologia() {
    if (this.itemSelected.id) {
      this.apiService.modificarPatologia(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirPatologia(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarIdioma() {
    if (this.itemSelected.id) {
      this.apiService.modificarIdioma(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirIdioma(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarPais() {
    if (this.itemSelected.id) {
      this.apiService.modificarPais(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirPais(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarEnvio() {
    if (this.itemSelected.id) {
      this.apiService.modificarEnvio(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirEnvio(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarTipoProducto() {
    if (this.itemSelected.id) {
      this.apiService.modificarTipoProducto(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirTipoProducto(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarTipoSoporte() {
    if (this.itemSelected.id) {
      this.apiService.modificarTipoSoporte(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirTipoSoporte(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarTransportista() {
    if (this.itemSelected.id) {
      this.apiService.modificarTransportista(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirTransportista(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }
  private guardarCuenta() {
    if (this.itemSelected.id) {
      this.apiService.modificarCuenta(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      });
    }
    else {
      this.apiService.anadirCuenta(this.itemSelected.label).subscribe(dades => {
        this.modificarTabla();
      });
    }
  }


  resetItem() {
    this.itemSelected.id = null;
    this.itemSelected.editable = null;
    this.itemSelected.label = "";
    //this.goTo();
  }

  public envioDatos() {
    console.log("envia datos: ", this.data);
  }

  public modificarTabla() {
    switch (this.data.mantenimiento) {
      case 'situacion':
        this.modificarTablaSituacion(this.data.parametros);
        break;
      case 'condicion':
        this.modificarTablaCondicion();
        break;
      case 'validez':
        this.modificarValidezOferta();
        break;
      case 'forma de pago':
        this.modificarTablaFormaPago();
        break;
      case 'patologia':
        this.modificarTablaPatologia();
        break;
      case 'idioma':
        this.modificarTablaIdioma();
        break;
      case 'pais':
        this.modificarTablaPais();
        break;
      case 'envio':
        this.modificarTablaEnvio();
        break;
      case 'tipo Producto':
        this.modificarTablaTipoProducto();
        break;
      case 'tipo Soporte':
        this.modificarTablaTipoSoporte();
        break;
      case 'transportista':
        this.modificarTablaTransportista();
        break;
      case 'cuentas':
        this.modificarTablaCuentas();
        break;
      default:
        break;
    }
  }

  private modificarTablaSituacion(params: any) {
    this.obtenerSituaciones(this.data.parametros);
  }
  private modificarTablaCondicion() {
    this.obtenerCondPago();
  }
  private modificarValidezOferta() {
    this.obtenerValidez();
  }
  private modificarTablaFormaPago() {
    this.obtenerFormaPago();
  }
  private modificarTablaPatologia() {
    this.obtenerPatologia();
  }
  private modificarTablaIdioma() {
    this.obtenerIdioma();
  }
  private modificarTablaPais() {
    this.obtenerPais();
  }
  private modificarTablaEnvio() {
    this.obtenerEnvio();
  }
  private modificarTablaTipoProducto() {
    this.obtenerTipoProd();
  }
  private modificarTablaTipoSoporte() {
    this.obtenerTipoSoporte();
  }
  private modificarTablaTransportista() {
    this.obtenerTransportista();
  }
  private modificarTablaCuentas() {
    this.obtenerCuenta();
  }

  goTo() {
    if (this.itemSelected.id == null) {
      if (this.itemSelected.label != null) {
        this.items = this.itemsTot.filter(item => item.label.toLowerCase().includes(this.itemSelected.label.toLowerCase()));
        if (this.items.length > 0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }

  muestraUnItem(item: any) {
    console.log(item);
    this.itemSelected = { ...item };
  }

  public imprimirInforme(formulario: NgForm) {
    //    const url = this.router.navigate(['informe-material-vendido'], { queryParams: { anio: this.anioActual}});
    //   window.open(url, '_blank');
    /*  let url: any = ""
      url = this.router.navigate(['informe-material-vendido'], { queryParams: { anio: this.anioActual } });
      url = "http://localhost:4200/informe-material-vendido?anio=2021"
      window.open( url, '_blank');
  */
    console.log("Formulario: ", this.tipoProductoElegido)
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/informe-material-vendido?anio=' + this.anioActual + '&tipoProductoElegido=' + this.tipoProductoElegido;
    link.click();
    link.remove();

  }

}
