import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OfertasUnClienteComponent } from '../../ofertas/ofertas-un-cliente/ofertas-un-cliente.component';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-albaranes-pendientes-facturar',
  templateUrl: './albaranes-pendientes-facturar.component.html',
  styleUrls: ['./albaranes-pendientes-facturar.component.css']
})
export class AlbaranesPendientesFacturarComponent implements OnInit {

  public albaranAFacturar: any = {
    idFactura: "",
    nAlbaran: "",
    nOferta: "",
    idCliente: "",
    nombreCliente: "",
    fechaSalida: "",
    entregaPrevista: "",
    entregaReal: "",
    importeLineas: "",
    importeProductosOpcionales: "",
    costeTransporte: "",
    peso: "",
    transportista: "",
    observaciones: "",
    encargadoObra: "",
    telefonoObra: ""
  }

  public listaAlbaranesAFacturar: any[] = [];
  public nFactura = "";
  public idFactura = "";
  public nOferta = "";
  public idCliente = "";
  public nombreCliente = "";
  public dataSource!: MatTableDataSource<any>;
  public hayDatosEnTabla: boolean = true;
  private paramsUrl: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['buttons', 'nAlbaran', 'nOferta', 'nombreCliente', 'fechaSalida', 'entregaPrevista', 'entregaReal', 'importeLineas', 'importeProductosOpcionales', 'costeTransporte', 'peso', 'transportista'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaAlbaranesAFacturar);
   }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      this.nFactura = params.nFactura;
      this.idFactura = params.idFactura;
      this.nOferta = params.nOferta;
      this.idCliente = params.idCliente;
      this.nombreCliente = params.nombreCliente;
    })
    this.obtenerListaAlbaranes();
  }

  ngAfterViewInit() {
    this.obtenerListaAlbaranes();
  }

  private obtenerListaAlbaranes() {
    this.apiService.obtenerAlbaranFacturable(this.nOferta).subscribe( dades => {
      this.listaAlbaranesAFacturar = dades.response;
      this.dataSource = new MatTableDataSource<any>(this.listaAlbaranesAFacturar);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  public enviaUnAlbaran(albaran: any) {
    albaran.idFactura = this.paramsUrl.idFactura;
    this.apiService.anadirFacturaLn(albaran).subscribe( datos => {
      console.log("Anadiendo albaran a factura: ", datos);
      let albaranAEliminar = {
        nAlbaran: albaran.nAlbaran,
        idFactura: albaran.idFactura
      }
      this.router.navigate(['lineas-factura'], { queryParams: { ...albaran, ...this.paramsUrl } });
    });
  }


  public volver() {
    this.router.navigate(['lineas-factura'], { queryParams: { ...this.paramsUrl } });
  }
}


