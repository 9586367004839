import { EstadoCompraMapper, EstadoMapper, EstadoMapperInverso, PropsSumablesDeAlbaranChapa } from "../types/comprasChapa"

export const estadoCompra: EstadoCompraMapper = {
    ENTREGADO: 'Entregado',
    PENDIENTE: 'Pendiente',
    PARCIAL: 'Parcial'
}

export const estadoMapper: EstadoMapper = {
    'Entregado': 2,
    'Pendiente': 4,
    'Parcial': 5
}

export const estadoMapperInverso: EstadoMapperInverso = {
    2: 'Entregado',
    4: 'Pendiente',
    5: 'Parcial'
}

export const valoresDatosAlbaran = {
    IdAlbara: 'IdAlbara',
    NumPecesEntregats: 'NumPecesEntregats' as PropsSumablesDeAlbaranChapa,
    PesLliurat: 'PesLliurat' as PropsSumablesDeAlbaranChapa,
    NumAlbara: 'NumAlbara',
    DataAlbara: 'DataAlbara'
}

export const tiposCabeceraReport = {
    COMPRA_CHAPA: 'COMPRA CHAPA',
    ALBARANES: 'ALBARANES'
}