<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

    <div class="layout-filtro flex flex-100 search">

        <div class="flex-6-responsive">
            <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>

        <app-autocomplete class="autocomplete flex-15" name="tecnico" ngDefaultControl
            [idOpcionSeleccionada]="filtroTecnicos.selectedTecnico"
            (cuandoSeSeleccioneOpcion)="filtroTecnicos.selectedTecnico = $event" label="Tecnico"
            [options]="tecnicosAutocomplete">
        </app-autocomplete>

        <mat-form-field appearance="outline" class="flex-10-responsive">
            <mat-label>Telefono</mat-label>
            <input matInput type="text" [(ngModel)]="filtroTecnicos.selectedTelefono" name="telefono">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-10-responsive">
            <mat-label>NIF</mat-label>
            <input matInput type="text" [(ngModel)]="filtroTecnicos.selectedNIF" name="NIF">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-10-responsive">
            <mat-label>Email</mat-label>
            <input matInput type="text" [(ngModel)]="filtroTecnicos.selectedEmail" name="Email">
        </mat-form-field>

        <app-autocomplete class="autocomplete flex-12" name="ciudad" ngDefaultControl
            [idOpcionSeleccionada]="filtroTecnicos.selectedCiudad"
            (cuandoSeSeleccioneOpcion)="filtroTecnicos.selectedCiudad = $event" label="Ciudad"
            [options]="ciudadesAutocomplete">
        </app-autocomplete>

        <app-autocomplete class="autocomplete flex-11" name="provincia" ngDefaultControl
            [idOpcionSeleccionada]="filtroTecnicos.selectedProvincia"
            (cuandoSeSeleccioneOpcion)="filtroTecnicos.selectedProvincia = $event" label="Provincia"
            [options]="provincias">
        </app-autocomplete>

        <app-autocomplete class="autocomplete flex-12" name="comercial" ngDefaultControl
            [idOpcionSeleccionada]="filtroTecnicos.selectedComercial"
            (cuandoSeSeleccioneOpcion)="filtroTecnicos.selectedComercial = $event" label="Comercial"
            [options]="comerciales">
        </app-autocomplete>

        <div class="flex-14-responsive">
            <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
        </div>

    </div>


    <div class="layout-tabla flex-layout flex-65-responsive">

        <h2 *ngIf="paramsUrl.redirecTo" class="h2titulo">Elegir un Técnico o dar de alta uno nuevo</h2>

        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="esTecnicoNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="element.esBorrable" type="button"
                        (click)="dialogEliminarTecnico(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="tecnico">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tecnico </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreTecnico}} </td>
            </ng-container>

            <ng-container matColumnDef="ciudad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.ciudad}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="movil">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Movil </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.movil}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnTecnico(row)"
                [ngClass]="{'row-highlighted': tecnico.idTecnico == row.idTecnico}">
            </tr>

        </table>

        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaTecnicos.length > 0}" [pageSizeOptions]="[20]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaTecnicos.length == 0">No hay Tecnicos</div>

    </div>


    <form #formulario="ngForm" (ngSubmit)="guardarUnTecnico(formulario)" class="form-derecho flex-35-responsive">

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Tecnico</mat-label>
            <input required matInput type="text" [(ngModel)]="tecnico.nombreTecnico" name="nombreTecnico">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-50-responsive">
            <mat-label>NIF</mat-label>
            <input matInput type="text" [(ngModel)]="tecnico.NIF" name="NIF">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Direccion</mat-label>
            <input required matInput type="text" [(ngModel)]="tecnico.direccion" name="direccion">
        </mat-form-field>

        <app-autocomplete class="auto100 flex-100" name="ciudad" [idOpcionSeleccionada]="tecnico.idCiudad"
            (cuandoSeSeleccioneOpcion)="tecnico.idCiudad = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" label="Ciudad"
            [options]="ciudadesAutocomplete">
        </app-autocomplete>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Telefono</mat-label>
            <input matInput type="text" [(ngModel)]="tecnico.telefono" name="telefono">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Movil</mat-label>
            <input matInput type="text" [(ngModel)]="tecnico.movil" name="movil">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Email</mat-label>
            <input matInput type="text" [(ngModel)]="tecnico.email" name="email">
        </mat-form-field>

        <app-autocomplete class="auto100 flex-100" name="comercial" [idOpcionSeleccionada]="tecnico.idComercial"
            (cuandoSeSeleccioneOpcion)="tecnico.idComercial = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('comercial')" label="Comercial"
            [options]="comerciales">
        </app-autocomplete>


        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Notas</mat-label>
            <input matInput type="textArea" [(ngModel)]="tecnico.notas" name="notas">
        </mat-form-field>

        <div class="flex-row-wrap flex-100">
            <!--            <span class="flex-50-responsive">
                <button *ngIf="tecnico.esBorrable" mat-raised-button type="button"
                    (onclick)="dialogEliminarTecnico(tecnico)">BORRAR</button>
            </span>
            -->

            <span class="flex-48-responsive">
                <button mat-raised-button type="submit">GUARDAR</button>
            </span>

            <span class="flex-48-responsive">
                <button *ngIf="paramsUrl.redirecTo" mat-raised-button type="button"
                    (click)="volverAOferta()">VOLVER</button>
            </span>

        </div>
    </form>

</form>