<div class="container flex-row-wrap">

    <div class="flex-layout flex-65-responsive">

        <h2 class="h2titulo">Lista Productos con Precios Especiales de la Oferta no. {{nOferta}}</h2>    <br>

        <table mat-table [dataSource]="productosPreciosEspeciales" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="ProductoPrecioEspecialNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <!--
                    <button  mat-icon-button type="button"
                        (click)="muestraOtroEnOferta(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    -->
                    <button mat-icon-button type="button" (click)="eliminarProductoPrecioEspecial($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="producto">
                <th mat-header-cell *matHeaderCellDef> Productos-Precios Especiales </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="unidadMedida">
                <th mat-header-cell *matHeaderCellDef class="center"> Unidad Medida </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.unidadMedida}} </td>
            </ng-container>

            <ng-container matColumnDef="precioUnitarioEspecial">
                <th mat-header-cell *matHeaderCellDef class="center"> Precio Unitario Especial </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.precioUnitarioEspecial | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="precioUnitarioEstandar">
                <th mat-header-cell *matHeaderCellDef class="center"> Precio Unitario Estandar </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.precioUnitarioEstandar | number:'0.2-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraProductoPrecioEspecial(row)" [ngClass]="{'row-highlighted': productoPrecioEspecial.idProducto == row.idProducto }"></tr>
        </table>

    </div>

    <form (ngSubmit)="guardarProductoPrecioEspecial()" class="flex-row-wrap flex-35-responsive">
        <app-autocomplete [disabled]="!!ofertaAsignada" class="autocomplete flex-100" name="producto" ngDefaultControl
            [idOpcionSeleccionada]="productoPrecioEspecial.idProducto"
            (cuandoSeSeleccioneOpcion)="productoPrecioEspecial.idProducto = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="Producto" [options]="productos">
        </app-autocomplete>
        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Unidad Medida</mat-label>
            <input matInput type="text" disabled  [value]="obtenerDatosProducto(productoPrecioEspecial.idProducto).unidadMedida || ''">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>Precio Unitario Especial</mat-label>
            <input [disabled]="ofertaAsignada" type="number" min="0" matInput [(ngModel)]="productoPrecioEspecial.precioUnitarioEspecial" name="precioUnitarioEspecial">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>Precio Unitario Estandar</mat-label>
            <input matInput type="number" disabled  [value]="obtenerDatosProducto(productoPrecioEspecial.idProducto).precioUnitarioEstandar">
        </mat-form-field>

        <div class="flex-row-wrap flex-100">
            <span class="flex-50">
                <button [disabled]="!productoPrecioEspecial.idProducto || !productoPrecioEspecial.precioUnitarioEspecial || !!ofertaAsignada"  mat-raised-button type="submit">Guardar</button>
            </span>
            <span class="flex-50">
                <button mat-raised-button type="button" (click)="volverAClientes()">Volver</button>
            </span>
        </div>

    </form>

</div>
