import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';

@Component({
  selector: 'app-portada-oferta',
  templateUrl: './portada-oferta.component.html',
  styleUrls: ['./portada-oferta.component.css']
})
export class PortadaOfertaComponent implements OnInit {

  @Input() nOferta: any = "";
  @Input() idCliente: any = "";
  @Input() idIdioma: number = 0;
  @Input() direccionDelegacion = "Polígono Industrial Can Prunera, C/. del Garraf, nº 16";
  @Input() cpDelegacion = "08759";
  @Input() poblacionDelegacion = "Vallirana";
  @Input() provinciaDelegacion = "Barcelona";

  public fechaOferta = "";
  public direccionObra = "";
  public poblacionObra = "";
  public provinciaObra = "";
  public codigoPostal = "";
  public idioma = 2;
  public delegacion = 1;
  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    let FiltroOfertas: FiltroOfertas = {
      selectedFechaDesde: undefined,
      selectedFechaHasta: undefined,
      selectedComercial: undefined,
      selectedTecnico: undefined,
      selectedCliente: this.idCliente,
      selectedProvincia: undefined,
      selectedDireccionObra: undefined,
      selectednOferta: this.nOferta,
      selectedChecked: undefined,
      selectedCerrado: undefined,
      selectedColaboracion: undefined,
      pdf: true,
    };
    this.apiService.obtenerOfertas(FiltroOfertas).subscribe(datosOferta => {
      let datosOfe = datosOferta.response[0];
      console.log("datosOfe: ", datosOfe);
      this.idioma = datosOfe.idIdioma;
      this.delegacion = datosOfe.idDelegacion;
      this.fechaOferta = datosOfe.fechaOferta;
      this.direccionObra = datosOfe.direccionObra;
      this.poblacionObra = datosOfe.ciudad;
      this.provinciaObra = datosOfe.provincia;
      this.codigoPostal = datosOfe.codigoPostal;
      })
  }

}
