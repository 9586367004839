import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { itemManteniment3 } from 'src/app/types/mantenimiento';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';

@Component({
  selector: 'app-dialog-mantenimiento3',
  templateUrl: './dialog-mantenimiento3.component.html',
  styleUrls: ['./dialog-mantenimiento3.component.css']
})
export class DialogMantenimiento3Component implements OnInit {

  opcionesProveedor = [
    {
      idProveedor: "idProveedor1",
      nombre: "Proveedor 1",
      direccion: "Direccion 1",
      telefono: "telefono 1"
    },
    {
      idProveedor: "idProveedor2",
      nombre: "Proveedor 2",
      direccion: "Direccion 2",
      telefono: "telefono 2"
    },
    {
      idProveedor: "idProveedor3",
      nombre: "Proveedor 3",
      direccion: "Direccion 3",
      telefono: "telefono 3"
    },
  ]
  ciudades : any= [];
  public ciudadesAutocomplete: IdLabelObject[] = [];

  public opcionProveedor: any = '';

  itemSelected: any = {
    idPrincipal: null,
    labelPrincipal: null,
    label2: null,
    label3: null,
    label4: null,
    editable: null
  };
  entradaZinc = 0;
  nuevo = true;

  productos: IdLabelObject[] = [];
  items: itemManteniment3[] = [];
  itemsTot: itemManteniment3[] = [];

  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2]];

  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<DialogMantenimiento3Component>,
    public dialog: MatDialog,
    public dialogVerificar: MatDialog,
    private router: Router,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {

    this.apiService.obtenerCiudades()
    .subscribe(data => {
      this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));
      this.ciudadesAutocomplete = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });

    this.displayedColumns = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2]];
    switch (this.data.tipo) {
      case 'comercial':
        this.obtenerComerciales();
        break;
      case 'stock':
        this.obtenerStockEnProduccion();
        this.obtenerProveedor();
        break;
      case 'delegacion':
        this.obtenerDelegacion();
        this.obtenerCiudades();
        break;
    }
  }

  private obtenerComerciales() {
    this.apiService.obtenerComerciales().subscribe(dades => {
      this.items = dades.response.map(comercial => ({ idPrincipal: comercial.idComercial, labelPrincipal: comercial.nombre, label2: comercial.material, label3: comercial.colocacion, editable: comercial.estaAssignada }));
      this.itemsTot = this.items;
    });
  }
  private obtenerCiudades() {
    this.apiService.obtenerCiudades().subscribe(dades => {
      this.ciudades = dades.response;
    });
  }

  private obtenerStockEnProduccion() {
    this.apiService.obtenerStockWIP().subscribe(dades => {
      this.items = dades.response.map(stock => ({ idPrincipal: stock.idProducto, labelPrincipal: stock.nombreProducto, label2: stock.materialComprado, label3: stock.stockZincado, editable: false }));
      this.itemsTot = this.items;
    })
    this.apiService.obtenerProductosStock().subscribe(dades => {
      this.productos = dades.response.map(stock => ({ id: stock.idProducto.toString(), label: stock.nombreProducto }));
    })
  }
  private obtenerProveedor(){
    this.apiService.obtenerProveedor().subscribe(data=>{
      console.log(data.response);
      this.opcionesProveedor= data.response;
    })
  }

  private obtenerDelegacion() {
    this.apiService.obtenerDelegacion().subscribe(dades => {
      this.items = dades.response.map(delegacion => ({ idPrincipal: delegacion.idDelegacion, labelPrincipal: delegacion.nombre, label2: delegacion.direccion, label3: delegacion.codigoPostal, label4: delegacion.idCiudad, editable: delegacion.estaAssignada }));
      this.itemsTot = this.items;
    });
  }



  dialogEliminarUnCampo(event: any, item: any) {
    this.itemSelected = item;
    this.openDialog("ELIMINAR", "¿Quiere eliminar " + item.labelPrincipal + "?", true, "eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem() {
    switch (this.data.tipo) {
      case 'comercial':
        this.eliminarComercial(this.itemSelected);
        break;
      case 'stock':
        this.eliminarStockEnProduccion(this.itemSelected)
        break;
      case 'delegacion':
        this.eliminarDelegacion(this.itemSelected);
        break;
    }

  }
  public eliminarComercial(comercial: any) {
    console.log("borrar situacion", comercial.idPrincipal);
    this.apiService.borrarComercial(comercial.idPrincipal).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
  }
  public eliminarStockEnProduccion(stockWIP: any) {
    console.log("borrar situacion", stockWIP.idPrincipal);
    this.apiService.eliminarStock(stockWIP.idPrincipal).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
  }
  public eliminarDelegacion(delegacion: any) {
    console.log("borrar situacion", delegacion.idPrincipal);
    this.apiService.borrarDelegacion(delegacion.idPrincipal).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
  }




  public guardarItem(form: NgForm) {
    console.log(this.itemSelected);
    if (this.itemSelected.labelPrincipal == null && this.itemSelected.idPrincipal == null) {
      var msg = "No se puede guardar, falta el campo Producto"
      if (this.data.tipo == "comercial") msg = "No se puede guardar, falta el campo Comercial";
      this.openDialog("AVISO", msg, true, "");
      return;
    }
    switch (this.data.tipo) {
      case 'comercial':
        this.guardarComercial();
        break;
      case 'stock':
        this.guardarStock()
        break;
      case 'delegacion':
        this.guardarDelegacion();
        break;
    }
  }

  private guardarComercial() {
    console.log("guardar:", this.itemSelected);
    if (this.itemSelected.idPrincipal) {
      this.apiService.modificarComercial(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      })

    }
    else {
      console.log("guardar", this.itemSelected);
      this.apiService.anadirComercial(this.itemSelected).subscribe(data => {
        this.itemSelected.idPrincipal = data.response;
        console.log(data);
        this.modificarTabla();
      });
    }
  }

  private guardarStock() {
    console.log("guardar:", this.itemSelected);
    if (this.items.find(x => x.idPrincipal === parseInt(this.itemSelected.idPrincipal,10))) {
      this.itemSelected.label2 = this.itemSelected.label2 - this.entradaZinc;
      this.itemSelected.label3 = this.itemSelected.label3 + this.entradaZinc;
      this.apiService.modificarStock(this.itemSelected).subscribe(data => {
        this.modificarTabla();
        this.entradaZinc = 0;
      })
    }
    else {
      this.itemSelected.label2 = this.itemSelected.label2 - this.entradaZinc;
      this.itemSelected.label3 = this.itemSelected.label3 + this.entradaZinc;
      this.apiService.anadirStock(this.itemSelected).subscribe(data => {
        this.itemSelected.idPrincipal = data.response;
        this.modificarTabla();
      });
    }
  }

  private guardarDelegacion() {
    console.log("guardar:", this.itemSelected);
    if (this.itemSelected.idPrincipal) {
      this.apiService.modificarDelegacion(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      })

    }
    else {
      console.log("guardar", this.itemSelected);
      this.apiService.anadirDelegacion(this.itemSelected).subscribe(data => {
        this.itemSelected.idPrincipal = data.response;
        this.modificarTabla();
      });
    }
  }


  resetItem() {
    this.itemSelected.idPrincipal = null;
    this.itemSelected.editable = undefined;
    this.itemSelected.labelPrincipal = "";
    this.itemSelected.label3 = "";
    this.itemSelected.label2 = "";
    this.entradaZinc = 0;
    this.nuevo = true;
    this.goTo();
  }

  public envioDatos() {
    console.log("envia datos: ", this.data);
  }

  public modificarTabla() {

    switch (this.data.tipo) {
      case 'comercial':
        this.modificarTablaComerciales();
        break;
      case 'stock':
        this.modificarTablaStock()
        break;
      case 'delegacion':
        this.modificarTablaDelegacion();
        break;
    }
  }

  private modificarTablaComerciales() {
    this.obtenerComerciales();
  }
  private modificarTablaStock() {
    this.obtenerStockEnProduccion();
  }
  private modificarTablaDelegacion() {
    this.obtenerDelegacion();
  }

  cuandoSeCliqueEnMantenimiento(label: string) {
    console.log(label);
  }

  muestraUnItem(item: any) {
    console.log(item);
    this.nuevo = false;
    this.itemSelected = { ...item };
    console.log(this.itemSelected);
  }

  goTo() {
    if (this.itemSelected.idPrincipal == null) {
      if (this.itemSelected.labelPrincipal != null) {
        this.items = this.itemsTot.filter(item => item.labelPrincipal.toLowerCase().includes(this.itemSelected.labelPrincipal.toLowerCase()));
        if (this.items.length > 0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }

  ImprimirAlbaranZincador() {
    console.log(this.opcionProveedor);
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-albaran-zincado?nProveedor=' + this.opcionProveedor;
    link.click();
    link.remove();

  }

}
