import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from 'src/app/services/api.service';
import { DialogMantenimientoComponent } from '../../../dialogs/dialog-mantenimiento/dialog-mantenimiento.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { mantenimiento2Kits, mantenimiento2ProductoOpc } from "../../../services/llamadasMantenimiento";
import { NgForm } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-kits-un-cliente',
  templateUrl: './kits-un-cliente.component.html',
  styleUrls: ['./kits-un-cliente.component.css']
})

export class KitsUnClienteComponent implements OnInit {

  @Input() idClienteAsignado: string | undefined = undefined;
  @Input() oferta: any = 0;
  @Input() kitsUnaOferta: any[] = [];
  @Output() clickEnVolverAPantalla = new EventEmitter();
  @Output() cuandoKitsCambien = new EventEmitter();
  @Output() dataOferta = new EventEmitter();

  productos: IdLabelObject[] = [];
  infoProductos: any[] = [];
  situaciones: IdLabelObject[] = []
  esKitNuevo: boolean = false;
  idComanda: number = 0;
  kitABorrar: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public dataSource!: MatTableDataSource<any>;

  public kitUnaOferta: any = {
    nOferta: "",
    idProducto: "",
    nombreProducto: "",
    idSituacion: "",
    idSituacionVieja: "",
    cantidad: "",
    precioUnitario: "",
    pesoUnitario: "",
    interaje: "",
    luz: "",
    cargaRepartidaAP: "",
    cargaRepartidaAV: "",
    cargaPuntualAP: "",
    cargaPuntualAV: "",
    distanciaApoyo: "",
    imprimirCalculos: "",
    calculos: ""
  }

  displayedColumns: string[] = ['buttons', 'producto', 'situacion', 'cantidad', 'precioUnitario', 'pesoUnitario' /*, 'interaje', 'luz', 'cargaRepartidaAP', 'cargaRepartidaAV', 'cargaPuntualAV', 'cargaPuntualAV', 'distanciaApoyo'*/, 'imprimirCalculos', 'calculos'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private apiService: ApiService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource(this.kitsUnaOferta);
   }

  ngOnInit(): void {
    this.apiService.obtenerProductosKit(this.oferta.nOferta).subscribe(data => {
      this.infoProductos = data.response.map(producto => ({ ...producto, idProducto: producto.idProducto.toString() }));
      this.productos = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
    });
    this.obtenerSituaciones();
  }

  ngAfterViewInit(): void {
    console.log(this.kitsUnaOferta);
//    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<any>(this.kitsUnaOferta);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    console.log(this.kitsUnaOferta);
//    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<any>(this.kitsUnaOferta);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  public volverATablaUnaOferta() {
    this.clickEnVolverAPantalla.emit();
  }

  public esKitNuevoEnOferta() {
    this.esKitNuevo = true;
    this.resetKitUnCliente();
  }

  public dialogEliminarKitEnOferta(kitUnaOferta: any) {
    this.kitABorrar = kitUnaOferta;
    this.openDialog("ELIMINAR KIT", "¿Quiere eliminar el kit " + kitUnaOferta.nombreProducto + " de la oferta?", true, "eliminar");

  }

  public guardarKit(formulario: NgForm) {
    if (!formulario.valid) { return };
    var msg = "";
    this.kitUnaOferta.precioUnitario = this.obtenerDatosProducto(this.kitUnaOferta.idProducto).precioUnitario;
    this.kitUnaOferta.pesoUnitario = this.obtenerDatosProducto(this.kitUnaOferta.idProducto).pesoUnitario;
/*    if (!this.miraNegativos(this.kitUnaOferta.cantidad)) {
      msg = 'El campo cantidad debe ser mayor que 0';
    }
    else*/ if (!this.miraNegativos(this.kitUnaOferta.interaje)) {
      msg = 'El campo intereje debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.luz)) {
      msg = 'El campo luz debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.cargaRepartidaAP)) {
      msg = 'El campo carga Repartida Acciones Permanentes debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.cargaRepartidaAV)) {
      msg = 'El campo carga Repartida Acciones Variables debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.cargaPuntualAP)) {
      msg = 'El campo carga Puntual AccionesPe rmanentes debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.cargaPuntualAV)) {
      msg = 'El campo carga Puntual AccionesVa riables debe ser mayor que 0';
    }
    else if (!this.miraNegativos(this.kitUnaOferta.distanciaApoyo)) {
      msg = 'El campo distancia Apoyo debe ser mayor que 0';
    }

    if (msg != '') {
      this.openDialog("ALERTA", msg, false, '');
      return;
    }

    
    let kit = this.kitUnaOferta;
    if(this.kitUnaOferta.interaje == null) kit.interaje =0;
    if(this.kitUnaOferta.luz == null)kit.luz = 0; 
    if(this.kitUnaOferta.cargaRepartidaAP == null)kit.cargaRepartidaAP= 0;
    if(this.kitUnaOferta.cargaRepartidaAV == null)kit.cargaRepartidaAV= 0;
    if(this.kitUnaOferta.cargaPuntualAP == null)kit.cargaPuntualAP=0;
    if(this.kitUnaOferta.cargaPuntualAV == null)kit.cargaPuntualAV=0;
    if(this.kitUnaOferta.distanciaApoyo == null)kit.distanciaApoyo=0;
    kit.pdf = 0;
    
    if (this.esKitNuevo) {
      if (this.comprobarSiYaExiste(this.kitUnaOferta.idProducto, this.kitUnaOferta.idSituacion) && this.esKitNuevo) {
        this.openDialog("PRODUCTO EXISTE", "Este producto con esta situacción ya existe en esta oferta", false, '')
        //this.resetKitUnCliente();
      } 
      else {
        this.kitUnaOferta.nOferta = this.oferta.nOferta;
        this.kitUnaOferta.idSituacionVieja = this.kitUnaOferta.idSituacion;

        let correct = true;
        let dialogRef : any;
        if(kit.interaje>0 && kit.luz >0 ){
          this.apiService.calculo(kit).subscribe(data =>{
            if(data.response){
                dialogRef = this.dialog.open(DialogPopUpComponent, {
                data: { title: "Calculo Satisfactorio", msg: "El calculo es correcto",cancelBtn: false }
              });
              this.kitUnaOferta.calculos = true;
            }
            else {
              dialogRef = this.dialog.open(DialogPopUpComponent, {
                data: { title: "Calculo NO Satisfactorio", msg: "El calculo no ha sido correcto. Quieres modificar los valores igualmente?",cancelBtn: true }
              });
              correct = false;
            }
          
            dialogRef.afterClosed().subscribe((result: any) => {
              console.log("Dialog result:", result);
              if(!correct && result)this.kitUnaOferta.calculos = false;
              correct =  result;
              
              if(correct){      
                console.log("añadir");
                this.apiService.anadirKitsUnaComanda(this.kitUnaOferta).subscribe(dades => {
                  this.esKitNuevo = false;
                  this.cuandoKitsCambien.emit();
                  //this  .resetKitUnCliente();
                  this.recalcularPesoPrecio()
                });
              }
            })
          })
        }
        else{
          this.apiService.anadirKitsUnaComanda(this.kitUnaOferta).subscribe(dades => {
            console.log("ENTRO: ", dades)
            this.esKitNuevo = false;
            this.cuandoKitsCambien.emit();
            //this  .resetKitUnCliente();
            this.recalcularPesoPrecio();
          });
        }
      }
    }
    else {
      let correct = true;
      let dialogRef : any;
      if(kit.interaje>0 && kit.luz >0 ){
        this.apiService.calculo(kit).subscribe(data =>{
          if(data.response){
              dialogRef = this.dialog.open(DialogPopUpComponent, {
              data: { title: "Calculo Satisfactorio", msg: "El calculo es correcto",cancelBtn: false }
            });
            this.kitUnaOferta.calculos = true;
          }
          else {
            dialogRef = this.dialog.open(DialogPopUpComponent, {
              data: { title: "Calculo NO Satisfactorio", msg: "El calculo no ha sido correcto. Quieres modificar los valores igualmente?",cancelBtn: true }
            });
            correct = false;
          }
        
          dialogRef.afterClosed().subscribe((result: any) => {
            console.log("Dialog result:", result);
            if(!correct && result)this.kitUnaOferta.calculos = false;
            correct =  result;
            
            if(correct){
              console.log("modificar");
              this.apiService.modificarKitUnaComanda(this.kitUnaOferta).subscribe(dades => {
                this.esKitNuevo = false;
                this.cuandoKitsCambien.emit();
                //this.resetKitUnCliente();
                this.recalcularPesoPrecio()
              });
            }
          })
        })  
      }
      else {
        this.apiService.modificarKitUnaComanda(this.kitUnaOferta).subscribe(dades => {
          this.esKitNuevo = false;
          this.cuandoKitsCambien.emit();
          //this.resetKitUnCliente();
          this.recalcularPesoPrecio()
        });
      }
    }    
  }

  private comprobarSiYaExiste(idProducto: string, idSituacion: string) {
    let arrayComprobado;
    arrayComprobado = this.kitsUnaOferta.find(item => {
      if (item.idProducto == idProducto && item.idSituacion == idSituacion) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    let dialogRef: any;
    switch (label) {
      case 'producto':
        dialogRef = mantenimiento2Kits(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerProductosKit(this.oferta.nOferta).subscribe(data => {
            this.infoProductos = data.response.map(producto => ({ ...producto, idProducto: producto.idProducto.toString() }));
            this.productos = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
          });
        });
        break;
    }
  }

  public obtenerSituaciones() {
    this.apiService.obtenerSituacionComanda(this.oferta.nOferta).subscribe(data => {
      this.situaciones = data.response.map(situacion => ({ id: situacion.idSituacion.toString(), label: situacion.situacion }));
      console.log(this.situaciones);
      this.idComanda = this.oferta.nOferta;
    })
  }

  openDialogSituaciones() {

    const dialogRef = this.dialog.open(DialogMantenimientoComponent, {
      data: { title: "Mantenimiento Situaciones", msg: "Añade una situacion", mantenimiento: "situacion", parametros: this.idComanda }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.obtenerSituaciones();
      this.cuandoKitsCambien.emit();
    })
  }

  public muestraKitEnOferta(element: any) {
    this.esKitNuevo = false;
    this.kitUnaOferta = { ...element };
    this.kitUnaOferta.idSituacionVieja = this.kitUnaOferta.idSituacion;
    console.log("Cuando muestra: ", this.kitUnaOferta);
    this.kitUnaOferta.idProducto = this.kitUnaOferta.idProducto.toString();
    //  this.kitUnaOferta.idSituacion = this.kitUnaOferta.idSituacion.toString();
    if (this.kitUnaOferta.imprimirCalculos === "SI") {
      this.kitUnaOferta.imprimirCalculos = true;
    } else {
      this.kitUnaOferta.imprimirCalculos = false;
    }
    if (this.kitUnaOferta.calculos === "SI") {
      this.kitUnaOferta.calculos = true;
    } else {
      this.kitUnaOferta.calculos = false;
    }
  }

  public obtenerDatosProducto(idProducto: string) {
    var body = this.infoProductos.find(producto => producto.idProducto === idProducto) || {};
    return body;
  }

  private miraNegativos(numero: number) {
    if (numero && numero <= 0) {
      return false
    } else {
      return true
    }
  }

  public comprobarCalculos() {
    console.log("Comprobando Cálculos");
    let kit = this.kitUnaOferta;
    kit.pdf = 0;
    this.apiService.calculo(kit).subscribe(data => {
      console.log(data.response);
    })
  }

  public imprimirCalculos() {

    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-calculos?nOferta=' + this.oferta.nOferta;
    link.click();
    link.remove();

  /*  console.log("Imprimir Cálculos");
    let kit = this.kitUnaOferta;
    kit.pdf = 1;
    this.apiService.calculo(kit).subscribe(data => {
      console.log(data.response);
    })*/
  }

  private recalcularPesoPrecio() {
    this.apiService.obtenerKitsUnaComanda(this.oferta.nOferta).subscribe(data => {
      console.log("data response: ", data.response);
      var pesoT = 0;
      var precioT = 0;
      var kits = data.response;
      for (var i = 0; i < kits.length; i = i + 1) {
        console.log(kits[i]);
        var qty = kits[i]['cantidad'];
        pesoT = pesoT + (kits[i]['pesoUnitario'] * qty);
        precioT = precioT + (kits[i]['precioUnitario'] * qty);
      }

      var campo = { nOferta: this.oferta.nOferta, precioT, pesoT };
      this.apiService.modificarPesoPrecio(campo).subscribe();
    });
  }

  private resetKitUnCliente() {
    this.kitUnaOferta = {
      idProducto: undefined,
      nombreProducto: undefined,
      idSituacion: undefined,
      nombreSituacion: undefined,
      cantidad: undefined,
      precioUnitario: undefined,
      peso: undefined,
      interaje: undefined,
      luz: undefined,
      cargaRepartidaAP: undefined,
      cargaRepartidaAV: undefined,
      cargaPuntualAP: undefined,
      cargaPuntualAV: undefined,
      distanciaApoyo: undefined,
      imprimirCalculos: false,
      calculos: false
    }
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarKitEnOferta();
    });
  }
  eliminarKitEnOferta() {
    console.log("Eliminado");
    this.apiService.borrarKitsUnaComanda(this.kitABorrar).subscribe(dades => {
      this.cuandoKitsCambien.emit();
      this.resetKitUnCliente();
      this.esKitNuevo = false;
      this.recalcularPesoPrecio();
    })
  }

}
