import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { mantenimiento2Ciudad } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { itemManteniment3 } from 'src/app/types/mantenimiento';
import { DialogMantenimiento2Component } from '../dialog-mantenimiento2/dialog-mantenimiento2.component';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';


@Component({
  selector: 'app-dialog-mantenimiento4',
  templateUrl: './dialog-mantenimiento4.component.html',
  styleUrls: ['./dialog-mantenimiento4.component.css']
})
export class DialogMantenimiento4Component implements OnInit {


  itemSelected: any = {
    idPrincipal: null,
    labelPrincipal: null,
    label2: null,
    label3: null,
    idlabel4: null,
    label4: null,
    editable: null
  };
  
  nuevo = true;
  ciudad : any[] = [];
  items: any[] = [];
  itemsTot: any[] = [];

  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2], this.data.cols[3]];

  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<DialogMantenimiento2Component>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog, 
    private router: Router,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  

  ngOnInit(): void {
    this.displayedColumns = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2], this.data.cols[3]];
    this.obtenerProveedores();
    this.obtenerCiudades();
  }

  obtenerProveedores(){
    this.apiService.obtenerProveedor().subscribe(dades => {
      this.items = dades.response.map(proveedor => ({ idPrincipal: proveedor.idProveedor, labelPrincipal: proveedor.nombre, label2: proveedor.direccion, idlabel3: proveedor.idCiudad, label3: proveedor.ciudad, label4: proveedor.telefono, editable: 0 }));
      this.itemsTot = this.items;
    });
  }
  obtenerCiudades(){
    this.apiService.obtenerCiudades().subscribe(dades => {
      this.ciudad = dades.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
      console.log(this.ciudad);
    });
  }

  dialogEliminarUnCampo(event: any, item: any) {
    this.itemSelected = item;
    this.openDialog("ELIMINAR", "¿Quiere eliminar " + item.labelPrincipal + "?", true, "eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem() {
    this.apiService.borrarProveedor(this.itemSelected.idPrincipal).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
  }

  guardarItem() {
    if (this.itemSelected.labelPrincipal == null && this.itemSelected.idPrincipal == null) {
      var msg = "No se puede guardar, falta el campo Producto"
      if (this.data.tipo == "comercial") msg = "No se puede guardar, falta el campo Comercial";
      this.openDialog("AVISO", msg, true, "");
      return;
    }
    if (this.itemSelected.idPrincipal) {
      this.apiService.modificarProveedor(this.itemSelected).subscribe(data => {
        this.modificarTabla();
      })

    }
    else {
      console.log("guardar", this.itemSelected);
      this.apiService.anadirProveedor(this.itemSelected).subscribe(data => {
        this.itemSelected.idPrincipal = data.response;
        console.log(data);
        this.modificarTabla();
      });
    }
  }



  protected modificarTabla(){
    this.obtenerProveedores();
  }

  resetItem(){
    this.itemSelected.idPrincipal = null;
    this.itemSelected.editable = undefined;
    this.itemSelected.labelPrincipal = "";
    this.itemSelected.label3 = "";
    this.itemSelected.idlabel3 = null;
    this.itemSelected.label2 = "";
    this.itemSelected.label4 = "";
    this.nuevo = true;
    this.goTo();
  }

  public goTo() {
    if (this.itemSelected.idPrincipal == null) {
      if (this.itemSelected.labelPrincipal != null) {
        this.items = this.itemsTot.filter(item => item.labelPrincipal.toLowerCase().includes(this.itemSelected.labelPrincipal.toLowerCase()));
        if (this.items.length > 0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }

  muestraUnItem(item: any) {
    console.log(item);
    this.nuevo = false;
    this.itemSelected = { ...item };
    console.log(this.itemSelected);
  }
  cuandoSeCliqueEnMantenimiento(label: any){
    let dialogRef = mantenimiento2Ciudad(this.dialogMantenimiento);
    dialogRef.afterClosed().subscribe( (data:any) => {
      this.obtenerCiudades();
    });
  }

}
