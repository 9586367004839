<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

    <div class="layout-filtro flex-row-wrap flex-100 search">

        <div class="flex-6-responsive">
            <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>

        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Inicio
            </mat-label>
            <input matInput [(ngModel)]="filtroFacturas.selectedFechaInicio" name="fechaInicio" disabled
                [matDatepicker]="picker">
            <button class="button-date" *ngIf="filtroFacturas.selectedFechaInicio" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroFacturas.selectedFechaInicio=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Fin
            </mat-label>
            <input matInput [(ngModel)]="filtroFacturas.selectedFechaFin" disabled
                [min]="filtroFacturas.selectedFechaInicio" name="fechaFin" [matDatepicker]="picker2">
            <button class="button-date" *ngIf="filtroFacturas.selectedFechaFin" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroFacturas.selectedFechaFin=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-10-responsive">
            <mat-label>Nº Factura</mat-label>
            <input matInput type="text" [(ngModel)]="filtroFacturas.selectednFactura" name="telefono">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-10-responsive">
            <mat-label>Nº Oferta</mat-label>
            <input matInput type="number" [(ngModel)]="filtroFacturas.selectednOferta" name="telefono">
        </mat-form-field>

        <app-autocomplete class="autocomplete flex-25" name="cliente" ngDefaultControl
            [idOpcionSeleccionada]="filtroFacturas.selectedidCliente"
            (cuandoSeSeleccioneOpcion)="filtroFacturas.selectedidCliente = $event" label="Cliente"
            [options]="clientesAutocomplete">
        </app-autocomplete>

        <app-autocomplete class="autocomplete flex-25" name="comercial" ngDefaultControl
            [idOpcionSeleccionada]="filtroFacturas.selectedidComercial"
            (cuandoSeSeleccioneOpcion)="filtroFacturas.selectedidComercial = $event" label="Comercial"
            [options]="comercialesAutocomplete">
        </app-autocomplete>
        <!--
        <div class="button-checkbox" appearance="outline" class="flex-10-responsive">
            <mat-checkbox [(ngModel)]="filtroFacturas.selectedPagado" name="pagado">¿Pagado?</mat-checkbox>
        </div>

        <div class="button-checkbox" appearance="outline" class="flex-10-responsive">
            <mat-checkbox [(ngModel)]="filtroFacturas.selectedAdelanto" name="adelanto">¿Adelanto?</mat-checkbox>
        </div>

        <div class="button-checkbox" appearance="outline" class="flex-10-responsive">
            <mat-checkbox [(ngModel)]="filtroFacturas.selectedAnulada" name="anulada">¿Anulada?</mat-checkbox>
        </div>
    -->

        <mat-form-field class="select" appearance="outline">
            <mat-label>¿Pagado?</mat-label>
            <mat-select [(ngModel)]="filtroFacturas.selectedPagado" name="pagado">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="select" appearance="outline">
            <mat-label>¿Adelanto?</mat-label>
            <mat-select [(ngModel)]="filtroFacturas.selectedAdelanto" name="adelanto">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="select" appearance="outline">
            <mat-label>¿Anulada?</mat-label>
            <mat-select [(ngModel)]="filtroFacturas.selectedAnulada" name="anulada">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex-14-responsive">
            <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
        </div>

    </div>

    <div class="layout-tabla flex-layout flex-70-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="anadirFactura()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                </td>
            </ng-container>

            <ng-container matColumnDef="nFactura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Factura </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nFactura}} </td>
            </ng-container>

            <ng-container matColumnDef="nOferta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Oferta </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nOferta}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Cliente </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreCliente}} </td>
            </ng-container>

            <ng-container matColumnDef="fechaFactura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Factura </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.fechaFactura | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="adelanto">
                <th mat-header-cell class="AAA" *matHeaderCellDef mat-sort-header> ¿Adelanto? </th>
                <td mat-cell *matCellDef="let element" class="center"> {{element.adelanto}} </td>
            </ng-container>

            <ng-container matColumnDef="anulada">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ¿Anulada? </th>
                <td mat-cell *matCellDef="let element" class="center"> {{element.anulada}} </td>
            </ng-container>

            <ng-container matColumnDef="pagoPrevisto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pago Previsto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.pagoPrevisto | date: 'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef="pagoReal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pago Real </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.pagoReal | date: 'dd/MM/yyyy'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnaFactura(row)"
                [ngClass]="{'row-highlighted': factura.nFactura == row.nFactura}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaFacturas.length > 0}" [pageSizeOptions]="[20]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaFacturas.length == 0">No hay facturas</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="modificarFactura(formulario)"
        class="form-derecho row-gap-10 flex-30-responsive">
        <!--
        <span class="flex-33-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Facturas Clientes</button>
        </span>
        <span class="flex-33-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Fras.Ptes.Pago</button>
        </span>
        <span class="flex-33-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Registro Factutas</button>
        </span>
-->

        <mat-form-field class="flex-46-responsive" appearance="outline">
            <mat-label> Nº Factura</mat-label>
            <input disabled [(ngModel)]="factura.nFactura" name="nFactura" matInput type="number">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-46-responsive">
            <mat-label>
                Fecha Factura
            </mat-label>
            <input required matInput [max]="factura.pagoPrevisto" [(ngModel)]="factura.fechaFactura" name="fechaFactura"
                [matDatepicker]="picker3" disabled>
            <button [disabled]="factura.pagoReal" class="button-date" *ngIf="factura.fechaFactura" matSuffix
                mat-icon-button aria-label="Clear" (click)="factura.fechaFactura=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle [disabled]="factura.pagoReal" matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-46-responsive" appearance="outline">
            <mat-label>Nº Oferta</mat-label>
            <input disabled [(ngModel)]="factura.nOferta" name="nOferta" matInput type="number">
        </mat-form-field>

        <mat-checkbox disabled class="checkbox0 flex-46" [ngModel]="factura.adelanto" name="adelanto">¿Adelanto?
        </mat-checkbox>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Cliente</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.nombreCliente" name="nombreCliente" matInput
                type="text">
        </mat-form-field>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Dirección Factura</mat-label>
            <textarea [disabled]="factura.pagoReal" maxlength="70" rows="2" required
                [(ngModel)]="factura.direccionFactura" name="direccionFactura" matInput type="text"></textarea>
            <mat-hint>Para saltar de línea utilizar "enter"</mat-hint>
        </mat-form-field>

        <app-autocomplete class="autocomplete flex-70-responsive" name="ciudad" ngDefaultControl
            [idOpcionSeleccionada]="factura.idCiudad" [disabled]="factura.pagoReal"
            (cuandoSeSeleccioneOpcion)="factura.idCiudad = $event" label="Ciudad" [options]="ciudadesAutocomplete">
        </app-autocomplete>

        <mat-form-field class="flex-20-responsive" appearance="outline">
            <mat-label>Código Postal</mat-label>
            <input [disabled]="factura.pagoReal" required [(ngModel)]="factura.codigoPostal" name="cogigoPostal"
                matInput type="text">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>N.I.F.</mat-label>
            <input [disabled]="factura.pagoReal" required [(ngModel)]="factura.nif" name="nif" matInput type="text">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.telefono" name="telefono" matInput type="text">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Comercial</mat-label>
            <input disabled [(ngModel)]="factura.nombreComercial" name="nombreComercial" matInput type="text">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Qty Comisiones</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.qtyComisiones" name="qtyComisiones" matInput
                type="number">
        </mat-form-field>

        <mat-checkbox [disabled]="factura.pagoReal" *ngIf="factura.nOferta" class="checkbox0 flex-35" disalbles
            [ngModel]="factura.comercialPagado" name="comercialPagado">¿Está pagado?
        </mat-checkbox>

        <mat-form-field *ngIf="factura.nOferta" class="flex-55-responsive" appearance="outline">
            <mat-label>
                Fecha Pago Comercial
            </mat-label>
            <input disabled matInput [(ngModel)]="factura.fechaPagoComercial" name="fechaPagoComercial"
                [matDatepicker]="picker5">
            <button [disabled]="factura.pagoReal" class="button-date" *ngIf="factura.fechaPagoComercial" matSuffix
                mat-icon-button aria-label="Clear" (click)="factura.fechaPagoComercial=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle [disabled]="factura.pagoReal" matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Nº Cuenta</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.cuenta" name="cuenta" matInput type="text">
        </mat-form-field>
        <!--
        <app-autocomplete class="autocomplete flex-50-responsive" name="condicionesPago"
            ngDefaultControl [idOpcionSeleccionada]="factura.condicionesPago" [disabled]="factura.pagoReal"
            (cuandoSeSeleccioneOpcion)="factura.condicionesPago = $event" label="Condiciones de Pago" [options]="condicionesPagoAutocomplete">
        </app-autocomplete>
    -->
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Condiciones de Pago</mat-label>
            <input disabled [(ngModel)]="factura.condicionesPago" name="condicionesPago" matInput>
        </mat-form-field>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Forma de Pago</mat-label>
            <input [disabled]="factura.pagoReal" matInput [(ngModel)]="factura.formaPago" name="formaPago">
        </mat-form-field>




        <mat-form-field *ngIf="!factura.nOferta" class="descripcion " appearance="outline">
            <mat-label>Descripcion</mat-label>
            <textarea id="myTextarea" style="font-size: 18px;" matInput [(ngModel)]="factura.descripcion"
                name="descripcion" cols="40" rows="33"></textarea>
        </mat-form-field>
        <!--
        <mat-form-field *ngIf="factura.nOferta" class="flex-50-responsive" appearance="outline">
            <mat-label></mat-label>
            <textarea disabled matInput [(ngModel)]="factura.conceptoOtrosGastos" name="conceptoOtrosGastos" rows="3"></textarea>
        </mat-form-field>
-->
        <mat-form-field *ngIf="factura.nOferta" style="width: 548px;" appearance="outline">
            <mat-label>Notas</mat-label>
            <textarea [disabled]="factura.pagoReal" matInput [(ngModel)]="factura.notas" maxlength="200" name="notas"
                rows="3"></textarea>
            <mat-hint>Para saltar de línea utilizar "enter"</mat-hint>
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Líneas</mat-label>
            <input disabled [(ngModel)]="factura.importeLineas" name="importeLineas" matInput type="number">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Productos Opcionales</mat-label>
            <input disabled [(ngModel)]="factura.importeProductosOpcionales" name="importeProductosOpcionales" matInput
                type="number">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Trasporte</mat-label>
            <input disabled [(ngModel)]="factura.transporte" name="transporte" matInput type="number">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label></mat-label>
            <input disabled name="otrosGastos" matInput type="number">
        </mat-form-field>

        <mat-form-field *ngIf="factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Factura</mat-label>
            <input disabled [(ngModel)]="factura.importeFactura" name="importeFactura" matInput type="number">
        </mat-form-field>

        <mat-form-field *ngIf="!factura.nOferta" class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Factura</mat-label>
            <input [(ngModel)]="factura.importeFactura" name="importeFactura" matInput type="number">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Dto. Pronto Pago</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.descuentoPP" name="descuentoPP" matInput
                type="number">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> IVA %</mat-label>
            <input [disabled]="factura.pagoReal" [(ngModel)]="factura.iva" name="iva" matInput type="number">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Importe IVA</mat-label>
            <input disabled [(ngModel)]="factura.importeIva" name="importeIva" matInput type="number">
        </mat-form-field>

        <mat-checkbox [disabled]="factura.pagoReal" class="checkbox0 flex-45" disalbles [ngModel]="factura.anulada"
            name="anulada">¿Anulada?
        </mat-checkbox>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Importe Factura con IVA</mat-label>
            <input disabled [(ngModel)]="factura.importeFacturaConIva" name="importeFacturaConIva" matInput
                type="number">
        </mat-form-field>

        <mat-checkbox disabled class="checkbox0 flex-45" disalbles [ngModel]="factura.pagoReal" name="pagado">
            ¿Factura pagada?
        </mat-checkbox>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label>
                Fecha Pago Real
            </mat-label>
            <input disabled matInput [(ngModel)]="factura.pagoReal" name="pagoReal" [matDatepicker]="picker6">
            <button mat-button *ngIf="factura.pagoReal" matSuffix mat-icon-button aria-label="Clear"
                (click)="factura.pagoReal=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-51-responsive">
            <mat-label>
                Fecha Pago Prevista
            </mat-label>
            <input disabled matInput [min]="factura.fechaFactura" [(ngModel)]="factura.pagoPrevisto" name="pagoPrevisto"
                [matDatepicker]="picker4">
            <button [disabled]="factura.pagoReal" class="button-date" *ngIf="factura.pagoPrevisto" matSuffix
                mat-icon-button aria-label="Clear" (click)="factura.pagoPrevisto=undefined">
                <mat-icon>close</mat-icon>
            </button>

            <mat-datepicker-toggle [disabled]="factura.pagoReal" matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4 [disabled]="false"></mat-datepicker>
        </mat-form-field>
        <!--
        <span class="flex-50-responsive">
            <button class="button__buscar" type="button" mat-raised-button (click)="anadirFactura()">Añadir</button>
        </span>
-->
        <!-- NO HO UTILITZEN
        <span class="flex-50-responsive">
            <button [disabled]="!factura.adelanto || factura.anulada" class="button__buscar" type="button"
                mat-raised-button>Devolcer Adelanto Pendiente</button>
        </span>
-->
        <span class="flex-48-responsive mt-10">
            <button [disabled]="!factura.nFactura || factura.anulada" class="button__buscar" type="button"
                mat-raised-button (click)="irADetalles()">Detalles</button>
        </span>

        <span class="flex-48-responsive mt-10">
            <button
                [disabled]="!factura.nFactura ||factura.anulada || !factura.direccionFactura || !factura.codigoPostal || !factura.nif"
                class="button__buscar" type="submit" mat-raised-button>Guardar</button>
        </span>

        <!--        <span class="flex-50-responsive">
            <button class="button__buscar" type="button" mat-raised-button (click)="anadirVacia()">Añadir Vacia</button>
        </span>
        
        <span class="flex-50-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Imprimir Comercial</button>
        </span>
-->
        <span class="flex-100 mt-10">
            <button
                [disabled]="!factura.nFactura ||factura.anulada || !factura.direccionFactura || !factura.codigoPostal || !factura.nif"
                class="button__buscar" type="button" (click)="imprimirFactura()" mat-raised-button>Imprimir
                Factura</button>
        </span>

    </form>


</form>