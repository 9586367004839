import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  nombreCliente: string
}


@Component({
  selector: 'app-dialog-clientes-oferta',
  templateUrl: './dialog-clientes-oferta.component.html',
  styleUrls: ['./dialog-clientes-oferta.component.css']
})
export class DialogClientesOfertaComponent implements OnInit {


  opened: boolean = false;
  
  clientes: any[] = [
    {nombre: 'Cliente 1'},
    {nombre: 'Cliente 2'},
    {nombre: 'Cliente 3'}
  ] 
  

  displayedColumns: string[] = ['cliente', 'fechaOferta', 'descuento', 'incremento', 'anticipo', 'colaboracion', 'montaje'];
  clientesOferta: any[] = [
    {cliente: 'Cliente 1', fechaOferta: '15/03/2021', descuento: 10, incremento: 20, anticipo: 50, colaboracion: true, montaje: true},
    {cliente: 'Cliente 2', fechaOferta: '16/03/2021', descuento: 10, incremento: 20, anticipo: 50, colaboracion: true, montaje: true},
    {cliente: 'Cliente 3', fechaOferta: '17/03/2021', descuento: 10, incremento: 20, anticipo: 50, colaboracion: true, montaje: true},
    {cliente: 'Cliente 4', fechaOferta: '18/03/2021', descuento: 10, incremento: 20, anticipo: 50, colaboracion: true, montaje: true},
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log(data);
   }

  ngOnInit(): void {
  }

}
