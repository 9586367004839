export function DateToString(data: any){
    console.log(data);
    if(data){
        if (typeof data == 'string') { 
            data = StringToDate(data);
        }
        var day = data.getDate().toString().padStart(2, "0");
        var month = (1+ data.getMonth()).toString().padStart(2, "0");
        var year = 1900 + data.getYear();
        var answer = year+"/"+month+"/"+day;
        return answer;
    }
    return null;
}
export function DateToStringGuion(data: any){
    var day = data.getDate().toString().padStart(2, "0");
    var month = (1+ data.getMonth()).toString().padStart(2, "0");
    var year = 1900 + data.getYear();
    var answer = year+"-"+month+"-"+day;
    return answer;
}

export function StringToDate(data: any){
    return new Date(data);
}