import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TituloService {

  nombreTitulo: string = "";

  constructor(
    private router: Router
  ) { }

  enviarTitulo() {
    this.router.events
    .pipe(
      filter( (event: any) => event instanceof ActivationEnd),
      map( (event: ActivationEnd) => event.snapshot.data)
    ).subscribe( data => {
      const titulo = data.titulo;
      this.nombreTitulo = titulo;
    });

  }
}
