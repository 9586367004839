<h1 mat-dialog-title>Añadir albarán</h1>

<div mat-dialog-content class="dialog-container">

    <form #formulario="ngForm" (submit)="anadirAlbaranCompraChapa()" class="dialog-form">
        <div class="dialog-form-section">
            <div class="checkbox-container">
                <mat-checkbox (change)="inputNumAlbaranToggle($event.checked)">Buscar Albarán</mat-checkbox>

                <mat-form-field class="flex-50-responsive" appearance="outline">
                    <mat-label>{{buscarNumAlbaran ? 'Buscar Nº Albarán' : 'Nuevo Albarán'}}</mat-label>

                    @if(buscarNumAlbaran) {
                    <input type="text" name="NumAlbara" placeholder='Seleccionar Albaran' aria-label="Numero Albaran"
                        matInput [formControl]="control" [matAutocomplete]="auto" (input)="onInputChange($event)"
                        required>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                        (optionSelected)="onAlbaranSelected($event)">
                        @for (option of filteredOptions | async; track option) {
                        <mat-option [value]="option.NumAlbara">{{option.NumAlbara}}</mat-option>
                        }
                    </mat-autocomplete>

                    @if (this.hayAlbaranSeleccionado) {
                    <button class="limpiar" matSuffix mat-icon-button aria-label="Clear"
                        (click)="resetAlbaranSeleccionado()">
                        <mat-icon>close</mat-icon>
                    </button>
                    }

                    } @else {
                    <input type="text" name="NumAlbara" placeholder="Nº Albaran" aria-label="Numero Albaran" matInput
                        [(ngModel)]="nuevoAlbaran_Chapa.NumAlbara" required>
                    }

                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" class="flex-30-responsive">
                <mat-label>Fecha</mat-label>

                <input matInput [matDatepicker]="picker" name="DataEntrega" [(ngModel)]="nuevoAlbaran_Chapa.DataAlbara"
                    [disabled]="albaranEncontrado !== undefined">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

        </div>
        <div class="dialog-form-section">

            <mat-form-field appearance="outline" class="flex-45-responsive">
                <mat-label> No. Piezas entregadas </mat-label>

                <input matInput name="NumPecesEntregats" type="number"
                    [(ngModel)]="nuevoAlbaran_Chapa.NumPecesEntregats" (ngModelChange)="onNumPecesChange($event)"
                    min="0" required>

            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-45-responsive">
                <mat-label> Peso entregado (kg) </mat-label>

                <input matInput name="PesLliurat" type="number" [(ngModel)]="nuevoAlbaran_Chapa.PesLliurat"
                    (ngModelChange)="onPesLliuratChange($event)" min="0" required disabled>

            </mat-form-field>
        </div>


        <div class="flex flex-75-responsive w-100 justify-around mt-10">
            <button class="flex-45-responsive" type="submit" mat-raised-button
                [disabled]="!nuevoAlbaran_Chapa.NumAlbara || !nuevoAlbaran_Chapa.NumPecesEntregats">
                Guardar
            </button>

            <button class="flex-45-responsive" type="button" mat-raised-button (click)="resetNuevoAlbaran()">
                Limpiar
            </button>
        </div>
    </form>

    <mat-divider class="w-100"></mat-divider>

    <div class="info-comanda">
        <span><b>Información de comanda:</b></span>
        <!-- Table -->
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 standar-table-mecanoviga"
            aria-label="Comandas de compra">

            <ng-container matColumnDef='NumPecesEntregats'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> No. Piezas entregadas </th>
                <td mat-cell *matCellDef="let comanda"> {{(comanda.TotalPecesEntregats +
                    (nuevoAlbaran_Chapa.NumPecesEntregats))}} de
                    <b>{{comanda.NumPeces}} piezas</b>
                </td>
            </ng-container>

            <ng-container matColumnDef='PesLliurat'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso entregado (kg) </th>
                <td mat-cell *matCellDef="let comanda"> {{(comanda.TotalPesLliurat + nuevoAlbaran_Chapa.PesLliurat)}} de
                    <b>{{comanda.PesAlta}} kg</b>
                </td>
            </ng-container>

            <ng-container matColumnDef='Estat'>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let comanda"> <b>{{comanda.Estat}}</b> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pointer"
                [ngClass]="definirColorRow(row.IdComanda, row.Estat)">
            </tr>

        </table>

    </div>
</div>

<mat-dialog-actions align="end" class="actions-padding">
    <button color=" primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>