 <div class="content">
     
    <div class="header-mecanoviga">
        <img src="../../../../assets/logo mecanoviga2.jpg" alt="">
        <p style="font-size: 20px;" class="m-0"><p style="margin-bottom: 2px;">MECANOVIGA, S.L.</p>
        <p style="font-size: 18px;" class="m-0"><p style="margin-bottom: 2px;">C.I.F.: B-60-369.303</p>
        <p *ngIf="idIdioma != 1" style="font-size: 18px;" class="m-0">{{direccionDelegacion}}</p>
        <p *ngIf="idIdioma == 1 && poblacionDelegacion === 'Vallirana'" style="font-size: 18px;" class="m-0">Polígon Industrial Can Prunera, C/. del Garraf nº 16</p>
        <p style="font-size: 18px;" class="m-0">{{cpDelegacion}} - {{poblacionDelegacion}} ({{provinciaDelegacion}})</p>
        <p style="font-size: 18px;" class="color-primary m-0"><p style="margin-bottom: 2px;">+ 34 93.633.36.50 / mecanoviga&#64;mecanoviga.com</p>
        <p style="font-size: 18px;" class="color-primary m-0"><p>www.mecanoviga.com</p>

    </div>

 </div>   
