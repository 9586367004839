import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { DialogMantenimientoComponent } from '../../../dialogs/dialog-mantenimiento/dialog-mantenimiento.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';


@Component({
  selector: 'app-precios-especiales',
  templateUrl: './precios-especiales.component.html',
  styleUrls: ['./precios-especiales.component.css']
})
export class PreciosEspecialesComponent implements OnInit {

  @Input() nOferta: any = '';
  @Input() idCliente: any = '';
  @Input() ofertaAsignada = '';
  @Output() cambioPantalla = new EventEmitter();

  public productosPreciosEspeciales: any[] = [];
  public productos: IdLabelObject[] = [];
  public infoProductos: any[] = [];
  public esProductoPrecioEspecialNuevo: boolean = true;
  productoPrecioEspecialABorrar: any;

  public productoPrecioEspecial: any = {
    idProducto: "",
    nombreProducto: "",
    unidadMedida: "",
    precioUnitarioEspecial: "",
    precioUnitarioEstandar: ""
  }

  displayedColumns: string[] = ['buttons', 'producto', 'unidadMedida', 'precioUnitarioEspecial', 'precioUnitarioEstandar'];

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    console.log(this.nOferta);
    console.log(this.idCliente);
    this.apiService.obtenerProductosPreciosEspeciales(this.nOferta, this.idCliente).subscribe(data => {
      console.log(data);
      this.productosPreciosEspeciales = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
      console.log("productosPreciosEspeciales: ", this.productosPreciosEspeciales);
    });
    this.apiService.obtenerProductosEspecialesOferta(this.nOferta).subscribe(data => {
      this.infoProductos = data.response.map((producto: any) => ({ ...producto , idProducto: producto.idProducto.toString() }));
      this.productos = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto}));
      console.log("Todos:", this.infoProductos);

    })
  }

  public muestraProductoPrecioEspecial(element: any) {
    this.esProductoPrecioEspecialNuevo = false;
    this.productoPrecioEspecial = { ...element };
    this.productoPrecioEspecial.idProducto = this.productoPrecioEspecial.idProducto.toString();
  }

  public volverAClientes() {
    this.cambioPantalla.emit();
  }

  public guardarProductoPrecioEspecial() {
    var msg = "";
    if(!this.miraNegativos(this.productoPrecioEspecial.precioUnitarioEspecial)) msg = 'El campo Precio Unitario Especial tiene un número negativo';
    if (this.comprobarSiYaExiste(this.productoPrecioEspecial.idProducto) && this.esProductoPrecioEspecialNuevo) msg ='Este producto  con esta situacción ya existe en esta oferta';
    if(msg!= ""){
      this.openDialog("ALERTA", msg, false, "");
      return;
    }
    if (!this.comprobarSiYaExiste(this.productoPrecioEspecial.idProducto)){
      console.log("PUE: ", this.productoPrecioEspecial.precioUnitarioEspecial);
      this.apiService.anadirProductosPreciosEspeciales(this.nOferta, this.idCliente, this.productoPrecioEspecial.idProducto, this.productoPrecioEspecial.precioUnitarioEspecial).subscribe(dades => {
        this.apiService.obtenerProductosPreciosEspeciales(this.nOferta, this.idCliente).subscribe(data => {
          this.productosPreciosEspeciales = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          this.resetProductoPrecioEspecial();
        });
      });
    }
    else {
      this.apiService.modificarProductosPreciosEspeciales(this.nOferta, this.idCliente, this.productoPrecioEspecial.idProducto, this.productoPrecioEspecial.precioUnitarioEspecial).subscribe(dades => {
        this.esProductoPrecioEspecialNuevo = true;
        this.apiService.obtenerProductosPreciosEspeciales(this.nOferta, this.idCliente).subscribe(data => {
          console.log(data);
          this.productosPreciosEspeciales = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          console.log("productosPreciosEspeciales: ", this.productosPreciosEspeciales);
          this.resetProductoPrecioEspecial();
        });
      });
    }
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.productosPreciosEspeciales.find( item => {
      if(item.idProducto == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if(numero < 0) { 
      return false
    } else { 
      return true
    }
  }

  public eliminarProductoPrecioEspecial(event: any, productoPrecioEspecial: any) {
    this.productoPrecioEspecialABorrar = productoPrecioEspecial;
    this.openDialog("ELIMINAR PRODUCTO ESPECIAL","¿Quire eliminar el producto "+productoPrecioEspecial.nombreProducto+" de la oferta "+this.nOferta+"?", true, "eliminar");
  }

  public ProductoPrecioEspecialNuevo() {
    this.esProductoPrecioEspecialNuevo = true;
    this.resetProductoPrecioEspecial();
  }

  public obtenerDatosProducto(idProducto: string) {
    var body =  this.infoProductos.find(producto => producto.idProducto === idProducto) || {};
    return body;
  }


  public cuandoSeCliqueEnMantenimiento(label: string) {
    console.log(label);
  }

  private resetProductoPrecioEspecial() {
    this.productoPrecioEspecial = {
      idProducto: "",
      nombreProducto: "",
      unidadMedida: "",
      precioUnitarioEspecial: "",
      precioUnitarioEstandar: ""
      }
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo == "eliminar") this.eliminarProductoEspecial();
    });
  }
  eliminarProductoEspecial(){
    console.log("Eliminado 1");
      this.apiService.borrarProductosPreciosEspeciales(this.nOferta, this.idCliente, this.productoPrecioEspecialABorrar.idProducto).subscribe(dades => {
        this.apiService.obtenerProductosPreciosEspeciales(this.nOferta, this.idCliente).subscribe(data => {
          console.log(data);
          this.productosPreciosEspeciales = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          console.log("productosPreciosEspeciales: ", this.productosPreciosEspeciales);
          this.resetProductoPrecioEspecial();
          this.esProductoPrecioEspecialNuevo = true;
        });
      })
  }

}
