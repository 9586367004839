<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container">
    <div class="tabla flex-layout flex-70-responsive">
        <table mat-table [dataSource]="items" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button type="button"
                        (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> Descripcion </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.descripcion}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef> Producto Opcional </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.tipoProdOpc}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef> Unidad medida </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.unidadMedida}} </td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef={{data.cols[3]}}>
                <th mat-header-cell *matHeaderCellDef> Peso </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.peso}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[4]}}>
                <th mat-header-cell *matHeaderCellDef> Tipo Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.tipoProd}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[5]}}>
                <th mat-header-cell *matHeaderCellDef> Conversion </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.conversion}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[6]}}>
                <th mat-header-cell *matHeaderCellDef> Stock Minimo </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.stockMinimo}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef={{data.cols[7]}}>
                <th mat-header-cell *matHeaderCellDef> Stock Actual </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.stockActual}} </td>
            </ng-container>


            <ng-container matColumnDef={{data.cols[8]}}>
                <th mat-header-cell *matHeaderCellDef> Fecha Modificacion </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.dataIni | date: 'dd/MM/yyyy'}} </td>
            </ng-container>


            <ng-container matColumnDef={{data.cols[9]}}>
                <th mat-header-cell *matHeaderCellDef> Precio </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.precio}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row id="rowIndex" *matRowDef="let row; columns: displayedColumns" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idProducto == row.idProducto }"></tr>

        </table>
    </div>

    <form #formulario="ngForm" class="flex-row-wrap row-gap-30 justify-between flex-30-responsive">

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> Descripcion </mat-label>
            <input required [(ngModel)]="itemSelected.descripcion" name="Descripcion" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.descripcion" matSuffix mat-icon-button
                aria-label="Clear" (click)="resetItem()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <app-autocomplete appearance="outline" class="auto100 flex-48-responsive" name="labelTipoProdOpc"
            [idOpcionSeleccionada]="itemSelected.idTipoProdOpc"
            (cuandoSeSeleccioneOpcion)="itemSelected.idTipoProdOpc = $event" label="Tipo Producto Opcional"
            [options]="tipoProductoOpc">
        </app-autocomplete>

        <app-autocomplete appearance="outline" class="auto100 flex-48-responsive" name="labelUnidadMedida"
            [idOpcionSeleccionada]="itemSelected.idUnidadMedida"
            (cuandoSeSeleccioneOpcion)="itemSelected.idUnidadMedida = $event" label="Unidad de Medida"
            [options]="unidadMedida">
        </app-autocomplete>



        <app-autocomplete appearance="outline" class="auto100 flex-100" name="labelPare"
            [idOpcionSeleccionada]="itemSelected.idTipoProd" (cuandoSeCliqueEnIcono)="openDialogTipoProd()"
            (cuandoSeSeleccioneOpcion)="itemSelected.idTipoProd = $event" label="Tipo Producto" [options]="tipoProducto"
            icon="settings">
        </app-autocomplete>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> Peso </mat-label>
            <input [(ngModel)]="itemSelected.peso" name="Peso" matInput type="number" min="0">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> conversion </mat-label>
            <input required [(ngModel)]="itemSelected.conversion" name="conversion" matInput type="number" min="1">
        </mat-form-field>


        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> stock Minimo </mat-label>
            <input [(ngModel)]="itemSelected.stockMinimo" name="StockMinimo" matInput type="number">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" disabled appearance="outline">
            <mat-label> stock Actual </mat-label>
            <input [(ngModel)]="itemSelected.stockActual" name="StockActual" matInput type="number">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>
                Ultima Modificacion de precio
            </mat-label>
            <input matInput readonly='true' [(ngModel)]="precioSelected.fechaInicio" name="fechaInicio"
                [matDatepicker]="picker2" [min]="minDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field disabled appearance="outline" class="flex-100">
            <mat-label> Precio Actual </mat-label>
            <input [(ngModel)]="precioOriginal" name="precioOriginal" matInput type="number" min="0">
        </mat-form-field>



        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span class="flex-48-responsive">
                <button [disabled]="!itemSelected.idProducto" mat-raised-button
                    (click)="muestraPrecios($event)">PRECIOS</button>
            </span>
            <span class="flex-48-responsive">
                <button [disabled]="!itemSelected.conversion" mat-raised-button
                    (click)="guardarItem(formulario)">GUARDAR</button>
            </span>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color=" primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>