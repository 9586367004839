import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { OrdenCompra } from '../../../types/compras';
import { pick } from 'lodash';
import { DateToString, StringToDate } from '../../../services/dataFunction';
import { TituloService } from 'src/app/services/titulo.service';


@Component({
  selector: 'app-ordenes-compra',
  templateUrl: './ordenes-compra.component.html',
  styleUrls: ['./ordenes-compra.component.css']
})
export class OrdenesCompraComponent implements OnInit {

  public filtroOrdenesCompra: any = {
    fechaInicioSelected: undefined,
    fechaFinSelected: undefined,
    nCompraSelected: undefined,
    cerradaSelected: undefined
  }

  public ordenCompra: OrdenCompra = {
    nCompra: "",
    fechaCompra: "",
    fechaCierre: ""
  }

  opcionesSiNo = [
    {
      nombre: "SI",
      valor: true
    },
    {
      nombre: "NO",
      valor: false
    }
  ]

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public listaOrdenesCompra: OrdenCompra[] = [];
  public nCompraResaltada = '';
  private action: string = "";
  public fechaCierreForm = "";
  public dataSource!: MatTableDataSource<OrdenCompra>;

  displayedColumns: string[] = ['buttons', 'nCompra', 'fechaCompra', 'fechaCierre'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaOrdenesCompra);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetOrdenCompra();
    this.action = this.activatedRoute.snapshot.queryParams.action;
    console.log('ACTION:', this.action);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.nCompra) {
        this.nCompraResaltada = params.nCompra;
        this.ordenCompra.nCompra = params.nCompra;
        this.filtroOrdenesCompra = {
          ...pick(params, Object.keys(this.filtroOrdenesCompra)),
        };
        if (this.filtroOrdenesCompra.fechaInicioSelected) this.filtroOrdenesCompra.fechaInicioSelected = StringToDate(this.filtroOrdenesCompra.fechaInicioSelected);
        if (this.filtroOrdenesCompra.fechaFinSelected) this.filtroOrdenesCompra.fechaFinSelected = StringToDate(this.filtroOrdenesCompra.fechaFinSelected);
        this.envioDatosFiltro();
      } else {
        //      this.envioDatosFiltro();
      }

    });

  }

  ngAfterViewInit(): void {
    this.envioDatosFiltro();
    /*    this.dataSource = new MatTableDataSource<OrdenCompra>(this.listaOrdenesCompra);
        let page = this.buscarIndiceEnOfertasFiltradas(this.nCompraResaltada) || 0;
        this.paginator.pageIndex = page;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
  }

  /*  ngOnChanges(): void {
      console.log("PASO3");
      this.dataSource = new MatTableDataSource<OrdenCompra>(this.listaOrdenesCompra);
      let page = this.buscarIndiceEnOfertasFiltradas(this.nCompraResaltada) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }*/

  public envioDatosFiltro() {
    if (this.filtroOrdenesCompra.fechaInicioSelected) this.filtroOrdenesCompra.fechaInicioSelected = DateToString(this.filtroOrdenesCompra.fechaInicioSelected);
    if (this.filtroOrdenesCompra.fechaFinSelected) this.filtroOrdenesCompra.fechaFinSelected = DateToString(this.filtroOrdenesCompra.fechaFinSelected);
    // obtener Ordenes Compra
    this.apiService.obtenerOrdenesCompra(this.filtroOrdenesCompra).subscribe(dades => {
      this.listaOrdenesCompra = dades.response;
      console.log("lista compras; ", this.listaOrdenesCompra);
      this.dataSource = new MatTableDataSource<OrdenCompra>(this.listaOrdenesCompra);
      let page = this.buscarIndiceEnOfertasFiltradas(this.nCompraResaltada) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.filtroOrdenesCompra.fechaInicioSelected) this.filtroOrdenesCompra.fechaInicioSelected = StringToDate(this.filtroOrdenesCompra.fechaInicioSelected);
      if (this.filtroOrdenesCompra.fechaFinSelected) this.filtroOrdenesCompra.fechaFinSelected = StringToDate(this.filtroOrdenesCompra.fechaFinSelected);
      if (this.nCompraResaltada) {
        const element = this.listaOrdenesCompra.find(element => element.nCompra == this.nCompraResaltada);
        this.muestraUnaOrdenCompra(element);
      }
    });
  }

  public guardarOrdenEntrada(formulario: NgForm) {
    this.ordenCompra.fechaCierre = this.fechaCierreForm;
    if (this.ordenCompra.fechaCierre) this.ordenCompra.fechaCierre = DateToString(this.ordenCompra.fechaCierre);
    this.apiService.modificarOrdenCompra(this.ordenCompra).subscribe(data => {
      //this.crearAlbaranNuevo = true;
      //      this.resetAlbaranEntrada();
      this.nCompraResaltada = this.ordenCompra.nCompra;
      this.envioDatosFiltro();
    });
  }

  public muestraUnaOrdenCompra(element: any) {
    if (this.action === "albaranes-salida") {
      this.router.navigate(['albaranes-salida'], { queryParams: { nCompraBuscada: element.nCompra } });
    } else if (this.action === "albaranes-entrada") {
      this.router.navigate(['albaranes-entrada'], { queryParams: { nCompraBuscada: element.nCompra } });
    }
    this.ordenCompra = { ...element };
    if (this.ordenCompra.fechaCierre) this.ordenCompra.fechaCierre = StringToDate(this.ordenCompra.fechaCierre);
    this.fechaCierreForm = this.ordenCompra.fechaCierre;
  }

  private buscarIndiceEnOfertasFiltradas(nCompraResaltada: any) {
    if (!nCompraResaltada) return 0;
    let busqueda = nCompraResaltada.toString();
    let indice: number;
    indice = this.listaOrdenesCompra.findIndex(compra => compra.nCompra.toString() === busqueda);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  public irADetallesOrdenesCompra() {
    if (this.filtroOrdenesCompra.fechaInicioSelected) this.filtroOrdenesCompra.fechaInicioSelected = DateToString(this.filtroOrdenesCompra.fechaInicioSelected);
    if (this.filtroOrdenesCompra.fechaFinSelected) this.filtroOrdenesCompra.fechaFinSelected = DateToString(this.filtroOrdenesCompra.fechaFinSelected);
    if (this.ordenCompra.fechaCierre) this.ordenCompra.fechaCierre = DateToString(this.ordenCompra.fechaCierre);
    this.router.navigate(['detalles-ordenes-compra'], { queryParams: { ...this.filtroOrdenesCompra, nCompra: this.ordenCompra.nCompra, fechaCierre: this.ordenCompra.fechaCierre } });
  }


  public resetOrdenCompra() {
    this.ordenCompra = {
      nCompra: "",
      fechaCompra: "",
      fechaCierre: ""
    }
  }

  public resetFiltro() {
    this.filtroOrdenesCompra = {
      fechaInicioSelected: undefined,
      fechaFinSelected: undefined,
      nCompraSelected: undefined,
      cerradaSelected: undefined
    }
    this.nCompraResaltada = '';
    this.resetOrdenCompra();
  }


}
