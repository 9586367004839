import { Component, Input, OnInit, Inject, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Oferta } from '../../../types/ofertas';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth-service';
import { ActivatedRoute, Router } from '@angular/router';
import { omit } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-tabla-ofertas',
  templateUrl: './tabla-ofertas.component.html',
  styleUrls: ['./tabla-ofertas.component.css']
})
export class TablaOfertasComponent implements OnInit, AfterViewInit {

  @Input() ofertasFiltradas: Oferta[] = [];
  @Input() ofertaAsignada: boolean = false;
  @Input() hayDatosEnTabla: boolean = false;
  @Input() action: string = "";
  @Output() dataOferta = new EventEmitter();
  @Output() cuandoEliminoUnaOferta = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public filtros: any;
  public ofertaResaltada: any = null;
  public dataSource!: MatTableDataSource<Oferta>;
  ofertaABorrar: any;
  public tipoListado: number = 0;
  public loaded = false;

  displayedColumns: string[] = ['buttons', 'nOferta', 'fechaOferta', 'direccionObra', 'ciudad', 'provincia', 'cliente', 'comercial', 'tecnico', 'importeKits', 'importeOtros', 'pesoKits', 'pesoOtros', 'fechaCierre'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource(this.ofertasFiltradas);
  }

  ngAfterViewInit() {
    this.guardarOfertasEnLocalStorage(this.ofertasFiltradas);
    this.dataSource = new MatTableDataSource<Oferta>(this.ofertasFiltradas);
    let page = this.buscarIndiceEnOfertasFiltradas(this.ofertaResaltada) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaded = true;
  }

  ngOnChanges() {
    if (this.loaded) {
      this.guardarOfertasEnLocalStorage(this.ofertasFiltradas);
      let page = this.buscarIndiceEnOfertasFiltradas(this.ofertaResaltada) || 0;
      this.dataSource = new MatTableDataSource<Oferta>(this.ofertasFiltradas);
      if (this.paginator && this.paginator.pageIndex) this.paginator.pageIndex = page || 0;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
    this.filtros = omit(this.activatedRoute.snapshot.queryParams, ['ofertaResaltada']);
    this.ofertaResaltada = this.activatedRoute.snapshot.queryParamMap.get('ofertaResaltada') || null;
    this.action = this.activatedRoute.snapshot.queryParams.action;
    console.log(this.hayDatosEnTabla);
    console.log("oninit de tabla: ", this.action);
  }

  muestraUnaOferta(oferta: any) {
    if (this.action === "albaranes-salida") {
      return this.router.navigate(['albaranes-salida'], { queryParams: { nOfertaBuscada: oferta.nOferta } });
    }
    else if (this.action === "albaranes-entrada") {
      return this.router.navigate(['albaranes-entrada'], { queryParams: { nOfertaBuscada: oferta.nOferta } });
    }
    return this.dataOferta.emit(oferta);
  }

  dialogEliminarUnaOferta(event: any, oferta: any) {
    this.ofertaABorrar = oferta;
    this.openDialog("ELIMINAR OFERTA", "¿Quiere eliminar la oferta " + oferta.nOferta + "?", true, "eliminar");
    event.stopPropagation();
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarOferta();
    });
  }

  eliminarOferta() {
    console.log("Eliminado");
    this.apiService.borrarComanda(this.ofertaABorrar.nOferta).subscribe(dades => {
      console.log("Eliminada la oferta: ", this.ofertaABorrar)
      this.cuandoEliminoUnaOferta.emit();
    });
  }

  buscarIndiceEnOfertasFiltradas(ofertaResaltada: any) {
    if (!ofertaResaltada) return 0;
    let busqueda = ofertaResaltada.toString();
    let indice: number;
    indice = this.ofertasFiltradas.findIndex(oferta => oferta.nOferta.toString() === busqueda);
    console.log("El elemento buscado está en el índice ", indice);
    console.log("pagesize: ", this.paginator.pageSize);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  public imprimirStock() {

    this.apiService.carregarClientesByOferta(this.ofertasFiltradas).subscribe(dades => {
      this.guardarOfertasEnLocalStorage(dades.response);
      this.router.navigate(['report-listado-ofertas']);
    });
  }

  private guardarOfertasEnLocalStorage(ofertas: any) {
    this.authService.arrayOfertas = ofertas;
  }


  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('l', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`listadoOfertas.pdf`);
    })
  }


}
