<div class="content">
    <div class="header-cliente">
        <div class="nref color-primary">
            <div style="margin-top: 12px;" class="ref-fac">
                <ng-container *ngIf="tipo === tiposCabecera.COMPRA_CHAPA">
                    <p *ngIf="idIdioma !== 1">Compra de chapa</p>
                    <p *ngIf="idIdioma === 1">Compra de xapa</p>
                </ng-container>

                <ng-container *ngIf="tipo === tiposCabecera.ALBARANES">
                    <p *ngIf="idIdioma !== 1">Albaranes</p>
                    <p *ngIf="idIdioma === 1">Albarans</p>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="tipo === tiposCabecera.COMPRA_CHAPA">
            <div *ngIf="idIdioma !== 1 && data && data['dataCompra']" class="fecha mt-8">FECHA COMPRA:
                {{data['dataCompra'] | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma === 1 && data && data['dataCompra']" class="fecha mt-8">DATA COMPRA:
                {{data['dataCompra'] | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma !== 1 && data && data['idTipo']" class="fecha mt-8">TIPO:
                {{data['idTipo'].toUpperCase()}}</div>
            <div *ngIf="idIdioma === 1 && data && data['idTipo']" class="fecha mt-8">TIPUS:
                {{data['idTipo'].toUpperCase()}}</div>
            <div *ngIf="idIdioma !== 1 && data && data['preu']" class="fecha mt-8">PRECIO:
                {{data['preu'].toUpperCase()}}€/T</div>
            <div *ngIf="idIdioma === 1 && data && data['preu']" class="fecha mt-8">PREU:
                {{data['preu'].toUpperCase()}}€/T</div>
            <div *ngIf="idIdioma !== 1 && data && data['estado']" class="fecha mt-8">ESTADO:
                {{data['estado'].toUpperCase()}}</div>
            <div *ngIf="idIdioma === 1 && data && data['estado']" class="fecha mt-8">ESTAT:
                {{data['estado'].toUpperCase()}}</div>
        </ng-container>

        <ng-container *ngIf="tipo === tiposCabecera.ALBARANES">
            <div *ngIf="idIdioma !== 1 && data && data['fechaDesde']" class="fecha mt-8">FECHA DESDE:
                {{data['fechaDesde'] | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma === 1 && data && data['fechaDesde']" class="fecha mt-8">DES DATA:
                {{data['fechaDesde'] | date:'dd/MM/yyyy':'es'}}</div>

            <div *ngIf="idIdioma !== 1 && data && data['fechaHasta']" class="fecha mt-8">FECHA HASTA:
                {{data['fechaHasta'] | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma === 1 && data && data['fechaHasta']" class="fecha mt-8">FINS DATA:
                {{data['fechaHasta'] | date:'dd/MM/yyyy':'es'}}</div>
        </ng-container>

        <div class="linea-gruesa color-primary"></div>

    </div>
</div>