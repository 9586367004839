<div class="layout-filtro">
  <app-filtro-ofertas></app-filtro-ofertas>
</div>
<div class="layout-tabla standar-table-mecanoviga">
  <app-tabla-ofertas *ngIf="ofertas.length > 0" [ofertaAsignada]="ultimosFiltrosUsados.selectedChecked!"
    [ofertasFiltradas]="ofertas" [hayDatosEnTabla]="hayDatosEnTabla" (dataOferta)="enviaDataASidenav($event)"
    (cuandoEliminoUnaOferta)="refrescarOfertas()">
  </app-tabla-ofertas>

  <div class="noHayNadaEnTabla" *ngIf="!hayDatosEnTabla">No hay ofertas</div>
</div>
