<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container">
    <div class="tabla flex-layout flex-70-responsive">
        <table mat-table [dataSource]="items" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let element">
                    <button class="limpiar" *ngIf="!element.editable" mat-icon-button type="button"
                        (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Descripcion Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> Descripcion </th>
                <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
            </ng-container>

            <!--
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef> Longitud Central </th>
                <td mat-cell *matCellDef="let element" > {{element.longitudCentral}} </td>
            </ng-container>
        
            
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef> Longitud Extremo </th>
                <td mat-cell *matCellDef="let element" > {{element.longitudExtremo}} </td>
            </ng-container>
            
            <ng-container matColumnDef={{data.cols[3]}}>
                <th mat-header-cell *matHeaderCellDef> Inercia Central </th>
                <td mat-cell *matCellDef="let element" > {{element.inerciaCentral}} </td>
            </ng-container>
            
            
            <ng-container matColumnDef={{data.cols[4]}}>
                <th mat-header-cell *matHeaderCellDef> Inercia Extremo </th>
                <td mat-cell *matCellDef="let element" > {{element.inerciaExtremo}} </td>
            </ng-container>
            
            <ng-container matColumnDef={{data.cols[5]}}>
                <th mat-header-cell *matHeaderCellDef> W Central </th>
                <td mat-cell *matCellDef="let element" > {{element.wCentral}} </td>
            </ng-container>

            
            <ng-container matColumnDef={{data.cols[6]}}>
                <th mat-header-cell *matHeaderCellDef> W Extremo </th>
                <td mat-cell *matCellDef="let element" > {{element.wExtremo}} </td>
            </ng-container>
        -->
            <!-- Column -->
            <ng-container matColumnDef={{data.cols[7]}}>
                <th mat-header-cell *matHeaderCellDef> Stock Minimo </th>
                <td mat-cell *matCellDef="let element"> {{element.stockMinimo}} </td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef={{data.cols[8]}}>
                <th mat-header-cell *matHeaderCellDef> Stock Actual </th>
                <td mat-cell *matCellDef="let element"> {{element.stockActual}} </td>
            </ng-container>
            <!-- Column -->
            <ng-container matColumnDef={{data.cols[9]}}>
                <th mat-header-cell *matHeaderCellDef> fecha Inicio </th>
                <td mat-cell *matCellDef="let element"> {{element.dataIni| date: 'dd/MM/yyyy'}} </td>
            </ng-container>
            <ng-container matColumnDef={{data.cols[10]}}>
                <th mat-header-cell *matHeaderCellDef> Precio </th>
                <td mat-cell *matCellDef="let element"> {{element.precio}} </td>
            </ng-container>
            <ng-container matColumnDef={{data.cols[11]}}>
                <th mat-header-cell *matHeaderCellDef> Tiene Imagen? </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.tieneImagen}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef=" let row; columns: displayedColumns" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idProd == row.idProd }"></tr>

        </table>
    </div>
    <form #formulario="ngForm" class="flex-row-wrap row-gap-20 justify-between flex-30-responsive">

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> Descripcion </mat-label>
            <input [(ngModel)]="itemSelected.descripcion" name="Descripcion" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.descripcion" matSuffix mat-icon-button
                aria-label="Clear" (click)="resetItem()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> Long. Central </mat-label>
            <input [(ngModel)]="itemSelected.longitudCentral" name="longitudCentral" matInput type="number" min="0">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> Long. Extremo </mat-label>
            <input [(ngModel)]="itemSelected.longitudExtremo" name="longitudExtremo" matInput type="number" min="0">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> Inercia Central </mat-label>
            <input [(ngModel)]="itemSelected.inerciaCentral" name="inerciaCentral" matInput type="number">
        </mat-form-field>
        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> Inercia Extremo </mat-label>
            <input [(ngModel)]="itemSelected.inerciaExtremo" name="inerciaExtremo" matInput type="number">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> W Central </mat-label>
            <input [(ngModel)]="itemSelected.wCentral" name="wCentral" matInput type="number">
        </mat-form-field>
        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> W Extremo </mat-label>
            <input [(ngModel)]="itemSelected.wExtremo" name="wExtremo" matInput type="number">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> stock Minimo </mat-label>
            <input [(ngModel)]="itemSelected.stockMinimo" name="StockMinimo" matInput type="number">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> stock Actual </mat-label>
            <input [(ngModel)]="itemSelected.stockActual" name="StockActual" matInput type="number">
        </mat-form-field>


        <app-autocomplete appearance="outline" class="auto100 flex-100" name="labelPare"
            [idOpcionSeleccionada]="itemSelected.idSubTipo" (cuandoSeSeleccioneOpcion)="itemSelected.idSubTipo = $event"
            label="SubTipo" [options]="subTipo">
        </app-autocomplete>



        <mat-form-field appearance="outline">
            <mat-label>
                Ultima Modificacion de precio
            </mat-label>
            <input matInput readonly='true' [(ngModel)]="precioSelected.fechaInicio" name="fechaInicio"
                [matDatepicker]="picker2" [min]="minDate" disabled>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field disabled appearance="outline" class="flex-100">
            <mat-label> Precio Actual </mat-label>
            <input [(ngModel)]="precioOriginal" name="precioOriginal" matInput type="number" min="0">
        </mat-form-field>

        <img *ngIf="itemSelected.imagen" style="margin-bottom: 20px;" src="../../../assets/kits/{{itemSelected.imagen}}"
            alt="" width="350px" height="100px">

        <div class="LimpiarGuardar flex-row-wrap flex-100">

            <button class="flex-48" [disabled]="!itemSelected.idProd" mat-raised-button
                (click)="muestraPrecios($event)">PRECIOS</button>

            <button class="flex-48" [disabled]="!itemSelected.idProd" mat-raised-button
                (click)="muestraComponents()">Componentes</button>

            <button class="flex-48" [disabled]="!itemSelected.idProd" mat-raised-button
                style="margin-top: 10px; padding: 0px;" (click)="adjuntarImagen=!adjuntarImagen">Adjuntar
                imagen</button>
            <input #imageInput type="file" name="avatar" (change)="onFileSelect($event)" class="adjunarImg"
                *ngIf="adjuntarImagen" [(ngModel)]="itemSelected.imagen">

            <button class="flex-48" mat-raised-button (click)="guardarItem(formulario)"
                style="margin-top: 10px;">GUARDAR</button>


        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>