import { Component, Input } from '@angular/core';
import { tiposCabeceraReport } from 'src/app/constants/comprasChapa.constant';

@Component({
  selector: 'app-cabecera-generica',
  standalone: false,
  templateUrl: './cabecera-generica.component.html',
  styleUrl: './cabecera-generica.component.css'
})
export class CabeceraGenericaComponent {
  @Input() data: any = {};
  @Input() dataComplementaria: any = {};
  @Input() tipo = "";
  public idIdioma = 2;
  public tiposCabecera = tiposCabeceraReport;

  constructor() { }
}
