 <div class="content">
    <div class="header-cliente">

        <div class="nfac">
            <div>
                <p *ngIf="!esAbono" style="font-size: 30px;">Factura  Nº  {{datosFactura.nFactura}}</p>
                <p *ngIf="esAbono" style="font-size: 30px;">Abono  Nº  {{datosFactura.nFactura}}</p>
            </div>
            <p style="font-size: 60px; color: #004f9f;">F</p>
        </div>
        
        <div class="nref color-primary">
            <p style="margin-top: 12px;">REF:</p>
            <div style="margin-top: 12px;" class="ref-fac">
                <p>{{datosFactura.nOferta}}</p>
                <p> / </p>
                <p>{{datosFactura.nFactura}}</p>
            </div>
        </div>

        <div *ngIf="datosFactura.nOferta" class="obra">
            <div class="cabeceraObra">
                OBRA:
            </div>
            <div class="datosObra">
                <div style="text-align: left; max-width: 404px;">{{datosOferta.direccionObra}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosOferta.codigoPostalObra}} - {{datosOferta.ciudadObra}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosOferta.provinciaObra}}</div>
            </div>
        </div>

        <div class="cliente">
            <div *ngIf="idIdioma != 1" class="cabeceraCliente">
                CLIENTE:
            </div>
            <div *ngIf="idIdioma == 1" class="cabeceraCliente">
                CLIENT:
            </div>
            <div class="datosCliente">
                <div style="text-align: left; max-width: 404px;">{{datosFactura.nombreCliente}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosFactura.cifCliente}}</div>
                <textarea class="direccionFactura" name="" id="" cols="70" rows="2">{{datosFactura.direccionCliente}}</textarea>
                <div style="text-align: left; max-width: 404px;">{{datosFactura.codigoPostalCliente}} - {{datosFactura.ciudadCliente}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosFactura.nombreProvincia}}</div>
            </div>
        </div>

    </div>

 </div>   

