<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container">

    <div class="tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="items" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button
                        (click)="dialogEliminarUnCampo($event, element)"><mat-icon>deletes</mat-icon></button>
                </td>
            </ng-container>

            <!-- Fill Column -->
            <ng-container *ngIf="data.mantenimiento != 'components' " matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.labelFill}} </td>
            </ng-container>


            <!-- Pare Column -->
            <ng-container *ngIf="data.mantenimiento != 'components' " matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.labelPare}} </td>
            </ng-container>



            <!-- Pare Column -->
            <ng-container *ngIf="data.mantenimiento=='components'" matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.labelPare}} </td>
            </ng-container>

            <!-- Fill Column -->
            <ng-container *ngIf="data.mantenimiento=='components'" matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.labelFill}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  (data.mantenimiento != 'components' && itemSelected.idFill == row.idFill) ||
                                            (data.mantenimiento == 'components' && itemSelected.idPare == row.idPare)  }"></tr>
        </table>
    </div>

    <form class="form flex-row-wrap row-gap-25 flex-35-responsive" #formulario="ngForm"
        (ngSubmit)="guardarItem(formulario)">

        <mat-form-field
            *ngIf="data.mantenimiento!='components' && data.mantenimiento!='tipo Producto' && data.mantenimiento!='encargado Obra' "
            appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input [(ngModel)]="itemSelected.labelFill" name="labelFill" matInput type="text" (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.labelFill && data.mantenimiento!='components'"
                matSuffix mat-icon-button aria-label="Clear" (click)="resetItem()" type="button"
                type="button"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>


        <app-autocomplete
            *ngIf="data.mantenimiento!='components' && data.mantenimiento!='tipo Producto' && data.mantenimiento!='encargado Obra' "
            appearance="outline" class="auto100 flex-100" name="labelPare" [idOpcionSeleccionada]="itemSelected.idPare"
            (cuandoSeCliqueEnIcono)="mantenimientoPadre()" (cuandoSeSeleccioneOpcion)="itemSelected.idPare = $event"
            label="{{data.cols[1]}}" [options]="pareAutocomplete" icon="settings">
        </app-autocomplete>



        <app-autocomplete *ngIf="data.mantenimiento=='components'" appearance="outline" class="auto100 flex-100"
            name="labelPare" [idOpcionSeleccionada]="itemSelected.idPare" (cuandoSeCliqueEnIcono)="mantenimientoPadre()"
            (cuandoSeSeleccioneOpcion)="itemSelected.idPare = $event" label="{{data.cols[0]}}"
            [options]="pareAutocomplete" [disabled]="!itemSelected.editable">
        </app-autocomplete>

        <mat-form-field *ngIf="data.mantenimiento=='components'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input required [(ngModel)]="itemSelected.labelFill" name="labelFill" matInput type="number" min="0">
        </mat-form-field>

        <mat-form-field *ngIf="data.mantenimiento=='tipo Producto'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input required [(ngModel)]="itemSelected.labelFill" name="labelFill" matInput type="text"
                (ngModelChange)="goTo()">
            <mat-icon *ngIf="itemSelected.labelFill" matSuffix
                (click)="resetItem(); $event.stopPropagation()">close</mat-icon>
        </mat-form-field>

        <div *ngIf="data.mantenimiento=='tipo Producto'" appearance="outline" class="flex-100">
            <mat-checkbox [(ngModel)]="itemSelected.idPare" name="idPare">{{data.cols[1]}}</mat-checkbox>
        </div>


        <mat-form-field *ngIf="data.mantenimiento=='encargado Obra'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input required [(ngModel)]="itemSelected.labelFill" name="labelFill" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="resetItem()"
                type="button"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field *ngIf="data.mantenimiento=='encargado Obra' " appearance="outline" class="flex-100">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input [(ngModel)]="itemSelected.labelPare" name="labelPare" matInput type="text">
        </mat-form-field>

        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span *ngIf="data.mantenimiento=='components'" class="flex-48-responsive">
                <button mat-raised-button (click)="resetItem2($event)" type="button">LIMPIAR</button>
            </span>
            <span class="flex-48-responsive">
                <button mat-raised-button type="submit">GUARDAR</button>
            </span>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color=" primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>