<h1 mat-dialog-title>{{data.title}}</h1>

<div>
    <mat-dialog-content class="mantenimiento container">
        <div class="tabla flex-layout flex-70-responsive">
            <table mat-table [dataSource]="items" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button class="limpiar" *ngIf="!element.editable" mat-icon-button
                            (click)="dialogEliminarUnCampo($event, element)">
                            <mat-icon>deletes</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Descripcion Column -->
                <ng-container matColumnDef={{data.cols[0]}}>
                    <th mat-header-cell *matHeaderCellDef> Descripcion </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.descripcion}} </td>
                </ng-container>


                <!-- Oferta Column -->
                <ng-container matColumnDef={{data.cols[1]}}>
                    <th mat-header-cell *matHeaderCellDef> Longitud </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.longitud}} </td>
                </ng-container>

                <!-- Chapa Column -->
                <ng-container matColumnDef={{data.cols[2]}}>
                    <th mat-header-cell *matHeaderCellDef> Chapa </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.chapa}} </td>
                </ng-container>

                <!-- Oferta Column -->
                <ng-container matColumnDef={{data.cols[3]}}>
                    <th mat-header-cell *matHeaderCellDef> Peso </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.peso}} </td>
                </ng-container>

                <!-- Oferta Column -->
                <ng-container matColumnDef={{data.cols[4]}}>
                    <th mat-header-cell *matHeaderCellDef> Tipo Producto </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.tipoProd}} </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[5]}}>
                    <th mat-header-cell *matHeaderCellDef> Se zinca </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.seZinca}}
                    </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[6]}}>
                    <th mat-header-cell *matHeaderCellDef> Se fabrica </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.seFabrica}} </td>
                </ng-container>

                <!-- Oferta Column -->
                <ng-container matColumnDef={{data.cols[7]}}>
                    <th mat-header-cell *matHeaderCellDef> Stock Minimo </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.stockMinimo}} </td>
                </ng-container>
                <!-- Oferta Column -->
                <ng-container matColumnDef={{data.cols[8]}}>
                    <th mat-header-cell *matHeaderCellDef> Stock Actual </th>
                    <td mat-cell *matCellDef="let element" align="left" style="font-weight: 600;">
                        {{element.stockActual}} </td>
                </ng-container>
                <!-- Oferta Column -->

                <ng-container matColumnDef={{data.cols[9]}}>
                    <th mat-header-cell *matHeaderCellDef> Punzonado y plegado </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.stockActual - (element.zincado +
                        element.enZincador + element.punzonado)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[10]}}>
                    <th mat-header-cell *matHeaderCellDef> Punzonado </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.punzonado}}
                    </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[11]}}>
                    <th mat-header-cell *matHeaderCellDef> Zincado </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.zincado}}
                    </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[12]}}>
                    <th mat-header-cell *matHeaderCellDef> Zincador </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.zincador}}
                    </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[13]}}>
                    <th mat-header-cell *matHeaderCellDef> Zincador a cliente </th>
                    <td mat-cell *matCellDef="let element" align="left" style="color: #ffb81d; font-weight: 600;">
                        {{element.zincadorAClient}} </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[14]}}>
                    <th mat-header-cell *matHeaderCellDef> Fecha Modificacion </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.dataIni| date: 'dd/MM/yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef={{data.cols[15]}}>
                    <th mat-header-cell *matHeaderCellDef> Precio </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.precio}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="muestraUnItem(row)"
                    [ngClass]="{'row-highlighted':  itemSelected.idProd == row.idProd }"></tr>

            </table>
        </div>
        <form #formulario="ngForm" class="flex-row-wrap flex-30-responsive justify-between row-gap-10">

            <mat-form-field appearance="outline" class="flex-100">
                <mat-label> Descripcion </mat-label>
                <input [(ngModel)]="itemSelected.descripcion" name="Descripcion" matInput type="text"
                    (ngModelChange)="goTo()" required>
                <button class="limpiar-seleccion" *ngIf="itemSelected.descripcion" matSuffix mat-icon-button
                    aria-label="Clear" (click)="resetItem()"><mat-icon>close</mat-icon>
                </button>
            </mat-form-field>


            <mat-form-field appearance="outline" class="flex-100">
                <mat-label> Longitud </mat-label>
                <input [(ngModel)]="itemSelected.longitud" name="Longitud" matInput type="number" min="0" [required]="this.itemSelected.seFabrica && (this.itemSelected.productoFabricacion &&
                this.productosFabricacionMap[this.itemSelected.productoFabricacion]['IdTipusProducteFabricacio'] ===
                this.idTiposGenericos.VIGA)">
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-100">
                <mat-label> Chapa </mat-label>
                <input [(ngModel)]="itemSelected.chapa" name="Chapa" matInput type="text">
            </mat-form-field>


            <mat-form-field appearance="outline" class="flex-100">
                <mat-label> Peso </mat-label>
                <input [(ngModel)]="itemSelected.peso" name="Peso" matInput type="number">
            </mat-form-field>

            <app-autocomplete appearance="outline" class="auto100 flex-100" name="labelPare"
                [idOpcionSeleccionada]="itemSelected.idTipoProd" (cuandoSeCliqueEnIcono)="openDialogTipoProd()"
                (cuandoSeSeleccioneOpcion)="itemSelected.idTipoProd = $event" label="Tipo Producto"
                [options]="tipoProducto" icon="settings" [isRequired]="true">
            </app-autocomplete>


            <mat-checkbox appearance="outline" class="flex-100" [(ngModel)]="itemSelected.zincado"
                name="zincado">¿Zincado?</mat-checkbox>

            <mat-checkbox appearance="outline" class="flex-100" [(ngModel)]="itemSelected.seFabrica"
                name="seFabrica">¿Se
                Fabrica?</mat-checkbox>

            @if(itemSelected && itemSelected.seFabrica) {

            @if(this.productosFabricacion && this.productosFabricacion.length > 0) {
            <mat-form-field appearance="outline">
                <mat-label>Producto a fabricar</mat-label>
                <mat-select [(value)]="itemSelected.productoFabricacion" required
                    (selectionChange)="this.newProductoFabricacion()">
                    <mat-option>None</mat-option>
                    @for (producto of this.productosFabricacion; track producto) {
                    <mat-option
                        [value]="producto['IdProducteFabricacio']">{{producto['DescProducteFabricacio']}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }

            @if (this.itemSelected.productoFabricacion > 0 && ((this.itemSelected.seFabrica &&
            this.productosFabricacionMap[this.itemSelected.productoFabricacion]['IdTipusProducteFabricacio'] ===
            this.idTiposGenericos.VIGA)
            || (this.itemSelected.seFabrica &&
            this.productosFabricacionMap[this.itemSelected.productoFabricacion]['IdTipusProducteFabricacio'] ===
            this.idTiposGenericos.CARTELA)))
            {
            <mat-form-field appearance="outline">
                <mat-label>Medida</mat-label>
                <input [(ngModel)]="itemSelected.medida" name="Medida" matInput type="number">
            </mat-form-field>
            }

            @if (this.itemSelected.productoFabricacion > 0 && this.itemSelected.seFabrica &&
            this.productosFabricacionMap[this.itemSelected.productoFabricacion]['IdTipusProducteFabricacio'] ===
            this.idTiposGenericos.VIGA) {
            <mat-form-field appearance="outline">
                <mat-label> Modelo </mat-label>
                <input [(ngModel)]="itemSelected.modelo" name="Modelo" matInput type="text">

            </mat-form-field>
            }
            }

            <mat-form-field appearance="outline" class="flex-45-responsive">
                <mat-label> Stock Mínimo </mat-label>
                <input [(ngModel)]="itemSelected.stockMinimo" name="StockMinimo" matInput type="number">
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-45-responsive">
                <mat-label> Stock Actual </mat-label>
                <input [(ngModel)]="itemSelected.stockActual" name="StockActual" matInput type="number">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>
                    Ultima Modificacion de precio
                </mat-label>
                <input matInput readonly='true' [(ngModel)]="precioSelected.fechaInicio" name="fechaInicio"
                    [matDatepicker]="picker2" [min]="minDate" disabled>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field disabled appearance="outline" class="flex-100">
                <mat-label> Precio Actual </mat-label>
                <input [(ngModel)]="precioOriginal" name="precioOriginal" matInput type="number" min="0">
            </mat-form-field>




            <div class="LimpiarGuardar flex-row-wrap flex-100">
                <span class="flex-48-responsive">
                    <button [disabled]="!itemSelected.idProd" mat-raised-button
                        (click)="muestraPrecios($event)">PRECIOS</button>
                </span>
                <span class="flex-48-responsive">
                    <button mat-raised-button (click)="guardarItem(formulario)"
                        [disabled]="!this.itemSelected.idTipoProd ||
                        this.itemSelected.seFabrica && !this.itemSelected.productoFabricacion ||
                        this.itemSelected.seFabrica && this.itemSelected.descTipoFabricacion === this.tiposFabricacionGenericos.VIGA && this.itemSelected.medida === 0 || 
                        this.itemSelected.seFabrica && this.itemSelected.descTipoFabricacion === this.tiposFabricacionGenericos.CARTELA && this.itemSelected.medida === 0 ||
                        this.itemSelected.seFabrica && this.itemSelected.descTipoFabricacion === this.tiposFabricacionGenericos.VIGA && this.itemSelected.modelo === '' || 
                        this.itemSelected.seFabrica && this.itemSelected.descTipoFabricacion === this.tiposFabricacionGenericos.VIGA && this.itemSelected.longitud === 0">GUARDAR</button>
                </span>
            </div>
        </form>
    </mat-dialog-content>
</div>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>