<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input type="text" placeholder="Entrar palabras" aria-label="Number" matInput [formControl]="control"
    [matAutocomplete]="auto" [required]="isRequired">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
  <mat-icon *ngIf="icon" matSuffix (click)="cuandoSeCliqueEnIcono($event)">{{icon}}</mat-icon>
</mat-form-field>