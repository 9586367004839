<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

    <div class="layout-filtro flex flex-100 search">

        <div class="flex-6-responsive">
            <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>
        <mat-form-field appearance="outline" class="flex-25-responsive">
            <mat-label>Tipo Entrada</mat-label>
            <mat-select [(ngModel)]="filtroAlbaranesEntrada.tipoEntradaSelected" name="tipoEntrada">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of tiposEntrada" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Inicio
            </mat-label>
            <input matInput [(ngModel)]="filtroAlbaranesEntrada.fechaInicioSelected" name="fechaInicio" disabled
                [matDatepicker]="picker">
            <button class="button-date" *ngIf="filtroAlbaranesEntrada.fechaInicioSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroAlbaranesEntrada.fechaInicioSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Fin
            </mat-label>
            <input matInput [(ngModel)]="filtroAlbaranesEntrada.fechaFinSelected" disabled
                [min]="filtroAlbaranesEntrada.fechaInicioSelected" name="fechaFin" [matDatepicker]="picker2">
            <button class="button-date" *ngIf="filtroAlbaranesEntrada.fechaFinSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroAlbaranesEntrada.fechaFinSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>

        <div class="criterios__input flex-30-responsive">
            <app-autocomplete class="autocomplete" name="cliente" ngDefaultControl
                [idOpcionSeleccionada]="filtroAlbaranesEntrada.clienteSelected"
                (cuandoSeSeleccioneOpcion)="filtroAlbaranesEntrada.clienteSelected = $event" icon="" label="Cliente"
                [options]="clientes">
            </app-autocomplete>
        </div>

        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>Nº Albarán</mat-label>
            <input matInput type="number" [(ngModel)]="filtroAlbaranesEntrada.nAlbaranSelected" name="cantidad">
        </mat-form-field>
        <div class="flex-14-responsive">
            <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
        </div>
    </div>

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga" aria-label="Albaranes de Entrada">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="esAlbaranEntradaNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button" (click)="dialogEliminarUnAlbaran(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nAlbaran">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Albarán </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nAlbaran}} </td>
            </ng-container>

            <ng-container matColumnDef="fechaAlbaran">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Albarán </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.fechaAlbaran | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="tipoEntrada">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Entrada </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.tipoEntrada}} </td>
            </ng-container>

            <ng-container matColumnDef="nCompra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Compra </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nCompra}} </td>
            </ng-container>

            <ng-container matColumnDef="refExterna">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref. Externa </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.refExterna}} </td>
            </ng-container>

            <ng-container matColumnDef="nFactura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Factura </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nFactura}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnAlbaran(row)"
                [ngClass]="{'row-highlighted': albaranEntrada.nAlbaran == row.nAlbaran}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': albaranEntrada.length > 0}" [pageSizeOptions]="[20]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="albaranesEntrada.length == 0">No hay albaranes</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarAlbaranEntrada(formulario)"
        class="form-derecho flex-35-responsive row-gap-10">

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Nº Albarán</mat-label>
            <input disabled [(ngModel)]="albaranEntrada.nAlbaran" name="nAlbarana" matInput type="number">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label>
                Fecha Albarán
            </mat-label>
            <input required matInput [(ngModel)]="albaranEntrada.fechaAlbaran" name="fechaAlbaran"
                [matDatepicker]="picker3" disabled>
            <button class="button-date" *ngIf="albaranEntrada.fechaAlbaran" matSuffix mat-icon-button aria-label="Clear"
                (click)="albaranEntrada.fechaAlbaran=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>Tipo Entrada</mat-label>
            <mat-select [disabled]="!crearAlbaranNuevo" [(ngModel)]="albaranEntrada.tipoEntrada" name="tipoEntrada">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of tiposEntrada" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex align-center">
            <mat-form-field class="flex-40-responsive" appearance="outline">
                <mat-label> Nº Compra/Oferta</mat-label>
                <input disabled [(ngModel)]="albaranEntrada.nCompra" name="nCompra" matInput type="text">
            </mat-form-field>
            <span class="flex-20-responsive">
                <button [disabled]="!albaranEntrada.tipoEntrada || !crearAlbaranNuevo" class="button__settings"
                    type="button" (click)="buscarEntrada()" mat-raised-button>
                    <mat-icon>settings</mat-icon>
                </button>
            </span>
        </div>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label> Ref. Externa</mat-label>
            <input [(ngModel)]="albaranEntrada.refExterna" name="RefExterna" matInput type="text">
        </mat-form-field>

        <mat-form-field class="flex-51-responsive" appearance="outline">
            <mat-label> Nº Factura</mat-label>
            <input disabled [(ngModel)]="albaranEntrada.nFactura" name="nFactura" matInput type="text">
        </mat-form-field>

        <div class="flex-row-wrap justify-between flex-100 row-gap-10">
            <span class="flex-50-responsive">
                <button [disabled]="!albaranEntrada.nAlbaran" class="button__buscar" type="button"
                    (click)="irADetallesEntrada()" mat-raised-button>Detalles</button>
            </span>

            <span class="flex-50-responsive">
                <button [disabled]="!albaranEntrada.nAlbaran" class="button__buscar" type="button"
                    (click)="imprimirAlbaran()" mat-raised-button>Imprimir Albarán</button>
            </span>

            <span class="flex-50-responsive">
                <button [disabled]="!albaranEntrada.nCompra || !albaranEntrada.tipoEntrada" class="button__buscar"
                    type="submit" mat-raised-button>Guardar</button>
            </span>
        </div>
        <!--
        <span class="flex-50-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Volver</button>
        </span>
-->
    </form>

</form>