import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-report-albaran-zincado',
  templateUrl: './report-albaran-zincado.component.html',
  styleUrls: ['./report-albaran-zincado.component.css']
})
export class ReportAlbaranZincadoComponent implements OnInit {

  public nombreProveedor: any = "";
  private idProveedor: any = '';
  public direccionProveedor: any = '';
  public ciudadProveedor: any = '';
  public telefonoProveedor: any = '';
  public nAlbaran = ""
  private date = new Date();
  public fechaZincado = this.modificarFecha(this.date);
  public letra = "AP";
  public totalKilos = 0;
  public transportista = "";
  public cliente = "";

  public paginas: any[] = [];
  public nPaginas: number = 0;
  public noCabeTexto = false;
  public nPagina = 0;

  public listaProductos = [
    {
      nombreProducto: "**Angulo Especial",
      longitud: 0,
      desarrollo: "*",
      peso: 2,
      nPiezas: 2,
    },
    {
      nombreProducto: "Cartela MVV12",
      longitud: 0,
      desarrollo: "*",
      peso: 3,
      nPiezas: 16,
    },
    {
      nombreProducto: "CENTRAL MVH12 ",
      longitud: 2000,
      desarrollo: "260 * 6",
      peso: 25,
      nPiezas: 198,
    },
    {
      nombreProducto: "CENTRAL MVH20 ",
      longitud: 3000,
      desarrollo: "260 * 8",
      peso: 30.5,
      nPiezas: 20,
    },
    {
      nombreProducto: "CENTRAL MVH30 ",
      longitud: 3000,
      desarrollo: "360 * 6",
      peso: 35.95,
      nPiezas: 110,
    },
    {
      nombreProducto: "CENTRAL MVH16 ESPECIAL ",
      longitud: 4000,
      desarrollo: "400 * 6",
      peso: 10,
      nPiezas: 20,
    },
    {
      nombreProducto: "CENTRAL MVH16 ",
      longitud: 2000,
      desarrollo: "260 * 6",
      peso: 25,
      nPiezas: 198,
    },
    {
      nombreProducto: "**Angulo Especial",
      longitud: 0,
      desarrollo: "*",
      peso: 2,
      nPiezas: 2,
    },
    {
      nombreProducto: "Cartela MVV12",
      longitud: 0,
      desarrollo: "*",
      peso: 3,
      nPiezas: 16,
    },
    {
      nombreProducto: "CENTRAL MVH12 ",
      longitud: 2000,
      desarrollo: "260 * 6",
      peso: 25,
      nPiezas: 198,
    },
    {
      nombreProducto: "CENTRAL MVH20 ",
      longitud: 3000,
      desarrollo: "260 * 8",
      peso: 30.5,
      nPiezas: 20,
    },
    /*   {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "**Angulo Especial",
         longitud: 0,
         desarrollo: "*",
         peso: 2,
         nPiezas: 2,
       },
       {
         nombreProducto: "Cartela MVV12",
         longitud: 0,
         desarrollo: "*",
         peso: 3,
         nPiezas: 16,
       },
       {
         nombreProducto: "CENTRAL MVH12 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH20 ",
         longitud: 3000,
         desarrollo: "260 * 8",
         peso: 30.5,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "**Angulo Especial",
         longitud: 0,
         desarrollo: "*",
         peso: 2,
         nPiezas: 2,
       },
       {
         nombreProducto: "Cartela MVV12",
         longitud: 0,
         desarrollo: "*",
         peso: 3,
         nPiezas: 16,
       },
       {
         nombreProducto: "CENTRAL MVH12 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH20 ",
         longitud: 3000,
         desarrollo: "260 * 8",
         peso: 30.5,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "**Angulo Especial",
         longitud: 0,
         desarrollo: "*",
         peso: 2,
         nPiezas: 2,
       },
       {
         nombreProducto: "Cartela MVV12",
         longitud: 0,
         desarrollo: "*",
         peso: 3,
         nPiezas: 16,
       },
       {
         nombreProducto: "CENTRAL MVH12 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH20 ",
         longitud: 3000,
         desarrollo: "260 * 8",
         peso: 30.5,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "**Angulo Especial",
         longitud: 0,
         desarrollo: "*",
         peso: 2,
         nPiezas: 2,
       },
       {
         nombreProducto: "Cartela MVV12",
         longitud: 0,
         desarrollo: "*",
         peso: 3,
         nPiezas: 16,
       },
       {
         nombreProducto: "CENTRAL MVH12 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH20 ",
         longitud: 3000,
         desarrollo: "260 * 8",
         peso: 30.5,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "**Angulo Especial",
         longitud: 0,
         desarrollo: "*",
         peso: 2,
         nPiezas: 2,
       },
       {
         nombreProducto: "Cartela MVV12",
         longitud: 0,
         desarrollo: "*",
         peso: 3,
         nPiezas: 16,
       },
       {
         nombreProducto: "CENTRAL MVH12 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       },
       {
         nombreProducto: "CENTRAL MVH20 ",
         longitud: 3000,
         desarrollo: "260 * 8",
         peso: 30.5,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH30 ",
         longitud: 3000,
         desarrollo: "360 * 6",
         peso: 35.95,
         nPiezas: 110,
       },
       {
         nombreProducto: "CENTRAL MVH16 ESPECIAL ",
         longitud: 4000,
         desarrollo: "400 * 6",
         peso: 10,
         nPiezas: 20,
       },
       {
         nombreProducto: "CENTRAL MVH16 ",
         longitud: 2000,
         desarrollo: "260 * 6",
         peso: 25,
         nPiezas: 198,
       }*/
  ];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(data => {
      this.idProveedor = data.nProveedor;
      this.apiService.obtenerProveedorParams(this.idProveedor).subscribe((dato: any) => {
        console.log(dato.response);
        this.nombreProveedor = dato.response[0].nombre;
        this.direccionProveedor = dato.response[0].direccion;
        this.ciudadProveedor = dato.response[0].ciudad;
        this.telefonoProveedor = dato.response[0].telefono;
        console.log(this.nombreProveedor);
      })
    });
    this.apiService.obtenerZincadoPdf().subscribe(data => {

      this.listaProductos = data.response;
      this.calcularPeso();
      this.cuentaLineas();

      console.log("Productos: >>>>>>>>>>>>>>>>>>>>", this.listaProductos);
      console.log('DATA: >>>>>>>>>', data.response)
    })

  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }


  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('antesDePagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`albaranZincador${this.fechaZincado}.pdf`);
    })
  }

  private cuentaLineas() {
    let lineasPagina = 0;
    let pagina: any[] = [];
    let arrayProductos: any[] = this.listaProductos;
    let totalLineas = this.listaProductos.length;
    let imprimirTotal = false;
    let nLinea = 0;
    for (let i = 0; i < totalLineas; i++) {
      let lineaProducto = arrayProductos[0];
      lineasPagina = lineasPagina + 1;
      if (i + 1 === totalLineas) {
        imprimirTotal = true;
      } else { imprimirTotal = false };
      let elementProducto = {
        ...lineaProducto,
        imprimirTotal: imprimirTotal,
      }
      pagina.push(elementProducto);
      arrayProductos.splice(0, 1);
      if (lineasPagina > 45) {
        this.nPaginas = this.nPaginas + 1;
        let item = {
          lineas: pagina,
          nPagina: this.nPaginas,
          imprimirTotal: imprimirTotal,
        }
        this.paginas.push(item);
        lineasPagina = 0;
        pagina = [];
      }
      if (i + 1 === totalLineas) {
        this.nPaginas = this.nPaginas + 1;
        let item = {
          lineas: pagina,
          nPagina: this.nPaginas,
          imprimirTotal: imprimirTotal,
        }
        this.paginas.push(item);
        if (lineasPagina > 28) {
          this.noCabeTexto = true;
          this.nPaginas = this.nPaginas + 1;
        }
        else { this.noCabeTexto = false };
      }
    }
    console.log("PAGINAS: ", this.paginas);
  }

  private calcularPeso() {
    this.listaProductos.forEach(producto => {
      this.totalKilos = this.totalKilos + producto.nPiezas * producto.peso;
    })
  }

  private modificarFecha(data: any) {
    var day = data.getDate().toString().padStart(2, "0");
    var month = (1 + data.getMonth()).toString().padStart(2, "0");
    var year = 1900 + data.getYear();
    var answer = day + "-" + month + "-" + year;
    return answer;
  }
}
