// Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15. De totes formes no veig que aquí s'utilitzi
//import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { DateToString } from 'src/app/services/dataFunction';
import { mantenimiento2Componente, preciosProd } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';



@Component({
  selector: 'app-dialog-mantenimiento-kit',
  templateUrl: './dialog-mantenimiento-kit.component.html',
  styleUrls: ['./dialog-mantenimiento-kit.component.css']
})
export class DialogMantenimientoKitComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimientoKitComponent>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog, 
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
  ) { }

  public adjuntarImagen = false;
  items: any[]=[];
  itemsTots: any[]=[];
  preciosTodos: any[]=[];
  preciosConcreto:any[]=[];
  subTipo: IdLabelObject[]= [];
  //displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2], this.data.cols[3], this.data.cols[4], this.data.cols[5], this.data.cols[6], this.data.cols[7], this.data.cols[8], this.data.cols[9], this.data.cols[10]];
  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[7], this.data.cols[8], this.data.cols[9], this.data.cols[10], this.data.cols[11]];
  itemSelected : any ={
    idProd:  null, 
    descripcion : null, 
    longitudCentral : null, 
    longitudExtremo : null, 
    inerciaCentral : null, 
    inerciaExtremo : null, 
    wCentral : null, 
    wExtremo : null,
    stockMinimo : null, 
    stockActual : null, 
    idSubTipo : null, 
    subTipo : null, 
    editable : false,
    imagen : null,
    tieneImagen: null
  };

  itemOriginal : any ={
    idProd:  null, 
    descripcion : null, 
    longitudCentral : null, 
    longitudExtremo : null, 
    inerciaCentral : null, 
    inerciaExtremo : null, 
    wCentral : null, 
    wExtremo : null,
    stockMinimo : null, 
    stockActual : null, 
    idSubTipo : null, 
    subTipo : null, 
    editable : false, 
    imagen : null,
    tieneImagen: null
  };

  precioSelected : any = {
    idProd: null,
    fechaInicio: null,
    fechaFin: null,
    precio: null,
  }
  preciosSelected = false;
  precioOriginal: number = 0;
  closeData: any;
  openData: any;
  minDate = new Date();
  selectedFile: any;
  form: any;
  file: any;


  ngOnInit(): void {
    this.obtenerKitMant();
    this.obtenerSubTipo();
    this.form = this.formBuilder.group({
      avatar: ['']
    });
  }
  
  private obtenerSubTipo(){
    this.apiService.obtenerSubTipo().subscribe(data => {
      console.log("tipo prod",data);
      this.subTipo = data.response;
    });
  }
  private obtenerKitMant(){
    this.apiService.obtenerKitMant().subscribe(data =>{
      console.log("data",data);
      this.items = data.response;
      this.itemsTots = this.items;
    })
  } 

  dialogEliminarUnCampo(event: any, item: any){
    this.openDialog("ELIMINAR","¿Quiere eliminar "+item.descripcion+"?", true,"eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo =="eliminar") this.eliminarItem();
      if(result && tipo =="confirmacion")this.anadirKit();
    });
  }

  eliminarItem(){
    this.apiService.borrarkitMant(this.itemSelected.idProd).subscribe(data =>{
      this.obtenerKitMant();
      this.resetItem();
    });
  }

  muestraUnItem(item : any){
    this.itemSelected = {... item};
    console.log("consulto; ", this.itemSelected);
     this.itemOriginal = {... item};
    this.obtenerPrecio();
  }

  guardarItem(formulario: NgForm){
    console.log("guardar; ", this.itemSelected);
    if (this.itemSelected.imgagen) {
      this.itemSelected.tieneImagen = "SI";
    }
    if(this.itemSelected.idProd != null){
      console.log("im in 1");
      console.log(this.itemOriginal , this.itemSelected)
      if(this.itemOriginal != this.itemSelected){
        console.log("im in 2");
          this.apiService.modificarkitMant(this.itemSelected).subscribe(dades => {
            this.obtenerKitMant();
            this.tratarPrecios();
          });
        
      }
      else this.tratarPrecios();
      
    }
    else{
      this.openDialog("KIT NUEVO","¿Quiere crear este kit nuevo "+this.itemSelected.descripcion+"?", true,"confirmacion");
    }
    if(this.file != null) this.onSubmit();    
  }

  anadirKit(){
    this.apiService.anadirkitMant(this.itemSelected).subscribe(dades => {
      this.itemSelected.idProd = dades.response;
      this.obtenerKitMant();
      this.tratarPrecios();
    });
  }


  muestraPrecios(event: any){
    event.stopPropagation();
    var dialogRef: any;
    dialogRef = preciosProd(this.dialogMantenimiento, this.itemSelected.descripcion,this.itemSelected.idProd );
    dialogRef.afterClosed().subscribe( (data:any) => {
      console.log("cerrrado");
      this.obtenerKitMant();
      this.obtenerPrecio();
    });
  }
  


  obtenerPrecio(){
    this.apiService.obtenerPrecioProducto(this.itemSelected.idProd).subscribe(dades => {
      console.log("idProd", this.itemSelected.idProd);
      console.log(dades.response.length -1);
      if(dades.response.length>0){
        this.precioSelected = dades.response[dades.response.length -1];
        console.log(this.precioSelected.fechaInicio);
        this.precioOriginal = this.precioSelected.precio;
        this.openData = this.precioSelected.fechaInicio;
        this.closeData = new Date(this.precioSelected.fechaInicio);
        console.log("data1", this.closeData);
        this.closeData.setDate(this.closeData.getDate() + 1);
        this.minDate = new Date();
        if(this.minDate < this.closeData) this.minDate = this.closeData; 
      }
      else {
        this.precioSelected.fechaInicio = null;
        this.precioSelected.precio = null;
        this.precioOriginal = 0;
        //this.minDate = new Date();
        //this.precioSelected.fechaInicio = this.minDate;
        console.log(this.precioSelected.fechaInicio);
      }  
    });
  }


  tratarPrecios(){

    let fechaIncio = new Date(this.precioSelected.fechaInicio); 
    console.log(fechaIncio, new Date());
    if(fechaIncio < new Date()) fechaIncio = new Date();


    this.closeData = fechaIncio;
    this.closeData.setDate(fechaIncio.getDate() - 1);
    this.closeData = DateToString(this.closeData);
    
    if(this.precioOriginal != this.precioSelected.precio && DateToString(this.precioSelected.fechaInicio) != DateToString(new Date()) ){
      var pvp ={
        idProd: this.itemSelected.idProd, 
        fechaIni : this.openData,
        fechaIniOriginal : this.closeData,
        precio: this.precioSelected.precio
      };
      console.log(pvp);
      this.apiService.modificarPrecioProducto(pvp).subscribe(data =>{
        fechaIncio.setDate(fechaIncio.getDate()+1);
        var pvp2 ={
          idProd: this.itemSelected.idProd, 
          fechaIni : DateToString(fechaIncio),
          precio: this.precioOriginal
        };
        this.apiService.anadirPrecioProducto(pvp2).subscribe(data=>{
          this.obtenerPrecio();
          this.obtenerKitMant();
        });
      })
    }
    //else this.openDialog("AVISO","No se ha modificado el precio, el canvio de precio no se va a efectuar ", true,"aviso");
  }



  resetItem(){
    this.itemSelected = {
      idProd:  null, 
      descripcion : null, 
      longitudCentral : null, 
      longitudExtremo : null, 
      inerciaCentral : null, 
      inerciaExtremo : null, 
      wCentral : null, 
      wExtremo : null,
      stockMinimo : null, 
      stockActual : null, 
      idSubTipo : null, 
      subTipo : null, 
      editable : false, 
    }
    this.itemOriginal = {
      idProd:  null, 
      descripcion : null, 
      longitudCentral : null, 
      longitudExtremo : null, 
      inerciaCentral : null, 
      inerciaExtremo : null, 
      wCentral : null, 
      wExtremo : null,
      stockMinimo : null, 
      stockActual : null, 
      idSubTipo : null, 
      subTipo : null, 
      editable : false, 
    }
    
    this.precioSelected = {
      idProducto: null,
      fechaInicio: null,
      fechaFin: null,
      precio: null,
    }
    this.goTo();
  }

  muestraComponents(){
    mantenimiento2Componente(this.dialog, this.itemSelected.idProd, this.itemSelected.descripcion);
  }

  public goTo(){
    if(this.itemSelected.idProducto == null){
      if(this.itemSelected.descripcion != null){
        this.items = this.itemsTots.filter(item => item.descripcion.toLowerCase().includes(this.itemSelected.descripcion.toLowerCase() ));
        if(this.items.length >0) this.table.renderRows();
      }
      else this.items = this.itemsTots;
    }
  }

  onFileSelect(event: Event) {
    let reader = new FileReader();
    let element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if(fileList){
      this.file  = fileList[0];
      
      this.form.get('avatar').setValue(this.file);
    }
  }    


  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);
    this.apiService.uploadFile(formData).subscribe( data =>{
      
      this.itemSelected.imagen = data.url;
      });
  }

}
