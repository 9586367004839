import { AsyncPipe, CommonModule, DOCUMENT, DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OpcionProveedor, Producto } from 'src/app/types/stock';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-dialog-mantenimiento-stock-produccion',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatIconModule,
    MatTable,
    MatLabel,
    MatDatepickerModule,
    MatDatepickerToggle,
    DatePipe,
    CommonModule,
    MatTableModule,
    MatSelect,
    MatOption,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatSuffix
  ],
  templateUrl: './dialog-mantenimiento-stock-produccion.component.html',
  styleUrl: './dialog-mantenimiento-stock-produccion.component.css'
})
export class DialogMantenimientoStockProduccionComponent {
  @ViewChild('numberInput') numberInput!: ElementRef;
  public dataSource!: MatTableDataSource<Producto>;

  displayedColumns: string[] = [
    "buttons",
    "Producto",
    "Fabricado",
    "PunzonadoYPlegado",
    "Punzonado",
    "Zincado",
    "Zincador",
    "Zincador al cliente"
  ]

  public producto: Producto = {} as Producto;
  public productos: Producto[] = [];
  public productosAlZincador: Producto[] = [];

  public cantidadAZincador: number | null = null;
  public cantidadDevueltas: number | null = null;
  public cantidadZincadorACliente: number | null = null;

  public opcionesProveedor: OpcionProveedor[] = [];
  public opcionProveedor: any = null;
  public hayProductoSeleccionado = false;
  public hayItemSeleccionado = false;
  public loaded = false;
  public loadedAutocomplete = false;

  public control = {} as FormControl;
  filteredOptions: Observable<any[]> = new Observable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<DialogMantenimientoStockProduccionComponent>,
    public dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource<Producto>(this.productosAlZincador);
  }

  ngOnInit(): void {
    this.obtenerProductos();
    this.obtenerProveedor();
    this.obtenerProductosAlZincador();
    this.initializeFormControl();
    this.loaded = true;
  }

  handleWheelEvent(event: WheelEvent): void {
    event.preventDefault();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.productos.filter(producto => producto?.nombreProducto?.toString().toLowerCase().includes(filterValue));
  }

  private obtenerProductos() {
    this.apiService.obtenerProductosStock().subscribe(data => {
      this.productos = data.response;
      console.log('PRODUCTOS', this.productos);
    })
  }

  private obtenerProveedor() {
    this.apiService.obtenerProveedor().subscribe(data => {
      console.log(data.response);
      this.opcionesProveedor = data.response;
    })
  }

  public displayFn(producto: Producto) {
    return producto?.nombreProducto ?? '';
  }

  public onProductSelected(selection: any) {
    this.producto = _.cloneDeep({ ...selection.option.value });
    this.cantidadAZincador = 0;
    this.cantidadDevueltas = 0;
    this.cantidadZincadorACliente = 0;
    this.hayProductoSeleccionado = true;
    this.control.disable();
    console.log('PRODUCTO', this.producto);
  }

  public guardarStock() {
    if ((this.producto.EnZincador - this.cantidadDevueltas!) - this.cantidadZincadorACliente! < 0) return;
    if (this.cantidadAZincador! > this.producto.StockActual) return;
    if (this.cantidadAZincador! < 0 || this.cantidadDevueltas! < 0 || this.cantidadZincadorACliente! < 0) return;

    const { nombreProducto, ...body } =
    {
      ...this.producto,
      StockActual: this.producto.StockActual - (this.cantidadZincadorACliente!),
      Zincado: this.producto.Zincado + this.cantidadDevueltas!,
      EnZincador: (this.producto.EnZincador - this.cantidadDevueltas!) - this.cantidadZincadorACliente! + this.cantidadAZincador!,
      ZincadorAClient: this.producto.ZincadorAClient + this.cantidadZincadorACliente!,
      EnviarAZincador: this.cantidadAZincador!,
      Tornades: this.cantidadDevueltas!
    };


    this.apiService.guardarStockProducto(body).subscribe(() => {
      this.obtenerProductos();
      this.obtenerProductosAlZincador();
      this.resetProduct();
    })
  }

  private obtenerProductosAlZincador() {
    this.apiService.obtenerProductosAlZincador().subscribe(data => {
      this.productosAlZincador = data.response;
      console.log('PRODUCTOS AL ZINCADOR:', this.productosAlZincador)
      this.dataSource = new MatTableDataSource<Producto>(this.productosAlZincador);
    })
  }

  public openDeleteDialog(id: number): void {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { id: id, title: 'QUITAR PRODUCTO', msg: `¿Quiere quitar este producto?`, cancelBtn: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.eliminarProductoAlZincador(id);
    });
  }

  private eliminarProductoAlZincador(id: number) {
    this.apiService.eliminarProductoAlZincador(id).subscribe(() => {
      this.obtenerProductos();
      this.obtenerProductosAlZincador();
      this.resetProduct();
      this.cantidadAZincador = null;
      this.cantidadDevueltas = null;
    })
  }

  public seleccionarProducto(producto: any) {
    this.producto = _.cloneDeep({ ...producto });
    this.cantidadAZincador = 0;
    this.cantidadDevueltas = 0;
    this.cantidadZincadorACliente = 0;
    this.hayItemSeleccionado = true;
    console.log('PRODUCTO SELECCIONADO', this.producto);
  }

  public resetProduct() {
    this.initializeFormControl();
    this.cantidadAZincador = null;
    this.cantidadDevueltas = null;
    this.cantidadZincadorACliente = null;
    this.opcionProveedor = null;
    this.hayItemSeleccionado = false;
  }

  public ImprimirAlbaranZincador() {
    console.log(this.opcionProveedor);
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-albaran-zincado?nProveedor=' + this.opcionProveedor;
    link.click();
    link.remove();
  }

  public initializeFormControl() {
    this.control = new FormControl('', Validators.required);
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value?.toString() ?? '')),
    );
    this.producto = {} as Producto;
    this.control.enable();
    this.hayProductoSeleccionado = false;
    this.loadedAutocomplete = true;
  }

  public noHayProductosParaEnviar(): boolean {
    return this.productosAlZincador.some(producto => producto.EnviarAZincador === 0);
  }

  public puedeGuardar(): boolean {
    return !this.hayProductoSeleccionado && !this.hayItemSeleccionado ||
      // Evita numeros negativos
      this.cantidadAZincador! < 0 || this.cantidadDevueltas! < 0 || this.cantidadZincadorACliente! < 0 ||
      // Enviar a Zincador
      this.cantidadAZincador! > this.producto.StockActual - (this.producto.Zincado + this.producto.EnZincador) ||
      // Devueltas
      this.cantidadDevueltas! > (this.producto.EnZincador - this.cantidadZincadorACliente!) ||
      // Zincador a Cliente
      this.cantidadZincadorACliente! > (this.producto.EnZincador - this.cantidadDevueltas!)
  }

  public onCantidadAZincadorChange(event: any) {
    this.cantidadAZincador = Number(event.target.value);
  }

  public onCantidadZincadorAClienteChange(event: any) {
    this.cantidadZincadorACliente = Number(event.target.value);
    console.log('Cantidad Zincador A Cliente:', this.cantidadZincadorACliente);
    console.log('Zincador A Cliente:', this.producto.ZincadorAClient);

  }
}
