import { CommonModule, DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { OrdenesCompraComponent } from '../../compras/ordenes-compra/ordenes-compra.component';
import { OrdenesElaboracionComponent } from '../ordenes-elaboracion.component';
import { ProductoParaElaborar } from 'src/app/types/ordenElaboracion';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-detalles-orden-elaboracion',
  standalone: true,
  imports: [
    MatIconModule,
    DatePipe,
    MatPaginatorModule,
    CommonModule,
    MatTableModule,
    MatSort,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatButtonModule,
  ],
  templateUrl: './detalles-orden-elaboracion.component.html',
  styleUrl: './detalles-orden-elaboracion.component.css'
})
export class DetallesOrdenElaboracionComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public dataSource!: MatTableDataSource<ProductoParaElaborar>;
  displayedColumns: string[] = ['DescProducte', 'Longitud', 'num_unitats', 'fab_estat'];

  public paramsUrl: Params = {};

  public hayProductoSeleccionado = false;
  public listaProductosElaboracion: ProductoParaElaborar[] = [];
  public IdOrdenElaboracion = 0;
  public productoElaboracion: ProductoParaElaborar = {} as ProductoParaElaborar;

  constructor(private apiService: ApiService, private router: Router, private tituloService: TituloService, private activatedRoute: ActivatedRoute) {
    this.dataSource = new MatTableDataSource(this.listaProductosElaboracion);
  }

  ngOnInit() {
    this.obtenerOrdenElaboracionById();
    this.tituloService.enviarTitulo();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private obtenerOrdenElaboracionById() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.IdOrdenElaboracion = Number(params.idOrden);
      this.apiService.obtenerOrdenElaboracionDetalle(this.IdOrdenElaboracion).subscribe((data) => {
        this.listaProductosElaboracion = data.response;
        this.listaProductosElaboracion.forEach(prod => {
          if (prod.origen === 'RECORTE') {
            prod.DescProducte = prod.DescProducte + ' - RECORTE';
          }
        });
        this.dataSource = new MatTableDataSource(this.listaProductosElaboracion);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  public seleccionarProducto(producto: any) {
    this.productoElaboracion = producto;
    this.hayProductoSeleccionado = true;
  }

  public resaltarProducto(rowIdElemento: number, IdProducto: number) {
    return OrdenesElaboracionComponent.definirColorRow(rowIdElemento, IdProducto);
  }

  public resetProductoSeleccionado() {
    this.productoElaboracion = {} as ProductoParaElaborar;
    this.hayProductoSeleccionado = false;
  }

  public volverAOrdenes() {
    this.router.navigate(['ordenes-de-elaboracion'], { queryParams: {} });
    this.resetProductoSeleccionado();
  }
}
