<div class="section-content">
    <p class="section-title">Tipo:</p>

    @if (this.fabricacionService.tiposProducto && this.fabricacionService.tiposProducto.length > 0) {
    @for (tipo of this.fabricacionService.tiposProducto; track $index) {
    <button mat-raised-button class="section-button"
        [ngClass]="{'selected': tipo.IdProducteFabricacio === this.fabricacionService.tipoProductoSeleccionado.IdProducteFabricacio}"
        (click)="onTipoSelection(tipo)" [disabled]="disabledButton()">
        {{tipo.DescProducteFabricacio | uppercase}}
    </button>
    }
    <!-- @if (disableVolverAtras()) {
    <button mat-raised-button class="action-button" (click)="this.fabricacionService.volverAChapas()" [disabled]="disableVolverAChapas()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Volver
    </button>
    } @else { -->
    <button mat-raised-button class="action-button" (click)="this.fabricacionService.volver()"
        [disabled]="disableVolverAtras()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Atrás
    </button>
    }
    <!-- } -->
</div>