<form class="container flex-row-wrap">

    <!-- Agregar criterios de filtrado -->
    <!-- <div class="layout-filtro flex flex-100">
    </div> -->

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 standar-table-mecanoviga"
            aria-label="Detalles de orden de elaboración">

            <!-- Column definitions -->
            <ng-container matColumnDef="DescProducte">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 28px !important;"> Producto
                </th>
                <td mat-cell *matCellDef="let producto" style="padding-left: 28px !important;">
                    {{producto.DescProducte}} </td>
            </ng-container>

            <ng-container matColumnDef="Longitud">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitud </th>
                <td mat-cell *matCellDef="let producto"> {{producto.Longitud}} </td>
            </ng-container>

            <ng-container matColumnDef="num_unitats">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidades </th>
                <td mat-cell *matCellDef="let producto"> {{producto.num_unitats}} </td>
            </ng-container>

            <ng-container matColumnDef="fab_estat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let producto"> {{producto.fab_estat.toUpperCase()}} </td>
            </ng-container>

            <!-- Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarProducto(row)"
                [ngClass]="resaltarProducto(row.IdElaboracioLn, productoElaboracion.IdElaboracioLn)"></tr>

        </table>

        @if (listaProductosElaboracion.length === 0) {
        <div class="noHayNadaEnTabla">No hay productos fabricados</div>
        }

        <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons
            [ngClass]="{'hidden':true, 'no-hidden': listaProductosElaboracion.length > 0}"
            aria-label="Seleccionar página de productos fabricados">
        </mat-paginator>

    </div>

    <form #formulario="ngForm" class="form-derecho flex-35-responsive justify-between row-gap-15">

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label> Producto </mat-label>
            <input matInput name="producto" type="text" [ngModel]="productoElaboracion.DescProducte" disabled>
        </mat-form-field>

        <mat-form-field class="flex-49-responsive" appearance="outline">
            <mat-label> Longitud </mat-label>
            <input matInput name="longitud" type="number" [ngModel]="productoElaboracion.Longitud" disabled>
        </mat-form-field>

        <mat-form-field class="flex-49-responsive" appearance="outline">
            <mat-label> Unidades </mat-label>
            <input matInput name="unidades" type="number" [ngModel]="productoElaboracion.num_unitats" disabled>
        </mat-form-field>

        <mat-form-field class="flex-49-responsive" appearance="outline">
            <mat-label> Estado </mat-label>
            <input matInput name="estado" type="text" [ngModel]="productoElaboracion.fab_estat" disabled>
        </mat-form-field>

        <span class="flex-48-responsive mt-10">
            <button [disabled]="!hayProductoSeleccionado" (click)="resetProductoSeleccionado()" type="button"
                mat-raised-button>Limpiar</button>
        </span>

        <span class="flex-48-responsive mt-10">
            <button class="button__buscar" type="button" mat-raised-button (click)="volverAOrdenes()">Volver</button>
        </span>
    </form>

</form>