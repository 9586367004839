import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { tipoMapper, tiposGenericos } from 'src/app/constants/fabricacion.constant';
import { FabricacionService } from 'src/app/services/fabricacion.service';

@Component({
  selector: 'app-seccion-unidades',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatRadioModule, FormsModule],
  templateUrl: './seccion-unidades.component.html',
  styleUrl: './seccion-unidades.component.css'
})
export class SeccionUnidadesComponent {

  private indiceActualLongitud: number = -1;
  public cantidadAIncrementarUnidades = 1;
  public tiposMapper = tipoMapper;
  public tipoGenerico = tiposGenericos;

  public tipoViga!: boolean;
  public tipoCartela!: boolean;
  public tipoPlacaDeUnion!: boolean;
  public tipoCartelaIpe!: boolean;

  private indiceProductoGeneradoAnterior = 0;
  private agregarLongitud = '';
  private agregarUnidades = '';

  public incrementar!: boolean;
  public mostrarSiguiente!: boolean;

  constructor(public fabricacionService: FabricacionService) { }

  ngOnInit() {
    this.tipoViga = this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.tipoGenerico.VIGA;
    this.tipoCartela = this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.tipoGenerico.CARTELA;
    this.tipoPlacaDeUnion = this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.tipoGenerico.PLACA_DE_UNION;
    this.tipoCartelaIpe = this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.tipoGenerico.CARTELA_ESPECIAL_IPE
    if (!this.tipoViga) this.setUnidades();
  }

  public siguienteLongitud() {
    this.fabricacionService.hayLongitudSeleccionada = true;
    this.indiceActualLongitud = (this.indiceActualLongitud + 1) % this.fabricacionService.longitudesDeProducto.length;
    this.setLongitud();
  }

  public anteriorLongitud() {
    this.fabricacionService.hayLongitudSeleccionada = true;
    this.indiceActualLongitud = (this.indiceActualLongitud - 1 + this.fabricacionService.longitudesDeProducto.length) % this.fabricacionService.longitudesDeProducto.length;
    this.setLongitud();
  }

  private setLongitud() {
    this.fabricacionService.longitudProductoSeleccionada = this.fabricacionService.longitudesDeProducto[this.indiceActualLongitud];
    this.agregarLongitud = `- Longitud: ${this.fabricacionService.longitudProductoSeleccionada}`;

    this.fabricacionService.productoGenerado = `${this.fabricacionService.productoGenerado.slice(0, this.fabricacionService.productoGeneradoModelosLength)} ${this.agregarLongitud}`;
    this.fabricacionService.productoGeneradoLongitudesLength = this.fabricacionService.productoGenerado.length;
    this.setUnidades();
  }

  public incrementarUnidades() {
    if (this.cantidadAIncrementarUnidades === 1) this.fabricacionService.unidades++;
    if (this.cantidadAIncrementarUnidades === 10) this.fabricacionService.unidades += 10;

    this.setUnidades();
  }

  public decrementarUnidades() {
    if (this.fabricacionService.unidades === 0 || this.fabricacionService.unidades - this.cantidadAIncrementarUnidades < 0) {

      this.fabricacionService.unidades = 1;
      this.fabricacionService.hayUnidadesSeleccionadas = false;

      this.setUnidades();

      return;
    }

    if (this.cantidadAIncrementarUnidades === 1) this.fabricacionService.unidades--;
    if (this.cantidadAIncrementarUnidades === 10) this.fabricacionService.unidades -= 10;

    this.setUnidades();
  }

  private setUnidades() {
    if (this.tipoViga) this.indiceProductoGeneradoAnterior = this.fabricacionService.productoGeneradoLongitudesLength;
    if (this.tipoCartela) this.indiceProductoGeneradoAnterior = this.fabricacionService.productoGeneradoOrigenesLength;
    if (this.tipoPlacaDeUnion) this.indiceProductoGeneradoAnterior = this.fabricacionService.productoGeneradoTiposLength;
    if (this.tipoCartelaIpe) this.indiceProductoGeneradoAnterior = this.fabricacionService.productoGeneradoOrigenesLength;

    if (this.fabricacionService.unidades !== 0 || this.fabricacionService.unidades - this.cantidadAIncrementarUnidades > 0) {
      this.fabricacionService.hayUnidadesSeleccionadas = true;
      this.agregarUnidades = `- Unidades: ${this.fabricacionService.unidades}`;
      this.fabricacionService.productoGenerado = `${this.fabricacionService.productoGenerado.slice(0, this.indiceProductoGeneradoAnterior)} ${this.agregarUnidades}`;
      this.fabricacionService.productoGeneradoUnidadesLength = this.fabricacionService.productoGenerado.length;
    }
  }

  public disableIncrementar() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public disableDecrementar() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado || this.fabricacionService.unidades === 1) return true;
    return false;
  }

  public disableUnidades() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoViga && !this.fabricacionService.hayModeloSeleccionado || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public disablelCheck1() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public disablelCheck10() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public disableActionButton() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && this.fabricacionService.unidades === 1 || this.tipoPlacaDeUnion && this.fabricacionService.unidades === 1) return true;
    return false;
  }

  public disableOkButton() {
    if (this.tipoViga && !this.fabricacionService.hayLongitudSeleccionada || this.tipoCartela && !this.fabricacionService.hayOrigenSeleccionado || this.tipoPlacaDeUnion && !this.fabricacionService.hayTipoProductoSeleccionado || this.fabricacionService.unidades === 0) return true;
    return false;
  }

  public handleVolverAtras() {
    if (this.tipoViga) this.fabricacionService.volverAModelos();
    if (this.tipoCartela) this.fabricacionService.volverAOrigenes();
    if (this.tipoPlacaDeUnion) this.fabricacionService.volverATipos();
  }
}
