import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth-service';

@Injectable({
    providedIn: 'root'
})
export class UserGuard {
    constructor(
        private authService: AuthService, 
        private router: Router
    ) { }

    canActivate(): boolean {
        if (this.authService.userRoles && !this.authService.isFabricante()) {
            return true;
        } else {
            console.log('FABRICANTE', this.authService.isFabricante());
            this.router.navigate(['taller']);
            return false;
        }
    }
}
