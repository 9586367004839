<div *ngIf="idiomaOferta != 1" class="content">

    <h1 class="titulo">NOTAS ACLARATORIAS</h1>

    <p class="resaltado">
        MECANOVIGA, S.L. facturará las unidades que se hayan suministrado según las necesidades de la obra
        y de acuerdo con la dirección facultativa.
        <br><br>
        La presente valoración se ha efectuado con los datos disponibles.
        <br><br>
        LOS PRECIOS FACILITADOS INCLUYEN:
    </p>
    <ul>
        <li>Todas las piezas que forman el kit MECANOVIGA y centrales pertinentes, tornilleria necesaria para la unión
            de los elementos principales, y sus respectivas cartelas de apoyo.
        </li>
    </ul>
    <p class="resaltado">
        LOS PRECIOS FACILITADOS NO INCLUYEN:
    </p>
    <ul>
        <li>Trabajos de albañilería  (p.ej.: repicado de paredes; saneado, si se requiere; derribo de falsos techos; paso de tabiques; 
            apuntalamientos; etc.).</li>
        <li>La mano de obra necesaria para el montaje del material.</li>
        <li>En los precios facilitados no está incluido el IVA vigente.</li>
    </ul>
    <p  class="resaltado">
        Detalles adicionales IMPORTANTES:
    </p>
    <ul>
        <li>MECANOVIGA, S.L. no se hace responsable de los posibles desperfectos que puedan ocasionarse por la existencia de muebles, electrodomésticos, etc... que
            no hayan sido retirados en el lugar de la reparación.</li>
        <li>Los kit Mecanoviga serie "MVV" están homologados por el Instituto Eduardo Torroja con el D.I.T. (Documento de Idoneidad Técnica) número 331/R.</li>
        <li>Los kit Mecanoviga serie "MVH" están homologados por el Instituto Eduardo Torroja con el D.I.T. (Documento de Idoneidad Técnica) número 303/R.</li>
        <li>El plazo de entrega de piezas especiales (voladizos, cartelas especiales, etc...) está sujeto a la llegada a fábrica de la materia prima -consultar proceso de entrega-.</li>
        <li>La validez de la oferta es de {{validezOferta}}</li>
        <li>El plazo de entrega del material es {{plazoEntrega}}.</li>
    </ul>
    <p  class="resaltado" style="font-size: 20px !important;">
        Forma de pago:
    </p>
    <p>
        {{condicionesPago}}
    </p>
    <p class="resaltado">
        CONFORME CLIENTE:
    </p>

    <p  class="resaltado" style="font-size: 20px !important;">
        Firmado:
    </p>
    
</div>

<div *ngIf="idiomaOferta == 1" class="content">


    <h1 class="titulo">NOTES ACLARIDORES</h1>

   <p class="resaltado">
      MECANOVIGA, S.L. facturarà les unitats que s'hagin subministrat segons les necessitats de l'obra
       i d'acord amb la direcció facultativa.
       <br><br>
       Aquesta valoració s'ha fet amb les dades disponibles.
       <br><br>

       ELS PREUS FACILITATS INCLOUEN:   </p>
   <ul>
       <li>Totes les peces que formen el kit MECANOVIGA i centrals pertinents, cargols necessaris per a la unió

           dels elements principals, i les respectives cartel·les de suport.
       </li>
   </ul>
   <p class="resaltado">
    ELS PREUS FACILITATS NO INCLOUEN:
   </p>
   <ul>
       <li>Treballs de paleta (p.ex.: repicat de parets; sanejat, si es requereix; enderrocament de sostres falsos; pas d'envans;
           apuntalaments; etc.).</li>
       <li>La mà d'obra necessària per al muntatge del material.</li>
       <li>Als preus facilitats no està inclòs l'IVA vigent.</li>
   </ul>
   <p class="resaltado">
       Detalls addicionals IMPORTANTS:
   </p>
   <ul>
       <li>MECANOVIGA, S.L. no es fa responsable dels possibles desperfectes que es puguin ocasionar per l'existència de mobles, electrodomèstics, etc.
           que no hagin estat retirats al lloc de la reparació.</li>
       <li>Els kit Mecanoviga sèrie "MVV" estan homologats per l'Institut Eduardo Torroja amb el D.I.T. (Document d'Idoneïtat Tècnica) número 331/R.</li>
       <li>Els kit Mecanoviga sèrie "MVH" estan homologats per l'Institut Eduardo Torroja amb el D.I.T. (Document d'Idoneïtat Tècnica) número 303/R.</li>
       <li>El termini de lliurament de peces especials (voladissos, cartells especials, etc...) està subjecte a l'arribada a fàbrica de la matèria primera -consultar procés de lliurament-.</li>
       <li>La validesa de l'oferta és de {{validezOferta}}</li>
       <li>El termini de lliurament del material és {{plazoEntrega}}.</li>
   </ul>
   <p  class="resaltado" style="font-size: 20px !important;">
       Forma de pagament:
   </p>
   <p>
       {{condicionesPago}}
   </p>
   <p class="resaltado">
       CONFORME CLIENT:
   </p>

   <p  class="resaltado" style="font-size: 20px !important;">
       Signat:
   </p>

</div>