import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FabricacionService } from 'src/app/services/fabricacion.service';
import { type MedidaProducto } from 'src/app/types/fabricacion';

@Component({
  selector: 'app-seccion-medida',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './seccion-medida.component.html',
  styleUrl: './seccion-medida.component.css'
})
export class SeccionMedidaComponent {

  constructor(public fabricacionService: FabricacionService) { }

  public onMedidaSelection(medida: MedidaProducto) {
    this.fabricacionService.medidaProductoSeleccionada = medida;
    this.fabricacionService.hayMedidaSeleccionada = true;
    this.fabricacionService.productoGenerado += ` ${this.fabricacionService.medidaProductoSeleccionada.Mida}`;
    this.fabricacionService.productoGeneradoMedidasLength = this.fabricacionService.productoGenerado.length;
    this.fabricacionService.obtenerModelosProducto(this.fabricacionService.seFabrica, this.fabricacionService.tipoProductoSeleccionado.IdProducteFabricacio, this.fabricacionService.medidaProductoSeleccionada.IdMidaProducte);
  }

  public disabledButton() {
    if (this.fabricacionService.hayMedidaSeleccionada || !this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public disableVolverAtras() {
    if (this.fabricacionService.hayModeloSeleccionado || this.fabricacionService.hayOrigenSeleccionado || !this.fabricacionService.hayMedidaSeleccionada) return true;
    return false;
  }

}
