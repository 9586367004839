<div class="content" #content>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="albaranes">

        <div class="pagina" id="heightTemplate" *ngFor="let listaAlbaranesPagina of paginas">
            <div class="header">
                <app-cabecera-mecanoviga [idIdioma]="idIdioma" class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-generica [data]="fechas" [dataComplementaria]="compraCompleta" [tipo]="tiposCabecera.ALBARANES"
                    class="header-cliente"></app-cabecera-generica>
            </div>

            <div class="linea-gruesa center color-primary mt-20 mb-20"></div>
            <div class="body">

                <div *ngIf="idIdioma != 1" class="cabecera-lineas-albaran">
                    <p style="width: 16%;" class="text-left">N ALB.</p>
                    <p style="width: 16%;" class="text-left">FECHA</p>
                    <p style="width: 48%;" class="text-left">DESCRIPCIÓN</p>
                    <P style="width: 6%;" class="text-right">UNI.</P>
                    <P style="width: 14%;" class="text-right">PESO (KG)</P>
                </div>
                <div *ngIf="idIdioma == 1" class="cabecera-lineas-albaran">
                    <p style="width: 16%;" class="text-left">N ALB.</p>
                    <p style="width: 16%;" class="text-left">DATA</p>
                    <p style="width: 48%;" class="text-left">DESCRIPCIÓ</p>
                    <P style="width: 6%;" class="text-right">UNI.</P>
                    <P style="width: 14%;" class="text-right">PES (KG)</P>
                </div>
                
                <div class="albaranes" *ngFor="let albaran of listaAlbaranesPagina.albaranes">
                    <div class="nAlbaran"><span class="w-16 text-left">{{albaran.NumAlbara}}</span> <span class="w-16 text-left">{{albaran.DataAlbara}}</span></div>
                
                    <table style="width: 95%;">
                        <tbody class="flex-row-wrap">
                            <tr style="display: flex; width: 100%;" *ngFor="let linea of albaran.Entregas">
                                <th style="width: 32%;" class="text-left" scope="row"></th>
                                <th style="width: 48%;" class="text-left" scope="row">{{linea.DescProducte}}</th>
                                <th style="width: 6%" scope="row" class="text-right">{{linea.NumPecesEntregats}}</th>
                                <th style="width: 14%" scope="row" class="text-right">{{linea.PesLliurat}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div *ngIf="idIdioma != 1" class="pie-pagina" style="align-items: flex-end; padding-bottom: 100px; margin-left: -30px;">Página {{listaAlbaranesPagina.npagina}} de {{paginas.length}}
            </div>
            <div *ngIf="idIdioma == 1" class="pie-pagina" style="align-items: flex-end; padding-bottom: 100px; margin-left: -30px;">Pàgina {{listaAlbaranesPagina.npagina}} de {{paginas.length}}
            </div>
            
        </div>

        <div class="flex justify-center">
            <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
        </div>

    </div>

    <span *ngIf="getHeightTemplate()"></span>
