import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { DialogMantenimientoComponent } from '../../../dialogs/dialog-mantenimiento/dialog-mantenimiento.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { mantenimiento2Producto, mantenimiento2ProductoOpc } from "../../../services/llamadasMantenimiento";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-otros-un-cliente',
  templateUrl: './otros-un-cliente.component.html',
  styleUrls: ['./otros-un-cliente.component.css']
})
export class OtrosUnClienteComponent implements OnInit {

  @Input() idClienteAsignado: string | undefined = undefined;
  @Input() oferta: any = 0;
  @Input() especialesUnaOferta: any[] = [];
  @Input() otrosUnaOferta: any[] = [];
  @Output() clickEnVolverAPantalla = new EventEmitter();
  @Output() cuandoOtrosCambien = new EventEmitter();

  tipoProducto: string = "especiales";
  productosEspecialesAutocomplete: IdLabelObject[] = [];
  infoProductosEspeciales: any[] = [];
  productosOtrosAutocomplete: any[] = [];
  infoProductosOtros: any[] = [];
  situaciones: IdLabelObject[] = [];
  idComanda: number = 0;
  resultDialog: boolean = false;
  kitABorrar: any;
  esOtroNuevo: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public dataSource!: MatTableDataSource<any>;

  public otroUnaOferta: any = {
    nOferta: "",
    idProducto: "",
    nombreProducto: "",
    idSituacion: "",
    idSituacionVieja: "",
    cantidad: "",
    unidadMedida: "",
    precioUnitario: 0,
    peso: 0,
    descuento: "",
    tipo: "",
    actualizacionManual: false,
    varios: ""
  }

  //displayedColumns: string[] = ['buttons', 'producto', 'cantidad', 'unidadMedida', 'precioUnitario', 'pesoUnitario', 'descuento', 'actualizacionManual', 'situacion'];
  displayedColumns: string[] = ['buttons', 'producto', 'cantidad', 'unidadMedida', 'precioUnitario', 'pesoUnitario', 'actualizacionManual', 'situacion'];


  constructor(
    private apiService: ApiService,
    public dialog: MatDialog
  ) {
    if (this.tipoProducto === "otros") {
      this.dataSource = new MatTableDataSource<any>(this.otrosUnaOferta);
    } else {
      this.dataSource = new MatTableDataSource<any>(this.especialesUnaOferta);
    }
  }

  ngOnInit(): void {
    this.apiService.obtenerProductosNoKit(this.oferta.nOferta).subscribe(data => {
      this.infoProductosEspeciales = data.response.map(producto => ({ ...producto, idProducto: producto.idProducto.toString() }));
      this.productosEspecialesAutocomplete = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
    });
    this.apiService.obtenerProductosNoKitNoOpc(this.oferta.nOferta).subscribe((dato: any) => {
      this.infoProductosOtros = dato.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
      this.productosOtrosAutocomplete = dato.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombre }));
    })
    this.obtenerSituaciones();
  }

  ngAfterViewInit(): void {
    //    this.envioDatosFiltro();
    if (this.tipoProducto === "otros") {
      this.dataSource = new MatTableDataSource<any>(this.otrosUnaOferta);
    } else {
      this.dataSource = new MatTableDataSource<any>(this.especialesUnaOferta);
      console.log("especialesUnaOferta: ", this.especialesUnaOferta);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    //    this.envioDatosFiltro();
    if (this.tipoProducto === "otros") {
      this.dataSource = new MatTableDataSource<any>(this.otrosUnaOferta);
    } else {
      this.dataSource = new MatTableDataSource<any>(this.especialesUnaOferta);
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public volverATablaUnaOferta() {
    this.clickEnVolverAPantalla.emit();
  }

  public esOtroNuevoEnOferta() {
    this.esOtroNuevo = true;
    this.resetOtroUnCliente();
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'crearProdOpcional':
          if (result) this.crearProductoOpcionales();
          break;
        case 'eliminarOtroOferta':
          if (result) this.eliminarOtroEnOferta();
          break;
        default:
          break;
      }

    });
  }


  eliminarOtroEnOferta() {
    console.log("Eliminado 1");
    this.apiService.borrarOtrosUnaComanda(this.kitABorrar).subscribe(dades => {
      this.cuandoOtrosCambien.emit();
      //this.resetOtroUnCliente();
      this.esOtroNuevo = false;
      this.recalcularPesoPrecio();

    })
  }


  public dialogEliminarOtroEnOferta(kitUnaOferta: any) {
    this.kitABorrar = kitUnaOferta;
    this.openDialog("ELIMINAR PRODUCTO", "¿Quiere eliminar el producto " + this.otroUnaOferta.nombreProducto + " de la oferta", true, "eliminarOtroOferta");

  }

  public guardarOtro() {
    var msg = "";
    console.log("1: ", this.otroUnaOferta);
    if (this.obtenerDatosProducto(this.otroUnaOferta.idProducto).varios === 1) {
     this.otroUnaOferta.varios = 1
  //   this.otroUnaOferta.precioUnitario = this.obtenerDatosProducto(this.otroUnaOferta.idProducto).precioUnitario;
    } else {
      this.otroUnaOferta.varios = 0;
      this.otroUnaOferta.precioUnitario = this.obtenerDatosProducto(this.otroUnaOferta.idProducto).precioUnitario;
     };
    this.otroUnaOferta.pesoUnitario = this.obtenerDatosProducto(this.otroUnaOferta.idProducto).pesoUnitario;
/*    if (!this.miraNegativos(this.otroUnaOferta.cantidad)) {
      msg = 'El campo cantidad tiene un número negativo';
    }
    else*/ if (!this.miraNegativos(this.otroUnaOferta.descuento)) {
      msg = 'El campo descuento tiene un número negativo';
    }
    else if (this.comprobarSiYaExiste(this.otroUnaOferta.idProducto, this.otroUnaOferta.idSituacion) && this.esOtroNuevo) {
      msg = 'Este producto con esta situacción ya existe en esta oferta';
    }

    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }

    this.otroUnaOferta.nOferta = this.oferta.nOferta;

    console.log(this.otroUnaOferta);

    if (this.esOtroNuevo) {
      console.log("cuando es true: ", this.obtenerDatosProducto(this.otroUnaOferta.idProducto).precioUnitario)
      this.otroUnaOferta.precioUnitario = this.obtenerDatosProducto(this.otroUnaOferta.idProducto).precioUnitario;
      this.otroUnaOferta.nOferta = this.oferta.nOferta;
      this.otroUnaOferta.idSituacionVieja = this.otroUnaOferta.idSituacion;
      this.apiService.anadirOtrosUnaComanda(this.otroUnaOferta).subscribe(dades => {
        this.esOtroNuevo = false;
        this.cuandoOtrosCambien.emit();
        //this.resetOtroUnCliente();
      });
    }
    else {
      this.apiService.modificarOtroUnaComanda(this.otroUnaOferta).subscribe(dades => {
        this.cuandoOtrosCambien.emit();
        //this.resetOtroUnCliente();
      });
    }
    this.recalcularPesoPrecio();
  }

  private comprobarSiYaExiste(idProducto: string, idSituacion: string) {
    let arrayComprobado;
    arrayComprobado = this.otrosUnaOferta.find(item => {
      if (item.idProducto == idProducto && item.idSituacion == idSituacion) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }


  public dialogCrearProductosOpcionales() {
    this.openDialog("CREAR PRODUCTOS OPCIONALES", "Se van a generar productos automaticamente, en caso de solapamiento las modificaciones manuales desapareceran,¿desea continuar?", true, 'crearProdOpcional');

  }

  crearProductoOpcionales() {
    this.apiService.crearOtrosUnaComanda(this.oferta.nOferta).subscribe(dades => {
      console.log(dades);
      this.cuandoOtrosCambien.emit();
      this.resetOtroUnCliente();
      this.recalcularPesoPrecio()
    });
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    let dialogRef: any;
    switch (label) {
      case 'productoEspecial':
        dialogRef = mantenimiento2ProductoOpc(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          if (this.tipoProducto === "especiales") {
            this.apiService.obtenerProductosNoKit(this.oferta.nOferta).subscribe(data => {
              this.infoProductosEspeciales = data.response.map(producto => ({ ...producto, idProducto: producto.idProducto.toString() }));
              this.productosEspecialesAutocomplete = data.response.map(producto => ({ id: producto.idProducto.toString(), label: producto.nombre }));
            });
          }
        });
        break;
        case 'productoOtro':
          dialogRef = mantenimiento2Producto(this.dialog);
          dialogRef.afterClosed().subscribe((data: any) => {
            if (this.tipoProducto === "otros") {
              this.apiService.obtenerProductosNoKitNoOpc(this.oferta.nOferta).subscribe((dato: any) => {
                this.infoProductosOtros = dato.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
                this.productosOtrosAutocomplete = dato.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombre }));
              })
            }
          });
          break;  
    }
  }


  obtenerSituaciones() {
    this.apiService.obtenerSituacionComanda(this.oferta.nOferta).subscribe(data => {
      this.situaciones = data.response.map(situacion => ({ id: situacion.idSituacion.toString(), label: situacion.situacion }));
      console.log(this.situaciones)
      this.idComanda = this.oferta.nOferta;
    });
  }

  openDialogSituaciones() {

    const dialogRef = this.dialog.open(DialogMantenimientoComponent, {
      data: { title: "Mantenimiento Situaciones", msg: "Añade una situacion", mantenimiento: "situacion", parametros: this.idComanda }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.obtenerSituaciones();
      this.cuandoOtrosCambien.emit();
    })
  }


  public muestraOtroEnOferta(element: any) {
    console.log("element: ", element);
    this.otroUnaOferta.precioUnitario = element.precioUnitario;
    this.esOtroNuevo = false;
    this.otroUnaOferta = { ...element };
    this.otroUnaOferta.idSituacionVieja = this.otroUnaOferta.idSituacion;
    this.otroUnaOferta.idProducto = this.otroUnaOferta.idProducto.toString();
    if (this.otroUnaOferta.actualizacionManual == "NO") this.otroUnaOferta.actualizacionManual = false;
    console.log("otroUnaOferta: ", this.otroUnaOferta);
  }

  public obtenerDatosProducto(idProducto: string) {
    if ( this.tipoProducto === "especiales") {
      var body = this.infoProductosEspeciales.find(producto => producto.idProducto === idProducto) || {};
  //    console.log("Body especiales: ", body);
    }else if ( this.tipoProducto === "otros") {
      var body = this.infoProductosOtros.find(producto => producto.idProducto === idProducto) || {};
  //    console.log("Body otros: ", body);
    }
    return body;
  }

  private recalcularPesoPrecio() {
    this.apiService.obtenerOtrosUnaComanda(this.oferta.nOferta).subscribe(data => {
      var pesoT = 0;
      var precioT = 0;
      var otros = data.response;
      this.apiService.obtenerNoKitsNoOpcUnaComanda(this.oferta.nOferta).subscribe(data =>{
        otros = otros.concat(data.response);
        
        for (var i = 0; i < otros.length; i = i + 1) {
          var qty = otros[i]['cantidad'];
          pesoT = pesoT + (otros[i]['pesoUnitario'] * qty);
          precioT = precioT + (otros[i]['precioUnitario'] * qty);
          precioT = precioT - otros[i]['descuento'];
        }
        var campo = { nOferta: this.oferta.nOferta, precioT, pesoT };
        console.log(campo);
        this.otrosUnaOferta = data.response;
        this.apiService.modificarPesoPrecioOpc(campo).subscribe();
      });
    });
  }

  public cambiarTipo() {
    if (this.tipoProducto === "especiales") {
      this.tipoProducto = "otros";
      this.otroUnaOferta.tipo = "otro"
    } else {
      this.tipoProducto = "especiales";
      this.otroUnaOferta.tipo = "especial"
    }
    this.ngAfterViewInit();
  }

  private resetOtroUnCliente() {
    this.otroUnaOferta = {
      nOferta: "",
      idProducto: "",
      nombreProducto: "",
      idSituacion: "",
      nombreSituacion: "",
      cantidad: "",
      unidadMedida: "",
      precioUnitario: 0,
      pesoUnitario: 0,
      descuento: "",
      actualizacionManual: false,
    }
  }



}
