import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutocompleteOption } from './AutocompleteOption';
import * as removeAccents from 'remove-accents';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {

  public control = new UntypedFormControl();

  @Input() label: string = '';
  @Input() icon: string | undefined = '';
  @Input() disabled: boolean = false;
  @Input() options: AutocompleteOption[] = [];
  @Input() isRequired: boolean = false;
  @Input() idOpcionSeleccionada: string | undefined = '';
  @Output() cuandoSeSeleccioneOpcion: EventEmitter<string | undefined> = new EventEmitter();
  @Output('cuandoSeCliqueEnIcono') cuandoSeCliqueEnIconoOutput: EventEmitter<string> = new EventEmitter();

  public filteredOptions: Observable<AutocompleteOption[]> = new Observable();

  constructor() { }

  ngOnChanges() {
    const selectedOption = this.options.find(option => option.id == this.idOpcionSeleccionada);
    this.control.setValue(selectedOption);
    if (this.disabled) {
      this.control.disable(); // TODO enable again
    } else {
      this.control.enable();
    }
  }

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
    this.control.valueChanges.subscribe((value: string | AutocompleteOption) => {
      if (typeof value === 'object') {
        this.cuandoSeSeleccioneOpcion.emit(value.id)
      } else if (value !== undefined) {
        this.cuandoSeSeleccioneOpcion.emit(undefined);
      }
    })
  }

  public displayFn(option: AutocompleteOption) {
    return option && option.label || '';
  }

  public cuandoSeCliqueEnIcono(event: Event) {
    event.stopPropagation();
    this.cuandoSeCliqueEnIconoOutput.emit();
  }

  private filter(value: AutocompleteOption | string) {
    if (!value) {
      return this.options;
    }
    const filterValue = typeof value === 'object' ? value.label.toLowerCase() : value.toLowerCase();
    return this.options.filter(option => {
      return removeAccents(option.label.toLowerCase()).includes(removeAccents(filterValue));
    });
  }


}
