<div class="container flex-row-wrap">

    <div class="layout-filtro flex flex-100 search">

        <div class="flex-6-responsive">
            <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>

        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Inicio
            </mat-label>
            <input matInput [(ngModel)]="filtroAlbaranesSalida.fechaInicioSelected" name="fechaInicio"
                [matDatepicker]="picker" disabled>
            <button class="button-date" *ngIf="filtroAlbaranesSalida.fechaInicioSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroAlbaranesSalida.fechaInicioSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Fin
            </mat-label>
            <input disabled matInput [(ngModel)]="filtroAlbaranesSalida.fechaFinSelected"
                [min]="filtroAlbaranesSalida.fechaInicioSelected" name="fechaFin" [matDatepicker]="picker2">
            <button class="button-date" *ngIf="filtroAlbaranesSalida.fechaFinSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroAlbaranesSalida.fechaFinSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-12-responsive">
            <mat-label>Nº Albarán</mat-label>
            <input matInput type="number" [(ngModel)]="filtroAlbaranesSalida.nAlbaranSelected" name="cantidad">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-12-responsive">
            <mat-label>Estado</mat-label>
            <mat-select [(ngModel)]="filtroAlbaranesSalida.estadoSelected" name="estado">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesEstado" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-12-responsive">
            <mat-label>Nº Oferta</mat-label>
            <input matInput type="number" [(ngModel)]="filtroAlbaranesSalida.nOfertaSelected" name="nOferta">
        </mat-form-field>

        <div class="criterios__input flex-25-responsive mr-10">
            <app-autocomplete class="autocomplete" name="cliente" ngDefaultControl
                [idOpcionSeleccionada]="filtroAlbaranesSalida.clienteSelected"
                (cuandoSeSeleccioneOpcion)="filtroAlbaranesSalida.clienteSelected = $event" icon="" label="Cliente"
                [options]="clientes">
            </app-autocomplete>
        </div>

        <app-autocomplete class="autocomplete flex-10-responsive" name="provincia" ngDefaultControl
            [idOpcionSeleccionada]="filtroAlbaranesSalida.provinciaSelected"
            (cuandoSeSeleccioneOpcion)="filtroAlbaranesSalida.provinciaSelected = $event" label="Provincia"
            [options]="provincias">
        </app-autocomplete>

        <div class="flex-9-responsive">
            <button class="button__buscar" color="primary" mat-stroked-button
                (click)="envioDatosFiltro()">Buscar</button>
        </div>
    </div>

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button mat-icon-button type="button" (click)="esAlbaranSalidaNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <!--
                    <button mat-icon-button type="button" (click)="muestraAlbaranSalida(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    -->
                    <button *ngIf="!element.fechaReal" mat-icon-button type="button"
                        (click)="dialogEliminarUnAlbaran(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nAlbaranSalida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Albarán </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nAlbaranSalida}} </td>
            </ng-container>

            <ng-container matColumnDef="nOferta">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Oferta </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nOferta}} </td>
            </ng-container>

            <ng-container matColumnDef="idCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº Cliente </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.idCliente}} </td>
            </ng-container>

            <ng-container matColumnDef="fechaSalida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre Cliente </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreCliente}} </td>
            </ng-container>

            <ng-container matColumnDef="nombreCliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Entrega Prevista </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.fechaPrevista | date: 'dd/MM/yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fechaReal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Entrega Real </th>
                <td mat-cell *matCellDef="let element"> {{element.fechaReal}} </td>
            </ng-container>
            <!--
            <ng-container matColumnDef="direccion">
                <th mat-header-cell *matHeaderCellDef> Direccion </th>
                <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
            </ng-container>

            <ng-container matColumnDef="cp">
                <th mat-header-cell *matHeaderCellDef> Código Postal </th>
                <td mat-cell *matCellDef="let element"> {{element.cp}} </td>
            </ng-container>
 -->
            <ng-container matColumnDef="idCiudad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
                <td mat-cell *matCellDef="let element"> {{element.ciudad}} </td>
            </ng-container>
            <!--
            <ng-container matColumnDef="idTransportista">
                <th mat-header-cell *matHeaderCellDef> Transportista </th>
                <td mat-cell *matCellDef="let element"> {{element.idTransportista}} </td>
            </ng-container>

            <ng-container matColumnDef="matricula">
                <th mat-header-cell *matHeaderCellDef> Matrícula </th>
                <td mat-cell *matCellDef="let element"> {{element.matricula}} </td>
            </ng-container>

            <ng-container matColumnDef="costeTransporte">
                <th mat-header-cell *matHeaderCellDef> Coste Transporte </th>
                <td mat-cell *matCellDef="let element"> {{element.costeTransporte}} </td>
            </ng-container>

            <ng-container matColumnDef="peso">
                <th mat-header-cell *matHeaderCellDef> Peso </th>
                <td mat-cell *matCellDef="let element"> {{element.peso}} </td>
            </ng-container>

            <ng-container matColumnDef="observaciones">
                <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
            </ng-container>

            <ng-container matColumnDef="encargadoObra">
                <th mat-header-cell *matHeaderCellDef> Encargado Obra </th>
                <td mat-cell *matCellDef="let element"> {{element.encargadoObra}} </td>
            </ng-container>

            <ng-container matColumnDef="telefonoObra">
                <th mat-header-cell *matHeaderCellDef> Teléfono Obra </th>
                <td mat-cell *matCellDef="let element"> {{element.telefonoObra}} </td>
            </ng-container>
-->
            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Estado </th>
                <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
            </ng-container>

            <ng-container matColumnDef="nFactura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Factura </th>
                <td mat-cell *matCellDef="let element"> {{element.nFactura}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraAlbaranSalida(row)"
                [ngClass]="{'row-highlighted': albaranSalida.nAlbaranSalida == row.nAlbaranSalida}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': albaranesSalida.length > 0}" [pageSizeOptions]="[20]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="albaranesSalida.length == 0">No hay albaranes</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarAlbaranSalida(formulario)"
        class="form-derecho row-gap-10 flex-35-responsive">

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label> Nº Albarán</mat-label>
            <input disabled [(ngModel)]="albaranSalida.nAlbaranSalida" name="nAlbaranSalida" matInput type="text">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label> Nº Oferta</mat-label>
            <input disabled [(ngModel)]="albaranSalida.nOferta" name="nOferta" matInput type="text">
            <mat-icon *ngIf="crearAlbaranNuevo" matSuffix (click)="cuandoSeCliqueEnIcono()">settings</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> Cliente</mat-label>
            <input required disabled [(ngModel)]="albaranSalida.nombreCliente" name="nombreCliente" matInput
                type="text">
        </mat-form-field>

        <mat-form-field style=" width: 300px;" appearance="outline">
            <mat-label> Dirección entrega</mat-label>
            <textarea [disabled]="albaranSalida.fechaReal" required matInput [(ngModel)]="albaranSalida.direccion"
                name="direccionObra" maxlength="80" rows="2"></textarea>

        </mat-form-field>

        <app-autocomplete class="autocomplete flex-45-responsive" name="ciudad" [disabled]="albaranSalida.fechaReal"
            ngDefaultControl [idOpcionSeleccionada]="albaranSalida.idCiudad"
            (cuandoSeSeleccioneOpcion)="albaranSalida.idCiudad = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" label="Ciudad entrega"
            [options]="ciudades">
        </app-autocomplete>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Código Postal entrega</mat-label>
            <input [disabled]="albaranSalida.fechaReal" [(ngModel)]="albaranSalida.cp" name="cp" matInput type="text">
        </mat-form-field>

        <app-autocomplete class="autocomplete flex-45-responsive" name="encargadoObra"
            [disabled]="albaranSalida.fechaReal" ngDefaultControl [idOpcionSeleccionada]="albaranSalida.idEncargadoObra"
            (cuandoSeSeleccioneOpcion)="albaranSalida.idEncargadoObra = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('encargadoObra')" label="Encargado Obra"
            [options]="encargadosObra">
        </app-autocomplete>

        <app-autocomplete class="autocomplete flex-45-responsive" name="transportista"
            [disabled]="albaranSalida.fechaReal" ngDefaultControl [idOpcionSeleccionada]="albaranSalida.idTransportista"
            (cuandoSeSeleccioneOpcion)="albaranSalida.idTransportista = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('transportista')" label="Transportista"
            [options]="transportistas">
        </app-autocomplete>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label>
                Fecha Albarán
            </mat-label>
            <input required matInput [(ngModel)]="albaranSalida.fechaSalida" name="fechaSalida"
                [matDatepicker]="picker3" disabled>
            <button [disabled]="albaranSalida.fechaReal" class="button-date" *ngIf="albaranSalida.fechaSalida" matSuffix
                mat-icon-button aria-label="Clear" (click)="albaranSalida.fechaSalida=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label>
                Entrega Prevista
            </mat-label>
            <input [min]="albaranSalida.fechaSalida" matInput [(ngModel)]="albaranSalida.fechaPrevista"
                name="fechaPrevista" [matDatepicker]="picker4" disabled>
            <button [disabled]="albaranSalida.fechaReal" class="button-date" *ngIf="albaranSalida.fechaPrevista"
                matSuffix mat-icon-button aria-label="Clear" (click)="albaranSalida.fechaPrevista=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <!--[disabled]="albaranSalida.fechaReal"-->
        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select [disabled]="!albaranSalida.tieneDetalles" required [(ngModel)]="albaranSalida.estado"
                name="estado">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesEstado" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="albaranSalida.estado != 'Entregado'" class="flex-45-responsive" appearance="outline">
            <mat-label>Entrega Real</mat-label>
            <input disabled matInput type="text">
        </mat-form-field>


        <mat-form-field *ngIf="albaranSalida.estado === 'Entregado'" appearance="outline" class="flex-45-responsive">
            <mat-label>
                Entrega Real
            </mat-label>
            <input [disabled]="albaranSalida.fechaReal" required matInput [(ngModel)]="fechaRealProv" name="fechaReal"
                [matDatepicker]="picker4">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-45-responsive">
            <mat-label> Matrícula</mat-label>
            <input [disabled]="albaranSalida.fechaReal" [(ngModel)]="albaranSalida.matricula" name="matricula" matInput
                type="text">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Coste Transporte</mat-label>
            <input [disabled]="albaranSalida.fechaReal" matInput type="number" step="0.01"
                [(ngModel)]="albaranSalida.costeTransporte" name="costeTransporte">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Peso</mat-label>
            <input disabled [(ngModel)]="albaranSalida.peso" name="peso" matInput type="text">
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Nº Factura</mat-label>
            <input disabled [(ngModel)]="albaranSalida.nFactura" name="nFactura" matInput type="text">
        </mat-form-field>

        <mat-form-field class="flex flex-100" appearance="outline">
            <mat-label>Notas</mat-label>
            <textarea [disabled]="albaranSalida.fechaReal" matInput [(ngModel)]="albaranSalida.notas" name="notas"
                rows="4"></textarea>
        </mat-form-field>

        <div class="flex-row-wrap justify-between flex-100 row-gap-10">
            <span class="flex-50-responsive">
                <button [disabled]="!albaranSalida.nAlbaranSalida" class="button__buscar" type="button"
                    (click)="irADetallesSalida()" mat-raised-button>Detalles</button>
            </span>
            <span class="flex-50-responsive">
                <button [disabled]="!albaranSalida.nAlbaranSalida || !albaranSalida.direccion" class="button__buscar"
                    type="button" mat-raised-button (click)="imprimirAlbaran()">Imprimir Albarán</button>
            </span>
            <!--[disabled]="!albaranSalida.nOferta ||albaranSalida.fechaReal"-->
            <span class="flex-50-responsive">
                <button [disabled]="!albaranSalida.direccion" class="button__buscar" type="submit"
                    mat-raised-button>Guardar</button>
            </span>
        </div>
        <!--
        <span class="flex-50-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Volver</button>
        </span>
-->
    </form>

</div>