<form class="container flex-row-wrap" #ComprasDeChapaComponent>
    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 standar-table-mecanoviga"
            aria-label="Nueva compra de chapa">

            <!-- Column definitions -->
            <ng-container matColumnDef="Chapa">
                <th mat-header-cell *matHeaderCellDef class="pl-45"> Chapa </th>
                <td mat-cell *matCellDef="let chapa" class="pl-45"> {{chapa.DescProducte}} </td>
            </ng-container>

            <ng-container matColumnDef="Cantidad">
                <th mat-header-cell *matHeaderCellDef class="w-10"> Cantidad </th>
                <td mat-cell *matCellDef="let chapa">
                    <mat-form-field class="flex-60-responsive" appearance="outline">
                        <mat-label> Unidades </mat-label>
                        <input matInput name="cantidad" type="number" [ngModel]="chapa.cantidad" (ngModelChange)="onCantidadChange(chapa.IdProducte, $event)" [disabled]="chapa.StockActual <= 0"  min="0">
                    </mat-form-field>
                </td>
            </ng-container>

            <!-- Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarUnaChapa(row)" [ngClass]="colorRow(row.IdProducte, chapa.IdProducte)"></tr>

        </table>

        @if (chapas && chapas.length === 0) {
        <div class="noHayNadaEnTabla">No hay compras de chapa</div>
        }

        <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons
            [ngClass]="{'hidden':true, 'no-hidden': (chapas && chapas.length > 0)}"
            aria-label="Seleccionar página de chapas">
        </mat-paginator>

    </div>

    <form #formulario="ngForm" (submit)="anadirCompra()" class="form-derecho flex-35-responsive row-gap-15">
        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Tipo </mat-label>
            <input matInput name="tipo" type="text" [(ngModel)]="compraChapaNueva.Tipus"
                required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>
                Fecha Compra
            </mat-label>
            <input matInput [matDatepicker]="picker" name="fechaCompra" [(ngModel)]="compraChapaNueva.DataCompra" [min]="fechaMinima"  required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Precio €/T </mat-label>
            <input matInput name="precio" type="number" [(ngModel)]="compraChapaNueva.Preu" min="0">
        </mat-form-field>

        <button type="button" mat-raised-button (click)="volverAComprasChapa()" class="flex-34-responsive mt-30">Volver</button>
        <button class="button__buscar" type="submit" mat-raised-button class="flex-34-responsive mt-30" [disabled]="handleDisableGuardar()">Guardar</button>
        
    </form>

</form>