import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { mantenimiento1Pais, mantenimiento2Provincia } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import {  itemManteniment2 } from 'src/app/types/mantenimiento';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';



@Component({
  selector: 'app-dialog-mantenimiento2',
  templateUrl: './dialog-mantenimiento2.component.html',
  styleUrls: ['./dialog-mantenimiento2.component.css']
})
export class DialogMantenimiento2Component implements OnInit {


  itemSelected: any = {
    idFill: null,
    labelFill: null,
    idPare: null,
    labelPare: null,
    editable: true
   };
   idKit = this.data.cols[2];

  items: itemManteniment2 [] = [];
  itemsTot: itemManteniment2 [] = [];
  nOferta : number = 0;
  pareAutocomplete: IdLabelObject[] = [];

  itemTProd : any={
    idFill: null,
    labelFill: null,
    labelPare: null,
    editable: true
  }
  
  
  displayedColumns: string[] = ['buttons', this.data.cols[0],this.data.cols[1] ];
  
  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimiento2Component>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog,
    public dialogVerificar: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
    ){}


    
  ngOnInit(){
    
    this.displayedColumns = ['buttons', this.data.cols[0], this.data.cols[1]];
    console.log(this.displayedColumns);
    switch(this.data.mantenimiento){
      case 'ciudad':
        this.obtenerCiudades();
      break;
      case 'provincia':
        this.obtenerProvincia();
      break;
      case 'components':
        this.obtenerComponents();
      break;
      case 'tipo Producto':
        this.obtenerTipoProducto();
      break;
      case 'encargado Obra':
        this.obtenerEncargadoObra();
      break;
      default:
      break;
    }

    //const matTable= document.getElementById('matTable');
    //if(matTable != null ) matTable.scrollToIndex(10);    

  }

  public obtenerCiudades(){
    this.apiService.obtenerCiudadesProvincias().subscribe( dades => {
      this.items = dades.response.map(ciudad => ({ idFill: ciudad.idCiudad, labelFill: ciudad.nombreCiutat, idPare: ciudad.idProvincia, labelPare: ciudad.nombreProvincia, editable: ciudad.estaAssignada }));
      
      this.itemsTot= this.items;
      
      this.goTo(); 
    });
    this.obtenerProvincias();
  }

  public obtenerProvincia(){
    this.apiService.obtenerProvinciasPais().subscribe( dades => {
      this.items = dades.response.map(provincia => ({ idFill: provincia.idProvincia, labelFill: provincia.nombreProvincia, idPare: provincia.idPais, labelPare: provincia.nombrePais, editable: provincia.estaAssignada }));    
      this.itemsTot= this.items;
      
      this.goTo(); 
    });
    this.obtenerPais();
      
  }
  obtenerProvincias(){
    this.apiService.obtenerProvincias().subscribe(data => {
      this.pareAutocomplete = data.response.map(provincias => ({ id: provincias.idProvincia.toString(), label: provincias.nombreProvincia }))
      console.log(this.pareAutocomplete);
    });
  }
  obtenerPais(){
    this.apiService.obtenerPais().subscribe(data => {
      this.pareAutocomplete = data.response.map(pais => ({ id: pais.idPais.toString(), label: pais.nombrePais }))
      console.log(this.pareAutocomplete);
    }); 
  }

  obtenerComponents(){
    this.obtenerOtros();
    this.apiService.obtenerComponents(this.data.cols[2]).subscribe(data =>{
      
      this.items = data.response.map((component: { id: any; qty: any; idComponent: any; nombreComponent: any; })  => ({ idFill: component.id, labelFill:component.qty, idPare: component.idComponent, labelPare: component.nombreComponent , editable: false }));
      //this.itemsTot= this.items;
      
    });
  }
  obtenerOtros(){
    this.apiService.obtenerComponentsAuto().subscribe(data =>{
      console.log(data.response);
      this.pareAutocomplete = data.response.map((prod: { idProd: { toString: () => any; }; nombreComponent: any; })  => ({id : prod.idProd.toString(),label: prod.nombreComponent}));
    });
  }
  obtenerTipoProducto(){
    this.apiService.obtenerTipoProducto().subscribe(data => {
      console.log(data.response);
      this.items = data.response.map(data  =>({idFill: data.idTipo, labelFill: data.nombre, idPare: data.controlStock, labelPare: data.controlStock, editable: data.estaAssignada }));
      this.itemsTot= this.items;
      this.goTo(); 
    })
  }

  obtenerEncargadoObra(){
    let id = this.data.parametros;
    console.log("idCliente", id);
    this.apiService.obtenerEncargadoObra(id).subscribe(data =>{
      this.items = data.response.map((data: { idEncargado: any; encargado: any; telefono: any; estaAssignada: any; })  =>({idFill: data.idEncargado, labelFill: data.encargado, labelPare: data.telefono, editable: data.estaAssignada}));
      this.itemsTot= this.items;
      this.goTo(); 
    })

  }


  dialogEliminarUnCampo( event: any, item: any) {
    this.itemSelected = item;
    if(this.data.mantenimiento== 'components')this.openDialog("ELIMINAR","¿Quiere eliminar "+item.labelPare+"?", true,"eliminar");
    else this.openDialog("ELIMINAR","¿Quiere eliminar "+item.labelFill+"?", true,"eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem(){
    switch(this.data.mantenimiento){
      case 'ciudad':
        this.eliminarCiudad( this.itemSelected);
      break;
      case 'provincia':
        this.eliminarProvincia( this.itemSelected);
      break;
      case 'components':
        this.eliminarComponent( this.itemSelected);
      break;
      case 'tipo Producto':
        this.eliminarTProd(this.itemSelected);
      break;
      case 'encargado Obra':
        this.eliminarEncargadoObra(this.itemSelected);
      break;
      default:
      break;
    }
  }
  public eliminarCiudad(ciudad : any){
    console.log("borrar situacion", ciudad.idFill);
    this.apiService.borrarCiudad(ciudad.idFill).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
    
  }

  public eliminarProvincia(provincia : any){
    console.log("eliminar Provincia", provincia);
    this.apiService.borrarProvincia(provincia.idFill).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    }); 
  }
  protected eliminarComponent(component: any){
    this.apiService.borrarComponents(component).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    }); 
  }
  
  protected eliminarTProd(component: any){
    this.apiService.borrarTipoProducto(component.idFill).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    }); 
  }
  protected eliminarEncargadoObra(encargado: any){
    this.apiService.borrarEncargadoObra(encargado.idFill).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    }); 
  }




  public guardarItem(form : NgForm){
    if(this.data.mantenimiento =="encargado Obra" || this.data.mantenimiento =="tipo Producto"){
      if(this.itemSelected.labelFill == null){
        this.openDialog("AVISO","No se puede guardar, falta el campo "+this.data.cols[0], true,"");  
        return ;
      }
    }
    else{
      if(this.itemSelected.idPare == null){
        this.openDialog("AVISO","No se puede guardar, falta el campo "+this.data.cols[1], true,"");  
        return ;
      }
    }
    switch(this.data.mantenimiento){
      case'ciudad':
      this.guardarCiudad();
      break;
      case 'provincia':
        this.guardarProvincia();
      break;
      case 'components':
        this.guardarComponent();
      break;
      case 'tipo Producto':
        this.guardarTProd();
      break;
      case 'encargado Obra':
        this.guardarEncargadoObra();
      break;
      default:
      break;
    }
  }

  private guardarCiudad(){
    console.log("guardar:", this.itemSelected);
    if(this.itemSelected.idFill){
      this.apiService.modificarCiudad(this.itemSelected).subscribe(data=>{
        
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      this.apiService.anadirCiudad(this.itemSelected).subscribe( data=>{
        console.log(data);
        this.itemSelected.idFill = data.response;
        this.modificarTabla();
      });
    }
  }
  private guardarProvincia(){
    
    if(this.itemSelected.idFill){
      this.apiService.modificarProvincia(this.itemSelected).subscribe(data=>{
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      this.apiService.anadirProvincia(this.itemSelected).subscribe( data=>{
        this.itemSelected.idFill = data.response;
        this.modificarTabla();
      });
    } 
  }

  private guardarComponent(){
    console.log(this.itemSelected);
    if(!this.itemSelected.editable){
      this.apiService.modificarComponents(this.itemSelected).subscribe(data=>{        
        this.modificarTabla();
      })

    }
    else{
      this.itemSelected.idFill = this.idKit;
      console.log("guardar", this.itemSelected);
      this.apiService.anadirComponents(this.itemSelected).subscribe( data=>{
        console.log(this.itemSelected);
        this.modificarTabla();
      });
    } 
  }
  private guardarTProd(){ 
    if(this.itemSelected.idFill){
      this.apiService.modificarTipoProducto(this.itemSelected).subscribe(data=>{ 
        console.log(data);                
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      this.apiService.anadirTipoProducto(this.itemSelected).subscribe( data=>{
        console.log(data);
        this.itemSelected.idFill = data.response;
        this.modificarTabla();
      });
    } 
  }

  protected guardarEncargadoObra(){
    if(this.itemSelected.idFill){
      this.apiService.modificarEncargadoObra(this.itemSelected).subscribe(data=>{
        
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      let params = {
        idCliente : this.data.parametros,
        idFill: this.itemSelected.idFill,
        idPare: this.itemSelected.idPare,
        labelFill: this.itemSelected.labelFill,
        labelPare: this.itemSelected.labelPare,
      }
      this.apiService.anadirEncargadoObra(params).subscribe( data=>{
        this.itemSelected.idFill = data.response;
        this.modificarTabla();
      });
    } 
  }



  resetItem(){
     this.itemSelected.idFill = null;
     this.itemSelected.editable= true;
     this.itemSelected.labelFill = null;
     this.itemSelected.idPare = undefined;
     this.itemSelected.labelPare = undefined;
     this.goTo();
  }

  resetItem2(event: any){
    event.stopPropagation();
    this.itemSelected.editable= true;
    this.itemSelected.labelFill = undefined;
    this.itemSelected.idPare = undefined;
    this.itemSelected.labelPare = undefined;
    this.goTo();
  }

  public envioDatos(){
    console.log("envia datos: ", this.data);
  }

  public modificarTabla(){
    switch(this.data.mantenimiento){
      case 'ciudad':
        this.modificarTablaCiudades();
      break;
      case 'provincia':
        this.modificarTablaProvincia();
      break;
      case 'components':
        this.modificarTablaComponents();
      break;
      case 'tipo Producto':
        this.modificarTablaTProd();
      break;
      case 'encargado Obra':
        this.modificarTablaEncargadoObra();
      break;
      default:
      break;
    }
  }

  private modificarTablaCiudades(){
    this.obtenerCiudades();
  }
  private modificarTablaProvincia(){
    this.obtenerProvincia();
  }
  private modificarTablaComponents(){
    this.obtenerComponents();
  }
  private modificarTablaTProd(){
    this.obtenerTipoProducto();
  }
  private modificarTablaEncargadoObra(){
    this.obtenerEncargadoObra();
  }
  

  muestraUnItem(item: any) {
    console.log(item);
    if(this.data.mantenimiento =='tipo Producto' ){
      this.itemSelected = {... item};
      this.itemSelected.idPare = (this.itemSelected.idPare =="SI"); 
      console.log(this.itemSelected);
    }
    else this.itemSelected = {... item};
  }

 
  mantenimientoPadre(){
    var dialogRef: any;
    switch(this.data.mantenimiento){
      case 'ciudad':
        dialogRef = mantenimiento2Provincia(this.dialogMantenimiento);
        dialogRef.afterClosed().subscribe( (data:any) => {
          this.obtenerProvincias();
        });
      break;
      case 'provincia':
        dialogRef = mantenimiento1Pais(this.dialogMantenimiento);
        dialogRef.afterClosed().subscribe( (data:any) => {
          this.obtenerPais();
        });
      break;
      }   
  }
  
  public goTo(){
    if(this.data.mantenimiento !="components"){
      if(this.itemSelected.idFill == null){
        if( this.itemSelected.labelFill != null){
          this.items = this.itemsTot.filter(item => item.labelFill.toLowerCase().includes(this.itemSelected.labelFill.toLowerCase()) );
          if(this.items.length >0) this.table.renderRows();
        }
        else this.items = this.itemsTot;
      }
    }
  }
 
}

