import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { IdLabelObject } from '../../types/global';
import { NgForm } from '@angular/forms';
import { DialogPopUpComponent } from '../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Cliente } from '../../types/clientes';
import { ActivatedRoute, Router } from '@angular/router';
import { mantenimiento2Encargado, mantenimiento2Ciudad, mantenimiento1Idioma } from "../../services/llamadasMantenimiento";
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html'
  , styleUrls: ['./clientes.component.scss']
})

export class ClientesComponent implements OnInit, AfterViewInit {

  public filtroClientes: any = {
    selectedEmpresa: undefined,
    selectedContacto: undefined,
    selectedNIF: undefined,
    selectedEmail: undefined,
    selectedCiudad: undefined,
    selectedCodigoPostal: undefined,
    selectedCondicionesPago: undefined,
    selectedProvincia: undefined,
    tieneOfertas: undefined,
  };

  public cliente: any = {
    nCliente: "",
    empresa: "",
    contacto: "",
    nif: "",
    direccionFacturacion: "",
    codigoPostal: "",
    telefono: "",
    fax: "",
    ciudad: "",
    idCiudad: "",
    provincia: "",
    idProvincia: "",
    cuenta: "",
    condicionesPago: "",
    Riesgo: "",
    idioma: "",
    Ididioma: "",
    dto: "",
    tieneOfertas: ""
  };

  opcionesSiNo = [
    { nombre: "SI",
      valor: true
    },
    { nombre: "NO",
      valor: false
    }
  ]

  //

  public listaClientes: any[] = [];
  public crearClienteNuevo: boolean = true;
  public clienteResaltado = null;
  public clienteABorrar: any;
  public ciudades: any[] = [];
  public ciudadesAutocomplete: IdLabelObject[] = [];
  public provincias: IdLabelObject[] = [];
  public clientesAutocomplete: IdLabelObject[] = [];
  public dataSource!: MatTableDataSource<Cliente>;
  public contactos: IdLabelObject[] = [];
  public idiomas: IdLabelObject[] = [];
  public hayDatosEnTabla: boolean = true;
  public paramsUrl: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['buttons', 'empresa', 'contacto', 'ciudad', 'email'];

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private Mantenimientodialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaClientes);
  /*  this.router.events
      .pipe(
        filter( (event: any) => event instanceof ActivationEnd),
        map( (event: ActivationEnd) => event.snapshot.data)
      ).subscribe( data => {
        console.log("EVENT: ", data);
        const titulo = data.titulo;
        console.log(titulo);
        this.tituloService.nombreTitulo = titulo;
      });*/
      this.tituloService.enviarTitulo();
  }


  ngOnInit(): void {
    this.resetCliente();
//    this.envioDatosFiltro();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      console.log("paramsUrl: ", this.paramsUrl);
    })
    this.apiService.obtenerCiudades().subscribe(data => {
      this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));
      this.ciudadesAutocomplete = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });
    this.apiService.obtenerProvincias().subscribe(data => {
      this.provincias = data.response.map(provincia => ({ id: provincia.idProvincia.toString(), label: provincia.nombreProvincia }));
    });
    this.apiService.obtenerIdioma().subscribe(data => {
      this.idiomas = data.response.map(idioma => ({ id: idioma.idIdioma.toString(), label: idioma.nombre }));
    });

    this.apiService.obtenerClientes().subscribe(data => {
      this.clientesAutocomplete = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
    })
  }

  ngAfterViewInit() {
    this.envioDatosFiltro();
/*    this.dataSource = new MatTableDataSource<Cliente>(this.listaClientes);
    let page = this.buscarIndiceEnOfertasFiltradas(this.clienteResaltado) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/
  }

/*  ngOnChanges(): void {
    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<Cliente>(this.listaClientes);
    let page = this.buscarIndiceEnOfertasFiltradas(this.clienteResaltado) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }*/

  public envioDatosFiltro() {
    this.apiService.obtenerClientesCompleto(this.filtroClientes).subscribe(data => {
      this.listaClientes = data.response;
      this.dataSource = new MatTableDataSource<Cliente>(this.listaClientes);
      let page = this.buscarIndiceEnOfertasFiltradas(this.clienteResaltado) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  public guardarUnCliente(formulario: NgForm) {
    // detectar si tenemos query param llamado redirectTo, y llamarlo
    // this.activatedRoute.snapshot.paramMap.get('redirectTo')
    
    if (!formulario.valid) { return };
    if (!this.crearClienteNuevo) {
      console.log("Guardar editado: ", this.cliente);
      this.revisarRedirecTo();
      this.apiService.modificarCliente(this.cliente).subscribe(dades => {
        this.envioDatosFiltro();
      });
    } else {
      console.log("Guardar nuevo: ", this.cliente);
      //  asignar nCliente y  guardar en base de datos
      this.apiService.anadirCliente(this.cliente).subscribe(dades => {
        console.log("this.alta.cliente: ", dades.response);
        this.cliente.nCliente = dades.response;
        this.revisarRedirecTo();
        this.crearClienteNuevo = false;
        this.clienteResaltado = dades.response;
        this.apiService.obtenerClientes().subscribe(data => {
          this.clientesAutocomplete = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
          
        })
        this.envioDatosFiltro();
      });
    }
  }

  private revisarRedirecTo() {
    if (this.paramsUrl.redirecTo) {
      let paramsEnv = {
        ...this.paramsUrl,
        id: this.cliente.nCliente
      }
      console.log(paramsEnv);
      this.router.navigate([paramsEnv.redirecTo], { queryParams: paramsEnv });
    }
  }

  public volverAOferta() {
    let paramsEnv = {
      ...this.paramsUrl,
    }
    console.log(paramsEnv);
    this.router.navigate([paramsEnv.redirecTo], { queryParams: paramsEnv });
  }

  private eliminarUnCliente() {
    this.apiService.borrarCliente(this.cliente.nCliente).subscribe(dades => {
      console.log("Eliminado el cliente: ", this.cliente);
      this.crearClienteNuevo = true;
      this.resetCliente();
      this.envioDatosFiltro();
    });
  }

  public dialogEliminarUnCliente(clienteABorrar: any) {
    this.clienteABorrar = clienteABorrar;
    this.openDialog("ELIMINAR CLIENTE", "¿Quire eliminar el cliente " + clienteABorrar.empresa + "?", true, "eliminarCliente")
  }

  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminarCliente':
          if (result) this.eliminarUnCliente();
          break;
        default:
          break;
      }
    });
  }

  public esClienteNuevo() {
    this.crearClienteNuevo = true;
    this.resetCliente();
  }

  muestraUnCliente(element: any) {
    console.log(element);
    this.cliente = { ...element };
    this.crearClienteNuevo = false;
  }

  private buscarIndiceEnOfertasFiltradas(clienteResaltado: any) {
    if (!clienteResaltado) return 0;
    let busqueda = clienteResaltado.toString();
    let indice: number;
    indice = this.listaClientes.findIndex(cliente => cliente.nCliente.toString() === busqueda);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    let dialogRef: any;
    switch (label) {
      case 'ciudad':
        dialogRef = mantenimiento2Ciudad(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerCiudades().subscribe(data => {
            this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));

          });
        });
        break;
      case 'idioma':
        dialogRef = mantenimiento1Idioma(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerIdioma()
            .subscribe(data => {
              this.idiomas = data.response.map(idioma => ({ id: idioma.idIdioma.toString(), label: idioma.nombre }))
            });
        });
        break;
    }
  }

  public obtenerDatosCiudad(idCiudad: string) {
    return this.ciudades.find(ciudad => ciudad.idCiudad === idCiudad) || {};
  }
  private resetCliente() {
    this.cliente = {
      nCliente: "",
      empresa: "",
      contacto: "",
      nif: "",
      direccionFacturacion: "",
      codigoPostal: "",
      telefono: "",
      fax: "",
      ciudad: "",
      idCiudad: "",
      provincia: "",
      idProvincia: "",
      cuenta: "",
      condicionesPago: "",
      Riesgo: "",
      idioma: "",
      Ididioma: "",
      dto: "",
      tieneOfertas: ""
    };
    this.crearClienteNuevo = true;
  }

  public resetFiltro() {
    this.filtroClientes = {
      selectedEmpresa: undefined,
      selectedContacto: undefined,
      selectedNIF: undefined,
      selectedEmail: undefined,
      selectedCiudad: undefined,
      selectedCodigoPostal: undefined,
      selectedCondicionesPago: undefined,
      selectedProvincia: undefined,
      tieneOfertas: undefined,
    };
    this.clienteResaltado = null;
    this.resetCliente();
  }

  public mantenimientoEncargadosObra(e: Event) {
    mantenimiento2Encargado(this.Mantenimientodialog, this.cliente.nCliente, this.cliente.empresa);
  }

}
