import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';

@Component({
  selector: 'app-cabecera-oferta',
  templateUrl: './cabecera-oferta.component.html',
  styleUrls: ['./cabecera-oferta.component.css']
})
export class CabeceraOfertaComponent implements OnInit {

  @Input() nOferta = "";
  @Input() idCliente: any = "";
  @Input() letra = "";
  @Input() nAlbaran = "";
  public idIdioma = 2;

  datosOferta = {
    direccionObra: "",
    ciudadObra: "",
    codigoPostalObra: "",
    provinciaObra: "",
    paisObra: "",
  }

  datosCliente = {
    nombreCliente: "",
    direccionCliente: "",
    ciudadCliente: "",
    codigoPostalCliente: "",
    provinciaCliente: "",
    cifCliente: ""
  }



  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    let FiltroOfertas: FiltroOfertas = {
      selectedFechaDesde: undefined,
      selectedFechaHasta: undefined,
      selectedComercial: undefined,
      selectedTecnico: undefined,
      selectedCliente: undefined,
      selectedProvincia: undefined,
      selectedDireccionObra: undefined,
      selectednOferta: this.nOferta,
      selectedChecked: undefined,
      selectedCerrado: undefined,
      selectedColaboracion: undefined,
      pdf: true,
    };
    this.apiService.obtenerOfertas(FiltroOfertas).subscribe(datosOferta => {
      let datosOfe = datosOferta.response[0];
      console.log("datosOfe: ", datosOfe);
      this.datosOferta.direccionObra = datosOfe.direccionObra;
      this.datosOferta.ciudadObra = datosOfe.ciudad;
      this.datosOferta.provinciaObra = datosOfe.provincia;
      this.datosOferta.codigoPostalObra = datosOfe.codigoPostal;
      this.idIdioma = datosOfe.idIdioma;
      this.apiService.obtenerDatosClientes(this.idCliente).subscribe(data => {
        console.log("obtener datos clientes", this.idCliente, data.response);
        this.datosCliente.nombreCliente = data.response[0].nombreCliente;
        this.datosCliente.direccionCliente = data.response[0].direccionCliente;
        this.datosCliente.ciudadCliente = data.response[0].ciudadCliente;
        this.datosCliente.codigoPostalCliente = data.response[0].codigoPostalCliente;
        this.datosCliente.provinciaCliente = data.response[0].provinciaCliente;
        this.datosCliente.cifCliente = data.response[0].cifCliente;
      })
    });

  }

}
