<div class="content">

    <div class="header-cliente">

        <div class="nfac">
            <div >
            </div>

            <strong style="font-size: 60px; color: #004f9f;">{{letra}}</strong>
        </div>

        <div class="nref color-primary">
            <strong>PROVEEDOR: </strong>
            <div  class="ref-fac">

                <strong>{{nombreProveedor}}</strong>
            </div>
        </div>

        <div class="obra">
            <div class="cabeceraObra">
                
            </div>
            <div class="datosObra">
                <div>{{direccionProveedor}}</div>
                <div>{{ciudadProveedor}}</div>
                <div>Télefono - {{telefonoProveedor}}</div>
            </div>
        </div>

        <div class="cliente">
            <div class="cabeceraCliente">
                
            </div>
            <div class="datosCliente">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    </div>

</div>
