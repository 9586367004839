<mat-toolbar color="primary" class="mat-toolbar-layout">

    @if (this.authService.isAuthenticated() && !this.authService.isFabricante()) {
    <div class="button-container">
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menuOfertas" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Ofertas y pedidos</button>
            <mat-menu #menuOfertas="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button mat-menu-item [routerLink]="['/oferta']">Nueva oferta</button>
                    <button mat-menu-item (click)="irAOfertas()"> Buscar ofertas</button>
                </div>
            </mat-menu>
        </ng-template>
    
        <!-- DESCOMENTAR PARA IMPLEMENTAR LA FUNCIONALIDAD ******************************************** -->
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menuFabricacion" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Fabricación</button>
            <mat-menu #menuFabricacion="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button mat-menu-item [routerLink]="['/compras-de-chapa']">Compras de chapa</button>
                    <button mat-menu-item [routerLink]="['/ordenes-de-elaboracion']">Órdenes de elaboración</button>
                    <button mat-menu-item (click)="openDialogStockProduccion()">Stock de zincador</button>
                    <button mat-menu-item [routerLink]="['/taller']">Taller</button>
                </div>
            </mat-menu>
        </ng-template>
        <!-- ***************************************************************************************** -->
    
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menuStock" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Stock</button>
            <mat-menu #menuStock="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button mat-menu-item [routerLink]="['/albaranes-entrada']">Entradas de Stock</button>
                    <button mat-menu-item [routerLink]="['/albaranes-salida']">Salidas de Stock</button>
                    <button mat-menu-item [routerLink]="['/control-stock']">Control de Stock</button>
                </div>
            </mat-menu>
        </ng-template>
    
        <ng-template ngIf="true">
            <button [routerLink]="['/clientes']" mat-button>Clientes</button>
        </ng-template>
    
        <ng-template ngIf="true">
            <button [routerLink]="['/tecnicos']" mat-button>Técnicos</button>
        </ng-template>
    
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menuFacturas" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Facturas</button>
            <mat-menu #menuFacturas="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button mat-menu-item [routerLink]="['/facturas']">Facturas</button>
                    <button mat-menu-item (click)="openDialogInformeVentas()">Informe Material Vendido</button>
                </div>
            </mat-menu>
        </ng-template>
    
    
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menuCompras" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Compras</button>
            <mat-menu #menuCompras="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button mat-menu-item [routerLink]="['/generar-compra']">Generar Compra</button>
                    <button mat-menu-item [routerLink]="['/ordenes-compra']">Órdenes de Compra</button>
                </div>
            </mat-menu>
        </ng-template>
    
        <ng-template ngIf="true">
            <button mat-button [matMenuTriggerFor]="menu2" #matMenuTrigger="matMenuTrigger"
                (mouseenter)="matMenuTrigger.openMenu()">Mantenimiento</button>
            <mat-menu #menu2="matMenu" [overlapTrigger]="false">
                <div (mouseleave)="matMenuTrigger.closeMenu()">
                    <button (click)="openDialogCondicionesPago()" mat-menu-item>Condiciones de pago</button>
                    <button (click)="openDialogValidezOferta()" mat-menu-item>Validez de la oferta</button>
                    <button (click)="openDialogIdioma()" mat-menu-item>Idioma</button>
                    <button (click)="openDialogPais()" mat-menu-item>País</button>
                    <button (click)="openDialogTipoProducto()" mat-menu-item>Tipo Producto</button>
                    <button (click)="openDialogTipoSoporte()" mat-menu-item>Tipo Soporte</button>
                    <button (click)="openDialogTransportista()" mat-menu-item>Transportista</button>
                    <button (click)="openDialogCuentas()" mat-menu-item>Cuentas bancarias</button>
                    <button (click)="openDialogCiudad()" mat-menu-item>Ciudad</button>
                    <button (click)="openDialogProvincia()" mat-menu-item>Provincia</button>
                    <button (click)="openDialogComercial()" mat-menu-item>Comercial</button>
                    <button (click)="openDialogTipoKit()" mat-menu-item>Tipo de Kits</button>
                    <button (click)="openDialogProducto()" mat-menu-item>Producto</button>
                    <button (click)="openDialogSubtipoKit()" mat-menu-item>Subtipo Kit (Rendimiento)</button>
                    <button (click)="openDialogProductoOpc()" mat-menu-item>Producto Opcional</button>
                    <button (click)="openDialogKits()" mat-menu-item>Kit</button>
                    <button (click)="openDialogProveedor()" mat-menu-item>Proveedor</button>
                </div>
            </mat-menu>
        </ng-template>
    
    </div>
    }

    <div class="title">
        <h2>{{tituloService.nombreTitulo}}</h2>
    </div>

    <div class="cerrar">
        <button mat-raised-button color="warn" (click)="logout()">Cerrar</button>
    </div>

</mat-toolbar>