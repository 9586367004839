<div class="login">
  <form (ngSubmit)="login()" class="custom-form" method="post">
    <h1>Login</h1>
    <input
      style="text-transform:uppercase"
      type="text"
      [(ngModel)]="email"
      name="email"
      placeholder="Usuario"
      required
    />
    <input
      type="password"
      style= "text-transform:uppercase"
      [(ngModel)]="password"
      name="password"
      placeholder="Contraseña"
      required
    />
    <button type="submit">Login</button>
  </form>
</div>