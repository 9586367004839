import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TituloService } from 'src/app/services/titulo.service';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-report-listado-ofertas',
  templateUrl: './report-listado-ofertas.component.html',
  styleUrls: ['./report-listado-ofertas.component.css']
})
export class ReportListadoOfertasComponent implements OnInit {

  public listaOfertas: any[] = [];
  public arrayItems: any[] = [];
  public paginasArrayItems: any[] = [];
  public loaded = false;

  constructor(
    private tituloService: TituloService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.controlLocalStorage();
    console.log(this.listaOfertas);
    this.prepararPaginas();
}

  private controlLocalStorage() {
    this.listaOfertas = this.authService.arrayOfertas;
  }

  private prepararPaginas() {
    this.arrayItems = this.listaOfertas;
    let pagina: any[] = [];
    let nPagina = 0;
    let totalHeightPage = 50;
    const totalLength = this.listaOfertas.length;
    const maxHeightTable = 1155 - 70;
    for (let i = 0; i < this.arrayItems.length; i++) {
      totalHeightPage = totalHeightPage + 45;
      let nClis = 0;
      if(this.arrayItems[i].clientesArray) nClis = this.arrayItems[i].clientesArray.length;
      let itemElement = {
        ... this.arrayItems[i],
      }
      if(nClis > 0) totalHeightPage = totalHeightPage + ((nClis - 1) * 45);
      pagina.push(itemElement);
      //this.arrayItems.splice(0, 1);
      if (totalHeightPage == maxHeightTable || totalHeightPage > maxHeightTable) {
        totalHeightPage = totalHeightPage;
        nPagina = nPagina + 1;
        let element = {
          datos: pagina,
          nPagina: nPagina,
          totalHeightPage: totalHeightPage + 'px'
        }
        this.paginasArrayItems.push(element);
        pagina = [];
        totalHeightPage = 50;
      } else if ((i + 1) == totalLength) {
        let element = {
          datos: pagina,
          nPagina: nPagina + 1,
          totalHeightPage: totalHeightPage + 'px'
        }
        this.paginasArrayItems.push(element);
      }
    }
    this.loaded = true;
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 2);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('l', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`listadoOfertas.pdf`);
    })
  }


}
