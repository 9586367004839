import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FiltroOfertas } from './FiltroOfertas';
import { IdLabelObject } from '../../../types/global';
import { ActivatedRoute, Router } from '@angular/router';
import { pick } from 'lodash';
import { DateToString, StringToDate } from '../../../services/dataFunction';

@Component({
  selector: 'app-filtro-ofertas',
  templateUrl: './filtro-ofertas.component.html',
  styleUrls: ['./filtro-ofertas.component.scss']
})
export class FiltroOfertasComponent {

  // Campos del filtro de OFERTAS

  filtros: FiltroOfertas = {
    selectedFechaDesde: undefined,
    selectedFechaHasta: undefined,
    selectedComercial: undefined,
    selectedTecnico: undefined,
    selectedCliente: this.activatedRoute.snapshot.queryParamMap.get('idCliente') || undefined,
    selectedProvincia: undefined,
    selectedCiudad: undefined,
    selectedDireccionObra: undefined,
    selectednOferta: undefined,
    selectedChecked: false,
    selectedCerrado: undefined,
    selectedColaboracion: undefined
  }

  // Opciones de los campos del filtro OFERTAS

  public comerciales: IdLabelObject[] = [];
  public tecnicos: IdLabelObject[] = [];
  public clientes: IdLabelObject[] = [];
  public provincias: IdLabelObject[] = [];
  public ciudades: IdLabelObject[] = [];
  public action: string = "";

  // Opciones campos SI/NO

  opcionesSiNo = [
    {
      nombre: "SI",
      valor: true
    },
    {
      nombre: "NO",
      valor: false
    }
  ]

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  /*ngAfterViewChecked(){
    console.log(JSON.stringify(this.activatedRoute.snapshot.queryParams))
    console.log(JSON.stringify(this.filtros))
    const filtrosComparar = {...this.filtros};
    if(filtrosComparar.selectednOferta) {
      (filtrosComparar.selectednOferta as any) = filtrosComparar.selectednOferta?.toString()
    } 
    if(JSON.stringify(this.activatedRoute.snapshot.queryParams) !== JSON.stringify({...filtrosComparar})) {
      this.router.navigate(['buscar-ofertas'], {queryParams: filtrosComparar})
    }
  }*/

  ngOnInit(): void {
    this.apiService.obtenerComerciales().subscribe(data => {
      this.comerciales = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }));
    });
    this.apiService.obtenerTecnicos().subscribe(data => {
      this.tecnicos = data.response.map(tecnico => ({ id: tecnico.idTecnico.toString(), label: tecnico.nombre }));
    });
    this.apiService.obtenerClientes().subscribe(data => {
      this.clientes = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
    });
    this.apiService.obtenerProvincias().subscribe(data => {
      this.provincias = data.response.map(provincia => ({ id: provincia.idProvincia.toString(), label: provincia.nombreProvincia }));
    });
    this.apiService.obtenerCiudades().subscribe(data => {
      this.ciudades = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.action) {
        this.filtros.selectedChecked = true;
      } else {
        this.filtros = {
          ...this.filtros,
          ...pick(params, Object.keys(this.filtros)),
          ...(params.selectedChecked ? { selectedChecked: params.selectedChecked === 'true' } : { selectedChecked: false })
        };
        if (params.selectedFechaDesde) this.filtros.selectedFechaDesde = StringToDate(params.selectedFechaDesde);
        if (params.selectedFechaHasta) this.filtros.selectedFechaHasta = StringToDate(params.selectedFechaHasta);
      }
      console.log(this.filtros.selectedChecked);
    });

    //    if(this.activatedRoute.snapshot.queryParamMap.get('action') === "albaranes-salida" || "albaranes-entrada") {
    //      this.filtros.selectedChecked = true;
    //    }

  }

  public envioDatosFiltro() {
    //  console.log("envio1")
    if (this.filtros.selectedCerrado == "SI") {
      this.filtros.selectedChecked = true;
    }
    //    if(this.activatedRoute.snapshot.queryParamMap.get('action')) {
    //      this.filtros.selectedChecked = true;
    //    }
    if (this.filtros.selectedFechaDesde) this.filtros.selectedFechaDesde = DateToString(this.filtros.selectedFechaDesde);
    if (this.filtros.selectedFechaHasta) this.filtros.selectedFechaHasta = DateToString(this.filtros.selectedFechaHasta);
    this.router.navigate(['ofertas'], { queryParams: { ...this.filtros, action: this.activatedRoute.snapshot.queryParams.action } })

  }

  private resetFiltros() {
    this.filtros = {
      selectedFechaDesde: undefined,
      selectedFechaHasta: undefined,
      selectedComercial: undefined,
      selectedTecnico: undefined,
      selectedCliente: undefined,
      selectedProvincia: undefined,
      selectedCiudad: undefined,
      selectedDireccionObra: undefined,
      selectednOferta: undefined,
      selectedChecked: undefined,
      selectedCerrado: undefined,
      selectedColaboracion: undefined
    }
  }

  public volverFiltros() {
    this.resetFiltros()
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    /*  let pantalla = { pantalla: "clientes"};*/
    const paramsEnv = {
      redireTo: "ofertas",
    }
    this.router.navigate([label], { queryParams: paramsEnv });
  }

}
