import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { valoresDatosAlbaran } from 'src/app/constants/comprasChapa.constant';
import { ComprasDeChapaComponent } from 'src/app/pages/compras-de-chapa/compras-de-chapa.component';
import { Albaran, AlbaranCompraChapa, ComandaChapaDesdeBD } from 'src/app/types/comprasChapa';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DateToStringGuion } from 'src/app/services/dataFunction';
import { ApiService } from 'src/app/services/api.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-dialog-agregar-albaran-compra-chapa',
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatDialogModule,
    MatIconModule,
    MatTable,
    MatFormFieldModule,
    MatLabel,
    MatDatepickerModule,
    MatDatepickerToggle,
    DatePipe,
    CommonModule,
    MatTableModule,
    MatPaginator,
    MatSort,
    MatSelect,
    MatOption,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    ReactiveFormsModule,
    AsyncPipe,
    MatCheckboxModule
  ],
  templateUrl: './dialog-agregar-albaran-compra-chapa.component.html',
  styleUrl: './dialog-agregar-albaran-compra-chapa.component.css'
})
export class DialogAgregarAlbaranCompraChapaComponent {
  public dataSource!: MatTableDataSource<ComandaChapaDesdeBD>;

  displayedColumns: string[] = [
    'NumPecesEntregats',
    'PesLliurat',
    'Estat'
  ];

  public albaranes: Albaran[] = [];
  control = new FormControl('', Validators.required);
  filteredOptions: Observable<any[]> = new Observable();

  public albaranEncontrado: Albaran | undefined = undefined;
  numAlbaranCustom: string = '';

  public comandaChapa: ComandaChapaDesdeBD = {} as ComandaChapaDesdeBD;
  public buscarNumAlbaran = false;
  public hayAlbaranSeleccionado = false;

  public nuevoAlbaran_Chapa: AlbaranCompraChapa = {
    IdComanda: this.comandaChapa.IdComanda,
    IdAlbara: null,
    NumAlbara: null,
    NumPecesEntregats: null,
    PesLliurat: null,
    DataAlbara: new Date(Date.now())
  }

  public valoresAlbaran = valoresDatosAlbaran;

  constructor(
    private apiService: ApiService,
    public dialogRef: MatDialogRef<DialogAgregarAlbaranCompraChapaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComandaChapaDesdeBD
  ) {
    this.dataSource = new MatTableDataSource([this.data]);
    this.comandaChapa = this.data;
  }

  ngOnInit() {
    this.obtenerAlbaranes();

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value?.toString() ?? '')),
    );
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.albaranes.filter(albaran => albaran?.NumAlbara?.toString().toLowerCase().startsWith(filterValue));
  }

  private obtenerAlbaranes() {
    this.apiService.obtenerAlbaranesDeCompraChapa(this.comandaChapa.IdComanda).subscribe((data: any) => {
      this.albaranes = data?.response ?? [];
    });
  }

  public definirColorRow(rowIdComanda: number, rowEstat: string, comandaChapaIdComanda?: number): any {
    return ComprasDeChapaComponent.colorRow(rowIdComanda, rowEstat, comandaChapaIdComanda);
  }

  public onNumPecesChange(newValue: string) {
    this.nuevoAlbaran_Chapa.NumPecesEntregats = Number(newValue);
    this.nuevoAlbaran_Chapa.PesLliurat = this.nuevoAlbaran_Chapa.NumPecesEntregats * this.comandaChapa.PesProducte;
  }

  public onPesLliuratChange(newValue: string) {
    this.nuevoAlbaran_Chapa.PesLliurat = Number(newValue);
  }

  public anadirAlbaranCompraChapa() {
    if (this.nuevoAlbaran_Chapa.NumPecesEntregats <= 0) return;
    if (this.nuevoAlbaran_Chapa.DataAlbara instanceof Date) this.nuevoAlbaran_Chapa.DataAlbara = DateToStringGuion(this.nuevoAlbaran_Chapa.DataAlbara);

    const albaranParaAñadir = {
      IdProducte: this.comandaChapa.IdProducte,
      IdComanda: this.comandaChapa.IdComanda,
      IdAlbara: this.nuevoAlbaran_Chapa?.IdAlbara,
      NumAlbara: this.nuevoAlbaran_Chapa?.NumAlbara,
      NumPecesEntregats: this.nuevoAlbaran_Chapa.NumPecesEntregats,
      PesLliurat: this.nuevoAlbaran_Chapa.PesLliurat,
      DataAlbara: this.nuevoAlbaran_Chapa?.DataAlbara
    }

    console.log('ALBARAN A INSERTAR >>>>>>>>', albaranParaAñadir);

    this.apiService.anadirAlbaranCompraChapa(albaranParaAñadir).subscribe(() => {
      this.dialogRef.close();
    });
  }

  public inputNumAlbaranToggle(checked: boolean) {
    this.buscarNumAlbaran = checked;
    this.control.reset();
    this.resetNuevoAlbaran();

    if (this.buscarNumAlbaran) {
      this.nuevoAlbaran_Chapa.DataAlbara = null;
    }
  }

  public onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.numAlbaranCustom = target.value;

    if (target.value === '') {
      this.albaranEncontrado = undefined
      this.nuevoAlbaran_Chapa.DataAlbara = new Date(Date.now());
    };

    if (!this.albaranEncontrado) {
      this.nuevoAlbaran_Chapa.IdComanda = this.comandaChapa.IdComanda;
      this.nuevoAlbaran_Chapa.NumAlbara = this.numAlbaranCustom;
    }
  }


  public onAlbaranSelected(event: MatAutocompleteSelectedEvent): void {
    this.albaranEncontrado = this.albaranes.find(albaran => albaran.NumAlbara === event.option.value);
    this.hayAlbaranSeleccionado = true;
    if (this.albaranEncontrado) {
      this.nuevoAlbaran_Chapa.IdComanda = this.comandaChapa.IdComanda;
      this.nuevoAlbaran_Chapa.IdAlbara = this.albaranEncontrado?.IdAlbara;
      this.nuevoAlbaran_Chapa.NumAlbara = this.albaranEncontrado?.NumAlbara;
      this.nuevoAlbaran_Chapa.DataAlbara = this.albaranEncontrado?.DataAlbara;
    }
  }

  public resetAlbaranSeleccionado() {
    this.albaranEncontrado = undefined;
    this.hayAlbaranSeleccionado = false;
    this.control.reset();
    this.resetNuevoAlbaran();
  }

  public resetNuevoAlbaran() {
    this.nuevoAlbaran_Chapa = {
      IdComanda: this.comandaChapa.IdComanda,
      IdAlbara: null,
      NumAlbara: null,
      NumPecesEntregats: null,
      PesLliurat: null,
      DataAlbara: new Date(Date.now())
    }

    this.control.reset();
    this.albaranEncontrado = undefined;
    this.numAlbaranCustom = '';
  }
}
