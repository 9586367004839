<form #formulario="ngForm" (ngSubmit)="guardarOferta(formulario)" class="container" autosize>
  <div *ngIf="pantalla == 'datosUnaOferta'" class="flex-layout">
    <div class="form-section flex-30-responsive">
      <mat-form-field class="disabled flex-45-responsive" appearance="outline">
        <mat-label> Nº Oferta</mat-label>
        <input disabled [value]="dataOferta.nOferta" name="nOferta" matInput type="text">
      </mat-form-field>

      <mat-form-field class="flex-45-responsive" appearance="outline">
        <mat-label>
          Fecha Oferta
        </mat-label>
        <input matInput required [(ngModel)]="dataOferta.fechaOferta" name="fechaOferta" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field style=" width: 300px;" appearance="outline">
        <mat-label>Dirección de obra</mat-label>
        <textarea [disabled]="fechaCierreForm" #direccionObra="ngModel" required matInput
          [(ngModel)]="dataOferta.direccionObra" name="direccionObra" maxlength="80" maxrows="2"></textarea>
        <mat-hint>Para saltar de línea utilizar "enter"</mat-hint>
      </mat-form-field>

      <app-autocomplete class="auto100 pl-6 pr-6" name="ciudad" [idOpcionSeleccionada]="dataOferta.idCiudad"
        (cuandoSeSeleccioneOpcion)="dataOferta.idCiudad = $event" icon="settings"
        (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" label="Ciudad"
        [options]="ciudadesAutocomplete">
      </app-autocomplete>

      <mat-form-field class="cp flex-45-responsive" appearance="outline">
        <mat-label>Provincia</mat-label>
        <input disabled matInput
          [value]="obtenerDatosCiudad(dataOferta.idCiudad).nombreProvincia || dataOferta.provincia" name="provincia"
          type="text">
      </mat-form-field>

      <mat-form-field class="cp flex-45-responsive" appearance="outline">
        <mat-label>Pais</mat-label>
        <input disabled matInput [value]="obtenerDatosCiudad(dataOferta.idCiudad).nombrePais" name="provincia"
          type="text">
      </mat-form-field>

      <mat-form-field class="cp flex-45-responsive" appearance="outline">
        <mat-label>C. P.</mat-label>
        <input [disabled]="dataOferta.idCliente" matInput [(ngModel)]="dataOferta.codigoPostal" name="codigoPostal"
          type="text">
      </mat-form-field>

      <mat-form-field class="cp flex-45-responsive" appearance="outline">
        <mat-label>Plazo de entrega</mat-label>
        <input [disabled]="dataOferta.idCliente" matInput [(ngModel)]="dataOferta.plazoEntrega" name="plazoEntrega"
          type="text">
      </mat-form-field>

      <app-autocomplete [disabled]="dataOferta.idCliente" class="auto100 pl-6 pr-6" name="validez"
        [idOpcionSeleccionada]="dataOferta.validezOferta" (cuandoSeSeleccioneOpcion)="dataOferta.validezOferta = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('validezOferta')" label="Validez Oferta"
        [options]="listaValidezOferta">
      </app-autocomplete>

      <mat-form-field class="flex-100" appearance="outline">
        <mat-label>Notas</mat-label>
        <textarea [disabled]="dataOferta.idCliente" matInput [(ngModel)]="dataOferta.notas" name="notas"
          rows="4"></textarea>
      </mat-form-field>
    </div>

    <div class="form-section flex-30-responsive">
      <app-autocomplete [disabled]="fechaCierreForm" class="auto100 pl-6 pr-6" name="comercial"
        [idOpcionSeleccionada]="dataOferta.idComercial" (cuandoSeSeleccioneOpcion)="dataOferta.idComercial = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('comercial')" label="Comercial"
        [options]="comerciales">
      </app-autocomplete>

      <app-autocomplete [disabled]="fechaCierreForm" class="auto100 pl-6 pr-6" name="delegacion"
        [idOpcionSeleccionada]="dataOferta.idDelegacion" (cuandoSeSeleccioneOpcion)="dataOferta.idDelegacion = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('delegacion')" label="Delegación"
        [options]="delegaciones">
      </app-autocomplete>

      <app-autocomplete [disabled]="dataOferta.idCliente" class="auto100 pl-6 pr-6" name="elementosPor"
        [idOpcionSeleccionada]="dataOferta.idElementoPor" (cuandoSeSeleccioneOpcion)="dataOferta.idElementoPor = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('elementosPor')"
        label="Elemento Portante" [options]="elementosPor">
      </app-autocomplete>

      <app-autocomplete [disabled]="dataOferta.idCliente" class="auto100 pl-6 pr-6" name="tecnico"
        [idOpcionSeleccionada]="dataOferta.idTecnico" (cuandoSeSeleccioneOpcion)="dataOferta.idTecnico = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('tecnico')" label="Técnico"
        [options]="tecnicos">
      </app-autocomplete>

      <app-autocomplete [disabled]="dataOferta.idCliente" class="auto100 pl-6 pr-6" name="idioma"
        [idOpcionSeleccionada]="dataOferta.idIdioma" (cuandoSeSeleccioneOpcion)="dataOferta.idIdioma = $event"
        icon="settings" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('idioma')" label="Idioma"
        [options]="idiomas">
      </app-autocomplete>

      <app-autocomplete [disabled]="dataOferta.idCliente" class="auto100 pl-6 pr-6" name="condiciones"
        [idOpcionSeleccionada]="dataOferta.condicionesPago"
        (cuandoSeSeleccioneOpcion)="dataOferta.condicionesPago = $event" icon="settings"
        (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('condicionesPago')" label="Condiciones de Pago"
        [options]="listaCondicionesPago">
      </app-autocomplete>

      <mat-form-field class="flex-100" appearance="outline">
        <mat-label>Notas Internas</mat-label>
        <textarea [disabled]="dataOferta.idCliente" matInput [(ngModel)]="dataOferta.notasInternas" name="notasInternas"
          rows="4"></textarea>
      </mat-form-field>
    </div>

    <div class="form-section flex-40-responsive justify-between">
      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>Importe Otros (€)</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.importeOtros" name="importeOtros" type="number">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>Peso Otros (Kg)</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.pesoOtros" name="pesoOtros" type="number">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>Importe Kits (€)</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.importeKits" name="importeKits" type="number">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>Peso Kits (Kg)</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.pesoKits" name="pesoKits" type="number">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>% Incremento Oferta (%)</mat-label>
        <input [disabled]="fechaCierreForm" matInput [(ngModel)]="dataOferta.incrementoDec" name="incrementoDec"
          type="number">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-47-responsive" appearance="outline">
        <mat-label>Descuento Oferta (%)</mat-label>
        <input [disabled]="fechaCierreForm" matInput [(ngModel)]="dataOferta.dtoOferta" name="dtoOferta" type="number">
      </mat-form-field>


      <mat-form-field *ngIf="dataOferta.nOferta" class="flex-51-responsive" appearance="outline">
        <mat-label>Descuento Pronto Pago (€)</mat-label>
        <input [disabled]="fechaCierreForm" matInput [(ngModel)]="dataOferta.dtoPP" name="dtoPP" type="number">
      </mat-form-field>


      <mat-form-field *ngIf="dataOferta.cliente" class="flex-50-responsive" appearance="outline">
        <mat-label>Cliente</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.cliente" name="cliente" type="string">
      </mat-form-field>

      <mat-form-field *ngIf="dataOferta.cliente" class="flex-50-responsive" appearance="outline">
        <mat-label>Fecha pedido</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.fechaPedido" name="fechaPedido" [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <div *ngIf="dataOferta.cliente" class="flex-row-wrap flex-100">
        <mat-checkbox class="flex-50-responsive" disabled [(ngModel)]="dataOferta.colaboracion"
          name="colaboracion">Colaboración
        </mat-checkbox>
        <mat-form-field class="flex-50-responsive" appearance="outline">
          <mat-label>Incremento Cliente Kits (%)</mat-label>
          <input disabled matInput [(ngModel)]="dataOferta.incrementoClienteKits" name="incrementoClienteKits"
            type="number">
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="dataOferta.cliente" class="flex-51-responsive" appearance="outline">
        <mat-label>Descuento Cliente Kits (%)</mat-label>
        <input disabled matInput [(ngModel)]="dataOferta.dtoOferta" name="dto" type="number">
      </mat-form-field>
      <!--
        <mat-form-field *ngIf="dataOferta.cliente" class="flex-50-responsive" appearance="outline">
          <mat-label>% Anticipo</mat-label>
          <input disabled matInput [(ngModel)]="dataOferta.anticipo" name="anticipo" type="number">
        </mat-form-field>
-->
      <div *ngIf="dataOferta.cliente" class="flex-row-wrap flex-100">
        <mat-checkbox class="flex-50-responsive" disabled [(ngModel)]="fechaCierreForm"
          name="cierre">Cerrada</mat-checkbox>
        <mat-form-field class="flex-50-responsive" appearance="outline">
          <mat-label>
            Fecha Cierre
          </mat-label>
          <input matInput [disabled]="fechaCierreForm" [(ngModel)]="fechaCierreForm" name="fechaCierreForm"
            [matDatepicker]="picker3">
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
      </div>


      <div class="flex-row-wrap flex-100">
        <span class="flex-33-responsive">
          <button type="button" *ngIf="dataOferta.nOferta" mat-raised-button (click)="openKitsUnCliente()">KITS</button>
        </span>
        <span class="flex-33-responsive">
          <button type="button" *ngIf="dataOferta.nOferta" mat-raised-button
            (click)="openOtrosUnCliente()">OTROS</button>
        </span>
        <span class="flex-33-responsive">
          <button type="button" *ngIf="dataOferta.nOferta" mat-raised-button
            (click)="openOfertasUnCliente()">CLIENTES</button>
        </span>
      </div>

      <div class="flex-row-wrap flex-100">
        <span class="flex-48-responsive">
          <button [disabled]="dataOferta.fechaCierre || !dataOferta.direccionObra" mat-raised-button
            type="submit">GUARDAR</button>
        </span>
        <span class="flex-48-responsive">
          <button mat-raised-button type="button" (click)="volverATabla()">VOLVER</button>
        </span>
      </div>
    </div>

  </div>

  <ng-container *ngIf="pantalla == 'clientes'">
    <app-ofertas-un-cliente [oferta]="dataOferta" [idClienteAsignado]="dataOferta.idCliente"
      [clientesUnaOferta]="clientes" (cuandoClientesCambien)="cuandoClientesCambien()"
      (clickEnVolverAPantalla)="clickEnVolverAPantalla()" (heAsignadoCliente)="volverATabla()"></app-ofertas-un-cliente>
  </ng-container>

  <ng-container *ngIf="pantalla == 'kits'">
    <app-kits-un-cliente [oferta]="dataOferta" [idClienteAsignado]="dataOferta.idCliente" [kitsUnaOferta]="kits"
      (cuandoKitsCambien)="cuandoKitsCambien()"
      (clickEnVolverAPantalla)="clickEnVolverAPantalla()"></app-kits-un-cliente>
  </ng-container>

  <ng-container *ngIf="pantalla == 'otros'">
    <app-otros-un-cliente [oferta]="dataOferta" [idClienteAsignado]="dataOferta.idCliente"
      [especialesUnaOferta]="especiales" [otrosUnaOferta]="otros" (cuandoOtrosCambien)="cuandoOtrosCambien()"
      (clickEnVolverAPantalla)="clickEnVolverAPantalla()"></app-otros-un-cliente>
  </ng-container>

</form>