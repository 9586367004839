<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

    <div class="layout-filtro flex flex-100" style="align-items: normal !important;">

        <div class="flex-6-responsive flex-col justify-center">
            <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
                <mat-icon>restart_alt</mat-icon>
            </button>
        </div>
        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Inicio
            </mat-label>
            <input matInput [(ngModel)]="filtroOrdenesCompra.fechaInicioSelected" name="fechaInicio" disabled
                [matDatepicker]="picker">
            <button class="button-date" *ngIf="filtroOrdenesCompra.fechaInicioSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroOrdenesCompra.fechaInicioSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>
                Fecha Fin
            </mat-label>
            <input matInput [(ngModel)]="filtroOrdenesCompra.fechaFinSelected" disabled
                [min]="filtroOrdenesCompra.fechaInicioSelected" name="fechaFin" [matDatepicker]="picker2">
            <button class="button-date" *ngIf="filtroOrdenesCompra.fechaFinSelected" matSuffix mat-icon-button
                aria-label="Clear" (click)="filtroOrdenesCompra.fechaFinSelected=undefined">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="cerrado" appearance="outline">
            <mat-label>¿Cerrado?</mat-label>
            <mat-select [(ngModel)]="filtroOrdenesCompra.cerradaSelected" name="cerrado">
                <mat-option></mat-option>
                <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                    {{opcion.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-15-responsive">
            <mat-label>Nº Compra</mat-label>
            <input matInput type="number" [(ngModel)]="filtroOrdenesCompra.nCompraSelected" name="cantidad">
        </mat-form-field>
        <div class="flex-14-responsive flex-col justify-center">
            <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
        </div>
    </div>

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <!--        <button class="button-create" mat-icon-button type="button" (click)="resetOrdenCompra()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
            -->
                </th>
                <td mat-cell *matCellDef="let element">
                </td>
            </ng-container>

            <ng-container matColumnDef="nCompra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Compra </th>
                <td mat-cell *matCellDef="let element" align="initial"> {{element.nCompra}} </td>
            </ng-container>

            <ng-container matColumnDef="fechaCompra">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Compra </th>
                <td mat-cell *matCellDef="let element" align="initial"> {{element.fechaCompra | date: 'dd/MM/yyyy'}}
                </td>
            </ng-container>


            <ng-container matColumnDef="fechaCierre">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha cierre </th>
                <td mat-cell *matCellDef="let element" align="initial"> {{element.fechaCierre | date: 'dd/MM/yyyy'}}
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnaOrdenCompra(row)"
                [ngClass]="{'row-highlighted': ordenCompra.nCompra == row.nCompra}"></tr>
        </table>

        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaOrdenesCompra.length > 0}" [pageSizeOptions]="[20]"
            showFirstLastButtons>
        </mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaOrdenesCompra.length == 0">No hay órdenes de compras</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarOrdenEntrada(formulario)"
        class="form-derecho row-gap-10 flex-35-responsive">

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Nº Compra</mat-label>
            <input disabled [(ngModel)]="ordenCompra.nCompra" name="nCompra" matInput type="number">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>
                Fecha Compra
            </mat-label>
            <input matInput disabled [(ngModel)]="ordenCompra.fechaCompra" name="fechaCompra" [matDatepicker]="picker3">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>
                Fecha Cierre
            </mat-label>
            <input matInput [(ngModel)]="fechaCierreForm" name="fechaCierreForm" disabled [matDatepicker]="picker4">
            <button class="button-date" *ngIf="fechaCierreForm" matSuffix mat-icon-button aria-label="Clear"
                (click)="fechaCierreForm=''">
                <mat-icon>close</mat-icon>
            </button>

            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <span class="flex-48-responsive mt-10">
            <button [disabled]="!ordenCompra.nCompra" class="button__buscar" type="button" mat-raised-button
                (click)="irADetallesOrdenesCompra()">Detalles</button>
        </span>


        <span class="flex-48-responsive mt-10">
            <button [disabled]="!ordenCompra.nCompra || ordenCompra.fechaCierre" class="button__buscar" type="submit"
                mat-raised-button>Guardar</button>
        </span>


    </form>

</form>