import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';
// Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15. De totes formes no veig que aquí s'utilitzi
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-report-oferta-catala',
  templateUrl: './report-oferta-catala.component.html',
  styleUrls: ['./report-oferta-catala.component.css']
})
export class ReportOfertaCatalaComponent implements OnInit {

  private paramsUrl: any = "";
  public letra = "P";
  public paginas: any[] = [];
  public nPaginas: number = 0;
  public idiomaOferta = 2;

  public nOferta: any = "";
  public idCliente: any = "";
  
  public tieneDto: boolean = false;
  public tieneTransporte: boolean = false;
  public porcentaje: number= 0;
  public condicionesPago = "";
  public validezOferta = "";
  public plazoEntrega = "";
  public fechaOferta = "";
  public poblacionDelegacion = "";
  public direccionDelegacion = "";
  public cpDelegacion = "";
  public provinciaDelegacion = "";
  private idDelegacion = "";

  totales = [
    {
      nombre: "Planta Baja",
      importe: 1000000
    },
    {
      nombre: "Planta Piso",
      importe: 200
    },
    {
      nombre: "Planta Sotano",
      importe: 300
    },
    {
      nombre: "Planta Terraza",
      importe: 400
    }
  ]
  public importeTotal = 0;
  datosOferta = [
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },

    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Baja",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2250",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 3500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2250",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 3500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 2250",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 3500",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Piso",
      esKit: false,
      cantidad: 1,
      unidad: 'Kg',
      desc: "Mortero MVH 491-680",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Terraza",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Sótano",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 5000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Sótano",
      esKit: true,
      cantidad: 1,
      unidad: 'kit',
      desc: "MVH12 5000",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Sótano",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
    {
      situacion: "Planta Sótano",
      esKit: false,
      cantidad: 1,
      unidad: 'Unid.',
      desc: "Colocación de kits MVH + Retacado",
      precio: 2,
      dto: 0,
      porcentaje:0
    },
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      this.nOferta = params.nOferta;
      this.idCliente = params.nCliente;
      let FiltroOfertas: FiltroOfertas = {
        selectedFechaDesde: undefined,
        selectedFechaHasta: undefined,
        selectedComercial: undefined,
        selectedTecnico: undefined,
        selectedCliente: this.idCliente,
        selectedProvincia: undefined,
        selectedDireccionObra: undefined,
        selectednOferta: this.nOferta,
        selectedChecked: undefined,
        selectedCerrado: undefined,
        selectedColaboracion: undefined,
        pdf: true,
      };
      this.apiService.obtenerOfertas(FiltroOfertas).subscribe( datosOferta => {

        let datosOfe = datosOferta.response[0] ;
        this.condicionesPago = datosOfe.nombreCondicionesPago;
        this.validezOferta = datosOfe.nombreValidezOferta;     
        this.plazoEntrega = datosOfe.plazoEntrega;
        this.fechaOferta = datosOfe.fechaOferta;
        this.idDelegacion = datosOfe.idDelegacion;
        this.idiomaOferta = datosOfe.idIdioma;
        this.apiService.obtenerDelegacionParams(this.idDelegacion).subscribe(datos => {
           this.direccionDelegacion = datos.response[0].direccionDelegacion;
           this.cpDelegacion = datos.response[0].cpDelegacion;
           this.poblacionDelegacion = datos.response[0].poblacionDelegacion;
           this.provinciaDelegacion = datos.response[0].provinciaDelegacion;
        })
      })
      
      this.apiService.detalleOfertaPDF(this.nOferta, this.idCliente).subscribe(data =>{
        this.datosOferta = data.response;
        this.tieneTransporte = data.response[0]['transporte'];
        this.datosOferta.forEach(item=>{
          if(!this.tieneDto && item['dto']!=0){
            this.tieneDto = true;
            if(item.esKit){
              this.porcentaje = item.porcentaje;
            }
          }
        });
        this.apiService.obtenerPrecioFinal(this.nOferta, this.idCliente).subscribe(data =>{
          this.totales= data.response;
          this.totales.forEach(item=>{
            this.importeTotal +=item['importe'];
          })
          
        });
        this.cuentaLineas();
      });
    });
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`oferta${this.nOferta}.pdf`);
      this.router.navigate([this.paramsUrl.redirecTo], { queryParams: this.paramsUrl });
  
    })
  }

  private cuentaLineas() {
    let nombreSituacion = "inicial";
    let imprimirSituacion: any = "";
    let imprimirCabecera: any = "";
    let lineasPagina: number = 0;
    let pagina: any[] = [];
    let contadorProductos = 0;
    let esUnKit: any = "inicial";
    let situacionAnterior = "";
    let totalCabecera = 0;
    let totalSituacion = 0;
    let totalCabeceraAnterior = 0;
    let totalSituacionAnterior = 0;

    this.datosOferta.forEach(item => {
      contadorProductos = contadorProductos + 1;
      //console.log(contadorProductos);
      if (nombreSituacion === 'inicial') {
        lineasPagina = lineasPagina + 2;
        imprimirSituacion = 'inicial';
        nombreSituacion = item.situacion;
      } else {
        if (nombreSituacion !== item.situacion) {
          lineasPagina = lineasPagina + 4;
          imprimirSituacion = "SI";
          nombreSituacion = item.situacion;
        } else {
          imprimirSituacion = "NO";
        }
      }
      if (esUnKit === 'inicial') {
        imprimirCabecera = 'inicial';
        esUnKit = item.esKit;
        lineasPagina = lineasPagina + 2
      } else {
        if (esUnKit !== item.esKit) {
          imprimirCabecera = "SI";
          esUnKit = item.esKit;
          lineasPagina = lineasPagina + 4
        } else {
          imprimirCabecera = "NO"
          if (imprimirSituacion === "SI") {
            imprimirCabecera = "SI-cont"
            lineasPagina = lineasPagina + 2
          }
        }
      }
      lineasPagina = lineasPagina + 1
  //    console.log(contadorProductos, lineasPagina);
      if (lineasPagina > 33) {
        this.paginas.push(pagina);
        let a = this.paginas[this.paginas.length - 1];
        let b = a[a.length-1];
        if (imprimirCabecera === "SI") {b.impCabecera = "final"};
  //      if (imprimirSituacion === "SI") {this.paginas[this.paginas.length-1].impSituacion = "final"};
        lineasPagina = 0;
        if (imprimirSituacion === "NO") {
          imprimirSituacion = "SI-cont";
        }
        else { imprimirSituacion = "SI"; }
    //    imprimirCabecera = "SI-cont";
        esUnKit = item.esKit;
        lineasPagina = lineasPagina + 5
        lineasPagina = lineasPagina + 1
        pagina = [];
        totalCabeceraAnterior = totalCabecera;
        totalSituacionAnterior = totalSituacion;
        if (imprimirCabecera === "SI") { totalCabecera = 0 };
        if (imprimirSituacion === "SI") { totalSituacion = 0; totalCabecera = 0; };
        totalCabecera = totalCabecera + item.cantidad * item.precio - item.dto;
        totalSituacion = totalSituacion + item.cantidad * item.precio - item.dto;
        let element = {
          ...item,
          impSituacion: imprimirSituacion,
          impCabecera: imprimirCabecera,
          importeCabecera: totalCabecera,
          importeSituacion: totalSituacion,
          importeCabeceraAnterior: totalCabeceraAnterior,
          importeSituacionAnterior: totalSituacionAnterior
        }
        pagina.push(element);
        //console.log(contadorProductos, lineasPagina);
        if (contadorProductos === this.datosOferta.length) {
          if (imprimirSituacion === "SI-cont") {
            pagina[pagina.length - 1].impSituacion = "final-cont";
          } else {
            pagina[pagina.length - 1].impSituacion = "final";
          }
          this.paginas.push(pagina);
        }
      } 
      else {
        if (contadorProductos > 1) {
          situacionAnterior = this.datosOferta[contadorProductos - 2].situacion;
        }
        totalCabeceraAnterior = totalCabecera;
        totalSituacionAnterior = totalSituacion;
        if (imprimirCabecera === "SI") { totalCabecera = 0 };
        if (imprimirSituacion === "SI") { totalSituacion = 0; totalCabecera = 0; };
        totalCabecera = totalCabecera + item.cantidad * item.precio - item.dto;
        totalSituacion = totalSituacion + item.cantidad * item.precio - item.dto;
        let element = {
          ...item,
          impSituacion: imprimirSituacion,
          impCabecera: imprimirCabecera,
          situacionAnt: situacionAnterior,
          importeCabecera: totalCabecera,
          importeSituacion: totalSituacion,
          importeCabeceraAnterior: totalCabeceraAnterior,
          importeSituacionAnterior: totalSituacionAnterior
        }
        pagina.push(element);
        //console.log(contadorProductos, lineasPagina);
        if (contadorProductos === this.datosOferta.length) {
          //console.log("1: ", pagina);
          pagina[pagina.length - 1].impSituacion = "final";
          this.paginas.push(pagina);
        }
      }
    });
    console.log("PAGINAS: ", this.paginas);
    this.nPaginas = this.paginas.length + 2;

  }
}

