import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-report-contrato-bomba',
  templateUrl: './report-contrato-bomba.component.html',
  styleUrls: ['./report-contrato-bomba.component.css']
})
export class ReportContratoBombaComponent implements OnInit {

  public nOferta: any;
  public letra = "CB"

  constructor(
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.nOferta = params.nOferta;
    })
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`contrato-bomba${this.nOferta}.pdf`);
    })
  }


}
