<!DOCTYPE html>
<html lang="es" class="h-100">

  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Mecanogest</title>
  </head>

  <body class="h-100">
    <div class="layout-header">
      <app-header></app-header>
    </div>
    <div class="layout-body">
      <router-outlet></router-outlet>
    </div>
  </body>

</html>
