import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { IdLabelObject } from '../../../types/global';
import { NgForm } from '@angular/forms';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Cliente } from '../../../types/clientes';
import { TituloService } from 'src/app/services/titulo.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-control-stock',
  templateUrl: './control-stock.component.html',
  styleUrls: ['./control-stock.component.css']
})
export class ControlStockComponent implements AfterViewInit {

  public filtroControlStock: any = {
    selectedTipoBusqueda: undefined,
    selectedProducto: undefined,
    selectedTipoProducto: undefined,
    selectedKits: undefined,
    selectedKitsQty: undefined
  };

  public producto: any = {
    idProducto: "",
    nombreProducto: "",
    peso: "",
    stockMinimo: "",
    stockActual: "",
    compraPendiente: "",
    materialComprado: "",
    stockZinc: "",
    tipoProducto: "",
    cantidad: "",
    disponible: "",
  };

  opcionesTipoBusqueda = [
    {
      id: 0,
      nombre: "Productos debajo stock"
    },
    {
      id: 1,
      nombre: "Productos encima stock"
    },
    {
      id: 2,
      nombre: "Todos los productos"
    }
  ];

  opcionesTipoProducto = [
    { nombre: "Hierro" },
    { nombre: "Tornillería" }
  ];

  listaKits: any[] = []

  public listaProductos: any[] = [];
  public crearProductoNuevo: boolean = true;
  public productoResaltado = null;
  public productoABorrar: any;
  public tiposProductoAutocomplete: IdLabelObject[] = [];
  public kitsAutocomplete: IdLabelObject[] = [];
  public dataSource!: MatTableDataSource<Cliente>;
  public hayDatosEnTabla: boolean = true;
  public idKitAGuardar: any = "";
  public cantidadKitAGuardar: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;

  displayedColumns: string[] = ['buttons', 'nombreProducto', 'peso', 'stockMinimo', 'stockActual', 'punzonadoyplegado', 'punzonado', 'zincado', 'zincador',
    'zincador al cliente', 'compraPendiente', 'materialComprado', 'tipoProducto', 'cantidad', 'disponible'];
  displayedColumns2: string[] = ['buttons', 'nombreKit', 'cantidadKit'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaProductos);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetProducto();
    this.apiService.obtenerTipoProductoStock().subscribe(data => {
      console.log("TIPOS PRODUCTO: ", data.response)
      this.tiposProductoAutocomplete = data.response.map(producto => ({ id: producto.idTipo.toString(), label: producto.nombre }));
    })
    this.apiService.obtenerKitReal().subscribe(data => {
      this.kitsAutocomplete = data.response.map(kit => ({ id: kit.idProducto.toString(), label: kit.nombre }));
    })
    this.filtroControlStock.selectedTipoBusqueda = 2;
    //    this.buscarListaProductos();
  }

  ngAfterViewInit() {

    this.buscarListaProductos();
    /*    this.dataSource = new MatTableDataSource<Cliente>(this.listaProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
  }
  /*
    ngOnChanges(): void {
      console.log("BUSCA 3: ", this.filtroControlStock.selectedTipoBusqueda);
      this.dataSource = new MatTableDataSource<Cliente>(this.listaProductos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  */

  public envioDatosFiltro() {
    this.buscarListaProductos();
  }

  private buscarListaProductos() {
    if (this.listaKits.length == 0) {
      this.apiService.obtenerControlStock(this.filtroControlStock).subscribe(dades => {
        this.listaProductos = dades.response;
        this.dataSource = new MatTableDataSource<Cliente>(this.listaProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    } else {
      this.transformaArrayKits();
      this.apiService.obtenerControlStock2(this.filtroControlStock).subscribe((dades: any) => {
        this.listaProductos = dades.response; this.dataSource = new MatTableDataSource<Cliente>(this.listaProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    }
  }

  private transformaArrayKits() {
    let nuevoString = "";
    this.listaKits.forEach(item => {
      let unID = '[' + item.idKit + '],';
      nuevoString = nuevoString + unID;
    });
    this.filtroControlStock.selectedKits = nuevoString.substring(0, nuevoString.length - 1);

    nuevoString = "";
    this.listaKits.forEach(item => {
      let unID = '[' + item.idKit + '|' + item.cantidadKit + '],';
      nuevoString = nuevoString + unID;
    });
    this.filtroControlStock.selectedKitsQty = nuevoString.substring(0, nuevoString.length - 1);
  }

  public guardaKit() {
    var msg = "";
    if (!this.miraNegativos(this.cantidadKitAGuardar)) {
      msg = 'El campo Cantidad Albarán tiene un número negativo';
    }
    else if (this.comprobarSiYaExiste(this.idKitAGuardar)) {
      msg = 'Este Kit ya esta creado';
    }

    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }

    let kitSelecionado: any = this.kitsAutocomplete.find(kit => kit.id === this.idKitAGuardar);
    let kitAGuardar = {
      idKit: this.idKitAGuardar,
      nombreKit: kitSelecionado.label,
      cantidadKit: this.cantidadKitAGuardar
    }
    this.listaKits.push(kitAGuardar);
    this.idKitAGuardar = "";
    this.cantidadKitAGuardar = "";
    this.table.renderRows();
  }

  public borrarTablaKits() {
    this.listaKits = [];
    this.table.renderRows();
  }

  public borrarUnKit(kitABorrar: any) {
    console.log(kitABorrar);
    this.listaKits = this.listaKits.filter(kit => kit.idKit !== kitABorrar.idKit);
    this.table.renderRows();
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        default:
          break;
      }
    });
  }


  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.listaKits.find(item => {
      if (item.idKit == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private resetProducto() {
    this.producto = {
      idProducto: "",
      nombreProducto: "",
      peso: "",
      stockMinimo: "",
      stockActual: "",
      compraPendiente: "",
      materialComprado: "",
      stockZinc: "",
      tipoProducto: "",
      cantidad: "",
      disponible: "",
    };
    this.crearProductoNuevo = true;
  }

  public resetFiltro() {
    this.filtroControlStock = {
      selectedTipoBusqueda: undefined,
      selectedProducto: undefined,
      selectedTipoProducto: undefined,
      selectedKits: undefined,
    };
    this.idKitAGuardar = "";
    this.cantidadKitAGuardar = "";
    this.productoResaltado = null;
    this.resetProducto();
    this.filtroControlStock.selectedTipoBusqueda = 2;
    this.buscarListaProductos();
  }

  public imprimirStock() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/informe-stock?tipoBusqueda=' + this.filtroControlStock.selectedTipoProducto;
    link.click();
    link.remove();
  }


}
