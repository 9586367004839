import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-informe-stock',
  templateUrl: './informe-stock.component.html',
  styleUrls: ['./informe-stock.component.css']
})
export class InformeStockComponent implements OnInit {

  public fechaConsulta = this.convertDate(new Date());
  public arrayMVV: any[] = [];
  public arrayMVH: any[] = [];
  public arrayTipo: any[] = [];
  public paginasArrayStock: any[] = [];
  public tipoProducto = 0;
  public totalKilosMVV = 0;
  public totalKilosMVH = 0;

  constructor(
    private apiService: ApiService,
    private activatedRouter: ActivatedRoute,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();

    this.activatedRouter.queryParams.subscribe(params => {
      if (params.tipoBusqueda == 1) {
        this.tipoProducto = 1;
        this.apiService.obtenerTipo1().subscribe(data => {
          this.arrayTipo = data.response;
          this.prepararPaginasTipo(1);
        })
      } else if (params.tipoBusqueda == 3) {
        this.tipoProducto = 3;
        this.apiService.obtenerTipo3().subscribe(data => {
          this.arrayTipo = data.response;
          this.prepararPaginasTipo(3);
        })
      } else {
        this.apiService.obtenerMVV().subscribe(data => {
          this.arrayMVV = data.response;
          this.apiService.obtenerMVH().subscribe(data => {
            this.arrayMVH = data.response;
            this.prepararPaginasSinTipo();
          });
        });
      }
    })


  }

  public convertirAPdf() {
    if (this.tipoProducto === 0) {
      console.log("TIPO 0")
      setTimeout(() => {
        this.downloadPDF();
      }, 200);
    } else {
      console.log("TIPO 1")
      setTimeout(() => {
        this.downloadPDFTipo();
      }, 200);

    }
  }


  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('l', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`informe-stock${this.fechaConsulta}.pdf`);
    })
  }

  downloadPDFTipo() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('paginaTipo')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`informe-stock${this.fechaConsulta}.pdf`);
    })
  }


  private convertDate(date: any) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split('');
    var ddChars = dd.split('');

    return (ddChars[1] ? dd : "0" + ddChars[0]) + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + yyyy;
  }

  private prepararPaginasSinTipo() {
    let paginaMVV: any[] = [];
    let paginaMVH: any[] = [];
    let nPagina = 0;
    let tipoMVV = "0";
    let tipoMVH = "0";
    let contadorLineas = 0;
    let contadorTotalLineas = 0
    const totalLineas = this.arrayMVH.length;
    if (this.arrayMVV.length > this.arrayMVH.length) {
      const totalLineas = this.arrayMVV.length;
    }    for (let i = 0; i < totalLineas; i++) {
      contadorLineas = contadorLineas + 1;
      contadorTotalLineas = contadorTotalLineas + 1;
      this.totalKilosMVH = parseInt(this.arrayMVH[0].kg) + this.totalKilosMVH;
      if (this.arrayMVV === undefined) {
        let item = {
          modelo: '',
          tipo: '',
          ch: '',
          long: '',
          espesor: '',
          cantidad: '',
          kg: ''
        }
        paginaMVV.push(item);
        contadorLineas = contadorLineas + 1;
      }

      if (this.arrayMVV.length > 0) {
        this.totalKilosMVV = parseInt(this.arrayMVV[0].kg) + this.totalKilosMVV;

        if (this.arrayMVV[0].tipo !== tipoMVV) {
          let item = {
            modelo: '',
            tipo: '',
            ch: '',
            long: '',
            espesor: '',
            cantidad: '',
            kg: ''
          }
          paginaMVV.push(item);
          tipoMVV = this.arrayMVV[0].tipo;
          contadorLineas = contadorLineas + 1;
        }
        paginaMVV.push(this.arrayMVV[0]);
        this.arrayMVV.splice(0, 1);
      }
      if (this.arrayMVH[0].tipo !== tipoMVH) {
        let item = {
          modelo: '',
          tipo: '',
          ch: '',
          long: '',
          espesor: '',
          cantidad: '',
          kg: ''
        }
        paginaMVH.push(item);
        tipoMVH = this.arrayMVH[0].tipo;
        contadorLineas = contadorLineas + 1;
      }
      paginaMVH.push(this.arrayMVH[0]);
      this.arrayMVH.splice(0, 1);
      if (contadorLineas > 26) {
        nPagina = nPagina + 1;
        let element = {
          datosMVV: paginaMVV,
          datosMVH: paginaMVH,
          nPagina: nPagina
        }
        this.paginasArrayStock.push(element);
        paginaMVV = [];
        paginaMVH = [];
        contadorLineas = 0
        tipoMVH = '0';
        tipoMVV = '0';
      } else if (contadorTotalLineas === totalLineas) {
        nPagina = nPagina + 1;
        let element = {
          datosMVV: paginaMVV,
          datosMVH: paginaMVH,
          nPagina: nPagina
        }
        this.paginasArrayStock.push(element);
        paginaMVV = [];
        paginaMVH = [];
      }
    }
    console.log("PAGINAS: ", this.paginasArrayStock);
    console.log(this.totalKilosMVV, this.totalKilosMVH);
  }

  private prepararPaginasTipo(tipo: number) {
    let paginaTipo: any[] = [];
    let contadorLineas = 0;
    let contadorTotalLineas = 0
    const totalLineas = this.arrayTipo.length;
    for (let i = 0; i < totalLineas; i++) {
      contadorLineas = contadorLineas + 1;
      contadorTotalLineas = contadorTotalLineas + 1;
      paginaTipo.push(this.arrayTipo[0]);
      this.arrayTipo.splice(0, 1);
    }
    this.paginasArrayStock.push(paginaTipo);
    console.log("PAGINAS: ", this.paginasArrayStock);
  }



}
