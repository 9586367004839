import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DetalleOrdenCompra } from '../../../types/compras';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-detalles-ordenes-compra',
  templateUrl: './detalles-ordenes-compra.component.html',
  styleUrls: ['./detalles-ordenes-compra.component.css']
})
export class DetallesOrdenesCompraComponent implements OnInit, AfterViewInit {

  public listaDetalleProductos: any[] = [];
  public productosAutocomplete: IdLabelObject[] = [];
  public crearProductoNuevo: boolean = false;
  public nCompra: any;
  private paramsUrl: any;
  public detalleUnProductoABorrar: any;
  public fechaCierre = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public productoResaltado = null;
  public dataSource!: MatTableDataSource<DetalleOrdenCompra>;

  public detalleUnProducto: any = {
    idProducto: "",
    nombreProducto: "",
    stockMinimo: "",
    stockActual: "",
    cantidadComprada: "",
    cantidadRecibida: ""
  }

  displayedColumns: string[] = ['buttons', 'nombreProducto', 'stockMinimo', 'stockActual', 'cantidadComprada', 'cantidadRecibida'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaDetalleProductos);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetDetalleUnProducto();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paramsUrl = params;
      this.nCompra = params.nCompra;
      this.fechaCierre = params.fechaCierre;
      this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
        console.log(data.response);
        this.listaDetalleProductos = data.response;
        this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    })
    this.apiService.obtenerProductosOrdenCompra().subscribe(data => {
      console.log(data.response);
      this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
    });
  }

  ngAfterViewInit(): void {
 /*   this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
      this.listaDetalleProductos = data.response;
      this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });*/
  }

/*  ngOnChanges(): void {
    this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
      this.listaDetalleProductos = data.response;
      this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }*/

  public muestradetalleProducto(element: any) {
    this.crearProductoNuevo = false;
    this.detalleUnProducto = { ...element };
  }

  public esDetalleProductoNuevo() {
    this.crearProductoNuevo = true;
    this.resetDetalleUnProducto();
  }

  public guardarDetalleProducto(formulario: NgForm) {
    this.detalleUnProducto.nCompra = this.nCompra;
    var msg = "";
    if (!this.miraNegativos(this.detalleUnProducto.cantidadComprada)) {
      msg = 'El campo Cantidad Entrada tiene un número negativo';
    }
    else if (this.comprobarSiYaExiste(this.detalleUnProducto.idProducto) && this.crearProductoNuevo) {
      msg = 'Este producto ya está dado de alta en esta compra';
    }
    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }
    if (this.crearProductoNuevo) {
      this.apiService.anadirOrdenCompraDetalle(this.detalleUnProducto).subscribe(data => {
        console.log("Guardo nuevo: ", this.detalleUnProducto);
        this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
          this.crearProductoNuevo = false;
          this.listaDetalleProductos = data.response;
          this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

      });
    } else {
      console.log("Guardo viejo: ", this.detalleUnProducto);
      this.apiService.modificarOrdenCompraDetalle (this.detalleUnProducto).subscribe(dades => {
        this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
          this.listaDetalleProductos = data.response;
          this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

      });
    }
  }

  public eliminarDetalleProducto() {
    this.apiService.borrarOrdenCompraDetalle(this.detalleUnProductoABorrar).subscribe(dades => {
      this.crearProductoNuevo = false;
      this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
        this.listaDetalleProductos = data.response;
        this.dataSource = new MatTableDataSource<DetalleOrdenCompra>(this.listaDetalleProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resetDetalleUnProducto();
      })
    })
  }

  public volverAOrdenesCompra() {
    this.router.navigate(['ordenes-compra'], { queryParams: this.paramsUrl });
  }

  public dialogEliminarDetalleProducto(detalleUnProducto: any) {
    this.detalleUnProductoABorrar = detalleUnProducto;
    this.detalleUnProductoABorrar.nCompra = this.nCompra;
    console.log("detalle a borrar: ", this.detalleUnProductoABorrar);
    this.openDialog("ELIMINAR PRODUCTO", "¿Quiere eliminar el producto " + detalleUnProducto.nombreProducto + " de la lista de Detalles de Compra", true, "eliminarDetalleProducto");
  }

  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminarDetalleProducto':
          if (result) this.eliminarDetalleProducto();
          break;
        default:
          break;
      }
    });
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.listaDetalleProductos.find(item => {
      if (item.idProducto == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }

  private resetDetalleUnProducto() {
    this.detalleUnProducto = {
      idProducto: "",
      nombreProducto: "",
      stockMinimo: "",
      stockActual: "",
      cantidadComprada: "",
      cantidadRecibida: ""
    }
  }

}
