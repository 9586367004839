<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

  <div class="layout-filtro search flex-row-wrap flex-100">

    <div class="flex-6-responsive">
      <button class="button__buscar" color="primary" mat-stroked-button (click)="resetFiltro()">
        <mat-icon>restart_alt</mat-icon>
      </button>
    </div>

    <app-autocomplete class="autocomplete flex-25" name="cliente" ngDefaultControl
      [idOpcionSeleccionada]="filtroClientes.selectedEmpresa"
      (cuandoSeSeleccioneOpcion)="filtroClientes.selectedEmpresa = $event"
      (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('cliente')" label="Empresa"
      [options]="clientesAutocomplete">
    </app-autocomplete>

    <mat-form-field appearance="outline" class="flex-20-responsive">
      <mat-label>Contacto</mat-label>
      <input matInput type="text" [(ngModel)]="filtroClientes.selectedContacto" name="contacto">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-10-responsive">
      <mat-label>NIF/CIF</mat-label>
      <input matInput type="text" [(ngModel)]="filtroClientes.selectedNIF" name="NIF/CIF">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-20-responsive">
      <mat-label>Email</mat-label>
      <input matInput type="text" [(ngModel)]="filtroClientes.selectedEmail" name="Email">
    </mat-form-field>

    <mat-form-field class="select flex-10" appearance="outline">
      <mat-label>¿Tiene Pedido?</mat-label>
      <mat-select [(ngModel)]="filtroClientes.selectedTienePedido" name="selectedTienePedido">
        <mat-option></mat-option>
        <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
          {{opcion.nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-autocomplete class="autocomplete flex-20" name="ciudad" ngDefaultControl
      [idOpcionSeleccionada]="filtroClientes.selectedCiudad"
      (cuandoSeSeleccioneOpcion)="filtroClientes.selectedCiudad = $event"
      (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" label="Ciudad" [options]="ciudadesAutocomplete">
    </app-autocomplete>

    <mat-form-field appearance="outline" class="flex-10-responsive">
      <mat-label>Codigo postal</mat-label>
      <input matInput type="text" [(ngModel)]="filtroClientes.selectedCodigoPostal" name="codigoPostal">
    </mat-form-field>

    <app-autocomplete class="autocomplete flex-20" name="provincia" ngDefaultControl
      [idOpcionSeleccionada]="filtroClientes.selectedProvincia"
      (cuandoSeSeleccioneOpcion)="filtroClientes.selectedProvincia = $event"
      (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('provincia')" label="Provincia" [options]="provincias">
    </app-autocomplete>

    <mat-form-field appearance="outline" class="flex-20-responsive">
      <mat-label>Condicion de pago</mat-label>
      <input matInput type="text" [(ngModel)]="filtroClientes.selectedCondicionesPago" name="condPago">
    </mat-form-field>

    <div class="flex-14-responsive">
      <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
    </div>

  </div>

  <div class="layout-tabla flex-layout flex-65-responsive">

    <h2 *ngIf="paramsUrl.redirecTo" class="h2titulo">Elegir un Cliente o dar de alta uno nuevo</h2>

    <table mat-table [dataSource]="dataSource" matSort
      class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

      <ng-container matColumnDef="buttons">
        <th mat-header-cell *matHeaderCellDef>
          <button class="button-create" mat-icon-button type="button" (click)="esClienteNuevo()">
            <mat-icon>create_new_folder</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let element">
          <button *ngIf="!element.tieneOfertas" mat-icon-button type="button"
            (click)="dialogEliminarUnCliente(element)">
            <mat-icon>deletes</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Oferta Column 
          <ng-container matColumnDef="nCliente">
              <th mat-header-cell *matHeaderCellDef> Nº cliente </th>
              <td mat-cell *matCellDef="let element"> {{element.nCliente}} </td>
          </ng-container>
      -->

      <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </th>
        <td mat-cell *matCellDef="let element" align="left"> {{element.empresa}} </td>
      </ng-container>

      <ng-container matColumnDef="contacto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contacto </th>
        <td mat-cell *matCellDef="let element" align="left"> {{element.contacto}} </td>
      </ng-container>

      <ng-container matColumnDef="ciudad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
        <td mat-cell *matCellDef="let element" align="left"> {{element.ciudad}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </th>
        <td mat-cell *matCellDef="let element" align="left"> {{element.email}} </td>
      </ng-container>

      <!--
            <ng-container matColumnDef="codigoPostal">
              <th mat-header-cell *matHeaderCellDef> C.Postal </th>
              <td mat-cell *matCellDef="let element"> {{element.codigoPostal}} </td>
            </ng-container>
          -->
      <!-- Idioma Column 
            <ng-container matColumnDef="idioma">
              <th mat-header-cell *matHeaderCellDef> Idioma </th>
              <td mat-cell *matCellDef="let element"> {{element.idioma}} </td>
            </ng-container>
          -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnCliente(row)"
        [ngClass]="{'row-highlighted': cliente.nCliente == row.nCliente}"></tr>

    </table>

    <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaClientes.length > 0}" [pageSizeOptions]="[20]"
      showFirstLastButtons></mat-paginator>
    <div class="noHayNadaEnTabla" *ngIf="listaClientes.length == 0">No hay Clientes</div>

  </div>

  <form #formulario="ngForm" (ngSubmit)="guardarUnCliente(formulario)"
    class="form-derecho row-gap-10 flex-35-responsive">

    <mat-form-field appearance="outline" class="flex-30-responsive">
      <mat-label> Nº Cliente</mat-label>
      <input disabled [value]="cliente.nCliente" name="nCliente" matInput type="text">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-60-responsive">
      <mat-label>Empresa</mat-label>
      <input maxlength="70" #empresa="ngModel" required [(ngModel)]="cliente.empresa" name="empresa" matInput
        type="text">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Contacto</mat-label>
      <input maxlength="35" #contacto="ngModel" required [(ngModel)]="cliente.contacto" name="contacto" matInput
        type="text">
    </mat-form-field>

    <app-autocomplete appearance="outline" class="auto100 flex-100" name="ciudad"
      [idOpcionSeleccionada]="cliente.idCiudad" (cuandoSeSeleccioneOpcion)="cliente.idCiudad = $event" label="Ciudad"
      [options]="ciudadesAutocomplete" (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')"
      icon="settings">
    </app-autocomplete>

    <mat-form-field appearance="outline" class="flex-47-responsive">
      <mat-label>Provincia</mat-label>
      <input disabled matInput [value]="obtenerDatosCiudad(cliente.idCiudad).nombreProvincia || ''" name="provincia"
        type="text">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-47-responsive">
      <mat-label>País</mat-label>
      <input disabled matInput [value]="obtenerDatosCiudad(cliente.idCiudad).nombrePais || ''" name="pais" type="text">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-70-responsive">
      <mat-label>Dirección facturación</mat-label>
      <textarea maxlength="70" rows="2" matInput required matInput [(ngModel)]="cliente.direccionFacturacion"
        name="direccionFacturacion" type="text"></textarea>
      <mat-hint>Para saltar de línea utilizar "enter"</mat-hint>
    </mat-form-field>

    <mat-form-field class="cp flex-28-responsive" appearance="outline">
      <mat-label>Codigo Postal</mat-label>
      <input [disabled]="cliente.idCliente" matInput [(ngModel)]="cliente.codigoPostal" name="codigoPostal" type="text">
    </mat-form-field>


    <mat-form-field class="cp flex-30-responsive" appearance="outline">
      <mat-label>Telefono</mat-label>
      <input matInput [(ngModel)]="cliente.telefono" name="telefono" type="text">
    </mat-form-field>

    <mat-form-field class="cp flex-30-responsive" appearance="outline">
      <mat-label>Móvil</mat-label>
      <input matInput [(ngModel)]="cliente.movil" name="movil" type="text">
    </mat-form-field>

    <mat-form-field class="cp flex-30-responsive" appearance="outline">
      <mat-label>Fax</mat-label>
      <input matInput [(ngModel)]="cliente.fax" name="fax" type="text">
    </mat-form-field>

    <mat-form-field class="cp flex-60-responsive" appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="cliente.email" name="email" type="text">
    </mat-form-field>

    <app-autocomplete class="auto100 pading-autocomplete flex-40-responsive" name="idioma"
      [idOpcionSeleccionada]="cliente.idIdioma" (cuandoSeSeleccioneOpcion)="cliente.idIdioma = $event" label="Idioma"
      (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('idioma')" [options]="idiomas" icon="settings">
    </app-autocomplete>

    <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Riesgo</mat-label>
      <textarea matInput [(ngModel)]="cliente.riesgo" name="riesgo" rows="2"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Notas</mat-label>
      <textarea matInput [(ngModel)]="cliente.notas" name="notas" rows="3"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-100">
      <mat-label>Condiciones de pago</mat-label>
      <textarea matInput [(ngModel)]="cliente.condicionesPago" name="condicionesPago" rows="3"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-30">
      <mat-label>CIF</mat-label>
      <input matInput [(ngModel)]="cliente.nif" name="nif" type="text">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-30-responsive">
      <mat-label>Cuenta</mat-label>
      <input matInput [(ngModel)]="cliente.cuenta" name="cuenta">
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-25-responsive">
      <mat-label>Descuento</mat-label>
      <input matInput [(ngModel)]="cliente.dto" name="dto" type="number">
    </mat-form-field>

    <div class="form-buttons flex-row-wrap flex-100">

      <span class="flex-50-responsive">
        <button mat-raised-button type="submit">GUARDAR</button>
      </span>

      <span class="flex-50-responsive">
        <button [disabled]="!cliente.nCliente" mat-raised-button (click)="mantenimientoEncargadosObra($event)"
          type="button">Encargados Obra</button>
      </span>

      <span class="flex-50-responsive">
        <button *ngIf="paramsUrl.redirecTo" mat-raised-button type="button" (click)="volverAOferta()">VOLVER</button>
      </span>

    </div>
  </form>

</form>