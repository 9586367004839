<div class="section-content">
    <p class="section-title">Modelo:</p>

    @for (modelo of this.fabricacionService.modelosDeProducto; track $index) {
    <button mat-raised-button class="section-button"
        [ngClass]="{'selected': modelo.IdModel === this.fabricacionService.modeloProductoSeleccionado.IdModel}"
        (click)="onModeloSelection(modelo)" [disabled]="disabledButton()">
        {{modelo.Model}}
    </button>
    }
    <button mat-raised-button class="action-button" (click)="this.fabricacionService.volverAMedidas()"
        [disabled]="disableVolverAtras()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Atrás
    </button>
</div>