<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento">
    <div class="tabla flex-layout flex-70-responsive">
        <table mat-table [dataSource]="items" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button
                        (click)="dialogEliminarUnCampo($event, element)"><mat-icon>deletes</mat-icon></button>
                </td>
            </ng-container>

            <!-- Comercial Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.labelPrincipal}} </td>
            </ng-container>


            <!-- Material Column -->
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label2}} </td>
            </ng-container>

            <!-- Colocacion Column -->
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[2]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label3}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idPrincipal == row.idPrincipal }"></tr>
        </table>
    </div>

    <form class="form flex-row-wrap flex-30-responsive" #formulario="ngForm" (ngSubmit)="guardarItem(formulario)">

        <mat-form-field *ngIf="data.tipo=='comercial' || data.tipo=='delegacion'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input [(ngModel)]="itemSelected.labelPrincipal" name="labelPrincipal" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.labelPrincipal" matSuffix mat-icon-button
                aria-label="Clear" (click)="resetItem()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field *ngIf="data.tipo=='delegacion'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input [(ngModel)]="itemSelected.label2" name="label2" matInput type="text">
        </mat-form-field>

        <mat-form-field *ngIf="data.tipo=='delegacion'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[2]}} </mat-label>
            <input [(ngModel)]="itemSelected.label3" name="label3" matInput type="text">
        </mat-form-field>



        <!--    <mat-form-field *ngIf="data.tipo=='delegacion'" appearance="outline" class="flex-100">
            <mat-label> {{data.cols[3]}} </mat-label>
            <input [(ngModel)]="itemSelected.label4" name="label4" matInput type="text">
        </mat-form-field>-->
        <app-autocomplete *ngIf="data.tipo=='delegacion'" class="auto100 flex-100" name="label4"
            [idOpcionSeleccionada]="itemSelected.label4" (cuandoSeSeleccioneOpcion)="itemSelected.label4 = $event"
            label="Ciudad" [options]="ciudadesAutocomplete">
        </app-autocomplete>



        <!--    
        <app-autocomplete *ngIf="data.tipo =='delegacio'"  class="autocomplete flex-100" name="labelPrincipal" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idPrincipal"
            (cuandoSeSeleccioneOpcion)="itemSelected.idPrincipal = $event" 
            label="{{data.cols[3]}}"
            [options]="ciudades"
            [(ngModel)]="itemSelected.labelPrincipal"
            (ngModelChange)="goTo()">
        </app-autocomplete>
    -->



        <mat-form-field *ngIf="data.tipo !='delegacion'" disabled="data.tipo=='stock'" appearance="outline"
            class="flex-100">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input [(ngModel)]="itemSelected.label2" name="label2" matInput type="number">
        </mat-form-field>

        <mat-form-field *ngIf="data.tipo !='delegacion'" disabled="data.tipo=='stock'" appearance="outline"
            class="flex-100">
            <mat-label> {{data.cols[2]}} </mat-label>
            <input [(ngModel)]="itemSelected.label3" name="label3" matInput type="number">
        </mat-form-field>


        <mat-form-field *ngIf="data.tipo=='stock'" appearance="outline" class="flex-100">
            <mat-label> Entrada de zincado </mat-label>
            <input [(ngModel)]="entradaZinc" name="entradaZinc" matInput type="number">
        </mat-form-field>



        <div class="LimpiarGuardar flex-row-wrap justify-center flex-100">
            <span class="flex-48-responsive">
                <button mat-raised-button type="submit">GUARDAR</button>
            </span>
            <span *ngIf="data.tipo=='stock'" class="flex-48-responsive">
                <button mat-raised-button type="button" (click)='resetItem()'>LIMPIAR</button>
            </span>
            <span *ngIf="data.tipo =='stock'" class="stock flex-100" style="height: 40px !important;">
                <mat-form-field appearance="outline" class="flex-100">
                    <mat-label>Elegir Proveedor</mat-label>
                    <mat-select [(ngModel)]="opcionProveedor" name="opcionProveedor">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opcion of opcionesProveedor" [value]="opcion.idProveedor">
                            {{opcion.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
            <button mat-raised-button type="button" (click)='ImprimirAlbaranZincador()' class="flex-100 mt-30">Albarán
                Zincador</button>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>