<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="container flex-row-wrap">

    <div class="layout-filtro flex flex-100 search">

        <div class="filtroTipo filtro-section">

            <mat-form-field appearance="outline" class="flex-100">
                <mat-label>Tipo Busqueda</mat-label>
                <mat-select [(ngModel)]="filtroControlStock.selectedTipoBusqueda" name="tipoBusqueda">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opcion of opcionesTipoBusqueda" [value]="opcion.id">
                        {{opcion.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-100">
                <mat-label>Producto</mat-label>
                <input matInput type="text" [(ngModel)]="filtroControlStock.selectedProducto" name="producto">
            </mat-form-field>

            <app-autocomplete class="autocomplete flex-100" name="tipoProducto" ngDefaultControl
                [idOpcionSeleccionada]="filtroControlStock.selectedTipoProducto"
                (cuandoSeSeleccioneOpcion)="filtroControlStock.selectedTipoProducto = $event" label="Tipo Producto"
                [options]="tiposProductoAutocomplete">
            </app-autocomplete>


        </div>

        <div class="filtroKit filtro-section">

            <app-autocomplete class="autocomplete flex-100" name="kits" ngDefaultControl
                [idOpcionSeleccionada]="idKitAGuardar" (cuandoSeSeleccioneOpcion)="idKitAGuardar = $event" label="Kits"
                [options]="kitsAutocomplete">
            </app-autocomplete>

            <mat-form-field appearance="outline" class="flex-45-responsive">
                <mat-label>Cantidad</mat-label>
                <input matInput type="number" min="0" [(ngModel)]="cantidadKitAGuardar" name="cantidad">
            </mat-form-field>

            <button [disabled]="!idKitAGuardar || !cantidadKitAGuardar" class="button flex-45-responsive"
                color="primary" mat-stroked-button (click)="guardaKit()">Guardar</button>

            <button color="primary" class="flex-45-responsive" mat-stroked-button
                (click)="resetFiltro()">Limpiar</button>

            <button class="button__buscar flex-45-responsive" color="primary" mat-stroked-button
                type="submit">Buscar</button>



        </div>

        <div class="filtroTabla">
            <table mat-table [dataSource]="listaKits" matSort
                class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef>
                        <button class="button-delete" mat-icon-button type="button" (click)="borrarTablaKits()">
                            <mat-icon>deletes</mat-icon>
                        </button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button class="button-delete" mat-icon-button type="button" (click)="borrarUnKit(element)">
                            <mat-icon>deletes</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nombreKit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Kit </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.nombreKit}} </td>
                </ng-container>
                <ng-container matColumnDef="cantidadKit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadKit}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
            </table>
        </div>

    </div>

    <div class="layout-tabla flex-layout flex-100">

        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="imprimirStock()">
                        <mat-icon>list</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                </td>
            </ng-container>

            <ng-container matColumnDef="nombreProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="peso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.peso}} </td>
            </ng-container>

            <ng-container matColumnDef="stockMinimo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Mínimo </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.stockMinimo}} </td>
            </ng-container>

            <ng-container matColumnDef="stockActual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Actual </th>
                <td mat-cell *matCellDef="let element" align="left" style="font-weight: 600;"> {{element.stockActual}}
                </td>
            </ng-container>

            <ng-container matColumnDef="punzonadoyplegado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Punzonado y plegado </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.stockActual - (element.zincado +
                    element.enZincador + element.punzonado)}} </td>
            </ng-container>

            <ng-container matColumnDef="punzonado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Punzonado </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.punzonado}} </td>
            </ng-container>

            <ng-container matColumnDef="zincado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Zincado </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.zincado}} </td>
            </ng-container>

            <ng-container matColumnDef="zincador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Zincador </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.enZincador}} </td>
            </ng-container>

            <ng-container matColumnDef="zincador al cliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Zincador al cliente </th>
                <td mat-cell *matCellDef="let element" align="left" style="color: #ffb81d; font-weight: 600;">
                    {{element.zincadorACliente}} </td>
            </ng-container>

            <ng-container matColumnDef="compraPendiente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compra Pendiente </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.compraPendiente}} </td>
            </ng-container>

            <ng-container matColumnDef="materialComprado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Material Comprado </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.materialComprado}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="stockZinc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Stock Zinc </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.stockZinc}} </td>
            </ng-container> -->

            <ng-container matColumnDef="tipoProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.tipoProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidad}} </td>
            </ng-container>

            <ng-container matColumnDef="disponible">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Disponible </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.disponible}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaProductos.length > 0}" [pageSizeOptions]="[15]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaProductos.length == 0">No hay Productos</div>

    </div>


</form>