import { CommonModule, DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { OrdenElaboracion } from 'src/app/types/ordenElaboracion';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-ordenes-elaboracion',
  standalone: true,
  imports: [
    MatIconModule,
    DatePipe,
    MatPaginatorModule,
    CommonModule,
    MatTableModule,
    MatSort,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatButtonModule,
  ],
  templateUrl: './ordenes-elaboracion.component.html',
  styleUrl: './ordenes-elaboracion.component.css'
})
export class OrdenesElaboracionComponent {
  @ViewChild(OrdenesElaboracionComponent) miComponente!: OrdenesElaboracionComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public dataSource!: MatTableDataSource<OrdenElaboracion>;
  displayedColumns: string[] = ['Buttons', 'DataElaboracio', 'DescProducte', 'Cantidad'];

  public hayOrdenSeleccionada = false;

  public listaOrdenesElaboracion: OrdenElaboracion[] = [];
  public ordenElaboracion: OrdenElaboracion = {} as OrdenElaboracion;

  constructor(private apiService: ApiService, private router: Router, private tituloService: TituloService, private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource(this.listaOrdenesElaboracion);
  }

  ngOnInit() {
    this.obtenerOrdenes();
    this.tituloService.enviarTitulo();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private obtenerOrdenes() {
    this.apiService.obtenerOrdenesElaboracion().subscribe((data) => {
      this.listaOrdenesElaboracion = data.response;
      this.dataSource = new MatTableDataSource(this.listaOrdenesElaboracion);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      console.log('ORDENES:', this.listaOrdenesElaboracion);
    });
  }

  public seleccionarOrden(orden: any) {
    this.ordenElaboracion = orden;
    this.hayOrdenSeleccionada = true;
    console.log('ORDEN SELECCIONADA:', this.ordenElaboracion);
  }

  static definirColorRow(rowIdElemento: number, IdOrden: number): any {
    return { 'row-highlighted': rowIdElemento === IdOrden };
  }

  public resaltarSeleccion(rowIdElemento: number, IdOrden: number): any {
    return OrdenesElaboracionComponent.definirColorRow(rowIdElemento, IdOrden);
  }

  public irADetallesOrden() {
    this.router.navigate(['detalles-orden-elaboracion'], { queryParams: { idOrden: this.ordenElaboracion.IdOrdreElaboracio } });

  }

  public limpiarOrden() {
    this.ordenElaboracion = {} as OrdenElaboracion;
    this.hayOrdenSeleccionada = false;
  }

  public createOrden() {
    this.router.navigate(['taller']);
  }
}
