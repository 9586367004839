import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { mantenimiento2ProductoOpc } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { itemManteniment3, itemManteniment5 } from 'src/app/types/mantenimiento';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';

@Component({
  selector: 'app-dialog-mantenimientoTipoKit',
  templateUrl: './dialog-mantenimientoTipoKit.component.html',
  styleUrls: ['./dialog-mantenimientoTipoKit.component.css']
})
export class DialogMantenimientoTipoKitComponent implements OnInit {

  itemSelected: any = {
    idTipoKit: null,
    TipoKit: null,
    idMortero: null,
    labelMortero: null,
    idResina: null,
    labelResina: null,
    idVarilla: null,
    idCementoPortland: null,
    idCementoEspumante: null,
    labelVarilla: null,
    idMorteroAlas: null,
    labelMorteroAlas: null,
    editable: null
  };

  mortero: IdLabelObject[] = [];
  resina: IdLabelObject[] = [];
  varilla: IdLabelObject[] = [];
  morteroAlas: IdLabelObject[] = [];
  cementoPortland: IdLabelObject[] = [];
  cementoEspumante: IdLabelObject[] = [];

  items: itemManteniment5 [] = [];  
  itemsTot: itemManteniment5 [] = [];

  displayedColumns: string[] = ['buttons', this.data.cols[0],this.data.cols[1],this.data.cols[2],this.data.cols[3],this.data.cols[4],this.data.cols[5],this.data.cols[6] ];
  
  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimientoTipoKitComponent>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog,
    public dialogVerificar: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
    ){}

    
  ngOnInit(){
    
    this.displayedColumns = ['buttons', this.data.cols[0],this.data.cols[1],this.data.cols[2],this.data.cols[3],this.data.cols[4] ];
    console.log(this.displayedColumns);
    this.obtenerTipoKits();
    
  }

  public obtenerTipoKits(){
    this.apiService.obtenerTipoKits().subscribe( dades => {

      this.items = dades.response.map( tKit => ({ idTipoKit: tKit.idTipoKit, TipoKit: tKit.tipoKit, MorteroDef: tKit.MorterDef, ResinaDef: tKit.ResinaDef, 
                                                  VarillaDef: tKit.VarillaDef, MorteroAlas: tKit.MorterAlesDef, idCementoPortland: tKit.idCementoPortland, idCementoEspumante: tKit.idCementoEspumante , editable: tKit.estaAssignada, idMorteroAlas: tKit.morterAlesDef, idMorteroDef: tKit.morterDef, idVarillaDef: tKit.varillaDef, idResinaDef: tKit.resinaDef  }));      
      this.itemsTot = this.items;
    });
    this.obtenerMorteroMant();
    this.obtenerResinaMant();
    this.obtenerVarillaMant();
    this.obtenerCementoPortlandMant();
    this.obtenerCementoEspumanteMant();
  }

  public obtenerCementoPortlandMant(){
    this.apiService.obtenerCementoPortlandMant().subscribe( dades => {
      console.log(dades.response);
      this.cementoPortland = dades.response.map(item => ({id: item.IdProducte.toString(), label: item.nombre}))
    });
  }  
  public obtenerCementoEspumanteMant(){
    this.apiService.obtenerCementoEspumanteMant().subscribe( dades => {
      console.log(dades.response);
      this.cementoEspumante = dades.response.map(item => ({id: item.IdProducte.toString(), label: item.nombre}))
    });
  }  
 
  public obtenerMorteroMant(){
    this.apiService.obtenerMorteroMant().subscribe( dades => {
      console.log(dades.response);
      this.mortero = dades.response.map(mort => ({id: mort.IdProducte.toString(), label: mort.nombre}))
      this.morteroAlas = dades.response.map(mort => ({id: mort.IdProducte.toString(), label: mort.nombre}))
    });
  }  
  public obtenerVarillaMant(){
    this.apiService.obtenerVarillaMant().subscribe( dades => {
      console.log(dades.response);
      this.varilla = dades.response.map(varilla => ({id: varilla.IdProducte.toString(), label: varilla.nombre}))
    });
  }  
  public obtenerResinaMant(){
    this.apiService.obtenerResinaMant().subscribe( dades => {
      console.log(dades.response);
      this.resina = dades.response.map(resina => ({id: resina.IdProducte.toString(), label: resina.nombre}))
    });
  } 


  dialogEliminarUnCampo( event: any, item: any) {
    this.itemSelected = item;
    this.openDialog("ELIMINAR","¿Quiere eliminar "+item.TipoKit+"?", true,"eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem(){
    this.eliminarTipoKit(this.itemSelected);
  }
  public eliminarTipoKit(TipoKit : any){
    console.log("borrar tipoKit", TipoKit.idTipoKit);
    this.apiService.borrarTipoKit(TipoKit.idTipoKit).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
    
  }




  public guardarItem(form : NgForm){
    console.log(this.itemSelected);
    if(this.itemSelected.TipoKit == null){
      this.openDialog("AVISO","No se puede guardar, falta el campo TipoKit", true,"");  
      return ;
    }
    this.guardarTipoKit();
    
  }

  private guardarTipoKit(){
    console.log("guardar:", this.itemSelected);
    if(this.itemSelected.idTipoKit){
      this.apiService.modificarTipoKit(this.itemSelected).subscribe(data=>{
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      this.apiService.anadirTipoKit(this.itemSelected).subscribe( data=>{
        this.itemSelected.idTipoKit = data.response;
        this.modificarTabla();
      });
    }
  }


  resetItem(){
    this.itemSelected.idTipoKit = null;
    this.itemSelected.editable = undefined;
    this.itemSelected.idMorteroDef = null;
    this.itemSelected.idVarillaDef  = null;
    this.itemSelected.idCementoPortland = null;
    this.itemSelected.idCementoEspumante = null;
    this.itemSelected.idResinaDef = null;
    this.itemSelected.idMorteroAlas = null;
    this.itemSelected.MorteroDef = null;
    this.itemSelected.ResinaDef= null;
    this.itemSelected.VarillaDef = null;
    this.itemSelected.MorteroAlas = null;
    this.itemSelected.TipoKit = "";
    this.goTo();
  }

  public envioDatos(){
    console.log("envia datos: ", this.data);
  }

  public modificarTabla(){
    this.modificarTablaTipoKit(); 
  }

  private modificarTablaTipoKit(){
    this.obtenerTipoKits();
  }
  cuandoSeCliqueEnMantenimiento(label : string){
    var dialogRef = mantenimiento2ProductoOpc(this.dialogMantenimiento);
    dialogRef.afterClosed().subscribe( (data:any) => {
      this.obtenerTipoKits();
    });
  }

  muestraUnItem(item: any) {
    console.log(item);
    this.itemSelected = {... item};
    console.log("item", this.itemSelected);
  }

  goTo(){
    if(this.itemSelected.idTipoKit == null){
      if(this.itemSelected.TipoKit != null){
        this.items = this.itemsTot.filter(item => item.TipoKit.toLowerCase().includes(this.itemSelected.TipoKit.toLowerCase()) );
        if(this.items.length >0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }
}
