<div *ngIf="paginas.length !== 0" class="content">
    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div  class="antesDePagina" *ngFor="let unaPagina of paginas">

            <div class="pagina">
                <div class="header">
                    <app-cabecera-mecanoviga [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                    <app-cabecera-oferta [nOferta]="nOferta" [nAlbaran]="nAlbaran" [letra]="letra" [idCliente]="nCliente" class="header-cliente">
                    </app-cabecera-oferta>
                </div>
    
                <div class="linea-gruesa" style="margin-top: 6px;"></div>
    
                <div class="datos-entrega">
                    <div class="direccion-entrega">
                        <p style="font-size: 24px;">Dirección de entrega:</p>
                        <p class="direccionEntrega">{{direccionEntrega}}</p>
                        <p>{{cpEntrega}} - {{ciudadEntrega}} ({{provinciaEntrega}})</p>
                    </div>
                    <div class="contacto">
                        <p>Persona de contacto: {{encargadoObra}}</p>
                        <p>Teléfono de contacto: {{telefonoEncargado}}</p>
                        <p>Transportista:  {{transportista}}</p>
                    </div>
                </div>
    
                <div class="linea-delgada"></div>
    
                <div class="fecha">{{fechaAlbaran}}</div>
    
                <div class="linea-delgada"></div>
    
                <div class="body">
    
                    <div class="cabeceras" style="width: 32%;">
    
                        <div *ngFor="let lineaKit of unaPagina[0].arrayKitsPagina; let iKits = index" class="kits">
                            <div *ngIf="iKits === 0" class="cabecera-kits">
                                <p class="mb-0">KITS MECANOVIGA</p>
                            </div>
                            <div *ngIf="iKits === 0" class="cabecera1">
                                <p class="encuadre-cantidad-cabecera1">Unid.</p>
                                <p class="encuadre-descripcion-cabecera1">Descripción</p>
                            </div>
                            <div class="cabecera2">
                                <p class="encuadre-cantidad-cabecera2">{{lineaKit.cantidadKit}}</p>
                                <p *ngIf="lineaKit.nombreKit.length < 19" class="encuadre-descripcion-cabecera2">{{lineaKit.nombreKit}}</p>
                                <p *ngIf="lineaKit.nombreKit.length > 18" style="justify-content: start;" class="encuadre-descripcion-cabecera2">{{lineaKit.nombreKit}}</p>
                            </div>
                        </div>
    
                    </div>
    
                    <div class="cabeceras" style="width: 65%;">
    
    
                        <div *ngFor="let lineaProducto of unaPagina[0].arrayProductosPagina" class="productos">
                            <div *ngIf="lineaProducto.imprimirCabeceraProducto" class="cabecera-productos">
                                <p class="mb-0">{{lineaProducto.tipoProducto | uppercase}}</p>
                            </div>
                            <div *ngIf="lineaProducto.imprimirCabeceraProducto" class="cabecera1">
                                <p class="encuadre-lateral-cabecera1">Unid.</p>
                                <p class="encuadre-central-cabecera1">ELEMENTOS</p>
                                <p class="encuadre-lateral-cabecera1">Peso</p>
                            </div>
                            <div class="cabecera2">
                                <p class="encuadre-lateral-cabecera2">{{lineaProducto.cantidadProducto}}</p>
                                <p *ngIf="lineaProducto.nombreProducto.length < 51" class="encuadre-central-cabecera2">{{lineaProducto.nombreProducto}}</p>
                                <p *ngIf="lineaProducto.nombreProducto.length > 50" style="justify-content: start;" class="encuadre-central-cabecera2">{{lineaProducto.nombreProducto}}</p>
                                <p class="encuadre-lateral-cabecera2">{{lineaProducto.pesoProducto | number:'1.0-0'}}</p>
                            </div>
    
                            <div *ngIf="lineaProducto.imprimirTotal" class="total">
                                <p class="mt-15">PESO TOTAL:</p>
                                <p class="mt-15">{{pesoTotal | number:'1.0-0'}} Kgs.</p>
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
                <div *ngIf="notasAlbaran && unaPagina[0].imprimirTotal && unaPagina[0].nLineas < 20" class="notas">
                    {{notasAlbaran}}
                </div>
    
                <div *ngIf="unaPagina[0].imprimirTotal && unaPagina[0].nLineas < 20" class="texto1">
                    <p>"La entrega de las Mercaderías puestas a disposición en favor del consignatario no implica la
                        transmisión de la propiedad de las mismas</p>
                    <p style="margin-top: -10px !important;">sino hasta que Mecanoviga, S.L. haya recibido el pago total de
                        las mismas."</p>
                </div>
    
                <div *ngIf="unaPagina[0].imprimirTotal && unaPagina[0].nLineas < 20" class="texto2">
                    <p style="margin-bottom: 30px;">Preparado por:</p>
                    <p>Transportista:  {{transportista}}</p>
                    <div class="conforme">
                        <p>Recibido conforme el Transportista</p>
                        <p>Recibido conforme el Cliente</p>
                    </div>
                    <div class="conforme" style="margin-top: 50px;">
                        <p>Fdo ..................................................</p>
                        <p>Fdo ..................................................</p>
                    </div>
    
                </div>
    
            </div>

            <div class="numeroPagina">
                Pagina {{unaPagina[0].nPagina}} de {{nPaginas}}
            </div>

        </div>

        <div *ngIf="noCabeTexto" class="antesDePagina">

            <div class="pagina">

                <div class="header2">
                    <app-cabecera-mecanoviga [direccionDelegacion]="direccionDelegacion" [cpDelegacion]="cpDelegacion" [poblacionDelegacion]="poblacionDelegacion" [provinciaDelegacion]="provinciaDelegacion" class="header-mecanoviga"></app-cabecera-mecanoviga>
                    <app-cabecera-oferta [nOferta]="nOferta" [nAlbaran]="nAlbaran" [letra]="letra" [idCliente]="nCliente" class="header-cliente">
                    </app-cabecera-oferta>
                </div>
    
                <div class="linea-gruesa" style="margin-top: 6px;"></div>
    
                <div class="datos-entrega">
                    <div class="direccion-entrega2">
                        <p style="font-size: 24px;">Dirección de entrega:</p>
                        <p class="direccionEntrega">{{direccionEntrega}}</p>
                        <p>{{cpEntrega}} - {{ciudadEntrega}} ({{provinciaEntrega}})</p>
                    </div>
                    <div class="contacto">
                        <p>Persona de contacto: {{encargadoObra}}</p>
                        <p>Teléfono de contacto: {{telefonoEncargado}}</p>
                        <p>Transportista:  {{transportista}}</p>
                    </div>
                </div>
    
                <div class="linea-delgada"></div>
    
                <div class="fecha">{{fechaAlbaran}}</div>
    
                <div class="linea-delgada"></div>
    
                <div *ngIf="notasAlbaran" class="notas">
                    {{notasAlbaran}}
                </div>
    
    
                <div class="texto1">
                    <p>"La entrega de las Mercaderías puestas a disposición en favor del consignatario no implica la
                        transmisión de la propiedad de las mismas</p>
                    <p style="margin-top: -10px !important;">sino hasta que Mecanoviga, S.L. haya recibido el pago total de
                        las mismas."</p>
                </div>
    
                <div class="texto2">
                    <p style="margin-bottom: 30px;">Preparado por:</p>
                    <div class="conforme">
                        <p>Recibido conforme el Transportista</p>
                        <p>Recibido conforme el Cliente</p>
                    </div>
                    <div class="conforme" style="margin-top: 50px;">
                        <p>Fdo ..................................................</p>
                        <p>Fdo ..................................................</p>
                    </div>
    
                </div>
    
            </div>
    
            <div *ngIf="noCabeTexto" class="numeroPagina">
                Pagina {{nPaginas}} de {{nPaginas}}
            </div>

        </div> 
        
        <div *ngIf="paginasArrayImagenes">
            <div  class="antesDePagina" style="justify-content: flex-start !important;" *ngFor="let pagina of paginasArrayImagenes">
                <img class="img" src="../../../../assets/logo master.png" width="350px" height="90px">
                <div class="titulo-imagen">HOJA PARA QUEDARSE A PIE DE OBRA</div>
                <div class="pagina-imagen" *ngFor="let img of pagina">
                    <div class="linea-imagen">
                        <p style="margin-top: 20px">{{img.cantidadKit}} kit {{img.nombreKit}}</p>
                    <!--    <img src="http://mecanoviga.namastech.com/assets/{{img.imagen}}" alt="" width="500px" height=" 100px">-->
                        <img src="../../../assets/kits/{{img.imagen}}" alt="" width="1000px" height="300px">

                    </div>
                </div>
            </div>
        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>

<div class="noTiene" *ngIf="paginas.length === 0">
    Este albarán no tiene productos entrados
</div>