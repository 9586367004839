<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content *ngIf="data.mantenimiento !== 'seleccion'" class="container mantenimiento dialog-layout">
    <form (ngSubmit)="envioDatos()" class="flex-100">
        <mat-form-field class="flex-100" appearance="outline">
            <mat-label>{{data.msg}}</mat-label>
            <input required [disabled]="itemSelected.editable && data.mantenimiento == 'condicion'"
                [(ngModel)]="itemSelected.label" name="item" matInput type="text" (keydown.enter)="guardarItem()">
            <!--(ngModelChange)="goTo()"-->
            <button mat-button *ngIf="itemSelected.label" matSuffix mat-icon-button aria-label="Clear"
                (click)="resetItem()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="tabla flex-100">
        <table mat-table [dataSource]="items" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga"
            aria-label="Table with items">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element" style="width:15px;">
                    <button *ngIf="!element.editable" mat-icon-button type="button"
                        (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                    <!--<button *ngIf="!ofertaAsignada" mat-icon-button (click)="eliminarUnaOferta(element)"><mat-icon>deletes</mat-icon></button>
                    <button *ngIf="!ofertaAsignada" mat-icon-button (click)="eliminarUnaOferta(element)"><mat-icon>deletes</mat-icon></button> -->
                </td>
            </ng-container>
            <ng-container matColumnDef="id">
                <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
            </ng-container>

            <!-- Oferta Column -->
            <ng-container matColumnDef={{data.mantenimiento}}>
                <th mat-header-cell *matHeaderCellDef> {{data.mantenimiento}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row id="rowIndex" *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.label.toLowerCase() == row.label.toLowerCase()}"></tr>

        </table>
    </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="data.mantenimiento === 'seleccion'" class="container dialog-layout">
    <!--    <div class="seleccionarAnio">
        <p class="select-anio">Seleccionar año: </p>
        <input class="input-anio" matInput type="number"  max="{{anioMax}}" [(ngModel)]=anioActual>
    </div>-->
    <form #formulario="ngForm" class="flex-row-wrap row-gap-20 mt-20 flex-100-responsive justify-center">

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Tipo Producto</mat-label>
            <mat-select [(ngModel)]="tipoProductoElegido" name="tipoProducto">
                <mat-option *ngFor="let opcion of tipoProducto" [value]="opcion.id">
                    {{opcion.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label>Seleccionar año: </mat-label>
            <input [(ngModel)]="anioActual" name="anioActual" matInput type="number" max="{{anioMax}}">
        </mat-form-field>

        <button [disabled]="!tipoProductoElegido" class="button-imprimir" mat-raised-button mat-dialog-close
            (click)="imprimirInforme(formulario)">Imprimir Informe</button>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>