<div class="flex-row-wrap">
  <div *ngIf="pantallaClientes" class="flex-row-wrap">

    <h2 class="h2titulo flex flex-65-responsive justify-center">Lista Clientes de la Oferta Nº {{oferta.nOferta}}</h2>
    <br>

    <div class="flex-layout flex-65-responsive">

      <table mat-table [dataSource]="dataSource" matSort
        class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef>
            <button class="button-create" *ngIf="!idClienteAsignado" mat-icon-button type="button"
              (click)="esClienteNuevoEnOferta()">
              <mat-icon>create_new_folder</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="!idClienteAsignado" mat-icon-button type="button"
              (click)="dialogEliminarOfertaUnCliente( element)">
              <mat-icon>deletes</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Clientes-Oferta </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.cliente}} </td>
        </ng-container>

        <ng-container matColumnDef="fechaOferta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Oferta </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.fechaOferta | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="descuento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> % DTO. </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.descuento | number:'0.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="incremento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> % INC </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.incremento | number:'0.2-2'}} </td>
        </ng-container>
        <!--
        <ng-container matColumnDef="anticipo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> % ANT </th>
          <td mat-cell *matCellDef="let element" align="center"> {{element.anticipo | number:'0.2-2'}} </td>
        </ng-container>
-->
        <ng-container matColumnDef="colaboracion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Colaboración </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.colaboracion}} </td>
        </ng-container>

        <ng-container matColumnDef="montaje">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Montaje </th>
          <td mat-cell *matCellDef="let element" align="left"> {{element.montaje}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraDatosCliente(row)"
          [ngClass]="{'row-highlighted': ofertaUnCliente.idCliente == row.idCliente}"></tr>
      </table>

      <mat-paginator *ngIf="clientesUnaOferta.length>0" [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
      <div class="noHayNadaEnTabla" *ngIf="clientesUnaOferta.length == 0">No hay Clientes</div>

    </div>
    <form #formulario="ngForm" (ngSubmit)="guardarOfertaUnCliente(formulario)"
      class="form-derecho flex-35-responsive row-gap-20 pt-10">
      <app-autocomplete [disabled]="!!idClienteAsignado || !esClienteNuevo" class="autocomplete flex-100" name="cliente"
        ngDefaultControl [idOpcionSeleccionada]="ofertaUnCliente.idCliente"
        (cuandoSeSeleccioneOpcion)="buscaDatosCliente($event)" icon="settings"
        (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('cliente')" label="Cliente" [options]="clientes"
        required>
      </app-autocomplete>

      <mat-form-field appearance="outline" class="flex-47-responsive">
        <mat-label>Fecha pedido</mat-label>
        <input matInput [disabled]="!!idClienteAsignado" [(ngModel)]="ofertaUnCliente.fechaOferta" name="fechaOferta"
          [matDatepicker]="picker">
        <button [disabled]="!!idClienteAsignado" class="button-date" *ngIf="ofertaUnCliente.fechaOferta" matSuffix
          mat-icon-button aria-label="Clear" (click)="ofertaUnCliente.fechaOferta=undefined">
          <mat-icon>close</mat-icon>
        </button>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="flex-47-responsive">
        <mat-label>% Descuento</mat-label>
        <input matInput type="number" min="0" [(ngModel)]="ofertaUnCliente.descuento" name="descuento">
      </mat-form-field>

      <mat-form-field appearance="outline" class="flex-47-responsive">
        <mat-label>% Incremento Cliente Kits</mat-label>
        <input matInput type="number" min="0" [(ngModel)]="ofertaUnCliente.incremento" name="incremento">
      </mat-form-field>

      <!--   <mat-form-field appearance="outline" class="flex-50-responsive">
        <mat-label>% Anticipo</mat-label>
        <input matInput [disabled]="!!idClienteAsignado" type="number" min="0" [(ngModel)]="ofertaUnCliente.anticipo"
          name="anticipo">
      </mat-form-field>
-->
      <div class="flex-col flex-100">
        <mat-checkbox [disabled]="!!idClienteAsignado" [(ngModel)]="ofertaUnCliente.colaboracion" name="colaboracion">
          Colaboración</mat-checkbox>
        <mat-checkbox [disabled]="!!idClienteAsignado" [(ngModel)]="ofertaUnCliente.montaje" name="montaje">
          Montaje
        </mat-checkbox>
        <mat-checkbox name="ofertaAsignada" [ngModel]="!!ofertaUnCliente.asignada" [disabled]="true">Oferta
          Asignada
        </mat-checkbox>
      </div>

      <div class="flex-row-wrap flex-100">
        <span class="flex-33-responsive">
          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button type="submit">Guardar</button>
        </span>
        <span class="flex-33-responsive">
          <button [disabled]="!!idClienteAsignado || !ofertaUnCliente.idCliente  || esClienteNuevo" type="button"
            mat-raised-button (click)="dialogAsignarClienteUnaOferta(formulario)">Asignar</button>
        </span>
        <span class="flex-33-responsive">
          <button mat-raised-button type="button" (click)="volverATablaUnaOferta()">Volver</button>
        </span>
      </div>

      <div class="flex-row">
        <div class="flex-col">
          <button type="button" [disabled]="!ofertaUnCliente.idCliente" (click)="mostarPantallaPPE()"
            mat-raised-button>Precios Especiales Productos Opcionales ...</button>
          <!--  
          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button (click)="imprimirContratoBomba()">Contrato Bomba ...</button>
          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button>Formalización Contrato</button>
          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button>Tabla Rendimiento ...</button>
        -->
          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button (click)="imprimirOferta()">Detalle Oferta
            ...</button>

          <button [disabled]="!ofertaUnCliente.idCliente" mat-raised-button (click)="imprimirCalculos()">Justificantes
            de cálculo ...</button>
        </div>
      </div>

    </form>

  </div>

  <div *ngIf="!pantallaClientes">
    <app-precios-especiales [nOferta]="ofertaUnCliente.idOferta" [idCliente]="ofertaUnCliente.idCliente"
      [ofertaAsignada]="ofertaUnCliente.asignada" (cambioPantalla)="muestraPantallaClientes()"></app-precios-especiales>
  </div>

</div>