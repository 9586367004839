<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container flex-row-wrap justify-between">
    <div class=" flex-layout flex-65-responsive">
        <table mat-table [dataSource]="items" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- TipoKit Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.TipoKit}} </td>
            </ng-container>


            <!-- Mortero Column -->
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.MorteroDef}} </td>
            </ng-container>

            <!-- Resina Column -->
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[2]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.ResinaDef}} </td>
            </ng-container>
            <!-- Varilla Column -->
            <ng-container matColumnDef={{data.cols[3]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[3]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.VarillaDef}} </td>
            </ng-container>
            <!-- MorteroAlas Column -->
            <ng-container matColumnDef={{data.cols[4]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[4]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.MorteroAlas}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idTipoKit == row.idTipoKit }"></tr>
        </table>
    </div>

    <form class="form flex-row-wrap flex-35-responsive" #formulario="ngForm" (ngSubmit)="guardarItem(formulario)">

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input required [(ngModel)]="itemSelected.TipoKit" name="labelTipoKit" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.TipoKit" matSuffix mat-icon-button aria-label="Clear"
                (click)="resetItem()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <app-autocomplete class="auto100" name="labelMortero" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idMorteroDef"
            (cuandoSeSeleccioneOpcion)="itemSelected.idMorteroDef = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[1]}}"
            [options]="mortero">
        </app-autocomplete>
        <app-autocomplete class="auto100" name="labelResina" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idResinaDef"
            (cuandoSeSeleccioneOpcion)="itemSelected.idResinaDef = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[2]}}"
            [options]="resina">
        </app-autocomplete>
        <app-autocomplete class="auto100" name="labelVarilla" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idVarillaDef"
            (cuandoSeSeleccioneOpcion)="itemSelected.idVarillaDef = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[3]}}"
            [options]="varilla">
        </app-autocomplete>
        <app-autocomplete class="auto100" name="labelMorteroAlas" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idMorteroAlas"
            (cuandoSeSeleccioneOpcion)="itemSelected.idMorteroAlas = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[4]}}"
            [options]="morteroAlas">
        </app-autocomplete>
        <app-autocomplete class="auto100" name="labelCementoPortland" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idCementoPortland"
            (cuandoSeSeleccioneOpcion)="itemSelected.idCementoPortland = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[5]}}"
            [options]="cementoPortland">
        </app-autocomplete>
        <app-autocomplete class="auto100" name="labelCementoEspumante" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idCementoEspumante"
            (cuandoSeSeleccioneOpcion)="itemSelected.idCementoEspumante = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('producto')" label="{{data.cols[6]}}"
            [options]="cementoEspumante">
        </app-autocomplete>


        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span class="flex-50-responsive">
                <button mat-raised-button type="submit">GUARDAR</button>
            </span>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>