import { type TipoMapper, type TipoGenerico, type OrigenProductoFabricado } from "../types/fabricacion";

export const tiposGenericos: TipoGenerico = {
    VIGA: 'VIGA',
    CARTELA: 'CARTELA',
    PLACA_DE_UNION: 'PLACA',
    CARTELA_ESPECIAL_IPE: 'CARTELA ESPECIAL IPE',
}

export const idTiposGenericos = {
    VIGA: 1,
    CARTELA: 2,
    PLACA_DE_UNION: 3,
    CARTELA_ESPECIAL_IPE: 4,
}


export const tipoMapper: TipoMapper = {
    'H': tiposGenericos.VIGA,
    'V': tiposGenericos.VIGA,
    'Cartela MVH': tiposGenericos.CARTELA,
    'Cartela MVV': tiposGenericos.CARTELA,
    'Cartela Especial MVH': tiposGenericos.CARTELA,
    'Cartela Especial MVV': tiposGenericos.CARTELA,
    'Placa de unión': tiposGenericos.PLACA_DE_UNION,
}

export const origenProductoFabricado: OrigenProductoFabricado[] = [
    { id: 1, desc: 'RECORTE' },
    { id: 2, desc: 'CHAPA' },
]

export const IdTipoProducto = 1;
export const esFabricable = 1;