<div *ngIf="tipoListado==0" class="container">
    <table mat-table [dataSource]="dataSource" matSort
        class="pagina mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef>
                <button class="button-create" mat-icon-button type="button" (click)="imprimirStock()">
                    <mat-icon>list</mat-icon>
                </button>
            </th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!ofertaAsignada" mat-icon-button (click)="dialogEliminarUnaOferta($event, element)">
                    <mat-icon>deletes</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="nOferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº oferta </th>
            <td mat-cell *matCellDef="let element" align="center"> {{element.nOferta}} </td>
        </ng-container>

        <ng-container matColumnDef="fechaOferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha oferta </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.fechaOferta | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="direccionObra">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dirección Obra </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.direccionObra}} </td>
        </ng-container>

        <ng-container matColumnDef="ciudad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.ciudad}} </td>
        </ng-container>

        <ng-container matColumnDef="provincia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Provincia </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.provincia}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente asignado </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.cliente}} </td>
        </ng-container>

        <ng-container matColumnDef="comercial">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Comercial </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.comercial}} </td>
        </ng-container>

        <ng-container matColumnDef="tecnico">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Técnico </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.tecnico}} </td>
        </ng-container>

        <ng-container matColumnDef="importeKits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe kits</th>
            <td mat-cell *matCellDef="let element" align="center"> {{element.importeKits}} </td>
        </ng-container>

        <ng-container matColumnDef="importeOtros">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe otros </th>
            <td mat-cell *matCellDef="let element" align="center"> {{element.importeOtros}} </td>
        </ng-container>

        <ng-container matColumnDef="pesoKits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso kits </th>
            <td mat-cell *matCellDef="let element" align="center"> {{element.pesoKits}} </td>
        </ng-container>

        <ng-container matColumnDef="pesoOtros">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso otros </th>
            <td mat-cell *matCellDef="let element" align="center"> {{element.pesoOtros}} </td>
        </ng-container>

        <ng-container matColumnDef="fechaCierre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Cierre </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.fechaCierre | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnaOferta(row)"
            [ngClass]="{'row-highlighted':  activatedRoute.snapshot.queryParams.ofertaResaltada == row.nOferta}"></tr>

    </table>

    <mat-paginator *ngIf="ofertasFiltradas.length>0" [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
    <div class="noHayNadaEnTabla" *ngIf="ofertasFiltradas.length == 0">No hay ofertas</div>

</div>

<div class="container2" *ngIf="tipoListado==1">
    <div class="pagina">
        <div *ngFor="let unaOferta of ofertasFiltradas" class="lineaUnaOferta">
            <div style="width: 50px;">{{unaOferta.nOferta}}</div>
            <div style="width: 80px;">{{unaOferta.fechaOferta | date: 'dd/MM/yyyy'}}</div>
            <div style="width: 300px;">{{unaOferta.direccionObra}}</div>
            <div style="width: 200px;">{{unaOferta.ciudad}}</div>
            <div style="width: 200px;">{{unaOferta.provincia}}</div>
            <div style="width: 300px;">{{unaOferta.cliente}}</div>
            <div style="width: 150px;">{{unaOferta.comercial}}</div>
            <div style="width: 150px;">{{unaOferta.tecnico}}</div>
            <div style="width: 80px;">{{unaOferta.importeKits}}</div>
            <div style="width: 80px">{{unaOferta.importeOtros}}</div>
            <div style="width: 50px;">{{unaOferta.pesoKits}}</div>
            <div style="width: 50px;">{{unaOferta.pesoOtros}}</div>
            <div style="width: 80px;">{{unaOferta.fechaCierre | date: 'dd/MM/yyyy'}}</div>
        </div>
    </div>
</div>