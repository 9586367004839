<div class="section-content">
    <p class="section-title">Origen:</p>

    @for (origen of this.fabricacionService.origenesDeProducto; track $index) {
    <button mat-raised-button class="section-button"
        [ngClass]="{'selected': origen.id === this.fabricacionService.origenProductoSeleccionado.id}"
        (click)="onOrigenSelection(origen)" [disabled]="disabledButton()">
        {{origen.desc}}
    </button>
    }
    <button mat-raised-button class="action-button" (click)="this.volverAtras()" [disabled]="disableVolverAtras()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Atrás
    </button>
</div>