<!-- @if ((!this.fabricacionService.esFabricacion && !this.fabricacionService.esPlegado) ||
(this.fabricacionService.hayChapaSeleccionada && !this.fabricacionService.esFabricacion && this.fabricacionService.productesEnFabricacio.length > 0 && !this.fabricacionService.hayProdSeleccionado )) { -->
@if (!this.fabricacionService.esFabricacion && !this.fabricacionService.esPlegado) {
<ng-container>
    <header class="header show">
        <div class="chapa-selected">
            <div class="w-100 flex justify-center align-center row-gap-30">
                <div class="">
                    <span class="section-title">Selecciona un proceso:</span>
                </div>
                <div class="flex">
                    <div class="flex-row row-gap-40 justify-center align-center">
                        <button mat-raised-button class="section-button-insert" (click)="selectFabricacion()" [disabled]="this.fabricacionService.esFabricacion">Fabricación</button>
                        <button mat-raised-button class="section-button-insert" (click)="selectPlegado()" [disabled]="this.fabricacionService.esPlegado">Plegado</button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</ng-container>
}

@if (this.fabricacionService.esFabricacion) {
<ng-container>
    <header class="header show">
        <div class="chapa-selected">
            <div class="flex row-gap-30 justify-center align-center" style="max-width: 95%;">
                <div class="flex-col row-gap-12">
                    @if (this.fabricacionService.hayChapaSeleccionada) {
                        <span class="section-title">{{this.fabricacionService.chapaSeleccionada.DescProducte}}</span>
                        <span>En stock: <span class="text-green">{{this.fabricacionService.chapaSeleccionada.StockActual}}</span></span>
                    }
                </div>
                <div class="flex row-gap-30">
                    <div class="cantidades">
                        <button mat-raised-button class="section-button unit-button" (click)="decrementarUnidades()"
                            [disabled]="disableDecrementar()">
                            <mat-icon aria-hidden="false" aria-label="Prev button" fontIcon="remove" class="icon"></mat-icon>
                        </button>
    
                        <span [ngClass]="{'opacity-50': disableUnidades()}">{{this.fabricacionService.unidades}}</span>
    
                        <button mat-raised-button class="section-button unit-button" (click)="incrementarUnidades()"
                            [disabled]="disableIncrementar()">
                            <mat-icon aria-hidden="false" aria-label="Next button" fontIcon="add" class="icon"></mat-icon>
                        </button>
                    </div>
                    <div class="flex-row row-gap-30 justify-center align-center">
                        <div class="flex-row row-gap-30 justify-center align-center">
                            <button mat-raised-button class="section-button-insert" (click)="redirect()" [disabled]="!this.fabricacionService.hayChapaSeleccionada">Confirmar</button>
                            @if (this.fabricacionService.hayChapaSeleccionada) {
                            <button mat-raised-button class="section-button-delete" (click)="resetSelection()">Limpiar</button>
                            } @else {
                            <button mat-raised-button class="section-button-delete" (click)="resetProceso()">Volver</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</ng-container>
}

@if (this.fabricacionService.esFabricacion) {
<ng-container>
    <div class="container">
        <section class="chapa-container">
            @for (chapa of this.fabricacionService.listaChapas; track $index) {
            <button mat-raised-button class="chapa"
                [ngClass]="{'selected': chapa.IdProducte === this.fabricacionService.chapaSeleccionada.IdProducte}"
                (click)="onChapaSelection(chapa)" [disabled]="chapa.StockActual <= 0">
                {{chapa.DescProducte}}
                @if (chapa.StockActual
                <= 0) { <br />
                <span class="text-red">Sin Stock</span>
                }
            </button>
            }
        </section>
    </div>
</ng-container>
}

@if (this.fabricacionService.esPlegado && !this.fabricacionService.procesandoPlegado) {
<ng-container>
    <header class="header show">
        <div class="chapa-selected">
            <div class="w-100 flex-col row-gap-40 justify-center align-center">
                <div class="flex-row row-gap-60 align-center">
                    <div class="flex-col row-gap-12">
                        @if (this.fabricacionService.hayProdSeleccionado) {
                        <span class="section-title">{{this.fabricacionService.prodSeleccionado.DescProducte}}
                            {{this.fabricacionService.prodSeleccionado.Longitud !== 0 ? this.fabricacionService.prodSeleccionado.Longitud : ''}}</span>
                        <span>Pendientes de plegar: <span class="text-green">{{this.fabricacionService.prodSeleccionado.Punzonado}}</span></span>
                        }
                    </div>
                    <div class="cantidades">
                        <button mat-raised-button class="section-button unit-button" (click)="decrementarUnidades()"
                        [disabled]="disableDecrementar()">
                        <mat-icon aria-hidden="false" aria-label="Prev button" fontIcon="remove"
                        class="icon"></mat-icon>
                        </button>
                    
                        <span [ngClass]="{'opacity-50': disableUnidades()}">{{this.fabricacionService.unidades}}</span>
                        
                        <button mat-raised-button class="section-button unit-button" (click)="incrementarUnidades()"
                        [disabled]="disableIncrementar()">
                        <mat-icon aria-hidden="false" aria-label="Next button" fontIcon="add" class="icon"></mat-icon>
                        </button>
                    </div>
                </div>

                <div class="w-90 flex-row row-gap-30 justify-between align-center">
                    <button mat-raised-button class="section-button-delete" (click)="handleVolver()">Volver</button>
                    <div class="flex-row row-gap-30 justify-center align-center">
                    <button mat-raised-button class="section-button-insert" (click)="anadirPlegable()" [disabled]="this.fabricacionService.disableConfirmarPlegado()">Confirmar</button>

                    <button mat-raised-button class="section-button-view mr-0" [matMenuTriggerFor]="menu" [matBadge]="this.fabricacionService.productesEnFabricacio.length" matBadgeSize="large" matBadgeColor="accent" [matBadgeHidden]="this.fabricacionService.hiddenBadge()">Productos</button>
                    
                    <mat-menu #menu="matMenu">
                        @for(producto of this.fabricacionService.productesEnFabricacio; track $index) {
                        <button (click)="this.fabricacionService.deleteProduct($index)" mat-menu-item
                            class="pt-10 pb-10"><mat-icon aria-hidden="false" aria-label="Confirm button"
                                fontIcon="delete" class="icon-delete"></mat-icon>{{producto['label']}}
                            ({{producto['status']}})</button>
                        }
                    </mat-menu>
                    </div>

                    @if(!this.fabricacionService.hayChapaSeleccionada) {
                    <button mat-raised-button class="section-button-insert" (click)="this.fabricacionService.updateStockPlegados()" [disabled]="this.fabricacionService.hiddenBadge()">PLEGAR</button>
                    } @else {
                    <button mat-raised-button class="section-button-insert" (click)="this.fabricacionService.insertOrderFabrication()" [disabled]="this.fabricacionService.hiddenBadge()">CREAR ORDEN</button>
                    }

                </div>
                
            </div>
        </div>
    </header>
</ng-container>
}

@if (this.fabricacionService.esPlegado && !this.fabricacionService.procesandoPlegado) {
<ng-container>
    <div class="container">
        <section class="chapa-container">
            @for (prod of this.fabricacionService.listaPlegables; track $index) {
            <button mat-raised-button class="chapa"
                [ngClass]="{'selected': prod.IdProducte === this.fabricacionService.prodSeleccionado.IdProducte}"
                (click)="onProdSelection(prod)">
                {{prod.DescProducte}} {{prod.Longitud !== 0 ? prod.Longitud : null}}
            </button>
            }
        </section>
    </div>
</ng-container>
}

@if (this.fabricacionService.esPlegado && this.fabricacionService.procesandoPlegado) {
<mat-spinner style="margin-top: 50px; margin-left: auto; margin-right: auto;"></mat-spinner>
<div class="noHayNadaEnTabla">PROCESANDO...</div>
}