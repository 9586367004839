import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AlbaranEntradaLn } from '../../../types/stock';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { } from '../../../services/llamadasMantenimiento'
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-detalles-entrada',
  templateUrl: './detalles-entrada.component.html',
  styleUrls: ['./detalles-entrada.component.css']
})
export class DetallesEntradaComponent implements OnInit, AfterViewInit {

  public listaDetalleProductos: any[] = [];
  infoProductos: any[] = [];
  public productosAutocomplete: IdLabelObject[] = [];
  public crearProductoNuevo: boolean = false;
  private paramsUrl: any;
  public nCompra: any;
  public nAlbaran: any;
  public detalleUnProductoABorrar: any;
  public tipo: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public productoResaltado = null;
  public dataSource!: MatTableDataSource<AlbaranEntradaLn>;

  public detalleUnProducto: any = {
    idProducto: "",
    nombreProducto: "",
    cantidadEntrada: "",
    nAlbaran: "",
    nCompra: "",
    tipo: "",
    esZincable: 0
  }

  displayedColumns: string[] = ['buttons', 'nombreProducto', 'cantidad'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaDetalleProductos);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetDetalleUnProducto();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paramsUrl = params;
      this.nCompra = params.nCompra;
      this.tipo = params.tipo;

      this.nAlbaran = params.nAlbaran;
      this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
        this.listaDetalleProductos = data.response;
        console.log(this.listaDetalleProductos);
        this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    })
    if (this.tipo == 1) {
      this.apiService.obtenerProductosDetalle(this.nCompra).subscribe(data => {
        this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
        this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
      });
    }
    else if (this.tipo == 2) {
      this.apiService.obtenerProductosDetalleDevolucion(this.nCompra).subscribe(data => {
        this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
        console.log('INFO PRODUCTOS', this.infoProductos);
        this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
      });
    }
  }

  ngAfterViewInit(): void {
    this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
      this.listaDetalleProductos = data.response;
      this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  /*  ngOnChanges(): void {
      this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
        this.listaDetalleProductos = data.response;
        this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;      
      });
      if(this.tipo==1){
        this.apiService.obtenerProductosDetalle(this.nCompra).subscribe(data => {
          this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
        });
      }
      else if(this.tipo==2){
        this.apiService.obtenerProductosDetalleDevolucion(this.nCompra).subscribe(data => {
          this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
        });
      }
      
  }*/

  public muestradetalleProducto(element: any) {
    this.crearProductoNuevo = false;
    this.detalleUnProducto = { ...element };
    this.detalleUnProducto.tipo = this.tipo;
  }

  public esDetalleProductoNuevo() {
    this.crearProductoNuevo = true;
    this.resetDetalleUnProducto();
  }

  public guardarDetalleProducto(formulario: NgForm) {
    this.detalleUnProducto.nAlbaran = this.nAlbaran;
    this.detalleUnProducto.nCompra = this.nCompra;
    var msg = "";

 /*    if (!this.miraNegativos(this.detalleUnProducto.cantidadEntrada)) {
      msg = 'El campo Cantidad Entrada tiene un número negativo';
    }
    else*/ if (this.comprobarSiYaExiste(this.detalleUnProducto.idProducto) && this.crearProductoNuevo) {
      msg = 'Este producto ya existe en este albaran';
    }

    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }

    if (this.crearProductoNuevo) {
      //detalle un producto debe tener 	IdAlbaranEntrada, IdProducte, cantidad entrada (los nombres que quieras)
      this.apiService.anadirUnProductoEntradaLn(this.detalleUnProducto).subscribe(data => {
        this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
          this.crearProductoNuevo = false;
          this.listaDetalleProductos = data.response;
          this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

      });
    } else {
      // detalle un producto debe tener 	IdAlbaranEntrada, IdProducte, cantidad (los nombres que quieras)
      this.apiService.modificarUnProductoEntradaLn(this.detalleUnProducto).subscribe(dades => {
        this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
          this.listaDetalleProductos = data.response;
          this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

      });
    }

  }

  public volverAAlbaranesEntrada() {
    this.router.navigate(['albaranes-entrada'], { queryParams: this.paramsUrl });
  }

  public eliminarDetalleProducto() {
    this.apiService.borrarLineasAlbaranEntrada(this.detalleUnProductoABorrar).subscribe(dades => {
      this.crearProductoNuevo = false;
      this.apiService.obtenerLineasAlbaranEntrada(this.nAlbaran).subscribe(data => {
        this.listaDetalleProductos = data.response;
        this.dataSource = new MatTableDataSource<AlbaranEntradaLn>(this.listaDetalleProductos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resetDetalleUnProducto();
      });
      if (this.tipo == 1) {
        this.apiService.obtenerProductosDetalle(this.nCompra).subscribe(data => {
          this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
        });
      }
      else if (this.tipo == 2) {
        this.apiService.obtenerProductosDetalleDevolucion(this.nCompra).subscribe(data => {
          this.infoProductos = data.response.map((producto: any) => ({ ...producto, idProducto: producto.idProducto.toString() }));
          this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
        });
      }
    })
  }

  public dialogEliminarDetalleProducto(detalleUnProducto: any) {
    this.detalleUnProductoABorrar = detalleUnProducto;
    this.detalleUnProductoABorrar.nCompra = this.nCompra;
    console.log(detalleUnProducto);
    this.openDialog("ELIMINAR PRODUCTO", "¿Quiere eliminar el producto " + detalleUnProducto.nombreProducto + " de la lista de precios especiales", true, "eliminarDetalleProducto");
  }


  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminarDetalleProducto':
          if (result) this.eliminarDetalleProducto();
          break;
        default:
          break;
      }
    });
  }


  public obtenerDatosProducto(idProducto: string) {
    var body = this.infoProductos.find(producto => producto.idProducto === idProducto) || {};
    return body;
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.listaDetalleProductos.find(item => {
      if (item.idProducto == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }

  private resetDetalleUnProducto() {
    this.detalleUnProducto = {
      idProducto: "",
      nombreProducto: "",
      cantidadEntrada: "",
      nCompra: '',
      cantidadComprada: "",
      cantidadRecibida: ""
    }
  }

}
