import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material.module";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
//import { MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { StockComponent } from './pages/stock/stock.component';
import { FacturasComponent } from './pages/facturas/facturas.component';
import { MantenimientoComponent } from './pages/mantenimiento/mantenimiento.component';
import { FiltroOfertasComponent } from './components/filtros/filtro-ofertas/filtro-ofertas.component';
import { TablaOfertasComponent } from './components/tablas/tabla-ofertas/tabla-ofertas.component';
import { DialogClientesOfertaComponent } from './dialogs/dialog-clientes-oferta/dialog-clientes-oferta.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ListaOfertasComponent } from './pages/ofertas/lista-ofertas/lista-ofertas.component';
import { FormOfertaComponent } from './pages/ofertas/form-oferta/form-oferta.component';
import { KitsUnClienteComponent } from './pages/ofertas/kits-un-cliente/kits-un-cliente.component';
import { OtrosUnClienteComponent } from './pages/ofertas/otros-un-cliente/otros-un-cliente.component';
import { OfertasUnClienteComponent } from './pages/ofertas/ofertas-un-cliente/ofertas-un-cliente.component';
import { DialogMantenimientoComponent } from './dialogs/dialog-mantenimiento/dialog-mantenimiento.component';
import { AlbaranesSalidaComponent } from './pages/stock/albaranes-salida/albaranes-salida.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { TecnicosComponent } from './pages/tecnicos/tecnicos.component';
import { AlbaranesEntradaComponent } from './pages/stock/albaranes-entrada/albaranes-entrada.component';
import { DetallesEntradaComponent } from './pages/stock/detalles-entrada/detalles-entrada.component';
import { DetallesSalidaComponent } from './pages/stock/detalles-salida/detalles-salida.component';
import { PreciosEspecialesComponent } from './pages/ofertas/precios-especiales/precios-especiales.component';
import { DialogPopUpComponent } from './dialogs/dialog-pop-up/dialog-pop-up.component';
import { LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { DialogMantenimiento2Component } from './dialogs/dialog-mantenimiento2/dialog-mantenimiento2.component';
import { OrdenesCompraComponent } from './pages/compras/ordenes-compra/ordenes-compra.component';
import { DialogMantenimiento3Component } from './dialogs/dialog-mantenimiento3/dialog-mantenimiento3.component';
import { DialogMantenimientoTipoKitComponent } from './dialogs/dialog-mantenimientoTipoKit/dialog-mantenimientoTipoKit.component';
import { DialogMantenimientoProdComponent } from './dialogs/dialog-mantenimiento-prod/dialog-mantenimiento-prod.component';
import { DetallesOrdenesCompraComponent } from './pages/compras/detalles-ordenes-compra/detalles-ordenes-compra.component';
import { GenerarCompraComponent } from './pages/compras/generar-compra/generar-compra.component';
import { DialogMantenimientoSubTipoKitComponent } from './dialogs/dialog-mantenimiento-sub-tipo-kit/dialog-mantenimiento-sub-tipo-kit.component';
import { DialogMantenimientoProdOpcComponent } from './dialogs/dialog-mantenimiento-prod-opc/dialog-mantenimiento-prod-opc.component';
import { DialogMantenimientoKitComponent } from './dialogs/dialog-mantenimiento-kit/dialog-mantenimiento-kit.component';
import { DialogPreciosProdComponent } from './dialogs/dialog-mantenimiento-prod/dialog-precios-prod/dialog-precios-prod.component';
import { ControlStockComponent } from './pages/stock/control-stock/control-stock.component';
import { SeleccionFacturasComponent } from './pages/facturas/seleccion-facturas/seleccion-facturas.component';
import { LineasFacturaComponent } from './pages/facturas/lineas-factura/lineas-factura.component';
//import { ReportCalculsComponent } from './reports/report-calculs/report-calculs.component';
import { AlbaranesPendientesFacturarComponent } from './pages/facturas/albaranes-pendientes-facturar/albaranes-pendientes-facturar.component';
import { ReportFacturaComponent } from './reports/report-factura/report-factura.component';
import { CabeceraFacturaComponent } from './reports/cabeceras/cabecera-factura/cabecera-factura.component';
import { CabeceraMecanovigaComponent } from './reports/cabeceras/cabecera-mecanoviga/cabecera-mecanoviga.component';
import { PieCabeceraComponent } from './reports/cabeceras/pie-cabecera/pie-cabecera.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportOfertaComponent } from './reports/report-oferta/report-oferta.component';
import { PortadaOfertaComponent } from './reports/cabeceras/portada-oferta/portada-oferta.component';
import { CabeceraOfertaComponent } from './reports/cabeceras/cabecera-oferta/cabecera-oferta.component';
import { CondicionesOfertaComponent } from './reports/cabeceras/condiciones-oferta/condiciones-oferta.component';
import { CondicionesOfertaSinComponent } from './reports/cabeceras/condiciones-oferta-sin/condiciones-oferta-sin.component';
import { ReportContratoBombaComponent } from './reports/report-contrato-bomba/report-contrato-bomba.component';
import { ReportAlbaranSalidaComponent } from './reports/report-albaran-salida/report-albaran-salida.component';
import { ReportAlbaranZincadoComponent } from './reports/report-albaran-zincado/report-albaran-zincado.component';
import { CabeceraAlbaranZincadoComponent } from './reports/cabeceras/cabecera-albaran-zincado/cabecera-albaran-zincado.component';
import { ReportCalculosComponent } from './reports/report-calculos/report-calculos.component';
import { InformeMaterialVendidoComponent } from './reports/informe-material-vendido/informe-material-vendido.component';
import { DialogMantenimiento4Component } from './dialogs/dialog-mantenimiento4/dialog-mantenimiento4.component';
import { InformeStockComponent } from './reports/informe-stock/informe-stock.component';
import { ReportOfertaCatalaComponent } from './reports/report-oferta-catala/report-oferta-catala.component';
import { ReportListadoOfertasComponent } from './reports/report-listado-ofertas/report-listado-ofertas.component';
import { MAT_FORM_FIELD, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReportCompraChapaComponent } from './reports/report-compra-chapa/report-compra-chapa.component';
import { ReportAlbaranesCompraChapaComponent } from './reports/report-albaranes-compra-chapa/report-albaranes-compra-chapa.component';
import { CabeceraGenericaComponent } from './reports/cabeceras/cabecera-generica/cabecera-generica.component';
import { NuevaCompraChapaComponent } from './pages/compras-de-chapa/nueva-compra-chapa/nueva-compra-chapa.component';
import { MatMenu, MatMenuModule } from '@angular/material/menu';


registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StockComponent,
    FacturasComponent,
    MantenimientoComponent,
    FiltroOfertasComponent,
    TablaOfertasComponent,
    DialogClientesOfertaComponent,
    OfertasUnClienteComponent,
    AutocompleteComponent,
    ListaOfertasComponent,
    FormOfertaComponent,
    KitsUnClienteComponent,
    OtrosUnClienteComponent,
    DialogMantenimientoComponent,
    AlbaranesSalidaComponent,
    ClientesComponent,
    TecnicosComponent,
    AlbaranesEntradaComponent,
    DetallesEntradaComponent,
    DetallesSalidaComponent,
    PreciosEspecialesComponent,
    DialogPopUpComponent,
    DialogMantenimiento2Component,
    OrdenesCompraComponent,
    DialogMantenimiento3Component,
    DialogMantenimientoTipoKitComponent,
    DialogMantenimientoProdComponent,
    DetallesOrdenesCompraComponent,
    GenerarCompraComponent,
    DialogMantenimientoSubTipoKitComponent,
    DialogMantenimientoKitComponent,
    DialogMantenimientoProdOpcComponent,
    DialogPreciosProdComponent,
    ControlStockComponent,
    SeleccionFacturasComponent,
    LineasFacturaComponent,
    //  ReportCalculsComponent,
    AlbaranesPendientesFacturarComponent,
    ReportFacturaComponent,
    CabeceraFacturaComponent,
    CabeceraMecanovigaComponent,
    PieCabeceraComponent,
    LoginComponent,
    ReportOfertaComponent,
    PortadaOfertaComponent,
    CabeceraOfertaComponent,
    CondicionesOfertaComponent,
    CondicionesOfertaSinComponent,
    ReportContratoBombaComponent,
    ReportAlbaranSalidaComponent,
    ReportAlbaranZincadoComponent,
    CabeceraAlbaranZincadoComponent,
    ReportCalculosComponent,
    InformeMaterialVendidoComponent,
    DialogMantenimiento4Component,
    InformeStockComponent,
    ReportOfertaCatalaComponent,
    ReportListadoOfertasComponent,
    CabeceraMecanovigaComponent,
    CabeceraFacturaComponent,
    PieCabeceraComponent,
    ReportCompraChapaComponent,
    ReportAlbaranesCompraChapaComponent,
    CabeceraGenericaComponent,
    NuevaCompraChapaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
  ],
  exports: [
    ReportCompraChapaComponent,
    ReportAlbaranesCompraChapaComponent,
    CabeceraGenericaComponent,
    NuevaCompraChapaComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
