<h2 class="h2titulo flex flex-60-responsive justify-center">Líneas de Detalle del Albarán de Entrada {{nAlbaran}} de la
    Compra Nº {{nCompra}}</h2> <br>

<div class="container flex-row-wrap">

    <div class="layout-tabla flex-layout flex-60-responsive">


        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="esDetalleProductoNuevo()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.fechaReal" mat-icon-button type="button"
                        (click)="dialogEliminarDetalleProducto(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nombreProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Cantidad </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.cantidadEntrada}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestradetalleProducto(row)"
                [ngClass]="{'row-highlighted': detalleUnProducto.idProducto == row.idProducto}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaDetalleProductos.length > 0}"
            [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaDetalleProductos.length == 0">No hay productos</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarDetalleProducto(formulario)"
        class="form-derecho row-gap-15 flex-40-responsive">

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Nº Compra</mat-label>
            <input disabled [(ngModel)]="nCompra" name="nCompra" matInput type="text">
        </mat-form-field>

        <app-autocomplete class="autocomplete flex-100" name="producto" [disabled]="!crearProductoNuevo"
            ngDefaultControl [idOpcionSeleccionada]="detalleUnProducto.idProducto"
            (cuandoSeSeleccioneOpcion)="detalleUnProducto.idProducto = $event" label="Producto"
            [options]="productosAutocomplete">
        </app-autocomplete>

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Cantidad Comprada</mat-label>
            <input disabled [value]="obtenerDatosProducto(detalleUnProducto.idProducto).cantidadComprada"
                name="cantidadComprada" matInput type="number">
        </mat-form-field>

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Cantidad Recibida</mat-label>
            <input disabled [value]="obtenerDatosProducto(detalleUnProducto.idProducto).cantidadRecibida"
                name="cantidadRecibida" matInput type="number">
        </mat-form-field>

        <mat-form-field class="flex-70-responsive" appearance="outline">
            <mat-label> Cantidad Entrada</mat-label>
            <input [(ngModel)]="detalleUnProducto.cantidadEntrada" type="number" name="cantidadEntrada" matInput
                type="number">
        </mat-form-field>

        <span class="flex-48-responsive">
            <button [disabled]="!detalleUnProducto.idProducto || !detalleUnProducto.cantidadEntrada"
                class="button__buscar" type="submit" mat-raised-button>Guardar</button>
        </span>

        <span class="flex-48-responsive">
            <button class="button__buscar" type="button" (click)="volverAAlbaranesEntrada()"
                mat-raised-button>Volver</button>
        </span>

    </form>

</div>