<div *ngIf="tipoProducto === 0" class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina" *ngFor="let pagina of paginasArrayStock">

            <div class="titulo">
                INFORME STOCK PRODUCTOS A {{fechaConsulta}}
            </div>

            <div class="kits">

                <div class="MVV">

                    <table border="1">
                        <thead>
                            <th style="font-weight: normal;" class="flex-35" scope="row">MODELO</th>
                            <th style="font-weight: normal;" class="flex-15" scope="row">TIPO</th>
                            <th style="font-weight: normal;" class="flex-8" scope="row">CH</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">LONG</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">ESPESOR</th>
                            <th style="font-weight: normal;" class="flex-12" scope="row">CANTIDAD</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">KGS.</th>
                        </thead>
                        <tbody>
                            <tr class="celda" *ngFor="let producto of pagina.datosMVV; let i = index">
                                <th style="font-weight: normal;" class="text-center flex-35" scope="row">{{producto.modelo}}</th>
                                <th style="font-weight: normal;" class="text-center flex-15" scope="row">{{producto.tipo}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-8" scope="row">{{producto.ch | number: '1.0-0'}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.long | number: '1.0-0'}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.espesor | number: '1.0-0'}}
                                </th>
                                <th style="font-weight: normal;" class="text-rigth flex-12" scope="row">{{producto.cantidad | number: '1.0-0'}}
                                </th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.kg | number: '1.0-0'}}</th>
                            </tr>
                        </tbody>

                    </table>

                </div>

                <div class="MVH">

                    <table border="1">
                        <thead>
                            <th style="font-weight: normal;" class="flex-35" scope="row">MODELO</th>
                            <th style="font-weight: normal;" class="flex-15" scope="row">TIPO</th>
                            <th style="font-weight: normal;" class="flex-8" scope="row">CH</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">LONG</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">ESPESOR</th>
                            <th style="font-weight: normal;" class="flex-12" scope="row">CANTIDAD</th>
                            <th style="font-weight: normal;" class="flex-10" scope="row">KGS.</th>
                        </thead>
                        <tbody>
                            <tr class="celda" *ngFor="let producto of pagina.datosMVH; let i = index">
                                <th style="font-weight: normal;" class="text-center flex-35" scope="row">{{producto.modelo}}</th>
                                <th style="font-weight: normal;" class="text-center flex-15" scope="row">{{producto.tipo}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-8" scope="row">{{producto.ch | number: '1.0-0'}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.long | number: '1.0-0'}}</th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.espesor | number: '1.0-0'}}
                                </th>
                                <th style="font-weight: normal;" class="text-rigth flex-12" scope="row">{{producto.cantidad | number: '1.0-0'}}
                                </th>
                                <th style="font-weight: normal;" class="text-rigth flex-10" scope="row">{{producto.kg | number: '1.0-0'}}</th>
                            </tr>
                        </tbody>

                    </table>

                </div>

            </div>

            <div *ngIf="pagina.nPagina === paginasArrayStock.length" class="kilos">
                <div style="margin-right: 26px;" class="totalKgKits">{{totalKilosMVV | number: '1.0-0'}}</div>
                <div style="margin-right: 48px;" class="totalKgKits">{{totalKilosMVH | number: '1.0-0'}}</div>
            </div>

            <div *ngIf="pagina.nPagina === paginasArrayStock.length" class="fecha" style="font-size: 40px;">
                Total: {{totalKilosMVV + totalKilosMVH | number: '1.0-0'}} Kg.
            </div>

            <div  class="fecha">
                Página {{pagina.nPagina}} de {{paginasArrayStock.length}}
            </div>
        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>

<div *ngIf="tipoProducto !== 0" class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="paginaTipo" *ngFor="let pagina of paginasArrayStock">

            <div style="margin-top: 100px;" *ngIf="tipoProducto === 1" class="titulo">INFORME STOCK HIERRO A {{fechaConsulta}}</div>
            <div style="margin-top: 100px;" *ngIf="tipoProducto === 3" class="titulo">INFORME STOCK TORNILLERIA A {{fechaConsulta}}</div>

            <table style="font-weight: normal;" border="1">
                <thead>
                    <th style="font-weight: normal;" class="flex-80" scope="row">NOMBRE PRODUCTO</th>
                    <th style="font-weight: normal;" class="flex-20" scope="row">CANTIDAD</th>
                </thead>
                <tbody>
                    <tr class="celda" *ngFor="let producto of pagina; let i = index">
                        <th style="font-weight: normal;" class="text-center flex-80" scope="row">{{producto.nombre}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-20" scope="row">{{producto.stock}}</th>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>