import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FabricacionService } from 'src/app/services/fabricacion.service';
import { type ModeloProducto } from 'src/app/types/fabricacion';

@Component({
  selector: 'app-seccion-modelo',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './seccion-modelo.component.html',
  styleUrl: './seccion-modelo.component.css'
})
export class SeccionModeloComponent {

  constructor(public fabricacionService: FabricacionService) { }

  public onModeloSelection(modelo: ModeloProducto) {
    this.fabricacionService.modeloProductoSeleccionado = modelo;
    this.fabricacionService.hayModeloSeleccionado = true;
    this.fabricacionService.productoGenerado += ` ${this.fabricacionService.modeloProductoSeleccionado.Model}`;
    this.fabricacionService.productoGeneradoModelosLength = this.fabricacionService.productoGenerado.length;
    this.fabricacionService.obtenerLongitudesProducto(this.fabricacionService.seFabrica, this.fabricacionService.tipoProductoSeleccionado.IdProducteFabricacio, this.fabricacionService.medidaProductoSeleccionada.IdMidaProducte, this.fabricacionService.modeloProductoSeleccionado.IdModel)
  }

  public disabledButton() {
    if (this.fabricacionService.hayModeloSeleccionado || !this.fabricacionService.hayTipoProductoSeleccionado || !this.fabricacionService.hayMedidaSeleccionada) return true;
    return false;
  }

  public disableVolverAtras() {
    if (this.fabricacionService.hayLongitudSeleccionada || !this.fabricacionService.hayModeloSeleccionado) return true;
    return false;
  }

}
