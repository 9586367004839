@if (this.fabricacionService.esFabricacion && !this.fabricacionService.procesandoFabricacion) {
<header class="header">
    <section class="header-content">
        <div class="flex align-center justify-between w-95" style="margin-top: -10px;">
            <div class="flex align-center">
                <h2 class="text">Nuevo Producto:</h2>
                @if (this.fabricacionService.tipoProductoSeleccionado) {
                <div class="text description">
                    <span>{{this.fabricacionService.productoGenerado}}</span>
                </div>
                }
            </div>
            <p class="text">Cantidad de chapas: {{this.fabricacionService.cantidadDeChapas}}</p>
        </div>
    </section>
    <div class="w-95 flex align-center justify-between">
        <button mat-raised-button class="section-button-delete" (click)="this.fabricacionService.volverAChapas()"
            [disabled]="this.fabricacionService.disableVolverAChapas()">Volver</button>

        <div class="flex align-center row-gap-30">
            <button mat-raised-button class="section-button-view"
                (click)="this.fabricacionService.irAPlegado()">Plegado</button>
            <button mat-raised-button class="section-button-view" [matMenuTriggerFor]="menu"
                [matBadge]="this.fabricacionService.productesEnFabricacio.length" matBadgeSize="large"
                matBadgeColor="accent" [matBadgeHidden]="this.fabricacionService.hiddenBadge()">Productos</button>
        </div>
        <button mat-raised-button class="section-button-insert"
            (click)="this.fabricacionService.insertOrderFabrication()"
            [disabled]="this.fabricacionService.hiddenBadge()">CREAR ORDEN</button>
        <mat-menu #menu="matMenu">
            @for(producto of this.fabricacionService.productesEnFabricacio; track $index) {
            <button (click)="this.fabricacionService.deleteProduct($index)" mat-menu-item class="pt-10 pb-10"><mat-icon
                    aria-hidden="false" aria-label="Confirm button" fontIcon="delete"
                    class="icon-delete"></mat-icon>{{producto['label']}} ({{producto['status']}})</button>
            }
        </mat-menu>
    </div>
</header>

@if (this.fabricacionService.esFabricacion) {
<div class="section-container">
    <div class="section">
        <app-seccion-tipo></app-seccion-tipo>
    </div>

    @if (this.mostrarMedidas()) {
    <div class="section">
        <app-seccion-medida></app-seccion-medida>
    </div>
    }

    @if (mostrarOrigen()) {
    <div class="section">
        <app-seccion-origen></app-seccion-origen>
    </div>
    }

    @if (mostrarModelos()) {
    <div class="section">
        <app-seccion-modelo></app-seccion-modelo>
    </div>
    }

    @if (mostrarUnidades()) {
    <div class="section">
        <app-seccion-unidades></app-seccion-unidades>
    </div>
    }

</div>
}
}

@if (this.fabricacionService.esFabricacion && this.fabricacionService.procesandoFabricacion) {
<mat-spinner style="margin-top: 50px; margin-left: auto; margin-right: auto;"></mat-spinner>
<div class="noHayNadaEnTabla">PROCESANDO...</div>
}