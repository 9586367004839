<div class="content" #content>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="comanda">

        <div class="pagina" id="heightTemplate" *ngFor="let pagina of paginas">
            <div class="header">
                <div class="header-mecanoviga">
                    <img src="../../../../assets/logo mecanoviga2.jpg" alt="">
                </div>
                <app-cabecera-generica [data]="compra" [dataComplementaria]="compraCompleta" [tipo]="tiposCabecera.COMPRA_CHAPA"
                    class="header-cliente"></app-cabecera-generica>
            </div>

            <div class="body">

                <div class="linea-gruesa center color-primary mt-20"></div>
                <div *ngIf="idIdioma != 1" class="cabecera-lineas">
                    <p style="width: 46%;" class="text-left">DESCRIPCIÓN</p>
                    <P style="width: 6%;" class="text-right">UNI.</P>
                    <p style="width: 15%;" class="text-right">ESTADO</p>
                    <p style="width: 11%;" class="text-right">PESO (KG)</p>
                </div>
                <div *ngIf="idIdioma == 1" class="cabecera-lineas">
                    <p style="width: 46%;" class="text-left">DESCRIPCIÓ</p>
                    <P style="width: 6%;" class="text-right">UNI.</P>
                    <p style="width: 15%;" class="text-right">ESTAT</p>
                    <p style="width: 11%;" class="text-right">PES (KG)</p>
                </div>

                <div class="linea-delgada color-primary "></div>

                <div class="albaranes mt-15">
                    <table style="width: 95%;">
                        <tbody class="flex-row-wrap">
                            <tr style="display: flex; width: 100%; justify-content: space-between;"
                                *ngFor="let compra of pagina.compras">
                                <th style="width: 46%;" class="text-left" scope="row">{{compra.DescProducte}}</th>
                                <th style="width: 6%" scope="row" class="text-right">{{compra.NumPeces}}</th>
                                <th style="width: 15%" scope="row" class="text-right">{{compra.Estat}}</th>
                                <th style="width: 11%" scope="row" class="text-right">{{compra.PesAlta ?? 0}}</th>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
          
            <div *ngIf="idIdioma != 1" class="pie-pagina" style="align-items: flex-end; padding-bottom: 100px; margin-left: -30px;">
                Página {{pagina.npagina}} de {{paginas.length}}
            </div>
            <div *ngIf="idIdioma == 1" class="pie-pagina" style="align-items: flex-end; padding-bottom: 100px; margin-left: -30px;">
                Pàgina {{pagina.npagina}} de {{paginas.length}}
            </div>
        
        </div>



        <div class="flex justify-center">
            <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a
                PDF</button>
        </div>

    </div>

    <span *ngIf="getHeightTemplate()"></span>