import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { mantenimiento2TiposKit } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { itemMantenimentSubTipoKit } from 'src/app/types/mantenimiento';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';

@Component({
  selector: 'app-dialog-mantenimiento-sub-tipo-kit',
  templateUrl: './dialog-mantenimiento-sub-tipo-kit.component.html',
  styleUrls: ['./dialog-mantenimiento-sub-tipo-kit.component.css']
})
export class DialogMantenimientoSubTipoKitComponent implements OnInit {

  itemSelected: any = {
    idSubTipusKit: null,
    subtipoKit: null,
    rendMortero: null,
    rendResina: null,
    rendVarilla: null,
    rendMorteroAlas: null,
    rendCementoPortland: null,
    rendCementoEspumante: null,
    idTipusKit: null,
    TipusKit: null,
    editable: null
  };

  tipusKit: IdLabelObject[] = [];

  items: itemMantenimentSubTipoKit [] = [];
  itemsTot: itemMantenimentSubTipoKit [] = [];

  displayedColumns: string[] = [];
  
  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimientoSubTipoKitComponent>,
    public dialogMantenimiento: MatDialog,
    public dialog: MatDialog,
    public dialogVerificar: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
    ){}

    
  ngOnInit(){
    
    this.displayedColumns = ['buttons', this.data.cols[0],this.data.cols[1],this.data.cols[2],this.data.cols[3],this.data.cols[4],this.data.cols[5],this.data.cols[6] ];
    console.log(this.displayedColumns);
    this.obtenerSubTipoKits();
    this.obtenerTipoKit();
    
  }

  private obtenerSubTipoKits(){
    this.apiService.obtenerSubTipoKit().subscribe(dades => {
      this.items = dades.response;
      this.itemsTot= this.items;
      //this.goTo();
    })
  }
  private obtenerTipoKit(){
    this.apiService.obtenerTipoKits().subscribe(dades => {
      console.log(dades.response);
      this.tipusKit = dades.response.map(kit => ({id: kit.idTipoKit.toString(), label: kit.tipoKit }));
    })

  }



  dialogEliminarUnCampo( event: any, item: any) {
    console.log("hola", item);
    this.itemSelected = item;
    this.openDialog("ELIMINAR","¿Quiere eliminar "+this.itemSelected.subtipoKit+"?", true,"eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo == "eliminar") this.eliminarItem();
    });
  }

  eliminarItem(){
    this.eliminarSubTipoKit(this.itemSelected);
  }
  private eliminarSubTipoKit(TipoKit : any){
    console.log("borrar SubtipoKit", TipoKit.idSubTipusKit);
    this.apiService.borrarTipoKit(TipoKit.idSubTipusKit).subscribe(data => {
      this.modificarTabla();
      this.resetItem();
    });
    
  }




  public guardarItem(form : NgForm){
    if(this.itemSelected.subtipoKit == null || this.itemSelected.rendMortero == null || this.itemSelected.rendMorteroAlas == null
      || this.itemSelected.rendVarilla == null || this.itemSelected.rendResina == null || this.itemSelected.rendCementoPortland == null || this.itemSelected.rendCementoEspumante == null ){
      this.openDialog("AVISO","No se puede guardar, todos los campos deben estar llenos", true,"");  
      return ;
    }
    this.guardarSubTipoKit();
    
  }

  private guardarSubTipoKit(){
    console.log("guardar:", this.itemSelected);
    if(this.itemSelected.idSubTipusKit != null){
      this.apiService.modificarSubTipusKit(this.itemSelected).subscribe(data=>{
        this.modificarTabla();
      })

    }
    else{
      console.log("guardar", this.itemSelected);
      this.apiService.anadirSubTipusKit(this.itemSelected).subscribe( data=>{
        this.itemSelected.idSubTipusKit = data.response;
        this.modificarTabla();
      });
    }
  }

  goTo(){
    if(this.itemSelected.idSubTipusKit == null){
      if(this.itemSelected.subtipoKit != null){
        this.items = this.itemsTot.filter(item => item.subtipoKit.toLowerCase().includes(this.itemSelected.subtipoKit.toLowerCase()) );
        if(this.items.length >0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }


  resetItem(){
    this.itemSelected.idSubTipusKit = null;
    this.itemSelected.subtipoKit = "";
    this.itemSelected.idTipoKit = null;
    this.itemSelected.TipoKit = null;
    this.itemSelected.editable = undefined;
    this.itemSelected.rendMortero = null;
    this.itemSelected.rendVarilla  = null;
    this.itemSelected.rendResina = null;
    this.itemSelected.rendCementoPorlantd = null;
    this.itemSelected.rendCementoEspumante = null;
    this.itemSelected.rendMorteroAlas = null;
    this.goTo();
  }

  public envioDatos(){
    console.log("envia datos: ", this.data);
  }

  public modificarTabla(){
    this.modificarTablaSubTipoKit(); 
  }

  private modificarTablaSubTipoKit(){
    this.obtenerSubTipoKits();
  }
  cuandoSeCliqueEnMantenimientoTipoProd(){
    var dialogRef: any;
    dialogRef = mantenimiento2TiposKit(this.dialogMantenimiento);
    dialogRef.afterClosed().subscribe( (data:any) => {
        this.obtenerTipoKit();
    });
  }

  muestraUnItem(item: any) {
    console.log(item);
    this.itemSelected = {... item};
    console.log(this.itemSelected);
  }
  muestraPrecios(){
    
  }
}
