// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: '/backend/index.php',
  apiUrl: 'https://mecanoviga.namastech.com/backend/index.php',
  ofertas: '?module=ofertas&req=',
  ofertasCliente: '?module=ofertasClient&req=',
  comprasChapa: '?module=comprasChapa&req=',
  ordenesElaboracion: '?module=ordenesElaboracion&req=',
  fabricacion: '?module=fabricacion&req=',
  obtenciones: '?module=obtenciones&req=',
  clientes: '?module=clientes&req=',
  ofertaKits: '?module=ofertaKits&req=',
  ofertaOtros: '?module=ofertaOtros&req=',
  tecnicos: '?module=tecnicos&req=',
  matenimientoSimple: '?module=mantenimientoSimple&req=',
  matenimientoComplejo: '?module=mantenimientoComplejo&req=',
  albaranesSalida: '?module=albaranesSalida&req=',
  albaranesEntrada: '?module=albaranesEntrada&req=',
  encargadoObra: '?module=encargadoObra&req=',
  ordenCompra: '?module=ordenCompra&req=',
  generarCompra: '?module=generarCompra&req=',
  preciosProducto: '?module=preciosProducto&req=',
  stock: '?module=stock&req=',
  factura: '?module=factura&req=',
  login: '?module=login&req=',
  uploadFile: '/assets/kits/Upload.php'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
