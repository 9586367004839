import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FabricacionService } from 'src/app/services/fabricacion.service';
import { type TipoProductoFabricacion } from 'src/app/types/fabricacion';

@Component({
  selector: 'app-seccion-tipo',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './seccion-tipo.component.html',
  styleUrl: './seccion-tipo.component.css'
})
export class SeccionTipoComponent {

  constructor(public fabricacionService: FabricacionService) { }

  ngOnInit() {
    this.fabricacionService.obtenerTiposProductoFabricacion();
  }

  public onTipoSelection(tipoProducto: TipoProductoFabricacion) {
    this.fabricacionService.tipoProductoSeleccionado = tipoProducto;
    this.fabricacionService.hayTipoProductoSeleccionado = true;
    this.fabricacionService.productoGenerado = this.fabricacionService.tipoProductoSeleccionado.DescProducteFabricacio;
    this.fabricacionService.productoGeneradoTiposLength = this.fabricacionService.productoGenerado.length;
    this.fabricacionService.obtenerMedidasProducto(this.fabricacionService.seFabrica, this.fabricacionService.tipoProductoSeleccionado.IdProducteFabricacio);
    console.log(this.fabricacionService.tipoProductoSeleccionado);
  }

  public disabledButton() {
    if (this.fabricacionService.hayTipoProductoSeleccionado || this.fabricacionService.hayMedidaSeleccionada || this.fabricacionService.hayModeloSeleccionado) return true;
    return false;
  }

  public disableVolverAtras() {
    if (!this.fabricacionService.hayTipoProductoSeleccionado || this.fabricacionService.hayMedidaSeleccionada) return true;
    return false;
  }
}
