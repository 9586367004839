import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { DateToString } from 'src/app/services/dataFunction';
import { mantenimiento2TipoProducto, preciosProd } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';
import { idTiposGenericos, tiposGenericos } from 'src/app/constants/fabricacion.constant';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-mantenimiento-prod',
  templateUrl: './dialog-mantenimiento-prod.component.html',
  styleUrls: ['./dialog-mantenimiento-prod.component.css']
})
export class DialogMantenimientoProdComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimientoProdComponent>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog,
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
  ) { }

  items: any[] = [];
  itemsTot: any[] = [];
  preciosTodos: any[] = [];
  preciosConcreto: any[] = [];
  tipoProducto: IdLabelObject[] = [];
  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2], this.data.cols[3], this.data.cols[4], this.data.cols[5], this.data.cols[6], this.data.cols[7], this.data.cols[8], this.data.cols[9], this.data.cols[10], this.data.cols[11], this.data.cols[12], this.data.cols[13], this.data.cols[14], this.data.cols[15]];
  productosFabricacion = [];
  productosFabricacionMap = [];
  productosGenericosFabricacion: any[] = [];
  modelosProducto: any[] = [];
  medidasProducto: any[] = [];

  tiposFabricacionGenericos = tiposGenericos;
  idTiposGenericos = idTiposGenericos;

  preciosSelected = false;
  precioOriginal: number = 0;
  closeData: any;
  openData: any;
  minDate = new Date();

  itemSelected: any = {
    idProd: null,
    descripcion: null,
    chapa: null,
    longitud: null,
    idTipoProd: null,
    tipoProd: null,
    seZinca: false,
    seFabrica: false,
    productoFabricacion: null,
    descProductoFabricacion: null,
    tipoFabricacion: null,
    medida: null,
    modelo: null,
    peso: null,
    stockMinimo: null,
    stockActual: null,
    editable: false,
  };
  itemOriginal: any = {
    idProd: null,
    descripcion: null,
    chapa: null,
    longitud: null,
    idTipoProd: null,
    tipoProd: null,
    seZinca: false,
    seFabrica: false,
    productoFabricacion: null,
    descProductoFabricacion: null,
    tipoFabricacion: null,
    medida: null,
    modelo: null,
    peso: null,
    stockMinimo: null,
    stockActual: null,
    editable: false,
  };
  precioSelected: any = {
    idProd: null,
    fechaInicio: null,
    fechaFin: null,
    precio: null,
  }


  ngOnInit(): void {
    console.log('DATA', this.data);
    this.obtenerProductosMant();
    this.obtenerTipoProducto();
    this.obtenerProductosFabricacion()
  }

  private obtenerTipoProducto() {
    this.apiService.obtenerTipoProducto().subscribe(data => {
      console.log("tipo prod", data);
      this.tipoProducto = data.response.map(prod => ({ id: prod.idTipo.toString(), label: prod.nombre }));
      console.log(this.tipoProducto);
    });
  }
  private obtenerProductosMant() {
    this.apiService.obtenerProductosMant().subscribe(data => {
      console.log("data", data);
      this.items = data.response.map(d => ({
        idProd: d.idProducto, descripcion: d.descripcio, chapa: d.chapa, longitud: d.longitud,
        idTipoProd: d.idTipoProd, tipoProd: d.tipoProd, seZinca: d.zincat, seFabrica: d.fabricat,
        productoFabricacion: d.idProdFabricacio, descProductoFabricacion: d.descProdFabricacio,
        tipoFabricacion: d.idTipusProdFabricacio, descTipoFabricacion: d.tipusProdFabricacio, medida: d.mida, modelo: d.model, peso: d.peso,
        stockMinimo: d.stockMinim, stockActual: d.stockActual, punzonado: d.punzonado, zincado: d.zincado, zincador: d.enZincador, zincadorAClient: d.zincadorAClient, enZincador: d.enZincador, editable: d.estaAssignada, dataIni: d.dataIni, precio: d.precio
      }))
      console.log(this.items);
      this.itemsTot = this.items;

    })
  }

  private obtenerProductosFabricacion() {
    this.apiService.obtenerProductosFabricacion().subscribe(data => {
      let response: any = data.response;
      console.log("Fab", data);
      this.productosFabricacion = response['result'];
      this.productosFabricacionMap = response['resultMap'];
    })
  }

  dialogEliminarUnCampo(event: any, item: any) {
    this.openDialog("ELIMINAR", "¿Quiere eliminar " + item.descripcion + "?", true, "eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result && tipo == "eliminar") this.eliminarItem();
      if (result && tipo == "confirmacion") this.anadirProd();
    });
  }

  eliminarItem() {
    this.apiService.borrarProdMant(this.itemSelected.idProd).subscribe(data => {
      this.obtenerProductosMant();
      this.resetItem();
    });
  }

  public onProductSelect(productoAFabricarSeleccionado: any) {
    this.itemSelected.productoFabricacion = productoAFabricarSeleccionado.IdProducteFabricacio
    this.itemSelected.descProductoFabricacion = productoAFabricarSeleccionado.DescProducteFabricacio
    this.itemSelected.tipoFabricacion = productoAFabricarSeleccionado.IdTipusProducteFabricacio
    console.log('PRODUCTO FABRICACION SELECCIONADO:', productoAFabricarSeleccionado);
  }

  muestraUnItem(item: any) {
    this.itemSelected = { ...item, };
    if (item.zincado == "NO") this.itemSelected.zincado = false;
    if (item.seFabrica == "NO") this.itemSelected.seFabrica = false;
    this.itemOriginal = this.itemSelected;
    this.obtenerPrecio();
    console.log(this.itemSelected);
  }

  openDialogTipoProd() {
    var dialogRef: any;
    dialogRef = mantenimiento2TipoProducto(this.dialogMantenimiento);
    dialogRef.afterClosed().subscribe((data: any) => {
      console.log("cerrrado");
      this.obtenerTipoProducto();
    });
  }

  guardarItem(formulario: NgForm) {
    let itemToUpdate: any = {};
    if (this.itemSelected.idProd) {
      if (this.itemSelected.seFabrica) {
        itemToUpdate = this.itemSelected
        console.log("actualizar si se fabrica; ", itemToUpdate);
      } else {
        itemToUpdate = {
          ...this.itemSelected,
          productoFabricacion: null,
          medida: null,
          modelo: null,
          tipoFabricacion: null,
        };
        console.log("actualizar si NO se fabrica; ", itemToUpdate);
      }
      this.apiService.modificarProdMant(itemToUpdate).subscribe(dades => {
        this.tratarPrecios();
        this.obtenerProductosMant();
        this._snackBar.open("Producto modificado correctamente", "x", {
          duration: 7000,
          panelClass: ['success-snackbar']
        });
      });
    }
    else {
      console.log(this.itemSelected);
      this.openDialog("PRODUCTO NUEVO", "¿Quiere crear este producto " + this.itemSelected.descripcion + "?", true, "confirmacion");
    }
  }

  anadirProd() {
    let itemToSave: any = {};
    if (this.itemSelected.seFabrica) {
      itemToSave = this.itemSelected
      console.log("guardar si se fabrica; ", itemToSave);
    } else {
      itemToSave = {
        ...this.itemSelected,
        productoFabricacion: null,
        medida: null,
        modelo: null,
        tipoFabricacion: null,
      };
      console.log("guardar si NO se fabrica; ", itemToSave);
    }

    this.apiService.anadirProdMant(itemToSave).subscribe(dades => {
      this.itemSelected.idProd = dades.response;
      this.tratarPrecios();
      this.obtenerProductosMant();
      this.resetItem();
      this._snackBar.open("Producto creado correctamente", "x", {
        duration: 7000,
        panelClass: ['success-snackbar']
      });
    });

  }

  muestraPrecios(event: any) {
    event.stopPropagation();
    var dialogRef: any;
    dialogRef = preciosProd(this.dialogMantenimiento, this.itemSelected.descripcion, this.itemSelected.idProd);
    dialogRef.afterClosed().subscribe((data: any) => {
      console.log("cerrrado");
      this.obtenerProductosMant();
      this.obtenerPrecio();
    });
  }


  obtenerPrecio() {
    this.apiService.obtenerPrecioProducto(this.itemSelected.idProd).subscribe(dades => {
      console.log("idProd", this.itemSelected.idProd);
      console.log(dades.response.length - 1);
      if (dades.response.length > 0) {
        this.precioSelected = dades.response[dades.response.length - 1];
        console.log(this.precioSelected.fechaInicio);
        this.precioOriginal = this.precioSelected.precio;
        this.openData = this.precioSelected.fechaInicio;
        this.closeData = new Date(this.precioSelected.fechaInicio);
        console.log("data1", this.closeData);
        this.closeData.setDate(this.closeData.getDate() + 1);
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() + 1);
        if (this.minDate < this.closeData) this.minDate = this.closeData;
      }
      else {
        this.precioSelected.fechaInicio = null;
        this.precioSelected.precio = null;
        this.precioOriginal = 0;
        //this.minDate = new Date();
        //this.precioSelected.fechaInicio = this.minDate;
        console.log(this.precioSelected.fechaInicio);
      }
    });
  }

  tratarPrecios() {

    let fechaIncio = new Date(this.precioSelected.fechaInicio);
    console.log(fechaIncio, new Date());
    if (fechaIncio < new Date()) fechaIncio = new Date();


    this.closeData = fechaIncio;
    this.closeData.setDate(fechaIncio.getDate() - 1);
    this.closeData = DateToString(this.closeData);

    if (this.precioOriginal != this.precioSelected.precio && DateToString(this.precioSelected.fechaInicio) != DateToString(new Date())) {
      var pvp = {
        idProd: this.itemSelected.idProd,
        fechaIni: this.openData,
        fechaIniOriginal: this.closeData,
        precio: this.precioSelected.precio
      };
      console.log(pvp);
      this.apiService.modificarPrecioProducto(pvp).subscribe(data => {
        fechaIncio.setDate(fechaIncio.getDate() + 1);
        var pvp2 = {
          idProd: this.itemSelected.idProd,
          fechaIni: DateToString(fechaIncio),
          precio: this.precioOriginal
        };
        this.apiService.anadirPrecioProducto(pvp2).subscribe(data => {
          this.obtenerPrecio();
        });
      })
    }
    //else this.openDialog("AVISO","No se ha modificado el precio, el canvio de precio no se va a efectuar ", true,"aviso");
  }


  resetItem() {
    this.itemSelected = {
      idProd: null,
      descripcion: null,
      chapa: null,
      longitud: null,
      idTipoProd: null,
      tipoProd: null,
      seZinca: false,
      seFabrica: false,
      productoFabricacion: null,
      descProductoFabricacion: null,
      tipoFabricacion: null,
      medida: null,
      modelo: null,
      peso: null,
      stockMinimo: null,
      stockActual: null,
      editable: false,
    }

    this.precioSelected = {
      idProducto: null,
      fechaInicio: null,
      fechaFin: null,
      precio: null,
    }
    this.precioOriginal = 0;
    this.goTo();

  }

  public goTo() {
    if (this.itemSelected.idProducto == null) {
      if (this.itemSelected.descripcion != null) {
        this.items = this.itemsTot.filter(item => item.descripcion.toLowerCase().includes(this.itemSelected.descripcion.toLowerCase()));
        if (this.items.length > 0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }

  public newProductoFabricacion() {
    this.itemSelected.medida = null;
    this.itemSelected.modelo = null;
  }


}
