import { Component, OnInit, Input } from '@angular/core';
import { FiltroOfertas } from 'src/app/components/filtros/filtro-ofertas/FiltroOfertas';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cabecera-factura',
  templateUrl: './cabecera-factura.component.html',
  styleUrls: ['./cabecera-factura.component.css']
})
export class CabeceraFacturaComponent implements OnInit {

  @Input() factura: any = 0;
  @Input() esAbono: boolean = false;
  private nOferta: any = "";
  public idIdioma = 2;

  datosFactura = {
    nFactura: this.factura,
    nOferta: "",
    nombreCliente: "",
    direccionCliente: "",
    ciudadCliente: "",
    codigoPostalCliente: "",
    nombreProvincia: "",
    cifCliente: "",
    esAbono: this.esAbono
  }
  
  datosOferta = {
    nOferta: this.datosFactura.nOferta,
    direccionObra: "",
    ciudadObra: "",
    codigoPostalObra: "",
    provinciaObra: "",
    paisObra: ""
  }

  constructor(
    private apiService: ApiService
  ) { 
  }
  
  ngOnInit(): void {
    this.datosFactura.nFactura = this.factura;
    let filtroUnaOferta = {
      selectedFechaInicio: '',
      selectedFechaFin: '',
      selectednFactura: this.factura,
      selectednOferta: '',
      selectedidCliente: '',
      selectedidComercial: '',
      selectedPagado: '',
      selectedAdelanto: '',
      selectedAnulada: ''   
    }
    this.apiService.obtenerFactura(filtroUnaOferta).subscribe( dato => {
      let datosFac = dato.response[0];
      console.log(datosFac);
      this.datosFactura.nFactura = this.factura;
      this.datosFactura.nOferta =  datosFac.nOferta;
      this.datosFactura.nombreCliente = datosFac.nombreCliente;
      this.datosFactura.direccionCliente = datosFac.direccionFactura;
      this.datosFactura.ciudadCliente = datosFac.nombreCiudad;
      this.datosFactura.codigoPostalCliente = datosFac.codigoPostal;
      this.datosFactura.nombreProvincia = datosFac.nombreProvincia;
      this.datosFactura.cifCliente = datosFac.nif;
      this.datosFactura.esAbono = this.esAbono;
      this.esAbono= this.datosFactura.esAbono;
      this.nOferta = datosFac.nOferta;
      let FiltroOfertas: FiltroOfertas = {
        selectedFechaDesde: undefined,
        selectedFechaHasta: undefined,
        selectedComercial: undefined,
        selectedTecnico: undefined,
        selectedCliente: undefined,
        selectedProvincia: undefined,
        selectedDireccionObra: undefined,
        selectednOferta: this.nOferta,
        selectedChecked: undefined,
        selectedCerrado: undefined,
        selectedColaboracion: undefined,
        pdf: true,
      };
      if ( this.nOferta > 0 ) {
        this.apiService.obtenerOfertas(FiltroOfertas).subscribe( datosOferta => {
          let datosOfe = datosOferta.response[0] ;
          this.idIdioma = datosOfe.idIdioma;
    //      this.idIdioma = 1;
          this.datosOferta.direccionObra = datosOfe.direccionObra;
          this.datosOferta.ciudadObra = datosOfe.ciudad;
          this.datosOferta.codigoPostalObra = datosOfe.codigoPostal,
          this.datosOferta.provinciaObra = datosOfe.provincia,
          this.datosOferta.paisObra = "España";
        })
  
      }
    })

  }

}
