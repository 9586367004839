<div class="content">

    <div class="header-cliente">

        <div class="nfac">
            <div *ngIf="nAlbaran">
                <p style="font-size: 35px;">Albarán Nº {{nAlbaran}}</p>
            </div>
            <div *ngIf="!nAlbaran">
            </div>

            <p style="font-size: 60px; color: #004f9f;">{{letra}}</p>
        </div>

        <div class="nref color-primary">
            <p style="margin-top: 12px;">REF:</p>
            <div style="margin-top: 12px;"  class="ref-fac">
                <p *ngIf="nAlbaran" style="margin-left: -250px;">{{nOferta}}</p>
                <p *ngIf="!nAlbaran">{{nOferta}}</p>
                <p *ngIf="!nAlbaran"> / </p>
                <p *ngIf="!nAlbaran" >{{idCliente}}</p>
            </div>
        </div>

        <div class="obra">
            <div class="cabeceraObra">
                OBRA:
            </div>
            <div class="datosObra">
                <div>
                    <textarea class="direccionObra" name="" id="" cols="40" rows="2">{{datosOferta.direccionObra}}</textarea>
                </div>
                <div style="text-align: left; max-width: 404px;">{{datosOferta.codigoPostalObra}} - {{datosOferta.ciudadObra}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosOferta.provinciaObra}}</div>
            </div>
        </div>

        <div class="cliente">
            <div *ngIf="idIdioma != 1" class="cabeceraCliente">
                CLIENTE:
            </div>
            <div *ngIf="idIdioma == 1" class="cabeceraCliente">
                CLIENT:
            </div>
            <div class="datosCliente">
                <div style="text-align: left; max-width: 404px;">{{datosCliente.nombreCliente}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosCliente.direccionCliente}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosCliente.codigoPostalCliente}} - {{datosCliente.ciudadCliente}}</div>
                <div style="text-align: left; max-width: 404px;">{{datosCliente.provinciaCliente}}</div>
            </div>
        </div>

    </div>

</div>