<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container">
    <div class="tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="items" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- SubTipoKit Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.subtipoKit}} </td>
            </ng-container>


            <!-- Rendiment Mortero Column -->
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendMortero}} </td>
            </ng-container>


            <!-- Rendiment MorteroAlas Column -->
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[2]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendMorteroAlas}} </td>
            </ng-container>

            <!-- Rendiment Varilla Column -->
            <ng-container matColumnDef={{data.cols[3]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[3]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendResina}} </td>
            </ng-container>

            <!-- Rendiment Resina Column -->
            <ng-container matColumnDef={{data.cols[4]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[4]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendVarilla}} </td>
            </ng-container>

            <!-- Cemento portland Column -->
            <ng-container matColumnDef={{data.cols[5]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[5]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendCementoPortland}} </td>
            </ng-container>

            <!-- CEMENTO espumante Column -->
            <ng-container matColumnDef={{data.cols[6]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[6]}} </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.rendCementoEspumante}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idSubTipusKit == row.idSubTipusKit }"></tr>
        </table>
    </div>

    <form class="form flex-row-wrap row-gap-20 justify-between flex-35-responsive" #formulario="ngForm"
        (ngSubmit)="guardarItem(formulario)">

        <mat-form-field class="auto100 flex-100" appearance="outline">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input required [(ngModel)]="itemSelected.subtipoKit" name="labelSubtipoKit" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.subtipoKit" matSuffix mat-icon-button
                aria-label="Clear" (click)="resetItem()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendMortero" name="labelrendimentMortero" matInput type="number"
                min="0">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48" appearance="outline">
            <mat-label> {{data.cols[2]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendMorteroAlas" name="labelrendimentMorteroAlas" matInput
                type="number" min="0">

        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> {{data.cols[3]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendResina" name="labelrendimentResina" matInput type="number"
                min="0">

        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> {{data.cols[4]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendVarilla" name="labelrendimentVarilla" matInput type="number"
                min="0">

        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> {{data.cols[5]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendCementoPortland" name="labelrendimentCementoPorlantd" matInput
                type="number" min="0">
        </mat-form-field>

        <mat-form-field class="auto100 flex-48-responsive" appearance="outline">
            <mat-label> {{data.cols[6]}} </mat-label>
            <input required [(ngModel)]="itemSelected.rendCementoEspumante" name="labelrendimentCementoEspumante"
                matInput type="number" min="0">
        </mat-form-field>


        <app-autocomplete class="autocomplete flex-100" appearance="outline" name="label" ngDefaultControl
            [idOpcionSeleccionada]="itemSelected.idTipusKit"
            (cuandoSeSeleccioneOpcion)="itemSelected.idTipusKit = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimientoTipoProd()" label="{{data.cols[7]}}"
            [options]="tipusKit">
        </app-autocomplete>

        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span class="flex-50-responsive">
                <button [disabled]="!itemSelected.idTipusKit" mat-raised-button type="submit">GUARDAR</button>
            </span>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>