<div class="layout-tabla container flex-layout flex-100">

    <div class="titulos">
        <h2 class="h2titulo">Elegir un Albarán</h2>
        <button class="button-vacia" mat-raised-button (click)="anadirVacia()">Factura Vacía</button>
    </div>

    <table mat-table [dataSource]="dataSource" matSort
        class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef>
                <button class="button-create" mat-icon-button type="button" (click)="volver()">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>

        <ng-container matColumnDef="nOferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Oferta </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.nOferta}} </td>
        </ng-container>

        <ng-container matColumnDef="nombreCliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Cliente </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.nombreCliente}} </td>
        </ng-container>

        <ng-container matColumnDef="anticipoPendiente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Anticipo Pendiente </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.anticipoPendiente | number:
                '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="importeAlbaran">
            <th mat-header-cell align="center" *matHeaderCellDef mat-sort-header> Importe Albarán </th>
            <td mat-cell *matCellDef="let element" class="center"> {{element.importeAlbaran | number:
                '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="importeProductosOpcionales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp.Prod.Opcionales </th>
            <td mat-cell *matCellDef="let element" class="center"> {{element.importeProductosOpcionales | number:
                '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="transporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transporte </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.transporte | number:
                '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="albaranesPendientes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Albaranes Pendientes </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.albaranesPendientes}} </td>
        </ng-container>

        <ng-container matColumnDef="importeAbonos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Importe Abonos </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.importeAbonos | number:
                '1.2-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="abonosPendientes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Abonos Pendientes </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.abonosPendientes}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="enviaUnaOferta(row)"
            [ngClass]="{'row-highlighted': ofertaSeleccionable.nOferta == row.nOferta}"></tr>
    </table>

    <mat-paginator *ngIf="listaOfertasSeleccionables.length>0" [pageSizeOptions]="[20]"
        showFirstLastButtons></mat-paginator>
    <div class="noHayNadaEnTabla" *ngIf="listaOfertasSeleccionables.length == 0">No hay albaranes</div>

</div>