import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cabecera-mecanoviga',
  templateUrl: './cabecera-mecanoviga.component.html',
  styleUrls: ['./cabecera-mecanoviga.component.css']
})
export class CabeceraMecanovigaComponent implements OnInit {

  @Input() direccionDelegacion = "Polígono Industrial Can Prunera, C/. del Garraf, nº 16";
  @Input() cpDelegacion = "08759";
  @Input() poblacionDelegacion = "Vallirana";
  @Input() provinciaDelegacion = "Barcelona";
  @Input() idIdioma = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
