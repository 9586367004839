import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { IdLabelObject } from '../../../types/global';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { mantenimiento1CondicionesPago, mantenimiento1Idioma, mantenimiento1TipoSoporte, mantenimiento3Comercial, mantenimiento1ValidezOferta, mantenimiento2Ciudad, mantenimiento3Delegacion } from '../../../services/llamadasMantenimiento';
import { TituloService } from 'src/app/services/titulo.service';
import { DateToString, StringToDate } from '../../../services/dataFunction';
import { AuthService } from 'src/app/services/auth-service';

@Component({
  selector: 'app-form-oferta',
  templateUrl: './form-oferta.component.html',
  styleUrls: ['./form-oferta.component.scss']
})
export class FormOfertaComponent implements OnInit {

  public pantalla: string;
  public clientes: any[] = [];
  public kits: any[] = [];
  public otros: any[] = [];
  public especiales: any[] = [];
  public fechaCierreForm: boolean = false;

  public dataOferta: any = {
    nOferta: "",
    fechaOferta: new Date(),
    direccionObra: "",
    ciudad: "",
    idCiudad: "",
    provincia: "",
    idProvincia: "",
    idPais: "",
    pais: "",
    codigoPostal: "",
    plazoEntrega: "",
    notas: "",
    comercial: "",
    idComercial: "",
    delegacion: "",
    idDelegacion: "",
    elementoPor: "",
    idElementoPor: "",
    tecnico: "",
    idTecnico: "",
    idioma: "",
    idIdioma: "",
    notasInternas: "",
    importeOtros: "",
    pesoOtros: "",
    importeKits: "",
    pesoKits: "",
    incrementoDec: "",
    dtoOferta: "",
    dtoPP: "",
    cliente: "",
    idCliente: "",
    fechaPedido: "",
    colaboracion: false,
    incrementoClienteKits: "",
    dto: "",
    anticipo: "",
    fechaCierre: null,
    validezOferta: "",
    condicionesPago: ""
  };

  public ciudades: any[] = [];
  public ciudadesAutocomplete: IdLabelObject[] = [];
  public idiomas: IdLabelObject[] = [];
  public tecnicos: IdLabelObject[] = [];
  public comerciales: IdLabelObject[] = [];
  public provincias: IdLabelObject[] = [];
  public paises: IdLabelObject[] = [];
  public delegaciones: IdLabelObject[] = [];
  public elementosPor: IdLabelObject[] = [];
  public listaValidezOferta: IdLabelObject[] = [];
  public listaCondicionesPago: IdLabelObject[] = [];
  private paramsUrl: any = "";
  private ofertaActual: any = "";

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.pantalla = "datosUnaOferta";
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(dades => {
      if (dades.redirecTo === 'oferta') {
        this.dataOferta = JSON.parse(localStorage.getItem('objectDatosTecnico') || '{}');
        localStorage.removeItem('objectDatosTecnico')
        if (dades.idTecnico) {
          this.dataOferta.idTecnico = dades.idTecnico;
        }
      }
    })

    const paramId = parseInt(this.activatedRoute.snapshot.paramMap.get('id') || '');
    const selectednOferta = isNaN(paramId) ? undefined : paramId;
    console.log("selectedOferta:", selectednOferta);
    this.ofertaActual = selectednOferta;
    selectednOferta && this.apiService.obtenerOfertas({ selectednOferta })
      .subscribe(data => {
        this.dataOferta = data.response[0] || {}
        console.log("111: ", this.dataOferta);
        if (this.dataOferta.fechaCierre === "0000-00-00") {
          this.dataOferta.fechaCierre = null;
        }
        const paramPantalla = this.activatedRoute.snapshot.queryParamMap.get('pantalla' || '');
        if (paramPantalla === "clientes") {
          this.openOfertasUnCliente()
        };
        this.fechaCierreForm = this.dataOferta.fechaCierre;
      });
    if (this.dataOferta.idCliente === "") { this.dataOferta.idCliente = null };

    this.activatedRoute.queryParams.subscribe(dades => {
      if (dades) { this.paramsUrl = dades }
    })
    this.apiService.obtenerComerciales()
      .subscribe(data => {
        this.comerciales = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }))
      });
    this.apiService.obtenerTecnicos()
      .subscribe(data => {
        this.tecnicos = data.response.map(tecnico => ({ id: tecnico.idTecnico.toString(), label: tecnico.nombre }))
      });
    this.apiService.obtenerCiudades()
      .subscribe(data => {
        this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));
        this.ciudadesAutocomplete = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
      });
    this.apiService.obtenerDelegacion()
      .subscribe(data => {
        this.delegaciones = data.response.map(delegacion => ({ id: delegacion.idDelegacion.toString(), label: delegacion.nombre }))
      });
    this.apiService.obtenerIdioma()
      .subscribe(data => {
        this.idiomas = data.response.map(idioma => ({ id: idioma.idIdioma.toString(), label: idioma.nombre }))
      });
    this.apiService.obtenerElementoPor()
      .subscribe(data => {
        this.elementosPor = data.response.map(elementoPor => ({ id: elementoPor.idElementoPor.toString(), label: elementoPor.nombre }))
      });
    this.apiService.obtenerValidezOferta()
      .subscribe(data => {
        this.listaValidezOferta = data.response.map(validez => ({ id: validez.nValidez.toString(), label: validez.validez }));
      });
    this.apiService.obtenerCondicionesDePago()
      .subscribe(data => {
        this.listaCondicionesPago = data.response.map(condicion => ({ id: condicion.nCondicion.toString(), label: condicion.condicion }));
      });

  }


  public volverATabla() {
    this.router.navigate(['/ofertas'], { queryParams: { ...this.paramsUrl, ofertaResaltada: this.dataOferta.nOferta } });
  }

  public openOfertasUnCliente() {
    this.apiService.obtenerClientesUnaOferta(this.dataOferta.nOferta).subscribe(dades => {
      this.clientes = dades.response;
      console.log('clientes: ', this.clientes);
      this.pantalla = "clientes"
    });
  }

  public openKitsUnCliente() {
    this.apiService.obtenerKitsUnaComanda(this.dataOferta.nOferta).subscribe(dades => {
      this.kits = dades.response;
      this.pantalla = "kits"
    });

  }
  public openOtrosUnCliente() {
    this.apiService.obtenerOtrosUnaComanda(this.dataOferta.nOferta).subscribe(dades => {
      this.especiales = dades.response;
      this.pantalla = "otros"
      this.apiService.obtenerNoKitsNoOpcUnaComanda(this.dataOferta.nOferta).subscribe(datos => {
        this.otros = datos.response;
      });
    });
  }

  public clickEnVolverAPantalla() {
    if (this.ofertaActual) {
      //console.log("111110");
      let ofertaABuscar = {
        selectednOferta: this.ofertaActual
      }
      //console.log("22222");
      this.apiService.obtenerOfertas(ofertaABuscar).subscribe(data => {
        this.dataOferta = data.response[0];
        this.pantalla = "datosUnaOferta";
      })
      //console.log("33333");
      //    this.router.navigate(["oferta", this.ofertaActual]);
    }
    //console.log("44444");
    //   this.pantalla = "datosUnaOferta";
  }

  public obtenerDatosCiudad(idCiudad: string) {
    var body = this.ciudades.find(ciudad => ciudad.idCiudad === idCiudad) || {};
    if (!idCiudad) {
      body.nombreProvincia = "";
      body.nombrePais = "";
    }
    return body;
  }

  public cuandoClientesCambien() {
    this.openOfertasUnCliente();
  }

  public cuandoKitsCambien() {
    this.openKitsUnCliente();
  }

  public cuandoOtrosCambien() {
    this.openOtrosUnCliente();
  }

  public guardarOferta(formulario: NgForm) {
    //console.log("66666");
    if (!formulario.valid && !this.fechaCierreForm) { return };
    this.dataOferta.fechaCierre = DateToString(this.fechaCierreForm);
    this.dataOferta.fechaOferta = DateToString(this.dataOferta.fechaOferta);
    if (this.dataOferta.nOferta) {
      // Guardar en base de dadtos
      this.apiService.modificarComanda(this.dataOferta).subscribe(dades => {
        //  this.volverATabla();
      });
    } else {
      //asignar nOferta y  guardar en base de datos
      //console.log("HHHHOOOOLLLLAAA");
      this.apiService.anadirComanda(this.dataOferta).subscribe(dades => {
        this.ofertaActual = dades.response;
        this.clickEnVolverAPantalla();
        this.authService.arrayOfertas = [];

        //  this.volverATabla();
      });
    }
    this.dataOferta.fechaOferta = StringToDate(this.dataOferta.fechaOferta);
    //console.log("77777");
    // Ir a oferta con id 
    //  this.router.navigate(["oferta", this.ofertaActual]);
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    //  mantenimiento2Ciudad(this.dialog);
    let dialogRef: any;
    switch (label) {
      case 'ciudad':
        dialogRef = mantenimiento2Ciudad(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerCiudades().subscribe(data => {
            this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));
          });
        });
        break;
      case 'validezOferta':
        dialogRef = mantenimiento1ValidezOferta(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerValidezOferta()
            .subscribe(data => {
              this.listaValidezOferta = data.response.map(validez => ({ id: validez.nValidez.toString(), label: validez.validez }));
            });
        });
        break;
      case 'comercial':
        dialogRef = mantenimiento3Comercial(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerComerciales()
            .subscribe(data => {
              this.comerciales = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }))
            });
        });
        break;
      case 'delegacion':
        dialogRef = mantenimiento3Delegacion(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerDelegacion()
            .subscribe(data => {
              this.delegaciones = data.response.map(delegacion => ({ id: delegacion.idDelegacion.toString(), label: delegacion.nombre }))
            });
        });
        break;
      case 'elementosPor':
        dialogRef = mantenimiento1TipoSoporte(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerElementoPor()
            .subscribe(data => {
              this.elementosPor = data.response.map(elementoPor => ({ id: elementoPor.idElementoPor.toString(), label: elementoPor.nombre }))
            });
        });
        break;
      case 'tecnico':
        if (localStorage.getItem('objectDatosTecnico')) {
          localStorage.removeItem('objectDatosTecnico')
        }
        localStorage.setItem('objectDatosTecnico', JSON.stringify(this.dataOferta));
        this.router.navigate(['tecnicos'], { queryParams: { redirecTo: 'oferta' } });
        break;
      case 'idioma':
        dialogRef = mantenimiento1Idioma(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerIdioma()
            .subscribe(data => {
              this.idiomas = data.response.map(idioma => ({ id: idioma.idIdioma.toString(), label: idioma.nombre }))
            });
        });
        break;
      case 'condicionesPago':
        dialogRef = mantenimiento1CondicionesPago(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerCondicionesDePago()
            .subscribe(data => {
              this.listaCondicionesPago = data.response.map(condicion => ({ id: condicion.nCondicion.toString(), label: condicion.condicion }));
            });
        });
        break;
    }

  }
}
