import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CompraGenerada } from '../../../types/compras';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-lineas-factura',
  templateUrl: './lineas-factura.component.html',
  styleUrls: ['./lineas-factura.component.css']
})

export class LineasFacturaComponent implements OnInit {

  public listaLineasFactura: any[] = [];
  public nFactura = "";
  private idFactura = "";
  public nOferta = "";
  public idCliente = "";
  public nombreCliente = "";
  private paramsUrl: any;
  public lineaFacturaABorrar: any;
  //  public productoResaltado = null;
  public dataSource!: MatTableDataSource<CompraGenerada>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public lineaFactura: any = {
    nAlbaran: "",
    salida: "",
    importe: "",
    importeProductos: "",
    transporte: "",
  }

  displayedColumns: string[] = ['buttons', 'nAlbaran', 'salida', 'importe', 'importeProductos', 'transporte'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaLineasFactura);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      this.nFactura = params.nFactura;
      this.idFactura = params.idFactura;
      this.nOferta = params.nOferta;
      this.idCliente = params.idCliente;
      this.nombreCliente = params.nombreCliente;
    })
    this.obtenerListaLineasAFacturar();
  }

  ngAfterViewInit(): void {
    this.obtenerListaLineasAFacturar() 
   }

  public muestraUnaLineaFactura(element: any) {
    this.lineaFactura = { ...element };
  }

  public anadirAlbaran() {
    let paramsUrl = {
      nFactura: this.paramsUrl.nFactura,
      idFactura: this.paramsUrl.idFactura,
      nOferta: this.paramsUrl.nOferta,
      idCliente: this.paramsUrl.idCliente,
      nombreCliente: this.paramsUrl.nombreCliente
    }

    this.router.navigate(['albaranes-pendientes-facturar'], { queryParams: paramsUrl });
  }


  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if (result) {
        this.apiService.eliminarFacturaLn(tipo).subscribe(dades => {
          console.log("albaran eliminado de pendientes de facturar: ", dades);
          this.obtenerListaLineasAFacturar();
        })
      }
    });
  }


  public eliminarAlbaranDeFactura(albaran: any) {
    let albaranAEliminar = {
      nAlbaran: albaran.nAlbaran ,
      idFactura: albaran.idFactura,
      tipo: albaran.salida
    }
    this.openDialog("ELIMINAR", "¿Quiere eliminar el albaran " + albaran.nAlbaran + "?", true, albaranAEliminar);
  }

  public volverAFacturas() {
    this.router.navigate(['facturas'], { queryParams: { facturaResaltada: this.nFactura } });
  }

  private obtenerListaLineasAFacturar() {
    this.apiService.obtenerFacturaLn(this.idFactura).subscribe(datos => {
      console.log("Lineas factura: ", datos.response);
      this.listaLineasFactura = datos.response;
      this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaLineasFactura);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

}
