import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pie-cabecera',
  templateUrl: './pie-cabecera.component.html',
  styleUrls: ['./pie-cabecera.component.css']
})
export class PieCabeceraComponent implements OnInit {

  @Input() fechaFactura: any = "";
  @Input() formaPago: any = "";
  @Input() nCuenta: any = "";
  @Input() idIdioma = 2;

  public datosFactura = {
    cuenta1: "",
    cuenta2: "",
    cuenta3: "",
    cuenta4: "",
    cuenta5: "",

  }

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.obtenerCuenta().subscribe(data => {
      let listacuentas = data.response;
      for (let i = 0; i < listacuentas.length; i++) {
        if (i === 0){this.datosFactura.cuenta1 = listacuentas[i].cuenta}
        if (i === 1){this.datosFactura.cuenta2 = listacuentas[i].cuenta}
        if (i === 2){this.datosFactura.cuenta3 = listacuentas[i].cuenta}
        if (i === 3){this.datosFactura.cuenta4 = listacuentas[i].cuenta}
        if (i === 4){this.datosFactura.cuenta5 = listacuentas[i].cuenta}
      }
    })

  }

}
