import { Component, OnInit } from '@angular/core';
import { FiltroOfertas } from '../../../components/filtros/filtro-ofertas/FiltroOfertas';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth-service';
import { Oferta } from '../../../types/ofertas';
import { ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Router } from "@angular/router";
import { pick } from 'lodash';
import { TituloService } from 'src/app/services/titulo.service';

const listadoDeFiltrosDisponibles = [
  "selectedFechaDesde",
  "selectedFechaHasta",
  "selectedComercial",
  "selectedTecnico",
  "selectedCliente",
  "selectedProvincia",
  "selectedCiudad",
  "selectedDireccionObra",
  "selectednOferta",
  "selectedChecked",
  "selectedCerrado",
  "selectedColaboracion"
]

@Component({
  selector: 'app-lista-ofertas',
  templateUrl: './lista-ofertas.component.html',
  styleUrls: ['./lista-ofertas.component.css']
})
export class ListaOfertasComponent implements OnInit {

  public verFiltro = true;
  // public verSidenav = false;
  public hayDatosEnTabla = true;
  public dataOferta: any = {};
  public ofertas: Oferta[] = [];

  public ultimosFiltrosUsados: FiltroOfertas = {
    selectedFechaDesde: undefined,
    selectedFechaHasta: undefined,
    selectedComercial: undefined,
    selectedTecnico: undefined,
    selectedCliente: undefined,
    selectedProvincia: undefined,
    selectedCiudad: undefined,
    selectedDireccionObra: undefined,
    selectednOferta: undefined,
    selectedChecked: false,
    selectedCerrado: undefined,
    selectedColaboracion: undefined
  };

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tituloService: TituloService
  ) { }



  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    //  this.obtenerOfertas(this.ultimosFiltrosUsados);
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.action) {
        this.ultimosFiltrosUsados = {
          ...pick(params, listadoDeFiltrosDisponibles),
          ...(params.selectedChecked ? { selectedChecked: params.selectedChecked === 'true' } : {})
        };
        this.ultimosFiltrosUsados.selectedChecked = true;
        this.ultimosFiltrosUsados.selectedCerrado = "NO";
        this.obtenerOfertas(this.ultimosFiltrosUsados);
      }
      else {
        this.ultimosFiltrosUsados = {
          ...pick(params, listadoDeFiltrosDisponibles),
          ...(params.selectedChecked ? { selectedChecked: params.selectedChecked === 'true' } : {})
        };
        if (Object.keys(this.ultimosFiltrosUsados).length) {
          this.obtenerOfertas(this.ultimosFiltrosUsados)
        }
        else {
          this.obtenerOfertas(this.ultimosFiltrosUsados);
          this.controlLocalStorage();
        }
        //      if(this.activatedRoute.snapshot.queryParamMap.get('action') === "albaranes-salida" || "albaranes-entrada") {
        //        this.ultimosFiltrosUsados.selectedChecked = true;
        //        console.log("PASO 2");
        //        this.obtenerOfertas(this.ultimosFiltrosUsados);
        //  this.ofertas = [];
        //      }

        if (this.activatedRoute.snapshot.queryParamMap.get('ofertaResaltada')) {
          this.controlLocalStorage();
        }
      }
    });
  }

  clickEnVolverAFiltros() {
    //TODO check
    this.verFiltro = true;
    //this.ofertaAsignada = false;
    this.ofertas = [];
    this.hayDatosEnTabla = false;
  }

  refrescarOfertas() {
    this.obtenerOfertas(this.ultimosFiltrosUsados);
  }

  public obtenerOfertas(filtros: FiltroOfertas) {
    this.ultimosFiltrosUsados = filtros;
    this.apiService.obtenerOfertas(filtros).subscribe(dades => {
      this.ofertas = dades.response;
      console.log("ofertas:", this.ofertas);
      if (this.ofertas.length > 0) {
        this.hayDatosEnTabla = true; // TODO: es reduncd
      } else {
        this.hayDatosEnTabla = false;
      }
      //      this.verFiltro = false
    });
  }

  public enviaDataASidenav(data: any) {
    this.dataOferta = data;
    //    this.router.navigate(["ofertas"], { queryParams: { ...this.activatedRoute.snapshot.queryParams, ofertaResaltada: this.dataOferta.nOferta } });
    //    setTimeout(() => {
    //      this.router.navigate(["oferta", this.dataOferta.nOferta]);
    //    })
    this.router.navigate(["oferta", this.dataOferta.nOferta], { queryParams: this.activatedRoute.snapshot.queryParams });
  }

  private guardarOfertasEnLocalStorage(ofertas: any) {
    this.authService.arrayOfertas = ofertas;
  }

  private controlLocalStorage() {
    this.ofertas = this.authService.arrayOfertas;
  }

}
