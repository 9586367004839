import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { data } from 'jquery';
import { TituloService } from 'src/app/services/titulo.service';


@Component({
  selector: 'app-report-albaran-salida',
  templateUrl: './report-albaran-salida.component.html',
  styleUrls: ['./report-albaran-salida.component.css']
})
export class ReportAlbaranSalidaComponent implements OnInit {

  private paramsUrl: any = '';
  public letra = "A";
  public paginas: any[] = [];
  public nPaginas: number = 0;
  public pesoTotal = 0;
  public noCabeTexto = false;
  public tipoAlbaran = "";

  public direccionDelegacion = "";
  public cpDelegacion = "";
  public poblacionDelegacion = "";
  public provinciaDelegacion = "";

  public nOferta: any = "";
  public nAlbaran: any = "";

  public fechaAlbaran = "";
  public direccionEntrega = "";
  public ciudadEntrega = "";
  public provinciaEntrega = "";
  public cpEntrega = "";
  public encargadoObra = "";
  public telefonoEncargado = "";
  public notasAlbaran = "";
  public transportista = "";
  public nCliente = "";

  listaKits = [
    {
      nombreKit: "MVV12 1000 ESP 3",
      cantidadKit: 12,
      imagen: "FIG 1 MVV.jpg"
    },
    {
      nombreKit: "MVV12 2000",
      cantidadKit: 24,
      imagen: "FIG 1 MVH.jpg"
    },
    {
      nombreKit: "MVH12 3000",
      cantidadKit: 10,
      imagen: "FIG 8 MVH.jpg"
    },
    {
      nombreKit: "MVV12 1000 ESP 3",
      cantidadKit: 12,
      imagen: "FIG 1 MVV.jpg"
    },
    {
      nombreKit: "MVV12 2000",
      cantidadKit: 24,
      imagen: "FIG 1 MVH.jpg"
    },
    {
      nombreKit: "MVH12 3000",
      cantidadKit: 10,
      imagen: "FIG 8 MVH.jpg"
    }, {
      nombreKit: "MVV12 1000 ESP 3",
      cantidadKit: 12,
      imagen: "FIG 1 MVV.jpg"
    },
    {
      nombreKit: "MVV12 2000",
      cantidadKit: 24,
      imagen: "FIG 8 MVH.jpg"
    },
    {
      nombreKit: "MVH12 3000",
      cantidadKit: 10,
      imagen: "FIG 1 MVH.jpg"
    }
  ]

  listaProductos = [
    {
      nombreProducto: "Mortero",
      cantidadProducto: 39,
      pesoProducto: 975,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero especial",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Resina de dos comp. epoxi sin estiramien",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero",
      cantidadProducto: 39,
      pesoProducto: 975,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero especial",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Resina de dos comp. epoxi sin estiramien",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero",
      cantidadProducto: 39,
      pesoProducto: 975,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero especial",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Resina de dos comp. epoxi sin estiramien",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero",
      cantidadProducto: 39,
      pesoProducto: 975,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Mortero especial",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "Resina de dos comp. epoxi sin estiramien",
      cantidadProducto: 10,
      pesoProducto: 2000,
      tipoProducto: "Especial"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "Cartela MVV12",
      cantidadProducto: 24,
      pesoProducto: 62.40,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
/*    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "Cartela MVV12",
      cantidadProducto: 24,
      pesoProducto: 62.40,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "Cartela MVV12",
      cantidadProducto: 24,
      pesoProducto: 62.40,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "Cartela MVV12",
      cantidadProducto: 24,
      pesoProducto: 62.40,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "CENTRAL MVV12 1750",
      cantidadProducto: 12,
      pesoProducto: 504,
      tipoProducto: "Hierro"
    },
    {
      nombreProducto: "Cartela MVV12",
      cantidadProducto: 24,
      pesoProducto: 62.40,
      tipoProducto: "Hierro"
    },*/
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
 /*   {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },
    {
      nombreProducto: "Bomba de inyección",
      cantidadProducto: 1,
      pesoProducto: 0,
      tipoProducto: "Otros"
    },*/
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
/*    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: 4.32,
      tipoProducto: "Tornillería"
    },
    {
      nombreProducto: "Arandelas M12",
      cantidadProducto: 432,
      pesoProducto: -40000.76,
      tipoProducto: "Tornillería"
    }*/
  ]

  public arrayImagenes: any[] = [];
  public paginasArrayImagenes: any[] = [];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
      this.nOferta = params.nOferta;
      this.nAlbaran = params.nAlbaran;
      this.tipoAlbaran = params.tipo;
      this.apiService.obtenerHeaderAlbaranSalida(this.nAlbaran, this.tipoAlbaran).subscribe(info => {
        this.tipoAlbaran = "1";
        info = info.response[0];
        console.log(info);
        
        let date = info.fechaAlbaran;
        let day = date.substr(date.length - 2, 2);
        let month = date.substr(5, 2);
        let year = date.substr(0, 4);
        this.fechaAlbaran = day + "-" + month + "-" + year;
        this.direccionEntrega = info.direccionAlbaran;
        this.ciudadEntrega = info.ciudadAlbaran;
        this.provinciaEntrega = info.provinciaEntrega;
        this.cpEntrega = info.cp;
        this.encargadoObra = info.encargadoObra;
        this.telefonoEncargado = info.telefonoEncargado;
        this.notasAlbaran = info.notasAlbaran;
        this.transportista = info.transportista;
        this.nCliente = info.nCliente;
        if (info.direccionDelegacion) {
          this.direccionDelegacion = info.direccionDelegacion;
        } else { this.direccionDelegacion = "Polígono Industrial Can Prunera, C/. del Garraf, nº 16"};
        if (info.cpDelegacion) {
          this.cpDelegacion = info.cpDelegacion;;
        } else { this.cpDelegacion = "08759"};
        if (info.poblacionDelegacion) {
          this.poblacionDelegacion = info.poblacionDelegacion;
        } else { this.poblacionDelegacion = "Vallirana"};
        if (info.provinciaDelegacion) {
          this.provinciaDelegacion = info.provinciaDelegacion;
        } else { this.provinciaDelegacion = "Barcelona"};
        if (params.tipo == 1) {
          this.apiService.obtenerKitsAlbaranPDF(this.nAlbaran).subscribe(data => {
            this.listaKits = data.response;
            this.apiService.obtenerProductosAlbaranPDF(this.nAlbaran).subscribe(data => {
             this.listaProductos = data.response;
              this.cuentaLineas();
            });
          });
        }
        else {
          this.apiService.obtenerKitsAlbaranPDFEntrada(this.nAlbaran).subscribe(data => {
            this.listaKits = data.response;
            this.apiService.obtenerProductosAlbaranPDFEntrada(this.nAlbaran).subscribe(data => {
              this.listaProductos = data.response;
              this.cuentaLineas();
            });
          });
        }
      })
    });
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('antesDePagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`albaran${this.nAlbaran}.pdf`);
      this.router.navigate(['albaranes-salida'], { queryParams: this.paramsUrl })
    })
  }

  private cuentaLineas() {
    let lineasPagina = 0;
    let pagina: any[] = [];
    let arrayKits: any[] = this.listaKits;
    let arrayProductos: any[] = this.listaProductos;
    let arrayKitsPagina: any[] = [];
    let arrayProductosPagina: any[] = [];
    let totalLineas = this.listaProductos.length;
    if (this.listaKits.length > totalLineas) {
      totalLineas = this.listaKits.length;
    }
    let cabeceraProducto = "";
    let imprimirCabeceraProducto = false;
    let imprimirTotal = false;
    let nLinea = 0;
    for (let i = 0; i < totalLineas; i++) {
      let lineaProducto = arrayProductos[0];
      if (arrayProductos.length > 0) {
        this.pesoTotal = this.pesoTotal + parseFloat(lineaProducto.pesoProducto);
        lineasPagina = lineasPagina + 1;
        if (cabeceraProducto !== lineaProducto.tipoProducto) {
          lineasPagina = lineasPagina + 3;
          imprimirCabeceraProducto = true;
          cabeceraProducto = lineaProducto.tipoProducto;
        } else { imprimirCabeceraProducto = false; }
        if (i + 1 === totalLineas) {
          imprimirTotal = true;
        } else if (arrayProductos.length < 2) {
          imprimirTotal = true;
        }
        else { imprimirTotal = false };
        let elementProducto = {
          ...lineaProducto,
          imprimirCabeceraProducto: imprimirCabeceraProducto,
          imprimirTotal: imprimirTotal,
          nLinea: lineasPagina
        }
        arrayProductosPagina.push(elementProducto);
      }
      if (arrayKits.length > 0) {
        if (i + 1 === totalLineas) {
          imprimirTotal = true;
        } else { imprimirTotal = false };
        this.arrayImagenes.push(arrayKits[0]);
        let elementKit = {
          ...arrayKits[0],
          imprimirTotal: imprimirTotal,
          nLinea: lineasPagina
        }
        arrayKitsPagina.push(elementKit);
      }
      arrayKits.splice(0, 1);
      arrayProductos.splice(0, 1);
      if (lineasPagina > 27) {
        this.nPaginas = this.nPaginas + 1;
        let element = {
          arrayKitsPagina: arrayKitsPagina,
          arrayProductosPagina: arrayProductosPagina,
          imprimirTotal: imprimirTotal,
          nLineas: lineasPagina,
          nPagina: this.nPaginas
        }
        pagina.push(element);
        this.paginas.push(pagina);
        lineasPagina = 0;
        arrayKitsPagina = [];
        arrayProductosPagina = [];
        pagina = [];
      }
      if (i + 1 === totalLineas) {
        this.nPaginas = this.nPaginas + 1;
        let element = {
          arrayKitsPagina: arrayKitsPagina,
          arrayProductosPagina: arrayProductosPagina,
          imprimirTotal: imprimirTotal,
          nLineas: lineasPagina,
          nPagina: this.nPaginas
        }
        pagina.push(element);
        this.paginas.push(pagina);
        if (lineasPagina > 19) {
          this.noCabeTexto = true;
          this.nPaginas = this.nPaginas + 1;
        }
        else { this.noCabeTexto = false };
      }
    }
    console.log("PAGINAS: ", this.paginas);
    this.buscarImagenes();
  }

  private buscarImagenes() {
    let contadorImagenesReal = 0;
    let contadorTotalImagenes = 0;
    let pagina: any[] = [];
    this.arrayImagenes.forEach(data => {
    
      contadorTotalImagenes = contadorTotalImagenes + 1;
      
      if(data.imagen && data.cantidadKit > 0) {
        pagina.push(data);
        contadorImagenesReal += 1;
      }
      console.log("paginaContador: ",pagina);
      if (contadorTotalImagenes === this.arrayImagenes.length && contadorImagenesReal > 0) {
        this.paginasArrayImagenes.push(pagina);
      }
      if (contadorImagenesReal === 4 && contadorTotalImagenes !== this.arrayImagenes.length) {
        this.paginasArrayImagenes.push(pagina);
        pagina = [];
        contadorImagenesReal = 0;
      }
    });
    console.log("Array Imagenes: ", this.paginasArrayImagenes);
  }


}
