<div class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">


        <div class="pagina">

            <div class="header">
                <app-cabecera-mecanoviga class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [letra]="letra" class="header-cliente"></app-cabecera-oferta>
            </div>

            <div class="linea-gruesa"></div>

            <div class="body">

                <div class="titulo">
                    <strong>CONTRATO DE ALQUILER DE LA BOMBA</strong>
                </div>

                <p>De acuerdo a lo solicitado por Uds. nos place presentarles el contrato de alquiler de una bomba</p>
                <p>mezcladora para el inyectado de mortero autonivelante.</p>  
                
                <br><br><br>

                <div class="resaltado">EL IMPORTE DE BOMBA DE INYECCIÓN INCLUYE:</div>

                <ul>
                    <li>El alquiler de una bomba.</li>
                    <li>Un operario encargado de su correcta puesta en marcha.</li>
                </ul>

                <br><br>

                <div class="resaltado">Trabajos a realizar por la empresa encargada de la colocación de los perfiles previa llegada</div> 
                <div class="resaltado">del operario de Mecanoviga:</div>

                <ul>
                    <li>El mortero autonivelante debe estar en el mismo lugar donde se inyectan los perfiles Mecanoviga.</li>
                    <li style="line-height: 30px">Sellado de todas las uniones de los kits con silicona y con precinto la base donde se encuentran los agujeros, con
                        el fin de no derramar el mortero autonivelante cuando se vierta.
                    </li>
                </ul>

                <br><br>

                <div class="resaltado">Otros requerimientos:</div>

                <ul>
                    <li style="line-height: 30px">La empresa encargada de la colocación debe disponer en obra en el momento del inyectado personal suficiente
                        para realizar los trabajos de pastar el mortero y ayudas al inyectado.</li>
                    <li>La zona de trabajo debe disponer de toma de agua y de corriente con un mínimo de 20 Amperios.</li>
                </ul>

                <br><br>

                <div class="resaltado">Si los operarios de MECANOVIGA no pudieran inyectar las vigas debido a que no están </div> 
                <div class="resaltado">debidamente montadas y selladas, se facturará un cargo extra de desplazamiento.</div>

                <p style="margin-top: 70px;">Fecha:</p>

                <p style="margin-top: 30px;">Recibido-Conforme el Cliente</p>
                <p>Firmado:</p>
            </div>


        </div>


    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>
