import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AlbaranEntrada } from 'src/app/types/stock';
import { pick } from 'lodash';
import { DateToString, StringToDate } from '../../../services/dataFunction';
import { MatDialog } from '@angular/material/dialog';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { DOCUMENT } from '@angular/common';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-albaranes-entrada',
  templateUrl: './albaranes-entrada.component.html',
  styleUrls: ['./albaranes-entrada.component.css']
})
export class AlbaranesEntradaComponent implements OnInit, AfterViewInit {

  public filtroAlbaranesEntrada: any = {
    tipoEntradaSelected: undefined,
    fechaInicioSelected: undefined,
    fechaFinSelected: undefined,
    clienteSelected: undefined,
    nAlbaranSelected: undefined,
  }

  public clientes: IdLabelObject[] = [];

  public albaranesEntrada: any[] = [];
  public crearAlbaranNuevo: boolean = false;
  public albaranResaltado = null;
  public albaranABorrar = null;
  public dataSource!: MatTableDataSource<AlbaranEntrada>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  public tiposEntrada = [
    { nombre: "Devolución Material de Clientes" },
    { nombre: "Entrada Material Acabado" },
  ]

  public albaranEntrada: any = {
    nAlbaran: "",
    fechaAlbaran: "",
    tipoEntrada: "",
    nCompra: "",
    refExterna: "",
    nFactura: "",
    tipo: "entrada"
  }

  displayedColumns: string[] = ['buttons', 'nAlbaran', 'fechaAlbaran', 'tipoEntrada', 'nCompra', 'refExterna', 'nFactura'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.albaranesEntrada);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetAlbaranEntrada();
    this.apiService.obtenerClientes().subscribe(data => {
      this.clientes = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
    });
    //    this.envioDatosFiltro();
    this.activatedRoute.queryParams.subscribe((params) => {

      if (params.nOfertaBuscada) {
        this.albaranEntrada = JSON.parse(localStorage.getItem('objectAlbaranEntrada') || '[]');
        this.filtroAlbaranesEntrada = JSON.parse(localStorage.getItem('objectFiltrosEntrada') || '[]');
        if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = StringToDate(this.filtroAlbaranesEntrada.fechaInicioSelected);
        if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = StringToDate(this.filtroAlbaranesEntrada.fechaFinSelected);
        this.albaranEntrada.nCompra = params.nOfertaBuscada;
        console.log("albaran Entrada: ", this.albaranEntrada);
        console.log("filtro: ", this.filtroAlbaranesEntrada);
        localStorage.removeItem('objectAlbaranEntrada');
        localStorage.removeItem('objectFiltrosEntrada');
        this.crearAlbaranNuevo = true;
        this.envioDatosFiltro();
        this.muestraUnAlbaran
      }

      if (params.nCompraBuscada) {
        this.albaranEntrada = JSON.parse(localStorage.getItem('objectAlbaranEntrada') || '[]');
        this.filtroAlbaranesEntrada = JSON.parse(localStorage.getItem('objectFiltrosEntrada') || '[]');
        if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = StringToDate(this.filtroAlbaranesEntrada.fechaInicioSelected);
        if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = StringToDate(this.filtroAlbaranesEntrada.fechaFinSelected);
        this.albaranEntrada.nCompra = params.nCompraBuscada;
        localStorage.removeItem('objectAlbaranEntrada');
        localStorage.removeItem('objectFiltrosEntrada');
        this.crearAlbaranNuevo = true;
        this.envioDatosFiltro();
        this.muestraUnAlbaran
      }

      if (params.nAlbaran) {
        this.albaranResaltado = params.nAlbaran;
        this.albaranEntrada.nAlbaran = params.nAlbaran;
        this.filtroAlbaranesEntrada = {
          ...pick(params, Object.keys(this.filtroAlbaranesEntrada)),
        };
        if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = StringToDate(this.filtroAlbaranesEntrada.fechaInicioSelected);
        if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = StringToDate(this.filtroAlbaranesEntrada.fechaFinSelected);
        console.log(this.filtroAlbaranesEntrada);
        this.envioDatosFiltro();
      } else {
        //      this.envioDatosFiltro();
      }

    });
  }

  ngAfterViewInit(): void {
    this.envioDatosFiltro();
    /*    this.dataSource = new MatTableDataSource<AlbaranEntrada>(this.albaranesEntrada);
        let page = this.buscarIndiceEnOfertasFiltradas(this.albaranResaltado) || 0;
        this.paginator.pageIndex = page;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
  }

  public envioDatosFiltro() {
    if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = DateToString(this.filtroAlbaranesEntrada.fechaInicioSelected);
    if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = DateToString(this.filtroAlbaranesEntrada.fechaFinSelected);
    console.log("envia filtro: ", this.filtroAlbaranesEntrada);
    this.apiService.obtenerAlbaranesEntrada(this.filtroAlbaranesEntrada).subscribe(dades => {
      this.albaranesEntrada = dades.response;
      this.dataSource = new MatTableDataSource<AlbaranEntrada>(this.albaranesEntrada);
      let page = this.buscarIndiceEnOfertasFiltradas(this.albaranResaltado) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = StringToDate(this.filtroAlbaranesEntrada.fechaInicioSelected);
      if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = StringToDate(this.filtroAlbaranesEntrada.fechaFinSelected);
      console.log("filtro actual: ", this.filtroAlbaranesEntrada);
      if (this.albaranResaltado) {
        const element = this.albaranesEntrada.find(element => element.nAlbaran == this.albaranResaltado);
        this.muestraUnAlbaran(element);
      }
    });
  }

  public guardarAlbaranEntrada(formulario: NgForm) {
    if (this.crearAlbaranNuevo) {
      this.apiService.anadirAlbaranEntrada(this.albaranEntrada).subscribe(data => {
        console.log("Guardo nuevo: ", data.response);
        this.crearAlbaranNuevo = false;
        this.albaranResaltado = data.response;
        this.envioDatosFiltro();
      });

    } else {
      console.log("Guardo viejo: ", this.albaranEntrada);
      this.apiService.modificarAlbaranEntrada(this.albaranEntrada).subscribe(data => {
        this.albaranResaltado = this.albaranEntrada.nAlbaran;
        this.envioDatosFiltro();
      });
    }
  }

  public dialogEliminarUnAlbaran(albaranEntrada: any) {
    this.albaranABorrar = albaranEntrada.nAlbaran;
    this.openDialog("ELIMINAR ALBARAN", "¿Quire eliminar el albarán " + albaranEntrada.nAlbaran + " de la lista de entradas " + "?", true, "eliminar")
  }

  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminar':
          if (result) this.eliminarUnAlbaran();
          break;
      }
    });
  }

  private eliminarUnAlbaran() {
    this.apiService.borrarAlbaranesEntrada(this.albaranABorrar).subscribe(dades => {
      this.resetAlbaranEntrada();
      this.envioDatosFiltro();
    });
  }

  public muestraUnAlbaran(element: any) {
    this.crearAlbaranNuevo = false;
    this.albaranEntrada = { ...element };
  }

  public esAlbaranEntradaNuevo() {
    this.crearAlbaranNuevo = true;
    this.resetAlbaranEntrada();
  }

  public irADetallesEntrada() {
    let tipo = 2
    if (this.albaranEntrada.tipoEntrada == "Entrada Material Acabado") tipo = 1;
    if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = DateToString(this.filtroAlbaranesEntrada.fechaInicioSelected);
    if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = DateToString(this.filtroAlbaranesEntrada.fechaFinSelected);
    this.router.navigate(['detalles-entrada'], { queryParams: { ...this.filtroAlbaranesEntrada, nAlbaran: this.albaranEntrada.nAlbaran, nCompra: this.albaranEntrada.nCompra, tipo: tipo } });
  }

  public buscarEntrada() {
    console.log("1 ", this.albaranEntrada);
    if (this.filtroAlbaranesEntrada.fechaInicioSelected) this.filtroAlbaranesEntrada.fechaInicioSelected = DateToString(this.filtroAlbaranesEntrada.fechaInicioSelected);
    if (this.filtroAlbaranesEntrada.fechaFinSelected) this.filtroAlbaranesEntrada.fechaFinSelected = DateToString(this.filtroAlbaranesEntrada.fechaFinSelected);
    const action = "albaranes-entrada"
    if (localStorage.getItem('objectAlbaranEntrada')) {
      localStorage.removeItem('objectAlbaranEntrada');
    }
    if (localStorage.getItem('objectFiltrosEntrada')) {
      localStorage.removeItem('objectFiltrosEntrada');
    }
    localStorage.setItem('objectAlbaranEntrada', JSON.stringify(this.albaranEntrada));
    localStorage.setItem('objectFiltrosEntrada', JSON.stringify(this.filtroAlbaranesEntrada));
    if (this.albaranEntrada.tipoEntrada === "Devolución Material de Clientes") {
      this.router.navigate(['ofertas'], { queryParams: { action } });
    } else {
      this.router.navigate(['ordenes-compra'], { queryParams: { action } });
    }
  }

  private buscarIndiceEnOfertasFiltradas(albaranResaltado: any) {
    console.log("albaran resaltado: ", albaranResaltado);
    console.log(this.albaranesEntrada);
    if (!albaranResaltado) return 0;
    let busqueda = albaranResaltado.toString();
    let indice: number;
    indice = this.albaranesEntrada.findIndex(albaran => albaran.nAlbaran.toString() === busqueda);
    console.log("El elemento buscado está en el índice ", indice);
    console.log("pagesize: ", this.paginator.pageSize);
    console.log(Math.trunc((indice - 1) / this.paginator.pageSize));
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }


  private resetAlbaranEntrada() {
    this.albaranEntrada = {
      nAlbaran: undefined,
      fechaAlbaran: undefined,
      tipoEntrada: undefined,
      nCompra: undefined,
      refExterna: undefined,
      nFactura: undefined,
    }
  }

  public resetFiltro() {
    this.filtroAlbaranesEntrada = {
      tipoEentradaSelected: undefined,
      fechaInicioSelected: undefined,
      fechaFinSelected: undefined,
      clienteSelected: undefined,
      nAlbaranSelected: undefined,
    }
    this.albaranResaltado = null;
    this.resetAlbaranEntrada();
  }
  public imprimirAlbaran() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-albaran?nOferta=' + this.albaranEntrada.nCompra + '&nAlbaran=' + this.albaranEntrada.nAlbaran + '&tipo=' + 0;
    link.click();
    link.remove();
  }


}
