<div class="section-content">
    <p class="section-title">Medida:</p>
    
    @for (medida of this.fabricacionService.medidasDeProducto; track $index) {
    <button mat-raised-button class="section-button"
        [ngClass]="{'selected': medida.IdMidaProducte === this.fabricacionService.medidaProductoSeleccionada.IdMidaProducte}"
        (click)="onMedidaSelection(medida)" [disabled]="disabledButton()">
        {{medida.Mida}}
    </button>
    }
    <button mat-raised-button class="action-button" (click)="this.fabricacionService.volverATipos()"
        [disabled]="disableVolverAtras()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Atrás
    </button>
</div>