import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateToString } from 'src/app/services/dataFunction';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-seleccion-facturas',
  templateUrl: './seleccion-facturas.component.html',
  styleUrls: ['./seleccion-facturas.component.css']
})
export class SeleccionFacturasComponent implements OnInit, AfterViewInit {

  public ofertaSeleccionable: any = {
      nOferta: "",
      idCliente: "",
      nombreCliente: "",
      anticipoPendiente: "",
      importeAlbaran: "",
      importeProductosOpcionales: "",
      transporte: "",
      albaranesPendientes: "",
      importeAbonos: "",
      abonosPendientes: "",
      tipo: "",
      nAlbaran: ""
  }

  public listaOfertasSeleccionables: any[] = [];
  public dataSource!: MatTableDataSource<any>;
  public hayDatosEnTabla: boolean = true;
  private paramsUrl: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['buttons', 'nOferta', 'nombreCliente', 'anticipoPendiente', 'importeAlbaran', 'importeProductosOpcionales', 'transporte', 'albaranesPendientes', 'importeAbonos', 'abonosPendientes'];
//  displayedColumns: string[] = ['buttons', 'nOferta', 'nombreCliente', 'AvansamentPendent', 'importeAlbaran', 'importeProductosOpcionales', 'transporte', 'NumAlbarans', 'ImportAbonament', 'NumDevolucions'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaOfertasSeleccionables);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsUrl = params;
    })
    this.apiService.nuevaFactura().subscribe(data => {
      this.listaOfertasSeleccionables = data.response;
    })

  }

  ngAfterViewInit() {
    this.apiService.nuevaFactura().subscribe(data => {
      this.listaOfertasSeleccionables = data.response;
      this.dataSource = new MatTableDataSource<any>(this.listaOfertasSeleccionables);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

  }

  public enviaUnaOferta(oferta: any) {
    let datos = {
      nOferta: oferta.nOferta,
      nFactura: undefined,
      iva: 21,
      tipo: oferta.tipo,
      nAlbaran: oferta.nAlbaran
    }
    this.apiService.crearFactura(datos).subscribe(dades => {
       this.router.navigate(['facturas'], { queryParams: { nuevaFactura: dades.response } });
    })
  }

  public anadirVacia() {
       this.router.navigate(['facturas'], { queryParams: { nuevaFactura: 'vacia' } });
  }

  public volver() {
    this.router.navigate(['facturas'], { queryParams: this.paramsUrl });
  }



}
