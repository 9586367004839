import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { ApiService } from '../../services/api.service';
import { IdLabelObject } from '../../types/global';
import { Tecnico } from '../../types/ofertas';
import { mantenimiento2Ciudad, mantenimiento3Comercial } from "../../services/llamadasMantenimiento";
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-tecnicos',
  templateUrl: './tecnicos.component.html',
  styleUrls: ['./tecnicos.component.scss']
})
export class TecnicosComponent implements OnInit, AfterViewInit{

  public filtroTecnicos: any = {
    selectedTecnico: '',
    selectedTelefono: '',
    selectedNIF: '',
    selectedEmail: '',
    selectedCiudad: '',
    selectedProvincia: '',
    selectedComercial: ''
  }

  public tecnico: any = {
    idTecnico: null,
    nombreTecnico: undefined,
    direccion: null,
    telefono: undefined,
    movil: undefined,
    ciudad: undefined,
    nif: undefined,
    email: undefined,
    comercial: undefined,
    notas: undefined,
    esBorrable: undefined
}

  public listaTecnicos: any[] = [];
  public crearTecnicoNuevo: boolean = true;
  public tecnicoResaltado = null;
  public tecnicoABorrar: any;
  public ciudades: any[] = [];
  public ciudadesAutocomplete: IdLabelObject[] = [];
  public tecnicosAutocomplete: IdLabelObject[]= [];
  public comerciales: IdLabelObject[] = [];  
  public provincias: IdLabelObject[] = [];
  public dataSource!: MatTableDataSource<Tecnico>;
  public paramsUrl: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['buttons', 'tecnico', 'ciudad', 'email', 'movil'];

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaTecnicos);
   }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetTecnico();
//    this.envioDatosFiltro();
    this.activatedRoute.queryParams.subscribe(dades => {
      this.paramsUrl = dades;
    })
    this.apiService.obtenerCiudades().subscribe(data => {
        this.ciudades = data.response.map(ciudad => ({ ...ciudad, idCiudad: ciudad.idCiudad.toString() }));
        this.ciudadesAutocomplete = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });
    this.apiService.obtenerComerciales().subscribe(data => {
        this.comerciales = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }));
    });
    
    this.apiService.obtenerProvincias().subscribe(data => {
      this.provincias = data.response.map(provincias => ({ id: provincias.idProvincia.toString(), label: provincias.nombreProvincia }));
  });
    this.apiService.obtenerTecnicos().subscribe(data => {
      this.tecnicosAutocomplete = data.response.map(tecnico => ({ id: tecnico.idTecnico.toString(), label: tecnico.nombre }));
    })
  }

  ngAfterViewInit() {
    this.envioDatosFiltro();
/*    this.dataSource = new MatTableDataSource<Tecnico>(this.listaTecnicos);
    let page = this.buscarIndiceEnOfertasFiltradas(this.tecnicoResaltado) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    */
  }
/*
  ngOnChanges(): void {
    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<Tecnico>(this.listaTecnicos);
    let page = this.buscarIndiceEnOfertasFiltradas(this.tecnicoResaltado) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
*/
  envioDatosFiltro(){
    console.log("Filtro: ", this.filtroTecnicos);
    console.log("Ressaltado: ", this.tecnicoResaltado);
    this.apiService.obtenerTecnicoFiltrado(this.filtroTecnicos).subscribe(data =>{
      console.log(data.response);
      this.listaTecnicos = data.response;
      console.log("Resaltado: ", this.tecnicoResaltado);
      this.dataSource = new MatTableDataSource<Tecnico>(this.listaTecnicos);
      let page = this.buscarIndiceEnOfertasFiltradas(this.tecnicoResaltado) || 0;
      console.log("page:", page);
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  public guardarUnTecnico(formulario: NgForm) {
    console.log(this.crearTecnicoNuevo);
    if (!formulario.valid) { return };
    if (!this.crearTecnicoNuevo) {
      console.log("Guardar editado: ", this.tecnico);
      this.apiService.modificarTecnico(this.tecnico).subscribe(dades => { 
        if ( this.paramsUrl.redirecTo) {
          this.router.navigate(['oferta'], { queryParams: { ...this.paramsUrl, idTecnico: this.tecnico.idTecnico } });
        }
        this.envioDatosFiltro();
       });
    }
    else {
      console.log("Guardar nuevo: ", this.tecnico);
      //  asignar nCliente y  guardar en base de datos
      this.apiService.anadirTecnico(this.tecnico).subscribe(dades => {
        console.log("this.alta.tecnico: ", dades.response);
        this.tecnico.idTecnico = dades.response;
        if ( this.paramsUrl.redirecTo) {
          this.router.navigate(['oferta'], { queryParams: { ...this.paramsUrl, idTecnico: this.tecnico.idTecnico } });
        }
        this.crearTecnicoNuevo = false;
        this.tecnicoResaltado = dades.response;
        this.apiService.obtenerTecnicos().subscribe(data => {
          this.tecnicosAutocomplete = data.response.map(tecnico => ({ id: tecnico.idTecnico.toString(), label: tecnico.nombre }));
        })    
        this.envioDatosFiltro();
      });   
    }
  }

  public volverAOferta() {
    this.router.navigate(['oferta'], { queryParams: this.paramsUrl});
  }

  eliminarUnTecnico(){
    this.apiService.borrarTecnico(this.tecnico.idTecnico).subscribe(dades => {
      console.log("Eliminado el cliente: ", this.tecnico);
      this.crearTecnicoNuevo = true;
      this.resetTecnico()
      this.envioDatosFiltro();
    });
  }

  public dialogEliminarTecnico(tecnicoABorrar: any) {
    this.tecnicoABorrar = tecnicoABorrar;
    console.log("detalle a borrar: ", this.tecnicoABorrar);
    this.openDialog("ELIMINAR TECNICO", "¿Quiere eliminar el tecnico " + this.tecnicoABorrar.nombreTecnico + " de la lista?", true, "eliminarTecnico");
  }

  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminarTecnico':
          if (result) this.eliminarUnTecnico();
          break;
        default:
          break;
      }
    });
  }

  public esTecnicoNuevo() {
    this.resetTecnico();
    this.crearTecnicoNuevo = true;
  }
  
  muestraUnTecnico(element: any){
    this.tecnico = {... element};
    this.crearTecnicoNuevo = false;
  }
  
  public cuandoSeCliqueEnMantenimiento(label: string) {
    let dialogRef: any;
    switch (label) {
      case 'ciudad':
        dialogRef = mantenimiento2Ciudad(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerCiudades().subscribe(data => {
            this.ciudades = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
          });
        });
        break;
        case 'comercial':
          dialogRef = mantenimiento3Comercial(this.dialog);
          dialogRef.afterClosed().subscribe((data: any) => {
            this.apiService.obtenerComerciales().subscribe(data => {
              this.comerciales = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }));
          });
                });
          break;
    }
  }

  private buscarIndiceEnOfertasFiltradas(tecnicoResaltado: any) {
    console.log("Resaltado: ", this.tecnicoResaltado);
    if (!tecnicoResaltado) return 0;
    let busqueda = tecnicoResaltado.toString();
    let indice = this.listaTecnicos.findIndex(tecnico => tecnico.idTecnico.toString() === busqueda);
    console.log("indice: ", indice);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  resetTecnico(){
    this.tecnico = {
      idTecnico: null,
      nombreTecnico: undefined,
      direccion: null,
      telefono: undefined,
      movil: undefined,
      ciudad: undefined,
      nif: undefined,
      email: undefined,
      comercial: undefined,
      notas: undefined
      };
      this.crearTecnicoNuevo = true;
  }

  public resetFiltro() {
    this.filtroTecnicos = {
      selectedTecnico: '',
      selectedIdTecnico: '',
      selectedTelefono: '',
      selectedNIF: '',
      selectedEmail: '',
      selectedCiudad: '',
      selectedProvincia: '',
      selectedComercial: ''
      }
    this.tecnicoResaltado = null;
    this.resetTecnico();
  }


}
