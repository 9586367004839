<div class="content">

    <div class="linea-gruesa color-primary"></div>

    <div class="datos">

        <div class="fecha-forma">
            <div *ngIf="idIdioma != 1" class="fecha">Fecha Factura: {{fechaFactura | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma == 1" class="fecha">Data Factura: {{fechaFactura | date:'dd/MM/yyyy':'es'}}</div>
            <div *ngIf="idIdioma != 1" class="forma">Forma de Pago: {{formaPago}}</div>
            <div *ngIf="idIdioma == 1" class="forma">Forma de Pagament: {{formaPago}}</div>
        </div>


        <div *ngIf="!nCuenta" class="cuentas">
            <div *ngIf="idIdioma != 1" class="cabeceraCuentas">
                Cuentas:
            </div>
            <div *ngIf="idIdioma == 1" class="cabeceraCuentas">
                Comptes:
            </div>

            <div class="datosCuentas">
                <div>{{datosFactura.cuenta1}}</div>
                <div>{{datosFactura.cuenta2}}</div>
                <div>{{datosFactura.cuenta3}}</div>
                <div>{{datosFactura.cuenta4}}</div>
                <div>{{datosFactura.cuenta5}}</div>
            </div>
        </div>

        <div *ngIf="nCuenta" class="unaCuenta">
            <div *ngIf="idIdioma != 1" class="cabeceraCuentas">
                Cuenta:
            </div>
            <div *ngIf="idIdioma == 1" class="cabeceraCuentas">
                Compte:
            </div>
            <div class="datosCuentas">
                <div>{{nCuenta}}</div>
            </div>
        </div>


    </div>

    <div class="linea-delgada color-primary"></div>


</div>