<div class="cambiar flex-65-responsive">
    <div class="flex-20">
        <button class="button-cambiar" mat-button (click)="cambiarTipo()">Cambiar tipo</button>
    </div>
    <div class="flex-80">
        <h2 *ngIf="tipoProducto === 'otros'" class="h2titulo">Lista Productos No Kits - No Opcionales de la
            Oferta Nº
            {{oferta.nOferta}}</h2> <br>
        <h2 *ngIf="tipoProducto === 'especiales'" class="h2titulo">Lista Productos Opcionales de la Oferta
            Nº
            {{oferta.nOferta}}</h2> <br>
    </div>
</div>

<div class="container flex-row-wrap">

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" *ngIf="!idClienteAsignado" mat-icon-button type="button"
                        (click)="esOtroNuevoEnOferta()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <!--
                    <button  mat-icon-button type="button"
                        (click)="muestraOtroEnOferta(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    -->
                    <button *ngIf="!idClienteAsignado" mat-icon-button type="button"
                        (click)="dialogEliminarOtroEnOferta(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="producto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Productos-Opcionales </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Cantidad </th>
                <td mat-cell *matCellDef="let element" align="right" max-width="10px"> {{element.cantidad}} </td>
            </ng-container>

            <ng-container matColumnDef="unidadMedida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Unidad Medida </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.unidadMedida}} </td>
            </ng-container>

            <ng-container matColumnDef="precioUnitario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Precio Unitario </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.precioUnitario | number:'0.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="pesoUnitario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Peso Unitario </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.pesoUnitario | number:'0.2-2'}} </td>
            </ng-container>
            <!--
            <ng-container matColumnDef="descuento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Descuento </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.descuento | number:'0.2-2'}} </td>
            </ng-container>
-->
            <ng-container matColumnDef="actualizacionManual" class="center">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actualiz.Manual </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.actualizacionManual}} </td>
            </ng-container>

            <ng-container matColumnDef="situacion" class="center">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Situación </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.situacion}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraOtroEnOferta(row)"
                [ngClass]="{'row-highlighted': otroUnaOferta.idProducto == row.idProducto && otroUnaOferta.idSituacion == row.idSituacion}">
            </tr>
        </table>
        <mat-paginator *ngIf="otrosUnaOferta.length>0" [pageSizeOptions]="[20]" showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="tipoProducto === 'otros' && otrosUnaOferta.length == 0">No hay Productos
        </div>
        <div class="noHayNadaEnTabla" *ngIf="tipoProducto === 'especiales' && especialesUnaOferta.length == 0">No hay
            Productos</div>

    </div>

    <form (ngSubmit)="guardarOtro()" class="form-derecho flex-35-responsive row-gap-15 pt-10">
        <app-autocomplete *ngIf="tipoProducto === 'especiales'" [disabled]="!!idClienteAsignado || !esOtroNuevo"
            class="autocomplete flex-100" name="producto" ngDefaultControl
            [idOpcionSeleccionada]="otroUnaOferta.idProducto"
            (cuandoSeSeleccioneOpcion)="otroUnaOferta.idProducto = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('productoEspecial')" label="Producto"
            [options]="productosEspecialesAutocomplete">
        </app-autocomplete>
        <app-autocomplete *ngIf="tipoProducto === 'otros'" [disabled]="!!idClienteAsignado || !esOtroNuevo"
            class="autocomplete flex-100" name="producto" ngDefaultControl
            [idOpcionSeleccionada]="otroUnaOferta.idProducto"
            (cuandoSeSeleccioneOpcion)="otroUnaOferta.idProducto = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('productoOtro')" label="Producto"
            [options]="productosOtrosAutocomplete">
        </app-autocomplete>
        <app-autocomplete [disabled]="!!idClienteAsignado" class="autocomplete flex-100" name="situacion"
            ngDefaultControl [idOpcionSeleccionada]="otroUnaOferta.idSituacion"
            (cuandoSeSeleccioneOpcion)="otroUnaOferta.idSituacion = $event" icon="settings"
            (cuandoSeCliqueEnIcono)="openDialogSituaciones()" label="Situación" [options]="situaciones">
        </app-autocomplete>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Cantidad</mat-label>
            <input matInput [disabled]="!!idClienteAsignado" type="number" [(ngModel)]="otroUnaOferta.cantidad"
                name="cantidad">
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-47-responsive">
            <mat-label>Unidad Medida</mat-label>
            <input matInput disabled type="text"
                [value]="obtenerDatosProducto(otroUnaOferta.idProducto).unidadMedida || ''">
        </mat-form-field>

        <mat-form-field *ngIf="obtenerDatosProducto(otroUnaOferta.idProducto).varios == 1 && esOtroNuevo === true"
            appearance="outline" class="flex-47-responsive">
            <mat-label>Precio Unitario</mat-label>
            <input matInput [disabled]="obtenerDatosProducto(otroUnaOferta.idProducto).varios !== 1" type="number"
                [(ngModel)]="obtenerDatosProducto(otroUnaOferta.idProducto).precioUnitario" name="precioUnitario">
        </mat-form-field>
        <mat-form-field *ngIf="obtenerDatosProducto(otroUnaOferta.idProducto).varios == 1 && esOtroNuevo === false"
            appearance="outline" class="flex-47-responsive">
            <mat-label>Precio Unitario</mat-label>
            <input matInput [disabled]="obtenerDatosProducto(otroUnaOferta.idProducto).varios !== 1" type="number"
                [(ngModel)]="otroUnaOferta.precioUnitario" name="precioUnitario">
        </mat-form-field>

        <mat-form-field *ngIf="obtenerDatosProducto(otroUnaOferta.idProducto).varios == 0" appearance="outline"
            class="flex-47-responsive">
            <mat-label>Precio Unitario</mat-label>
            <input matInput [disabled]="obtenerDatosProducto(otroUnaOferta.idProducto).varios !== 1" type="number"
                [(ngModel)]="obtenerDatosProducto(otroUnaOferta.idProducto).precioUnitario" name="precioUnitario">
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-47-responsive mb-10">
            <mat-label>Peso Unitario</mat-label>
            <input matInput disabled type="number"
                [value]="obtenerDatosProducto(otroUnaOferta.idProducto).pesoUnitario">
        </mat-form-field>
        <!--
        <mat-form-field appearance="outline" class="flex-50-responsive">
            <mat-label>Descuento</mat-label>
            <input matInput [disabled]="!!idClienteAsignado" type="number" min="0" [(ngModel)]="otroUnaOferta.descuento"
                name="descuento">
        </mat-form-field>
        -->

        <mat-checkbox class="actualizacionManual flex-51-responsive" disabled
            [(ngModel)]="otroUnaOferta.actualizacionManual" name="imprimirCalculos">Actualización Manual</mat-checkbox>

        <div class="flex-row-wrap flex-100">
            <span class="flex-100 mt-10">
                <button *ngIf="tipoProducto !== 'otros'" [disabled]="!!idClienteAsignado" mat-raised-button
                    type="button" (click)="dialogCrearProductosOpcionales()">Crear Productos Opcionales ... </button>
            </span>
            <span class="flex-50 mt-10">
                <button
                    [disabled]="!!idClienteAsignado || !otroUnaOferta.idProducto || !otroUnaOferta.idSituacion || !otroUnaOferta.cantidad"
                    mat-raised-button type="submit">Guardar</button>
            </span>
            <span class="flex-50 mt-10">
                <button mat-raised-button type="button" (click)="volverATablaUnaOferta()">Volver</button>
            </span>
        </div>

    </form>

</div>