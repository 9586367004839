<div class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina" *ngFor="let pagina of paginasArrayItems">

            <div class="titulo">
                INFORME MATERIAL VENDIDO AÑO ({{nombreProducto}}) {{anio}}
            </div>

            <div class="totales" *ngIf="pagina.nPagina == 1">
                <p style="margin-left: 30px;" class="text-center flex-36" scope="row">TOTAL KILOS MES</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes1 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes2 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes3 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes4 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes5 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes6 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes7 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes8 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes9 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes10 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes11 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-5" scope="row">{{totalMes12 | number: '1.0-0'}}</p>
                <p style="text-align: center;" class="text-rigth flex-6" scope="row">
                    {{totalMeses | number: '1.0-0'}}</p>
            </div> 
                   
            <table style="font-weight: normal;" border="1">
                <thead>
                    <th style="font-weight: normal;" class="flex-34" scope="row"></th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">ENERO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">FEBRERO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">MARZO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">ABRIL</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">MAYO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">JUNIO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">JULIO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">AGOSTO</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">SEPTIEM.</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">OCTUBRE</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">NOVIEM.</th>
                    <th style="font-weight: normal;" class="flex-5" scope="row">DICIEM.</th>
                    <th style="font-weight: normal;" class="flex-6" scope="row">T O T A L</th>
                </thead>
                <tbody>
                    <tr class="celda" *ngFor="let producto of pagina.datos; let i = index">
                        <th style="font-weight: normal;" class="text-center flex-34" scope="row">{{producto.desc}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c1 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c2 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c3 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c4 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c5 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c6 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c7 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c8 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c9 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c10 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c11 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-5" scope="row">{{producto.c12 | number: '1.0-0'}}</th>
                        <th style="font-weight: normal;" class="text-rigth flex-6" scope="row">
                            {{producto.c1 +producto.c2+producto.c3+producto.c4+producto.c5+producto.c6+producto.c7+producto.c8+producto.c9+producto.c10+producto.c11+producto.c12
                            | number: '1.0-0'}}</th>
                    </tr>
                </tbody>

            </table>

            <div class="fecha">
                Página {{pagina.nPagina}} de {{paginasArrayItems.length}}
            </div>
        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>