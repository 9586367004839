<div class="section-content">
    @if (this.tipoViga) {
    <p class="section-title">Longitud:</p>
    <div class="cantidades mb-20">
        <button mat-raised-button class="section-button unit-button" (click)="anteriorLongitud()"
            [disabled]="!this.fabricacionService.hayModeloSeleccionado">
            <mat-icon aria-hidden="false" aria-label="Prev button" fontIcon="remove" class="icon"></mat-icon>
        </button>

        <span
            [ngClass]="{'opacity-50': !this.fabricacionService.hayModeloSeleccionado}">{{this.fabricacionService.longitudProductoSeleccionada}}</span>

        <button mat-raised-button class="section-button unit-button" (click)="siguienteLongitud()"
            [disabled]="!this.fabricacionService.hayModeloSeleccionado">
            <mat-icon aria-hidden="false" aria-label="Next button" fontIcon="add" class="icon"></mat-icon>
        </button>
    </div>
    }


    <p class="section-title">Unidades:</p>

    <div class="cantidades">
        <button mat-raised-button id="restaUnidades" class="section-button unit-button" (click)="decrementarUnidades()"
            [disabled]="disableDecrementar()">
            <mat-icon aria-hidden="false" aria-label="Prev button" fontIcon="remove" class="icon"></mat-icon>
        </button>

        <span [ngClass]="{'opacity-50': disableUnidades()}">{{this.fabricacionService.unidades}}</span>

        <button mat-raised-button id="sumaUnidades" class="section-button unit-button" (click)="incrementarUnidades()"
            [disabled]="disableIncrementar()">
            <mat-icon aria-hidden="false" aria-label="Next button" fontIcon="add" class="icon"></mat-icon>
        </button>
    </div>

    <div class="check-container">
        <label id="example-radio-group-label" for="example-radio-group" class="section-title">Sumar unidades:</label>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="cantidadAIncrementarUnidades">
            <mat-radio-button class="example-radio-button" [value]="1"
                [disabled]="disablelCheck1()">+1</mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="10"
                [disabled]="disablelCheck10()">+10</mat-radio-button>
        </mat-radio-group>
    </div>

    <button mat-raised-button class="action-button" (click)="handleVolverAtras()" [disabled]="disableActionButton()">
        <mat-icon aria-hidden="false" aria-label="Back button" fontIcon="arrow_back" class="back-icon"></mat-icon>
        Atrás
    </button>

    <button mat-raised-button class="action-button check" [disabled]="disableOkButton()"
        (click)="this.fabricacionService.anadirProducto()">
        <mat-icon aria-hidden="false" aria-label="Confirm button" fontIcon="check" class="icon"></mat-icon>
        Agregar
    </button>
</div>