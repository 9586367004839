<form class="container flex-row-wrap" #OrdenesElaboracionComponent>

    <!-- Agregar criterios de filtrado -->
    <!-- <div class="layout-filtro flex flex-100">
    </div> -->

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 standar-table-mecanoviga"
            aria-label="Ordenes de elaboración">

            <ng-container matColumnDef="Buttons">
                <th mat-header-cell *matHeaderCellDef style="width: 15px;">
                    <button class="button-create" mat-button type="button" disableRipple (click)="createOrden()">
                        <mat-icon>add</mat-icon>
                    </button>
                </th>

                <td mat-cell *matCellDef="let orden" style="width: 15px;">
                </td>
            </ng-container>

            <!-- Column definitions -->
            <ng-container matColumnDef="DataElaboracio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 180px;"> Fecha </th>
                <td mat-cell *matCellDef="let orden"> {{orden.DataElaboracio| date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="DescProducte">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Chapa </th>
                <td mat-cell *matCellDef="let orden"> {{orden.DescProducte}} </td>
            </ng-container>

            <ng-container matColumnDef="Cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                <td mat-cell *matCellDef="let orden"> {{orden.cantidad}} </td>
            </ng-container>

            <!-- Row definitions -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seleccionarOrden(row)"
                [ngClass]="resaltarSeleccion(row.IdOrdreElaboracio, ordenElaboracion.IdOrdreElaboracio)"></tr>

        </table>

        @if (listaOrdenesElaboracion.length === 0) {
        <div class="noHayNadaEnTabla">No hay órdenes de elaboración</div>
        }

        <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons
            [ngClass]="{'hidden':true, 'no-hidden': listaOrdenesElaboracion.length > 0}"
            aria-label="Seleccionar página órdenes de elaboración">
        </mat-paginator>

    </div>

    <form #formulario="ngForm" class="form-derecho flex-35-responsive row-gap-15">

        <mat-form-field appearance="outline" class="flex-70-responsive">
            <mat-label>
                Fecha Orden
            </mat-label>
            <input matInput [matDatepicker]="picker" name="fechaOrden" [ngModel]="ordenElaboracion.DataElaboracio"
                disabled>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-100" appearance="outline">
            <mat-label> Chapa </mat-label>
            <input matInput name="chapa" type="text" [ngModel]="ordenElaboracion.DescProducte" disabled>
        </mat-form-field>

        <mat-form-field class="flex-70" appearance="outline">
            <mat-label> Cantidad </mat-label>
            <input matInput name="cantidad" type="text" [ngModel]="ordenElaboracion.cantidad" disabled>
        </mat-form-field>

        <span class="flex-48-responsive mt-10">
            <button [disabled]="!hayOrdenSeleccionada" type="button" mat-raised-button
                (click)="irADetallesOrden()">Detalles</button>
        </span>

        <span class="flex-48-responsive mt-10">
            <button class="button__buscar" type="button" mat-raised-button [disabled]="!hayOrdenSeleccionada"
                (click)="limpiarOrden()">Limpiar</button>
        </span>
    </form>

</form>