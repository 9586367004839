import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SeccionMedidaComponent } from 'src/app/components/fabricacion/seccion-medida/seccion-medida.component';
import { SeccionTipoComponent } from 'src/app/components/fabricacion/seccion-tipo/seccion-tipo.component';
import { SeccionModeloComponent } from '../../../components/fabricacion/seccion-modelo/seccion-modelo.component';
import { SeccionUnidadesComponent } from '../../../components/fabricacion/seccion-unidades/seccion-unidades.component';
import { SeccionOrigenComponent } from 'src/app/components/fabricacion/seccion-origen/seccion-origen.component';
import { FabricacionService } from 'src/app/services/fabricacion.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-generar-orden-fabricacion',
  standalone: true,
  imports: [CommonModule, SeccionTipoComponent, SeccionMedidaComponent, SeccionModeloComponent, SeccionUnidadesComponent, SeccionOrigenComponent, MatMenuModule, MatButtonModule, MatIconModule, MatBadgeModule, MatProgressSpinnerModule],
  templateUrl: './generar-orden-fabricacion.component.html',
  styleUrl: './generar-orden-fabricacion.component.scss',
})
export class GenerarOrdenFabricacionComponent {

  constructor(public fabricacionService: FabricacionService, private router: Router) { }

  ngOnInit() {
    if (!this.fabricacionService.idChapa || this.fabricacionService.idChapa == 0) this.router.navigate(['taller']); // Por si hacen reload desde la creación del producto
  }

  public mostrarMedidas() {
    if (this.fabricacionService.medidasDeProducto.length > 0 && this.fabricacionService.hayTipoProductoSeleccionado) return true;
    return false;
  }

  public mostrarOrigen() {
    if (this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.CARTELA && this.fabricacionService.hayMedidaSeleccionada ||
      this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.CARTELA && this.fabricacionService.medidasDeProducto.length === 0 ||
      this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.CARTELA_ESPECIAL_IPE && this.fabricacionService.medidasDeProducto.length === 0) return true;
    return false;
  }

  public mostrarModelos() {
    if (this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.VIGA &&
      this.fabricacionService.hayMedidaSeleccionada) return true;
    return false;
  }

  public mostrarUnidades() {
    if (this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.PLACA_DE_UNION ||
      this.fabricacionService.tipoProductoSeleccionado.TipusProducteFabricacio === this.fabricacionService.tipoGenerico.CARTELA_ESPECIAL_IPE && this.fabricacionService.hayOrigenSeleccionado ||
      this.fabricacionService.hayModeloSeleccionado ||
      this.fabricacionService.hayOrigenSeleccionado) return true;
    return false;
  }

}
