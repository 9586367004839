import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-report-calculos',
  templateUrl: './report-calculos.component.html',
  styleUrls: ['./report-calculos.component.css']
})
export class ReportCalculosComponent implements OnInit {

  public nOferta: any = "";
  public nCliente = "";
  public letra = "C";
  private paramsUrl: any = '';

  public intereje = 0.70;
  public luz = 4.25;
  public cargaRepartidaAP = 3;
  public cargaRepartidaAV = 2;
  public cargaPuntualAP = 0;
  public cargaPuntualAV = 0;
  public distanciaApoyo = 0;
  public cargaTotalSin = 6;
  public situacion = "MECANOVIGA";
  public kitMecanoviga = "MVH12 4250";
  public longExtremo = 1000;
  public longCentral = 2250;
  public inerciaExtremo = 546;
  public inerciaCentral = 740;
  public mResistenteExt = 80;
  public mResistenteCent = 120;
  public accionesPermanentes = 1.35;
  public accionesVariables = 1.5;
  public reaccionDerecha = 9.36;
  public reaccionIzquierda = 9.36;
  public tension = 112.61;
  public momentoMaximo = 13.51;
  public deformacionInicial = 11.09;
  public entradaCarga = 5;
  public flecha = "8,09(1/526)";

  public listaKits: any[] = [];
  public paginas: any[] = [];
  public nPaginas: number = 0;
  public noCabeTexto = false;
  public nPagina = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private tituloService: TituloService
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(data => {
      this.paramsUrl = data;
      console.log("paramsUrl: ", this.paramsUrl);
      this.nOferta = data.nOferta;
      this.nCliente = data.nCliente;
      let kit = {
        noferta: this.nOferta,
        pdf: 1
      }
      this.apiService.calculo(kit).subscribe(data => {
        console.log("DATA: ", data);
        this.listaKits = data.response;
        console.log(this.listaKits);
        this.intereje = data.response[0]['intereje'];
        this.luz = data.response[0]['luz'];
        this.cargaRepartidaAP = data.response[0]['cargaRepartidaAP'];
        this.cargaRepartidaAV = data.response[0]['cargaRepartidaAV'];
        this.cargaPuntualAP = data.response[0]['cargaPuntualAP'];
        this.cargaPuntualAV = data.response[0]['cargaPuntualAV'];
        this.distanciaApoyo = data.response[0]['distanciaApoyo'];
        this.cargaTotalSin = data.response[0]['cargaTotalSin'];
        this.situacion = data.response[0]['situacion'];
        this.kitMecanoviga = data.response[0]['kitMecanoviga'];
        this.longExtremo = data.response[0]['longExtremo'];
        this.longCentral = data.response[0]['longCentral'];
        this.inerciaExtremo = data.response[0]['inerciaExtremo'];
        this.inerciaCentral = data.response[0]['inerciaCentral'];
        this.mResistenteExt = data.response[0]['mResistenteExt'];
        this.mResistenteCent = data.response[0]['mResistenteCent'];
        this.accionesPermanentes = data.response[0]['accionesPermanentes'];
        this.accionesVariables = data.response[0]['accionesVariables'];
        this.reaccionDerecha = data.response[0]['reaccionDerecha'];
        this.reaccionIzquierda = data.response[0]['reaccionIzquierda'];
        this.tension = data.response[0]['tension'];
        this.momentoMaximo = data.response[0]['momentoMaximo'];
        this.deformacionInicial = data.response[0]['deformacionInicial'];
        this.entradaCarga = data.response[0]['entradaCarga'];
        this.flecha = data.response[0]['flecha'];
      })
    });
  }

  public convertirAPdf() {
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }


  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, {scale: 2})
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`calculos${this.nOferta}.pdf`);
    })
  }


}
