import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FacturasComponent } from './pages/facturas/facturas.component';
import { MantenimientoComponent } from './pages/mantenimiento/mantenimiento.component';
import { StockComponent } from './pages/stock/stock.component';
import { ListaOfertasComponent } from './pages/ofertas/lista-ofertas/lista-ofertas.component';
import { FormOfertaComponent } from './pages/ofertas/form-oferta/form-oferta.component';
import { AlbaranesSalidaComponent } from './pages/stock/albaranes-salida/albaranes-salida.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { AlbaranesEntradaComponent } from './pages/stock/albaranes-entrada/albaranes-entrada.component';
import { TecnicosComponent } from './pages/tecnicos/tecnicos.component';
import { DetallesEntradaComponent } from './pages/stock/detalles-entrada/detalles-entrada.component';
import { DetallesSalidaComponent } from './pages/stock/detalles-salida/detalles-salida.component';
import { OrdenesCompraComponent } from './pages/compras/ordenes-compra/ordenes-compra.component';
import { DetallesOrdenesCompraComponent } from './pages/compras/detalles-ordenes-compra/detalles-ordenes-compra.component';
import { GenerarCompraComponent } from './pages/compras/generar-compra/generar-compra.component';
import { ControlStockComponent } from './pages/stock/control-stock/control-stock.component';
import { SeleccionFacturasComponent } from './pages/facturas/seleccion-facturas/seleccion-facturas.component';
import { LineasFacturaComponent } from './pages/facturas/lineas-factura/lineas-factura.component';
import { AlbaranesPendientesFacturarComponent } from './pages/facturas/albaranes-pendientes-facturar/albaranes-pendientes-facturar.component';
import { ReportFacturaComponent } from './reports/report-factura/report-factura.component';
import { LoginComponent } from './pages/login/login.component';
//import Service } from './services/role-guard.service';
import { ReportOfertaComponent } from './reports/report-oferta/report-oferta.component';
import { ReportContratoBombaComponent } from './reports/report-contrato-bomba/report-contrato-bomba.component';
import { ReportAlbaranSalidaComponent } from './reports/report-albaran-salida/report-albaran-salida.component';
import { ReportAlbaranZincadoComponent } from './reports/report-albaran-zincado/report-albaran-zincado.component';
import { ReportCalculosComponent } from './reports/report-calculos/report-calculos.component';
import { AuthGuard as AuthGuard } from './guards/auth.guard';
import { InformeMaterialVendidoComponent } from './reports/informe-material-vendido/informe-material-vendido.component';
import { InformeStockComponent } from './reports/informe-stock/informe-stock.component';
import { ReportOfertaCatalaComponent } from './reports/report-oferta-catala/report-oferta-catala.component';
import { ReportListadoOfertasComponent } from './reports/report-listado-ofertas/report-listado-ofertas.component';
import { ComprasDeChapaComponent } from './pages/compras-de-chapa/compras-de-chapa.component';
import { DetallesCompraChapaComponent } from './pages/compras-de-chapa/detalles-compra-chapa/detalles-compra-chapa.component';
import { OrdenesElaboracionComponent } from './pages/ordenes-elaboracion/ordenes-elaboracion.component';
import { DetallesOrdenElaboracionComponent } from './pages/ordenes-elaboracion/detalles-orden-elaboracion/detalles-orden-elaboracion.component';
import { FabricacionComponent } from './pages/fabricacion/fabricacion.component';
import { GenerarOrdenFabricacionComponent } from './pages/fabricacion/generar-orden-fabricacion/generar-orden-fabricacion.component';
import { ReportCompraChapaComponent } from './reports/report-compra-chapa/report-compra-chapa.component';
import { ReportAlbaranesCompraChapaComponent } from './reports/report-albaranes-compra-chapa/report-albaranes-compra-chapa.component';
import { NuevaCompraChapaComponent } from './pages/compras-de-chapa/nueva-compra-chapa/nueva-compra-chapa.component';
import { UserGuard } from './guards/user.guard';

// let token = localStorage.getItem('token');
const routes: Routes = [
  { path: "login", component: LoginComponent, data: { titulo: 'LOGIN' } },

  { path: 'ofertas', component: ListaOfertasComponent, data: { titulo: 'OFERTAS' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'oferta/:id', component: FormOfertaComponent, data: { titulo: 'OFERTAS' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'oferta', component: FormOfertaComponent, data: { titulo: 'OFERTAS' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'compras-de-chapa', component: ComprasDeChapaComponent, data: { titulo: 'COMPRAS DE CHAPA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'nueva-compra-de-chapa', component: NuevaCompraChapaComponent, data: { titulo: 'GENERAR COMPRA DE CHAPA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'detalles-compra-chapa', component: DetallesCompraChapaComponent, data: { titulo: 'DETALLES COMPRA DE CHAPA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'ordenes-de-elaboracion', component: OrdenesElaboracionComponent, data: { titulo: 'ÓRDENES DE ELABORACIÓN' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'detalles-orden-elaboracion', component: DetallesOrdenElaboracionComponent, data: { titulo: 'DETALLES ORDEN DE ELABORACIÓN' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'taller', component: FabricacionComponent, data: { titulo:'TALLER' }, canActivate: [AuthGuard] },
  { path: 'taller/fabricacion', component: FabricacionComponent, data: { titulo: 'FABRICACIÓN' }, canActivate: [AuthGuard] },
  { path: 'taller/plegado', component: FabricacionComponent, data: { titulo: 'PLEGADO' }, canActivate: [AuthGuard] },
  { path: 'generar-orden-fabricacion', component: GenerarOrdenFabricacionComponent, data: { titulo: 'GENERAR ORDEN DE FABRICACIÓN' }, canActivate: [AuthGuard] },


  { path: 'clientes', component: ClientesComponent, data: { titulo: 'CLIENTES' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'tecnicos', component: TecnicosComponent, data: { titulo: 'TÉCNICOS' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'albaranes-salida', component: AlbaranesSalidaComponent, data: { titulo: 'ALBARANES-SALIDA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'albaranes-entrada', component: AlbaranesEntradaComponent, data: { titulo: 'ALBARANES-ENTRADA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'detalles-entrada', component: DetallesEntradaComponent, data: { titulo: 'DETALLE-ALBARANES-ENTRADA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'detalles-salida', component: DetallesSalidaComponent, data: { titulo: 'DETALLE-ALBARANES-SALIDA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'control-stock', component: ControlStockComponent, data: { titulo: 'CONTROL DE STOCK' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'generar-compra', component: GenerarCompraComponent, data: { titulo: 'GENERAR COMPRA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'ordenes-compra', component: OrdenesCompraComponent, data: { titulo: 'ÓRDENES DE COMPRA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'detalles-ordenes-compra', component: DetallesOrdenesCompraComponent, data: { titulo: 'DETALLE ÓRDENES DE COMPRA' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'facturas', component: FacturasComponent, data: { titulo: 'FACTURAS' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'seleccion-facturas', component: SeleccionFacturasComponent, data: { titulo: 'SELECCIÓN FACTURAS' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'lineas-factura', component: LineasFacturaComponent, data: { titulo: 'LINEAS DE FACTURA' }, canActivate: [AuthGuard, UserGuard] },
  { path: 'albaranes-pendientes-facturar', component: AlbaranesPendientesFacturarComponent, data: { titulo: 'ALBARANES PTES. DE FACTURAR' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'mantenimiento/:item', component: MantenimientoComponent, data: { titulo: 'MANTENIMIENTO' }, canActivate: [AuthGuard, UserGuard] },

  { path: 'report-factura', component: ReportFacturaComponent, data: { titulo: 'REPORT FACTURAS' } },
  { path: 'report-compra-chapa', component: ReportCompraChapaComponent, data: { titulo: 'REPORT COMPRA DE CHAPA' } },
  { path: 'report-albaranes-compra-chapa', component: ReportAlbaranesCompraChapaComponent, data: { titulo: 'REPORT ALBARANES DE COMPRA DE CHAPA' } },
  { path: 'report-oferta', component: ReportOfertaComponent, data: { titulo: 'REPORT OFERTAS' } },
  { path: 'report-oferta-catala', component: ReportOfertaCatalaComponent, data: { titulo: 'REPORT OFERTAS' } },
  { path: 'report-contrato-bomba', component: ReportContratoBombaComponent, data: { titulo: 'REPORT CONTRATO BOMBA' } },
  { path: 'report-albaran', component: ReportAlbaranSalidaComponent, data: { titulo: 'REPORT ALBARAN' } },
  { path: 'report-albaran-zincado', component: ReportAlbaranZincadoComponent, data: { titulo: 'REPORT ALBARAN ZINCADOR' } },
  { path: 'report-calculos', component: ReportCalculosComponent, data: { titulo: 'REPORT CÁLCULOS' } },
  { path: 'informe-material-vendido', component: InformeMaterialVendidoComponent, data: { titulo: 'INFORME MATERIAL VENDIDO' } },
  { path: 'informe-stock', component: InformeStockComponent, data: { titulo: 'INFORME STOCK' } },
  { path: 'report-listado-ofertas', component: ReportListadoOfertasComponent, data: { titulo: 'LISTADO OFERTAS' } },

  { path: '**', redirectTo: 'login' }

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

