import { Component } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { SHA1 } from "crypto-js";
import { DialogPopUpComponent } from '../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
// Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15. De totes formes no veig que aquí s'utilitzi
//import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { TituloService } from "src/app/services/titulo.service";
import { AuthService } from "src/app/services/auth-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent {

  email: string = "";
  password: string = "";

  constructor(
    public dialog: MatDialog,
    public apiService: ApiService,
    public router: Router,
    public authService: AuthService,
    private tituloService: TituloService
    ) { }

    ngOnInit(): void {
      localStorage.removeItem('token');
      this.tituloService.enviarTitulo();
    }
  
  login() {
    // Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15
    //let encrypted = SHA1(this.password).toString();
    let encrypted = this.password;
    //CryptoJs.SHA1(this.password)
    const user = { email: this.email, password: encrypted };
    this.apiService.login(user).subscribe(data => {
    //  data.response = 0;
      if (data.response == -1) {
        localStorage.removeItem('token');
        this.openDialog("ALERTA", "Usuario no existe", false, "");
      }
      else if (data.response == -2) {
        localStorage.removeItem('token');
        this.openDialog("ALERTA", "Contraseña incorrecta", false, "");
      }
      else {
        this.authService.userRoles = data.response;
        const { authMantenimiento, authOfertas, authStock, authCompra, authFacturacion, authFabricacion, authFabricar } = data.response;
        const userRoles = { authMantenimiento, authOfertas, authStock, authCompra, authFacturacion, authFabricacion, authFabricar };

        localStorage.setItem('token', encrypted);
        localStorage.setItem('userRoles', JSON.stringify(userRoles));

        if (this.authService.isFabricante()) {
          this.router.navigate(['taller'])
          return;
        };

        this.router.navigate(['ofertas']);
      }
    });
  }
  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
    });
  }
}
