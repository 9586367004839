<div class="layout-tabla container flex-row-wrap flex-100">

    <h2 class="h2titulo">Albaranes pendientes de facturar de la oferta {{nOferta}} del cliente {{idCliente}} -
        {{nombreCliente}}</h2> <br>

    <table mat-table [dataSource]="dataSource" matSort
        class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

        <ng-container matColumnDef="buttons" class="justify-center align-start">
            <th mat-header-cell *matHeaderCellDef>
                <button class="button-create" mat-icon-button type="button" (click)="volver()">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </th>
            <td mat-cell *matCellDef="let element">
            </td>
        </ng-container>

        <ng-container matColumnDef="nAlbaran">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Albarán </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.nAlbaran}} </td>
        </ng-container>

        <ng-container matColumnDef="nOferta">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Oferta </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.nOferta}} </td>
        </ng-container>

        <ng-container matColumnDef="nombreCliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Cliente </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.nombreCliente}} </td>
        </ng-container>

        <ng-container matColumnDef="fechaSalida">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Anticipo Pendiente </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.fechaSalida | date:
                'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="entregaPrevista">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Entrega Prevista </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.entregaPrevista | date:
                'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="entregaReal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Entrega Real </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.entregaReal | date:
                'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="importeLineas">
            <th mat-header-cell align="center" *matHeaderCellDef mat-sort-header> Importe Líneas </th>
            <td mat-cell *matCellDef="let element" class="center"> {{element.importeLineas}} </td>
        </ng-container>

        <ng-container matColumnDef="importeProductosOpcionales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Imp.Prod.Opcionales </th>
            <td mat-cell *matCellDef="let element" class="center"> {{element.importeProductosOpcionales}} </td>
        </ng-container>

        <ng-container matColumnDef="costeTransporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transporte </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.costeTransporte}} </td>
        </ng-container>

        <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Peso </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.peso}} </td>
        </ng-container>

        <ng-container matColumnDef="transportista">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Transportista </th>
            <td mat-cell *matCellDef="let element" align="left"> {{element.transportista}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="enviaUnAlbaran(row)"
            [ngClass]="{'row-highlighted': albaranAFacturar.nFactura == row.nFactura}"></tr>

    </table>

    <mat-paginator *ngIf="listaAlbaranesAFacturar.length>0" [pageSizeOptions]="[20]" showFirstLastButtons>
    </mat-paginator>
    <div class="noHayNadaEnTabla" *ngIf="listaAlbaranesAFacturar.length == 0">No hay albaranes</div>

</div>