import { Component, EventEmitter, OnInit, Output, Input, ViewChild, Inject } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IdLabelObject } from '../../../types/global';
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DOCUMENT } from '@angular/common';
import { DateToString, StringToDate } from 'src/app/services/dataFunction';

@Component({
  selector: 'app-ofertas-un-cliente',
  templateUrl: './ofertas-un-cliente.component.html',
  styleUrls: ['./ofertas-un-cliente.component.css']
})
export class OfertasUnClienteComponent implements OnInit {

  @Output() clickEnVolverAPantalla = new EventEmitter();
  @Output() cuandoClientesCambien = new EventEmitter();
  @Output() heAsignadoCliente = new EventEmitter();
  @Input() clientesUnaOferta: any[] = [];
  @Input() idClienteAsignado: string | undefined = undefined;
  @Input() oferta: any = 0;

  public esClienteNuevo: boolean = true;
  private esAltaClienteNuevo: boolean = false;
  public clientes: IdLabelObject[] = [];
  public infoClientes: any[] = [];
  public pantallaClientes: boolean = true;
  public formularioAAsingar: any;
  public clienteABorrar: any;
  private idiomaOferta = "castellano";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public dataSource!: MatTableDataSource<any>;

  public ofertaUnCliente: any = {
    idCliente: this.activatedRoute.snapshot.queryParamMap.get('idCliente') || undefined,
    cliente: undefined,
    idOferta: undefined,
    fechaOferta: undefined,
    descuento: undefined,
    incremento: undefined,
    anticipo: undefined,
    colaboracion: false,
    montaje: false,
    asignada: 0
  }

  displayedColumns: string[] = ['buttons', 'cliente', 'fechaOferta', 'descuento', 'incremento', 'colaboracion', 'montaje'];


  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource(this.clientesUnaOferta);
  }

  ngOnInit() {
    this.apiService.obtenerClientes().subscribe(data => {
      this.clientes = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
      this.infoClientes = data.response.map(cliente => ( { id: cliente.idCliente.toString(), dto: cliente.descuento}))
    });
    if (this.idClienteAsignado) {
      const element = this.clientesUnaOferta.find(elemento => elemento.idCliente == this.idClienteAsignado);
      this.muestraDatosCliente(element);
    }
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if (params.id) {
        const element = this.clientesUnaOferta.find(elemento => elemento.idCliente == params.id);
        if (element) {
          this.muestraDatosCliente(element);
        } else {
          this.resetOfertaUnCliente();
          this.ofertaUnCliente.idCliente = params.id;
          this.esAltaClienteNuevo = true;
          this.muestraDatosCliente(this.ofertaUnCliente);
        }
      }
    })
  }

  ngAfterViewInit() {
    //    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<any>(this.clientesUnaOferta);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<any>(this.clientesUnaOferta);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  public guardarOfertaUnCliente(formulario: NgForm) {
    if (!this.ofertaUnCliente.fechaOferta) {
      this.ofertaUnCliente.fechaOferta = new Date();
    }
    console.log("AAA: ", this.ofertaUnCliente.fechaOferta);
    console.log("Entro en guardar: ", formulario);
    if (!formulario.valid) { return };
    var msg = "";
    if (!this.miraNegativos(this.ofertaUnCliente.descuento)) msg = 'El campo descuento tiene un número negativo';
    if (!this.miraNegativos(this.ofertaUnCliente.incremento)) msg = 'El campo incremento tiene un número negativo';
    if (!this.miraNegativos(this.ofertaUnCliente.anticipo)) msg = 'El campo anticipo tiene un número negativo';
    if (this.comprobarSiYaExiste(this.ofertaUnCliente.idCliente) && this.esClienteNuevo) msg = 'Este cliente ya existe en esta oferta';

    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }


    if (this.ofertaUnCliente.colaboracion == true) {
      this.ofertaUnCliente.colaboracion = 1;
    } else {
      this.ofertaUnCliente.colaboracion = 0
    }
    if (this.ofertaUnCliente.montaje == true) {
      this.ofertaUnCliente.montaje = 1;
    } else {
      this.ofertaUnCliente.montaje = 0
    }

    console.log(this.ofertaUnCliente.asignada);
    if (this.ofertaUnCliente.asignada === 1) {
      this.ofertaUnCliente.fechaOferta = DateToString(this.ofertaUnCliente.fechaOferta); 
      console.log("voy a asignar: ", this.ofertaUnCliente);
      this.apiService.asignarOferta(this.ofertaUnCliente).subscribe(dades => {
        this.apiService.modificarOferta(this.ofertaUnCliente).subscribe(dades => {
          this.cuandoClientesCambien.emit();
          });
        //        this.resetOfertaUnCliente();
        //        this.heAsignadoCliente.emit();
      });
    }
    else {
      this.ofertaUnCliente.fechaOferta = DateToString(this.ofertaUnCliente.fechaOferta);
        
      if (this.esClienteNuevo) {
        this.ofertaUnCliente.idOferta = this.oferta.nOferta;
        console.log("NUEVO: ", this.ofertaUnCliente);
        this.apiService.anadirClientesUnaOferta(this.ofertaUnCliente).subscribe(dades => {
          this.esClienteNuevo = false;
          this.cuandoClientesCambien.emit();
          //      this.resetOfertaUnCliente();
        });

      }
      else {
        this.apiService.modificarOferta(this.ofertaUnCliente).subscribe(dades => {
          this.cuandoClientesCambien.emit();
          //    this.resetOfertaUnCliente();
        });
      }
    }
    this.ofertaUnCliente.fechaOferta = StringToDate(this.ofertaUnCliente.fechaOferta);
  }

  public volverATablaUnaOferta() {
    this.clickEnVolverAPantalla.emit();
  }

  public muestraDatosCliente(element: any) {
    this.esClienteNuevo = false;
    if (this.esAltaClienteNuevo) { this.esClienteNuevo = true };
    this.ofertaUnCliente = { ...element };
    this.ofertaUnCliente.idCliente = this.ofertaUnCliente.idCliente.toString(); //TODO 
    if (this.ofertaUnCliente.colaboracion === "SI") {
      this.ofertaUnCliente.colaboracion = true;
    } else {
      this.ofertaUnCliente.colaboracion = false;
    }
    if (this.ofertaUnCliente.montaje === "SI") {
      this.ofertaUnCliente.montaje = true;
    } else {
      this.ofertaUnCliente.montaje = false;
    }
    this.esAltaClienteNuevo = false;
  }

  public buscaDatosCliente(event: any) {
    this.ofertaUnCliente.idCliente = event;
    if(this.esClienteNuevo){ 
      var body = this.infoClientes.find(cliente => cliente.id == event) || {};
      if (body.dto) {console.log(body.dto)};
      this.ofertaUnCliente.descuento = body.dto;
    }
  }

  public esClienteNuevoEnOferta() {
    this.esClienteNuevo = true;
    this.resetOfertaUnCliente();
    this.ofertaUnCliente.fechaOferta = new Date();
  }

  public dialogAsignarClienteUnaOferta(formulario: NgForm) {
    console.log("Entro en dialog: ", formulario);
    this.formularioAAsingar = formulario;
    this.openDialog("ASIGNAR CLIENTE", "¿Quiere asignar el cliente " + this.ofertaUnCliente.cliente + " a la oferta " + this.ofertaUnCliente.idOferta + "?", true, "asignar");
  }


  public dialogEliminarOfertaUnCliente(clienteUnaOferta: any) {
    this.clienteABorrar = clienteUnaOferta;
    this.openDialog("ELIMINAR OFERTA DEL CLIENTE", "¿Quire eliminar el cliente " + clienteUnaOferta.cliente + " de la oferta " + this.oferta.nOferta + "?", true, "eliminar")
  }

  private comprobarSiYaExiste(idCliente: string) {
    let arrayComprobado;
    arrayComprobado = this.clientesUnaOferta.find(item => {
      if (item.idCliente == idCliente) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    console.log("LABEL: ", label);
    const paramsEnv = {
      redirecTo: `/oferta/${this.oferta.nOferta}`,
      pantalla: 'clientes'
    }
    this.router.navigate(['clientes'], { queryParams: paramsEnv });
  }

  public mostarPantallaPPE() {
    this.pantallaClientes = false;
  }

  public muestraPantallaClientes() {
    this.pantallaClientes = true;
  }

  private resetOfertaUnCliente() {
    this.ofertaUnCliente = {
      idCliente: undefined,
      cliente: undefined,
      idOferta: undefined,
      fechaOferta: undefined,
      descuento: undefined,
      incremento: undefined,
      anticipo: undefined,
      colaboracion: false,
      montaje: false,
      asignada: 0
    }
    this.esAltaClienteNuevo = false;
  }

  openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'asignar':
          if (result) {
            this.ofertaUnCliente.asignada = 1;
            this.guardarOfertaUnCliente(this.formularioAAsingar);
            this.idClienteAsignado = this.ofertaUnCliente.idCliente.toString();
          }
          break;
        case 'eliminar':
          if (result) this.eliminarOfertaUnCliente();
      }
    });
  }

  eliminarOfertaUnCliente() {
    console.log("Eliminado");
    this.apiService.borrarOferta(this.clienteABorrar).subscribe(dades => {
      this.resetOfertaUnCliente();
      this.cuandoClientesCambien.emit();
    });
  }

  public imprimirOferta() {
    if ( this.idiomaOferta === "catalan") {
      const link = this.document.createElement('a');
      link.target = '_blank';
      link.href = '/report-oferta-catalan?nOferta=' + this.oferta.nOferta  + '&nCliente=' + this.ofertaUnCliente.idCliente;
      link.click();
      link.remove();  
    } else {
      const link = this.document.createElement('a');
      link.target = '_blank';
      link.href = '/report-oferta?nOferta=' + this.oferta.nOferta  + '&nCliente=' + this.ofertaUnCliente.idCliente;
      link.click();
      link.remove();
  
    }
    /*
    report-oferta?redirecTo=%2Foferta%2F5820&pantalla=clientes&nOferta=5820&nCliente=21
    console.log("thisOferta", this.ofertaUnCliente.idCliente);
    const paramsEnv = {
      redirecTo: `/oferta/${this.oferta.nOferta}`,
      pantalla: 'clientes',
      nOferta: this.oferta.nOferta,
      nCliente: this.ofertaUnCliente.idCliente
    }
    this.router.navigate(['report-oferta'], { queryParams: paramsEnv });
    */
  }

  public imprimirCalculos() {
    
    if (this.idiomaOferta === "catalan") {
      const link = this.document.createElement('a');
      link.target = '_blank';
      link.href = '/report-calculos-catalan?nOferta=' + this.oferta.nOferta  + '&nCliente=' + this.ofertaUnCliente.idCliente;
      link.click();
      link.remove();
    } else { 
      const link = this.document.createElement('a');
      link.target = '_blank';
      link.href = '/report-calculos?nOferta=' + this.oferta.nOferta  + '&nCliente=' + this.ofertaUnCliente.idCliente;
      link.click();
      link.remove();
    }
    /*
    const paramsEnv = {
      redirecTo: `/oferta/${this.oferta.nOferta}`,
      pantalla: 'clientes',
      nOferta: this.oferta.nOferta,
      nCliente: this.ofertaUnCliente.idCliente
    }
    this.router.navigate(['report-calculos'], { queryParams: paramsEnv });
    */
  }

  public imprimirContratoBomba() {
    const paramsEnv = {
      redirecTo: `/oferta/${this.oferta.nOferta}`,
      pantalla: 'clientes',
      nOferta: this.oferta.nOferta
    }
    this.router.navigate(['report-contrato-bomba'], { queryParams: paramsEnv });
  }

}
