import { AfterViewInit, Component, Inject, OnChanges, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AlbaranSalida } from '../../../types/stock';
import { DateToString, StringToDate } from '../../../services/dataFunction';
import { pick } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';


import {
  mantenimiento1CondicionesPago, mantenimiento1FormaPago, mantenimiento1Patologia, mantenimiento1Idioma,
  mantenimiento1ModoEnvio, mantenimiento1Pais, mantenimiento1TipoSoporte, mantenimiento1Transportista,
  mantenimiento3Comercial, mantenimiento2TiposKit, mantenimiento2SubTiposKit, mantenimiento3Stock,
  mantenimiento2TipoProducto, mantenimiento1ValidezOferta, mantenimiento2Ciudad, mantenimiento2Provincia,
  mantenimiento2Producto, mantenimiento2ProductoOpc, mantenimiento2Kits, mantenimiento2Encargado
} from '../../../services/llamadasMantenimiento';
import { DOCUMENT } from '@angular/common';
import { TituloService } from 'src/app/services/titulo.service';
import cli from '@angular/cli';


@Component({
  selector: 'app-albaranes-salida',
  templateUrl: './albaranes-salida.component.html',
  styleUrls: ['./albaranes-salida.component.css']
})
export class AlbaranesSalidaComponent implements OnInit, AfterViewInit {

  public filtroAlbaranesSalida: any = {
    fechaInicioSelected: undefined,
    fechaFinSelected: undefined,
    nAlbaranSelected: undefined,
    estadoSelected: undefined,
    nOfertaSelected: undefined,
    clienteSelected: undefined,
    provinciaSelected: undefined
  }

  public clientes: IdLabelObject[] = [];

  public albaranesSalida: any[] = [];
  public provincias: IdLabelObject[] = [];
  public ciudades: IdLabelObject[] = [];
  public transportistas: IdLabelObject[] = [];
  public encargadosObra: IdLabelObject[] = [];
  public crearAlbaranNuevo: boolean = false;
  public fechaRealProv: Date = new Date();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public albaranResaltado = null;
  public albaranABorrar = null;
  public dataSource!: MatTableDataSource<AlbaranSalida>;


  public opcionesEstado = [
    {
      nombre: "Preparado",
    },
    {
      nombre: "Entregado",
    },
    {
      nombre: "MECANOVIGA",
    }
  ]


  public albaranSalida: any = {
    nAlbaranSalida: "",
    nOferta: "",
    idCliente: "",
    nombreCliente: "",
    fechaSalida: new Date(),
    fechaPrevista: new Date(),
    fechaReal: null,
    direccion: "",
    cp: "",
    idCiudad: "",
    nFactura: "",
    idTransportista: "",
    matricula: "",
    costeTransporte: 0.00,
    peso: "",
    estado: "Preparado",
    encargadoObra: "",
    telefonoObra: "",
    idEncargadoObra: "",
    notas: "",
    tipo: "salida",
    tieneDetalles: ""
  }

  //  displayedColumns: string[] = ['buttons', 'nAlbaranSalida', 'nOferta', 'idCliente', 'fechaSalida', 'fechaPrevista', 'fechaReal', 'direccion', 'cp', 'idCiudad', 'idTransportista', 'matricula', 'costeTransporte', 'peso', 'observaciones', 'encargadoObra', 'telefonoObra', 'estado', 'nFactura'];
  displayedColumns: string[] = ['buttons', 'nAlbaranSalida', 'nOferta', 'idCliente', 'fechaSalida', 'nombreCliente', 'idCiudad', 'estado', 'nFactura'];


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.albaranesSalida);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetAlbaranSalida();
    this.apiService.obtenerClientes().subscribe(data => {
      this.clientes = data.response.map(cliente => ({ id: cliente.idCliente.toString(), label: cliente.nombre }));
    });
    //  this.envioDatosFiltro();
    this.activatedRoute.queryParams.subscribe((params) => {

      if (params.nAlbaran) {
        this.albaranResaltado = params.nAlbaran;
        this.albaranSalida.nAlbaranSalida = params.nAlbaran;
        this.filtroAlbaranesSalida = {
          ...pick(params, Object.keys(this.filtroAlbaranesSalida)),
        };
        //     this.envioDatosFiltro();
      }

      if (params.nOfertaBuscada) {
        this.crearAlbaranNuevo = true;
        const filtro = {
          selectednOferta: params.nOfertaBuscada,
          pdf: false,
        }
        this.apiService.obtenerOfertas(filtro).subscribe(dades => {
          console.log(dades);
          this.albaranSalida.nOferta = dades.response[0].nOferta;
          this.albaranSalida.nombreCliente = dades.response[0].cliente;
          this.albaranSalida.nombreCiudad = dades.response[0].ciudad;
          this.albaranSalida.direccion = dades.response[0].direccionObra;
          this.albaranSalida.cp = dades.response[0].codigoPostal;
          this.albaranSalida.idCiudad = dades.response[0].idCiudad;
          this.albaranSalida.idCliente = dades.response[0].idCliente;
          this.albaranSalida.peso = dades.response[0].peso;
        })
      }

    });

    this.apiService.obtenerProvincias().subscribe(data => {
      this.provincias = data.response.map(provincia => ({ id: provincia.idProvincia.toString(), label: provincia.nombreProvincia }));
    });
    this.apiService.obtenerCiudades().subscribe(data => {
      this.ciudades = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });
    this.apiService.obtenerTransportistas().subscribe(data => {
      this.transportistas = data.response.map(transportista => ({ id: transportista.idTransportista.toString(), label: transportista.transportista }));
    });

  }

  ngAfterViewInit(): void {
    this.envioDatosFiltro();
    /*    this.dataSource = new MatTableDataSource<AlbaranSalida>(this.albaranesSalida);
        let page = this.buscarIndiceEnOfertasFiltradas(this.albaranResaltado) || 0;
        this.paginator.pageIndex = page;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;*/
  }

  /*  ngOnChanges(): void {
      this.envioDatosFiltro();
      this.dataSource = new MatTableDataSource<AlbaranSalida>(this.albaranesSalida);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }*/


  public envioDatosFiltro() {
    // obtenerAlbaraesSalida
    if (this.filtroAlbaranesSalida.fechaInicioSelected) this.filtroAlbaranesSalida.fechaInicioSelected = DateToString(this.filtroAlbaranesSalida.fechaInicioSelected);
    if (this.filtroAlbaranesSalida.fechaFinSelected) this.filtroAlbaranesSalida.fechaFinSelected = DateToString(this.filtroAlbaranesSalida.fechaFinSelected);
    this.apiService.obtenerAlbaranesSalida(this.filtroAlbaranesSalida).subscribe(dades => {
      this.albaranesSalida = dades.response;
      this.dataSource = new MatTableDataSource<AlbaranSalida>(this.albaranesSalida);
      let page = this.buscarIndiceEnOfertasFiltradas(this.albaranResaltado) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.filtroAlbaranesSalida.fechaInicioSelected) this.filtroAlbaranesSalida.fechaInicioSelected = StringToDate(this.filtroAlbaranesSalida.fechaInicioSelected);
      if (this.filtroAlbaranesSalida.fechaFinSelected) this.filtroAlbaranesSalida.fechaFinSelected = StringToDate(this.filtroAlbaranesSalida.fechaFinSelected);
      this.fechaRealProv = new Date();
      //    this.resetAlbaranSalida();
      if (this.albaranResaltado) {
        this.albaranSalida.nAlbaran = this.albaranResaltado;
        const element = this.albaranesSalida.find(element => element.nAlbaranSalida == this.albaranResaltado);
        this.muestraAlbaranSalida(element);
      }
    });
  }

  public esAlbaranSalidaNuevo() {
    this.crearAlbaranNuevo = true;
    this.fechaRealProv = new Date();
    this.resetAlbaranSalida();
  }

  public dialogEliminarUnAlbaran(albaranSalida: any) {
    this.albaranABorrar = albaranSalida.nAlbaranSalida;
    this.openDialog("ELIMINAR ALBARAN", "¿Quire eliminar el albarán " + albaranSalida.nAlbaranSalida + " de la lista de salida " + "?", true, "eliminar")
  }


  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      switch (tipo) {
        case 'eliminar':
          if (result) this.eliminarUnAlbaran();
          break;
      }
    });
  }

  private eliminarUnAlbaran() {
    this.apiService.borrarAlbaranSalida(this.albaranABorrar).subscribe(dades => {
      this.resetAlbaranSalida();
      this.envioDatosFiltro();
    });
  }

  public muestraAlbaranSalida(element: any) {
    this.crearAlbaranNuevo = false;
    this.albaranSalida = { ...element };
    if (this.albaranSalida.estado === "Entregado") {
      this.fechaRealProv = this.albaranSalida.fechaReal;
    }
    console.log(this.albaranSalida);
    this.apiService.obtenerEncargadoObra(this.albaranSalida.idCliente).subscribe(data => {
      this.encargadosObra = data.response.map((encargado: { idEncargado: { toString: () => any; }; encargado: any; }) => ({ id: encargado.idEncargado.toString(), label: encargado.encargado }));
      console.log(this.encargadosObra);
    });
    console.log(this.albaranSalida);
    //    this.albaranSalida.fechaAlbaran = element.fechaSalida;
  }

  public guardarAlbaranSalida(formulario: NgForm) {
    //    if (!formulario.valid) { return };
    this.albaranSalida.fechaSalida = DateToString(this.albaranSalida.fechaSalida);
    this.albaranSalida.fechaPrevista = DateToString(this.albaranSalida.fechaPrevista);
    if (this.albaranSalida.estado === "Entregado") {
      this.albaranSalida.fechaReal = this.fechaRealProv;
      this.albaranSalida.fechaReal = StringToDate(this.albaranSalida.fechaReal);
    } else { this.albaranSalida.fechaReal = null };
    console.log(this.albaranSalida);
    console.log("nuevo albaran: ", this.crearAlbaranNuevo)

    if (this.crearAlbaranNuevo) {
      this.albaranSalida.fechaSalida = DateToString(this.albaranSalida.fechaSalida);
      this.albaranSalida.fechaPrevista = DateToString(this.albaranSalida.fechaPrevista);
      this.apiService.anadirAlbaranSalida(this.albaranSalida).subscribe(data => {
        console.log(data.response);
        if (this.albaranSalida.fechaSalida) { this.albaranSalida.fechaSalida = StringToDate(this.albaranSalida.fechaSalida); }
        if (this.albaranSalida.fechaPrevista) { this.albaranSalida.fechaPrevista = StringToDate(this.albaranSalida.fechaPrevista); }
        this.apiService.obtenerAlbaranesSalida(this.filtroAlbaranesSalida).subscribe(dades => {
          this.albaranSalida = dades.response[0];
          this.crearAlbaranNuevo = false;
          this.albaranResaltado = this.albaranSalida.nAlbaranSalida;
          this.envioDatosFiltro();
          console.log("Guardo nuevo: ", this.albaranResaltado);
        });
      });


    } else {
      this.apiService.modificarAlbaranSalida(this.albaranSalida).subscribe(data => {
        this.albaranResaltado = this.albaranSalida.nAlbaran;
        this.envioDatosFiltro();
      });
      if (this.albaranSalida.fechaSalida) { this.albaranSalida.fechaSalida = StringToDate(this.albaranSalida.fechaSalida); }
      if (this.albaranSalida.fechaPrevista) { this.albaranSalida.fechaPrevista = StringToDate(this.albaranSalida.fechaPrevista); }
      console.log("Guardo viejo: ", this.albaranSalida);
    }
  }

  public cuandoSeCliqueEnIcono() {
    const action = "albaranes-salida"
    this.router.navigate(['ofertas'], { queryParams: { action } });
  }

  public irADetallesSalida() {
    this.router.navigate(['detalles-salida'], { queryParams: { ...this.filtroAlbaranesSalida, nOferta: this.albaranSalida.nOferta, nAlbaran: this.albaranSalida.nAlbaranSalida, estado: this.albaranSalida.estado } });
  }

  public cuandoSeCliqueEnMantenimiento(label: string) {
    let dialogRef: any;
    switch (label) {
      case 'ciudad':
        dialogRef = mantenimiento2Ciudad(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerCiudades().subscribe(data => {
            this.ciudades = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
          });
        });
        break;
      case 'encargadoObra':
        dialogRef = mantenimiento2Encargado(this.dialog, this.albaranSalida.idCliente, this.albaranSalida.nombreCliente);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerEncargadoObra(this.albaranSalida.idCliente).subscribe(data => {
            this.encargadosObra = data.response.map((encargado: { idEncargado: { toString: () => any; }; encargado: any; }) => ({ id: encargado.idEncargado.toString(), label: encargado.encargado }));
            console.log(this.encargadosObra);
          });
        });
        break;
      case 'transportista':
        dialogRef = mantenimiento1Transportista(this.dialog);
        dialogRef.afterClosed().subscribe((data: any) => {
          this.apiService.obtenerTransportistas().subscribe(data => {
            this.transportistas = data.response.map(transportista => ({ id: transportista.idTransportista.toString(), label: transportista.transportista }));
          });
        });
        break;
    }

  }

  private buscarIndiceEnOfertasFiltradas(albaranResaltado: any) {
    if (!albaranResaltado) return 0;
    let busqueda = albaranResaltado.toString();
    let indice: number;
    indice = this.albaranesSalida.findIndex(albaran => albaran.nAlbaranSalida.toString() === busqueda);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  private resetAlbaranSalida() {
    this.albaranSalida = {
      nAlbaranSalida: "",
      nOferta: "",
      idCliente: "",
      nombreCliente: "",
      fechaSalida: new Date(),
      fechaPrevista: new Date(),
      fechaReal: null,
      direccion: "",
      cp: "",
      idCiudad: "",
      nombreCiudad: "",
      nFactura: "",
      idTransportista: "",
      nombreTransportista: "",
      matricula: "",
      costeTransporte: 0.00,
      peso: "",
      estado: "Preparado",
      encargadoObra: "",
      telefonoObra: "",
      notas: ""
    }
  }

  public resetFiltro() {
    this.filtroAlbaranesSalida = {
      fechaInicioSelected: undefined,
      fechaFinSelected: undefined,
      nAlbaranSelected: undefined,
      estadoSelected: undefined,
      nOfertaSelected: undefined,
      clienteSelected: undefined,
      provinciaSelected: undefined
    }
    this.envioDatosFiltro();
    //this.albaranesSalida = [];
    this.resetAlbaranSalida();
  }

  public imprimirAlbaran() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-albaran?nOferta=' + this.albaranSalida.nOferta + '&nAlbaran=' + this.albaranSalida.nAlbaranSalida + '&tipo=' + 1;
    link.click();
    link.remove();
  }

}
