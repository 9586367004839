import { CommonModule, DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { estadoCompra, estadoMapper } from 'src/app/constants/comprasChapa.constant';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { ApiService } from 'src/app/services/api.service';
import { DateToStringGuion } from 'src/app/services/dataFunction';
import { CompraChapaDesdeBD, CompraChapaNueva } from 'src/app/types/comprasChapa';
import { TituloService } from '../../services/titulo.service';

@Component({
  selector: 'app-compras-de-chapa',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    DatePipe,
    MatPaginator,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    MatIcon,
    MatLabel,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatSelect,
    MatOption,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './compras-de-chapa.component.html',
  styleUrl: './compras-de-chapa.component.css'
})

export class ComprasDeChapaComponent {
  @ViewChild(ComprasDeChapaComponent) miComponente!: ComprasDeChapaComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public compraChapa: CompraChapaDesdeBD = {} as CompraChapaDesdeBD;

  public mapper = estadoMapper;
  public estado = estadoCompra;
  public crearCompraNueva = false;
  public hayCompraSeleccionada = false;
  public fechaAlbaranesDesde: Date = new Date(Date.now());
  public fechaAlbaranesHasta: Date = new Date(Date.now());
  public albaranes: any[] = [];

  public listaComprasChapa: CompraChapaDesdeBD[] = [];
  public dataSource!: MatTableDataSource<CompraChapaDesdeBD>;
  displayedColumns: string[] = ['Buttons', 'Tipus', 'DataCompra', 'Preu', 'Estat'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private router: Router,
    private tituloService: TituloService,
    public dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource(this.listaComprasChapa);
  }


  ngOnInit() {
    this.obtenerCompras();
    this.tituloService.enviarTitulo();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  static colorRow(IdRow: number, rowEstado: string, IdElemento?: number): any {
    return {
      'row-delivered': rowEstado === estadoCompra.ENTREGADO,
      'row-partial-delivered': rowEstado === estadoCompra.PARCIAL && IdElemento !== IdRow,
      'row-highlighted': IdElemento == IdRow && rowEstado,
    };
  }

  private obtenerCompras() {
    this.apiService.obtenerComprasChapa().subscribe((data: any) => {
      this.listaComprasChapa = data.response;
      this.dataSource = new MatTableDataSource(this.listaComprasChapa);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public definirColorRow(rowIdCompra: number, rowEstat: string, compraChapaIdCompra?: number): any {
    return ComprasDeChapaComponent.colorRow(rowIdCompra, rowEstat, compraChapaIdCompra);
  }

  public seleccionarUnaCompra(compra: CompraChapaDesdeBD) {
    this.compraChapa = { ...compra };
    this.crearCompraNueva = false;
    this.hayCompraSeleccionada = true;
    console.log('COMPRA SELECCIONADA >>>>>>>>', this.compraChapa);
  }

  public actualizarCompra() {
    if (!this.compraChapa.Tipus || !this.compraChapa.DataCompra) return;
    if (this.compraChapa.Preu < 0) return;

    this.apiService.actualizarCompraChapa(this.compraChapa.IdCompra, this.compraChapa.Tipus, this.compraChapa.Preu).subscribe(() => {
      this.obtenerCompras();
      this.resetCompra();
    });

    console.log('DATOS A ACTUALIZAR >>>>>>>>', this.compraChapa.IdCompra, this.compraChapa.Tipus,this.compraChapa.Preu);
  }

  private borrarCompra(idCompra: number) {
    this.apiService.borrarCompraChapa(idCompra).subscribe(() => {
      this.obtenerCompras();
      this.resetCompra();
    });
  }

  public imprimirCompra() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-compra-chapa?idCompra=' + this.compraChapa.IdCompra + '&idTipo=' + this.compraChapa.Tipus + '&estado=' + this.compraChapa.Estat + '&preu=' + this.compraChapa.Preu + '&dataCompra=' + this.compraChapa.DataCompra;
    link.click();
    link.remove();
  }

  public imprimirAlbaranes() {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-albaranes-compra-chapa?fechaDesde=' + DateToStringGuion(this.fechaAlbaranesDesde) + '&fechaHasta=' + DateToStringGuion(this.fechaAlbaranesHasta);
    link.click();
    link.remove();
    this.fechaAlbaranesDesde = new Date(Date.now());
    this.fechaAlbaranesHasta = new Date(Date.now());
  }

  public irADetallesCompra() {
    this.router.navigate(['detalles-compra-chapa'], { queryParams: { idCompra: this.compraChapa.IdCompra } });
  }

  public resetCompra() {
    this.compraChapa = {} as CompraChapaDesdeBD;
    this.hayCompraSeleccionada = false;
  }

  public esCompraNueva() {
    this.router.navigate(['nueva-compra-de-chapa']);
    // this.resetCompra();
    // this.crearCompraNueva = true;
    // this.compraChapa.DataCompra = new Date(Date.now());
    // this.compraChapa.Estat = estadoCompra.PENDIENTE;
  }

  openDialog(id: number): void {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { id: id, title: 'ELIMINAR COMPRA', msg: `¿Quiere eliminar esta compra de la lista de compras?`, cancelBtn: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.borrarCompra(id);
    });
  }
}