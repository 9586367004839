<div class="content">

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="pagina" *ngFor="let kit of listaKits; let i = index">

            <div class="header">
                <app-cabecera-mecanoviga class="header-mecanoviga"></app-cabecera-mecanoviga>
                <app-cabecera-oferta [nOferta]="nOferta" [letra]="letra" [idCliente]="nCliente" class="header-cliente"></app-cabecera-oferta>
            </div>
            
            <div class="linea-gruesa"></div>

            <p class="apartadoCalculo">HIPÓTESIS DE CÁLCULO</p>
            <div class="lineaNegra"></div>

            <div class="cargas">
                <div class="unaCarga">
                    <p>CARGA REPARTIDA:</p>
                    <p class="mt-10">Se considera una viga de inercia variable biapoyada con carga uniforme</p>
                    <img src="../../../assets/imagen1.jpg" class="imagen1">
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">L2. Longitud central</p>
                        <p class="valorEspecifico">{{kit.longCentral | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">mm</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">L1. Longitud extremo</p>
                        <p class="valorEspecifico">{{kit.longExtremo | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">mm</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">I1. Inercia extremo</p>
                        <p class="valorEspecifico">{{kit.inerciaExtremo | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">cm4</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">I2. Inercia central</p>
                        <p class="valorEspecifico">{{kit.inerciaCentral | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">cm4</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">W1. M. resistente ext.</p>
                        <p class="valorEspecifico">{{kit.mResistenteExt | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">cm3</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">W2. M. resistente cent.</p>
                        <p class="valorEspecifico">{{kit.mResistenteCent | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">cm3</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">&gamma; (Acciones Permanentes)</p>
                        <p class="valorEspecifico">{{kit.accionesPermanentes | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico"></p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">&gamma; (Acciones Variables)</p>
                        <p class="valorEspecifico">{{kit.accionesVariables | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico"></p>
                    </div>

        
                </div>
                <div class="unaCarga">
                    <p>CARGA PUNTUAL:</p>
                    <p class="mt-10">Se considera una viga de inercia variable biapoyada con carga puntual</p>
                    <img src="../../../assets/imagen2.jpg" class="imagen2">
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Luz viga (L total)</p>
                        <p class="valorEspecifico">{{kit.luz | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">mts</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Intereje</p>
                        <p class="valorEspecifico">{{kit.intereje | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">mts</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Carga Repartida (Acc. Permanentes):</p>
                        <p class="valorEspecifico">{{kit.cargaRepartidaAP | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">KN/m2</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Carga Repartida (Acc. Variables):</p>
                        <p class="valorEspecifico">{{kit.cargaRepartidaAV | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">KN/m2</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Carga Puntual (Acc. Permanentes):</p>
                        <p class="valorEspecifico">{{kit.cargaPuntualAP | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">KN</p>
                    </div>
                    <div class="lineaEspecifica">
                        <p class="textoEspecifico">Carga Puntual (Acc. Variables):</p>
                        <p class="valorEspecifico">{{kit.cargaPuntualAV | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">KN</p>
                    </div>
                    <div class="lineaEspecifica" style="margin-bottom: 33px;">
                        <p class="textoEspecifico">Distancia al apoyo (a):</p>
                        <p class="valorEspecifico">{{kit.distanciaApoyo | number: '1.2-2'}}</p>
                        <p class="tipoEspecifico">mts</p>
                    </div>

                </div>
            </div>

            <p class="apartadoCalculo">RESULTADOS DEL CÁLCULO</p>
            <div class="lineaNegra"></div>
            <p class="texto">kit : {{kit.kitMecanoviga}}   situacion: {{kit.situacion}} </p>

            <div class="resultados">
                <div class="unResultado">
                    <div class="textoResultado">Reacción Total Izquierda:</div>
                    <div class="valorResultado">{{kit.reaccionIzquierda | number: '1.2-2'}}</div>
                    <div class="tipoResultado">KN</div>    
                </div>
                <div class="unResultado">
                    <div class="textoResultado">Reacción Total Derecha:</div>
                    <div class="valorResultado">{{kit.reaccionDerecha | number: '1.2-2'}}</div>
                    <div class="tipoResultado">KN</div>    
                </div>
                <div class="unResultado">
                    <div class="textoResultado">Tension Máxima:</div>
                    <div class="valorResultado">{{kit.tension | number: '1.2-2'}}</div>
                    <div class="tipoResultado">N/mm2<235</div>    
                </div>
                <div class="unResultado">
                    <div class="textoResultado">Momento Máx. mayorado:</div>
                    <div class="valorResultado">{{kit.momentoMaximo | number: '1.2-2'}}</div>
                    <div class="tipoResultado">KN*m</div>    
                </div>
                <div class="unResultado">
                    <div class="textoResultado">Deformación Inicial:</div>
                    <div class="valorResultado">{{kit.deformacionInicial | number: '1.2-2'}}</div>
                    <div class="tipoResultado">mm =>  1/{{kit.minDefInicial | number: '1.0-0'}}</div>    
                </div>
                <div class="unResultado">
                    <div class="textoResultado">Entrada en carga (tensado):</div>
                    <div class="valorResultado">{{kit.entradaCarga | number: '1.2-2'}}</div>
                    <div class="tipoResultado">mm</div>    
                </div>
                <br>
                <div class="unResultado">
                    <div class="textoResultado">Flecha Resultante:</div>
                    <div class="valorResultado">{{kit.flecha | number: '1.2-2'}}</div>
                    <div class="tipoResultado">mm =>  1/{{kit.minFlecha | number: '1.0-0'}}</div>    
                </div>

            </div>

            <div class="numeroPagina">
                Página {{i+1}} de {{listaKits.length}}
            </div>
        </div>

    </div>

    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>