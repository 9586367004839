<div class="content">

    <div>
        <img class="img" src="../../../../assets/logo mecanoviga.jpg" width="1000px" height="250px">
    </div>

    <div class="centrar">
        <div class="linea-delgada"></div>
        <p style="font-size: 30px;" *ngIf="idioma != 1">REFERENCIA</p>
        <p style="font-size: 30px;" *ngIf="idioma == 1">REFERÈNCIA</p>
        <div class="linea-delgada"></div>
    </div>

    <div class="nOferta">
        {{nOferta}} / {{idCliente}}
    </div>

    <div class="centrar mt-10">
        <div class="linea-delgada"></div>
        <p style="font-size: 30px;" *ngIf="idioma != 1">FECHA OFERTA</p>
        <p style="font-size: 30px;" *ngIf="idioma == 1">DATA OFERTA</p>
        <div class="linea-delgada"></div>
        
    </div>

    <div class="fecha mt-10">
        {{fechaOferta | date:'dd/MM/yyyy':'es'}}
    </div>

    <div class="centrar mt-10">
        <div class="linea-delgada"></div>
        <p style="font-size: 30px;">OBRA</p>
        <div class="linea-delgada"></div>
    </div>

    <div class="obra">
        <div style="max-width: 570px; line-height: 33px;">{{direccionObra}}</div>
        <div class="mt-10">{{codigoPostal}} - {{poblacionObra}}</div>
        <div class="mt-10">({{provinciaObra}})</div>
    </div>

    <div class="sello">
        <div class="linea-gruesa"></div>
        <div>
            <img src="../../../../assets/logo rehabilitando.png" alt="">
        </div>
    </div>

    <div class="pie-portada-oferta">
        <div class="datos">
            <p style="font-size: 20px;" class="m-0"><p style="margin-bottom: 0px;">MECANOVIGA, S.L.</p>
            <p style="font-size: 20px;" class="m-0"><p style="margin-bottom: 0px;">C.I.F.: B-60-369.303</p>
            <p *ngIf="idioma != 1" class="m-0">{{direccionDelegacion}}</p>
            <p *ngIf="idioma == 1 && delegacion == 1" class="m-0">Polígon Industrial Can Prunera, C/. del Garraf nº 16</p>
            <p class="m-0">{{cpDelegacion}} {{poblacionDelegacion}} ({{provinciaDelegacion}})</p>
            <p style="font-size: 15px;" class="color-primary m-0"><p style="margin-bottom: 0px;">+ 34 93.633.36.50</p>
            <p style="font-size: 15px;" class="color-primary m-0"><p style="margin-bottom: 0px;">www.mecanoviga.com</p>
            <p style="font-size: 15px;" class="color-primary m-0"><p>mecanoviga&#64;mecanoviga.com</p>
        </div>
        <div class="img-cert">
            <img src="../../../../assets/logo certificaciones.png" alt="">
        </div>

    </div>
</div>