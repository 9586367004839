<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mantenimiento">
    <div class="tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="items" class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button type="button"
                        (click)="dialogEliminarUnCampo($event, element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef=fechaInicio>
                <th mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.fechaInicio | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef=fechaFin>
                <th mat-header-cell *matHeaderCellDef> Fecha Fin </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.fechaFin | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Column -->
            <ng-container matColumnDef=precio>
                <th mat-header-cell *matHeaderCellDef> Precio </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.precio}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.fechaInicio == row.fechaInicio }"></tr>
        </table>
    </div>

    <form #formulario="ngForm" class="flex-row-wrap flex-35-responsive">

        <mat-form-field appearance="outline" class="flex-row-wrap flex-100">
            <mat-label>
                Fecha Inicio
            </mat-label>
            <input matInput readonly='true' [(ngModel)]="itemSelected.fechaInicio" name="fechaInicio"
                [matDatepicker]="picker1" [min]=fechaFinPrev [max]=itemSelected.fechaFin>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 [disabled]="itemSelected.editable!=0"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-row-wrap flex-100">
            <mat-label>
                Fecha Fin
            </mat-label>
            <input matInput readonly='true' [(ngModel)]="itemSelected.fechaFin" [min]="itemSelected.fechaInicio"
                name="fechaFin" [matDatepicker]="picker2" [min]=hoy [max]=fechaIniNext>
            <button mat-button *ngIf="itemSelected.fechaFin && !unaFechaNull" matSuffix mat-icon-button
                aria-label="Clear" (click)="itemSelected.fechaFin=null">
                <mat-icon>close</mat-icon>
            </button>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 [disabled]="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> Precio </mat-label>
            <input [(ngModel)]="itemSelected.precio" name="precio" matInput type="number" min="0"
                [disabled]="itemSelected.editable!=0">
        </mat-form-field>





        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span class="flex-50-responsive">
                <button mat-raised-button (click)="guardarItem(formulario)">GUARDAR</button>
            </span>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-padding">
    <button color="primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>