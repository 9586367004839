import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { DateToString } from 'src/app/services/dataFunction';
import { mantenimiento1TipoProducto, mantenimiento2TipoProducto, preciosProd } from 'src/app/services/llamadasMantenimiento';
import { DialogMantenimiento2, IdLabelObject } from 'src/app/types/global';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';

@Component({
  selector: 'app-dialog-mantenimiento-prod-opc',
  templateUrl: './dialog-mantenimiento-prod-opc.component.html',
  styleUrls: ['./dialog-mantenimiento-prod-opc.component.css']
})
export class DialogMantenimientoProdOpcComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<any>;
  constructor(
    public dialogRef: MatDialogRef<DialogMantenimientoProdOpcComponent>,
    public dialog: MatDialog,
    public dialogMantenimiento: MatDialog, 
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMantenimiento2
  ) { }
  
  items: any[]=[];
  itemsTot: any[]=[];
  preciosTodos: any[]=[];
  preciosConcreto:any[]=[];
  tipoProducto: IdLabelObject[]= [];
  unidadMedida: IdLabelObject[]= [];
  tipoProductoOpc: IdLabelObject[]= [];
  preciosSelected = false;
  precioOriginal: number = 0;
  closeData: any;
  openData: any;
  minDate = new Date();

  displayedColumns: string[] = ['buttons', this.data.cols[0], this.data.cols[1], this.data.cols[2], this.data.cols[3], this.data.cols[4], this.data.cols[5], this.data.cols[6], this.data.cols[7], this.data.cols[8], this.data.cols[9]];
  
  itemSelected : any ={
    idProducto: null,
    descripcion : null,
    idTipoProdOpc: null,
    tipoProdOpc: null,
    idUnidadMedida: null,
    unidadMedida: null,
    peso: null,
    idTipoProd : null,
    tipoProd : null, 
    conversion : null, 
    stockMinimo : null, 
    stockActual : null,
    editable : false,
  };
  itemOriginal : any ={
    idProducto: null,
    descripcion : null,
    idTipoProdOpc: null,
    tipoProdOpc: null,
    idUnidadMedida: null,
    unidadMedida: null,
    peso: null,
    idTipoProd : null,
    tipoProd : null, 
    conversion : null, 
    stockMinimo : null, 
    stockActual : null,
    editable : false,
  };
  precioSelected : any = {
    idProducto: null,
    fechaInicio: null,
    fechaFin: null,
    precio: null,
  }

  ngOnInit(): void {
    this.obtenerProductosOtros();
    this.obtenerTipoProducto();
    this.obtenerTipoProductoOpc();
    this.obtenerUnidadMedida();
  }


  
  private obtenerTipoProducto(){
    this.apiService.obtenerTipoProducto().subscribe(data => {
      console.log("tipo prod",data);
      this.tipoProducto = data.response.map(prod =>({id: prod.idTipo.toString(), label: prod.nombre}));
      console.log(this.tipoProducto);
    });
  }
  private obtenerTipoProductoOpc(){
    this.apiService.obtenerTipoProductosOpc().subscribe(data => {
      console.log("tipo prod",data);
      this.tipoProductoOpc = data.response.map(prod =>({id: prod.id.toString(), label: prod.tipo}));
      console.log(this.tipoProductoOpc);
    });
  }
  private obtenerUnidadMedida(){
    this.apiService.obtenerUnidadMedida().subscribe(data => {
      console.log("unidad medida",data);
      this.unidadMedida = data.response.map(prod =>({id: prod.id.toString(), label: prod.unidad}));
      console.log(this.unidadMedida);
    });
  }
  private obtenerProductosOtros(){
    this.apiService.obtenerProductosOtros().subscribe(data =>{
      this.items = data.response;
      this.itemsTot= this.items;
      
    })
  } 

  dialogEliminarUnCampo(event: any, item: any){
    this.itemSelected= item;
    console.log(item);
    this.openDialog("ELIMINAR","¿Quiere eliminar "+item.descripcion+"?", true,"eliminar");
    event.stopPropagation();
  }
  openDialog(title: string, msg: string, cancelBtn: boolean, tipo:any)  {
    const dialogRef = this.dialog.open(DialogPopUpComponent,{
      data: {  title: title, msg: msg, cancelBtn: cancelBtn}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Dialog result:", result);
      if(result && tipo == "eliminar") this.eliminarItem();
      if(result && tipo =="confirmacion")this.anadirProdOpc();
    });
  }

  eliminarItem(){
    this.apiService.borrarProdOpc(this.itemSelected.idProducto).subscribe(data =>{
      this.obtenerProductosOtros();
      this.resetItem();
    });
  }

  muestraUnItem(item : any){  
    this.itemSelected = {... item};
    this.itemOriginal = this.itemOriginal;
    this.obtenerPrecio(); 
  }

  obtenerPrecio(){
    this.apiService.obtenerPrecioProducto(this.itemSelected.idProducto).subscribe(dades => {
      console.log("idProd", this.itemSelected.idProducto);
      console.log(dades.response.length -1);
      if(dades.response.length>0){
        this.precioSelected = dades.response[dades.response.length -1];
        console.log(this.precioSelected.fechaInicio);
        this.precioOriginal = this.precioSelected.precio;
        this.openData = this.precioSelected.fechaInicio;
        this.closeData = new Date(this.precioSelected.fechaInicio);
        console.log("data1", this.closeData);
        this.closeData.setDate(this.closeData.getDate() + 1);
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate());
        if(this.minDate < this.closeData) this.minDate = this.closeData; 
      }
      else {
        this.precioSelected.fechaInicio = null;
        this.precioSelected.precio = null;
        this.precioOriginal = 0;
        //this.minDate = new Date();
        //this.precioSelected.fechaInicio = this.minDate;
        console.log(this.precioSelected.fechaInicio);
      }      
    });  
  }

  muestraUnaFecha(item : any){
    console.log("muestra una fecha", item);
  }


  muestraPrecios(event: any){
    event.stopPropagation();
    var dialogRef: any;
    dialogRef = preciosProd(this.dialogMantenimiento, this.itemSelected.descripcion,this.itemSelected.idProducto );
    dialogRef.afterClosed().subscribe( (data:any) => {
      console.log("cerrrado");
      this.obtenerProductosOtros();
      this.obtenerPrecio();
    });
  }

  openDialogTipoProd(){
    var dialogRef: any;
    dialogRef = mantenimiento2TipoProducto(this.dialogMantenimiento);
    dialogRef.afterClosed().subscribe( (data:any) => {
      console.log("cerrrado");
      this.obtenerTipoProducto();
    });
  }

  guardarItem(formulario: NgForm){
    if(!this.itemSelected.dataIni) this.itemSelected.dataIni = DateToString(new Date());
    console.log("guardar; ", this.itemSelected);
    if(this.itemSelected.idProducto){
      if(this.itemOriginal != this.itemSelected){
        this.apiService.modificarProdOpc(this.itemSelected).subscribe(dades => {
          this.obtenerProductosOtros();
          this.tratarPrecios();
        });
      }
      else this.tratarPrecios();
    }
    else{
      console.log(this.itemSelected);
      this.openDialog("PRODUCTO OPCIONAL NUEVO","¿Quiere crear este producto opcional nuevo "+this.itemSelected.descripcion+"?", true,"confirmacion");
    }
  }
  anadirProdOpc(){
    this.apiService.anadirProdOpc(this.itemSelected).subscribe(dades => {
      this.itemSelected.idProducto = dades.response;
      this.obtenerProductosOtros(); 
      this.tratarPrecios();
    });
  }

  tratarPrecios(){
    console.log("tratar", this.precioSelected.fechaInicio);

    let fechaIncio = new Date(this.precioSelected.fechaInicio);
    if(!this.precioSelected.fechaInicio) fechaIncio = new Date(); 
    console.log(fechaIncio, new Date());
    if(fechaIncio < new Date()) fechaIncio = new Date();


    this.closeData = fechaIncio;
    this.closeData.setDate(fechaIncio.getDate() - 1);
    this.closeData = DateToString(this.closeData);
    
    console.log(this.precioOriginal, this.precioSelected.precio, DateToString(this.precioSelected.fechaInicio), DateToString(new Date()));
    if(this.precioOriginal != this.precioSelected.precio && DateToString(this.precioSelected.fechaInicio) != DateToString(new Date())){
      var pvp ={
        idProd: this.itemSelected.idProducto, 
        fechaIni : this.openData,
        fechaIniOriginal : this.closeData,
        precio: this.precioSelected.precio
      };
      console.log(pvp);
      this.apiService.modificarPrecioProducto(pvp).subscribe(data =>{
        fechaIncio.setDate(fechaIncio.getDate()+1);
        var pvp2 ={
          idProd: this.itemSelected.idProducto, 
          fechaIni : DateToString(fechaIncio),
          precio: this.precioOriginal
        };
        this.apiService.anadirPrecioProducto(pvp2).subscribe(data=>{
          this.obtenerPrecio();
          this.obtenerProductosOtros();
        });
      })
    }
    //else this.openDialog("AVISO","No se ha modificado el precio, el canvio de precio no se va a efectuar ", true,"aviso");
  }

  public goTo(){
    if(this.itemSelected.idProducto == null){
      if(this.itemSelected.descripcion != null){
        this.items = this.itemsTot.filter(item => item.descripcion.toLowerCase().includes(this.itemSelected.descripcion.toLowerCase()) );
        if(this.items.length >0) this.table.renderRows();
      }
      else this.items = this.itemsTot;
    }
  }

  

  resetItem(){
    this.itemSelected = {
      idProducto: null,
      descripcion : null,
      idTipoProdOpc: null,
      tipoProdOpc: null,
      idUnidadMedida: null,
      unidadMedida: null,
      peso: null,
      idTipoProd : null,
      tipoProd : null, 
      conversion : null, 
      stockMinimo : null, 
      stockActual : null, 
      editable : false,
    }
    this.precioSelected = {
      idProducto: null,
      fechaInicio: null,
      fechaFin: null,
      precio: null,
    }
    this.precioOriginal = 0;
    this.goTo();

  }

}
