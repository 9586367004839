<div class="content">
    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

    <div class="container-report" id="oferta">

        <div class="antesDePagina" *ngFor="let unaPagina of paginas">

            <div class="pagina">
                <div class="header">
                    <app-cabecera-mecanoviga class="header-mecanoviga"></app-cabecera-mecanoviga>
                    <app-cabecera-albaran-zincado [nombreProveedor]="nombreProveedor" [direccionProveedor]="direccionProveedor" [telefonoProveedor]="telefonoProveedor"
                    [ciudadProveedor]="ciudadProveedor" [letra]="letra" class="header-cliente">
                    </app-cabecera-albaran-zincado>
                </div>

                <div class="linea-gruesa"></div>


                <div class="linea-delgada"></div>

                <div class="fecha">{{fechaZincado}}</div>

                <div class="linea-delgada"></div>

                <strong class="titulo">ALBARÁN DE ENTREGA</strong>

                <table border="1">
                    <thead>
                        <th class="flex-37" scope="row"></th>
                        <th class="text-center flex-12" scope="row">Long. (mm)</th>
                        <th class="text-center flex-17" scope="row">Desarrollo (mm)</th>
                        <th class="text-center flex-12" scope="row">Peso (kg/pz)</th>
                        <th class="text-center flex-10" scope="row">Nº Piezas</th>
                        <th class="text-right flex-17" scope="row">KG</th>
                    </thead>
                    <tbody>
                        <tr class="celda" *ngFor="let producto of unaPagina.lineas; let i = index">
                            <th class="text-left flex-37" scope="row"> {{producto.nombreProducto}}</th>
                            <th class="text-right flex-12" scope="row">{{producto.longitud | number: '1.2-2'}}</th>
                            <th class="text-right flex-17" scope="row">{{producto.desarrollo}}</th>
                            <th class="text-right flex-12" scope="row">{{producto.peso | number: '1.2-2'}}</th>
                            <th class="text-right flex-10" scope="row">{{producto.nPiezas}}</th>
                            <th class="text-right flex-17" scope="row">{{producto.peso * producto.nPiezas | number:
                                '1.2-2'}}</th>
                        </tr>
                    </tbody>

                </table>

                <div *ngIf="!noCabeTexto && unaPagina.imprimirTotal" class="footer">

                    <div class="kilos">
                        <strong>KILOS TOTALES</strong>
                        <strong>{{totalKilos | number: '1.2-2'}}</strong>
                    </div>

                    <div class="tratamiento">
                        <strong class="tituloTratamiento">Tratamiento</strong>
                        <div class="bodyTratamiento">
                            <strong class="cuadrado"></strong>
                            <strong class="tipoTratamiento">Zincado amarillo + bicromatado</strong>
                        </div>
                        <div class="bodyTratamiento">
                            <strong class="cuadrado"></strong>
                            <strong class="tipoTratamiento">Zincado color plata</strong>
                        </div>
                    </div>

                    <div class="firmas">
                        <div class="unaFirma">
                            <strong class="tituloFirmas">Transportista</strong>
                            <strong style="margin: 7px 0;">{{transportista}}</strong>
                            <strong>Recibido - conforme el Transportista</strong>
                            <strong style="margin-top: 70px;">Fdo. ........................................</strong>
                        </div>
                        <div class="unaFirma">
                            <strong class="tituloFirmas">Cliente</strong>
                            <strong style="margin: 7px 0;">{{cliente}}</strong>
                            <strong>Recibido - conforme el Cliente</strong>
                            <strong style="margin-top: 70px;">Fdo. ........................................</strong>

                        </div>
                    </div>

                </div>

            </div>

            <div class="numeroPagina">
                Pagina {{unaPagina.nPagina}} de {{nPaginas}}
            </div>

        </div>

        <div *ngIf="noCabeTexto" class="antesDePagina">

            <div class="pagina">

                <div class="header">
                    <app-cabecera-mecanoviga class="header-mecanoviga"></app-cabecera-mecanoviga>
                    <app-cabecera-albaran-zincado [nombreProveedor]="nombreProveedor" [direccionProveedor]="direccionProveedor" [telefonoProveedor]="telefonoProveedor"
                        [ciudadProveedor]="ciudadProveedor" [letra]="letra" class="header-cliente">
                    </app-cabecera-albaran-zincado>
                </div>

                <div class="linea-gruesa"></div>


                <div class="linea-delgada"></div>

                <div class="fecha">{{fechaZincado}}</div>

                <div class="linea-delgada"></div>

                <strong class="titulo">ALBARÁN DE ENTREGA</strong>

                <div class="footer">

                    <div class="kilos">
                        <strong>KILOS TOTALES</strong>
                        <strong>{{totalKilos | number: '1.2-2'}}</strong>
                    </div>

                    <div class="tratamiento">
                        <strong class="tituloTratamiento">Tratamiento</strong>
                        <div class="bodyTratamiento">
                            <strong class="cuadrado"></strong>
                            <strong class="tipoTratamiento">Zincado amarillo + bicromatado</strong>
                        </div>
                        <div class="bodyTratamiento">
                            <strong class="cuadrado"></strong>
                            <strong class="tipoTratamiento">Zincado color plata</strong>
                        </div>
                    </div>

                    <div class="firmas">
                        <div class="unaFirma">
                            <strong class="tituloFirmas">Transportista</strong>
                            <strong style="margin: 7px 0;"></strong>
                            <strong>Recibido - conforme el Transportista</strong>
                            <strong style="margin-top: 70px;">Fdo. ........................................</strong>
                        </div>
                        <div class="unaFirma">
                            <strong class="tituloFirmas">Cliente</strong>
                            <strong style="margin: 7px 0;"></strong>
                            <strong>Recibido - conforme el Cliente</strong>
                            <strong style="margin-top: 70px;">Fdo. ........................................</strong>

                        </div>
                    </div>

                </div>

            </div>

            <div *ngIf="noCabeTexto" class="numeroPagina">
                Pagina {{nPaginas}} de {{nPaginas}}
            </div>

        </div>

    </div>


    <div>
        <button type="button" mat-raised-button class="button-pdf" (click)="convertirAPdf()">Convertir a PDF</button>
    </div>

</div>