<h2 class="h2titulo flex flex-65-responsive justify-center">Líneas de la Factura {{nFactura}} de la oferta {{nOferta}}
    del cliente {{idCliente}} -
    {{nombreCliente}}</h2> <br>

<div class="container flex-row-wrap">

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button" (click)="eliminarAlbaranDeFactura(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nAlbaran">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº Albarán </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nAlbaran}} </td>
            </ng-container>

            <ng-container matColumnDef="salida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10">¿Salida?</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.salida}} </td>
            </ng-container>

            <ng-container matColumnDef="importe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Importe </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.importe}} </td>
            </ng-container>

            <ng-container matColumnDef="importeProductos">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Importe Prod.Opc. </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.importeProductos}} </td>
            </ng-container>

            <ng-container matColumnDef="transporte">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Transporte</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.transporte}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnaLineaFactura(row)"
                [ngClass]="{'row-highlighted': lineaFactura.nAlbaran == row.nAlbaran}"></tr>
        </table>
        <mat-paginator *ngIf="listaLineasFactura.length>0" [pageSizeOptions]="[20]"
            showFirstLastButtons></mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaLineasFactura.length == 0">No hay productos</div>

    </div>

    <form #formulario="ngForm" class="form-derecho row-gap-15 flex-35-responsive">


        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> NºAlbarán</mat-label>
            <input disabled [(ngModel)]="lineaFactura.nAlbaran" type="number" name="nAlbaran" matInput>
        </mat-form-field>

        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> Importe</mat-label>
            <input disabled [(ngModel)]="lineaFactura.importe" type="number" name="importe" matInput>
        </mat-form-field>

        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> Importe Prod. Opc.</mat-label>
            <input disabled [(ngModel)]="lineaFactura.importeProductos" type="number" name="importeProductos" matInput>
        </mat-form-field>

        <mat-form-field class="flex-60-responsive" appearance="outline">
            <mat-label> Transporte</mat-label>
            <input disabled [(ngModel)]="lineaFactura.transporte" type="number" name="transporte" matInput>
        </mat-form-field>

        <span class="flex-50-responsive mt-10">
            <button class="button__buscar" type="button" mat-raised-button (click)="anadirAlbaran()">Añadir
                Albarán</button>
        </span>

        <span class="flex-50-responsive mt-10">
            <button class="button__buscar" type="button" mat-raised-button (click)="volverAFacturas()">Volver</button>
        </span>

    </form>
</div>