import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IdTipoProducto, esFabricable, origenProductoFabricado, tipoMapper, tiposGenericos } from '../constants/fabricacion.constant';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChapaDesdeBD } from '../types/comprasChapa';
import { type MedidaProducto, type ModeloProducto, type OrigenProductoFabricado, type ProdPlegable, type TipoProductoFabricacion } from '../types/fabricacion';


@Injectable({
  providedIn: 'root'
})
export class FabricacionService {
  public idTipoProducto = IdTipoProducto;
  public cantidadDeChapas = 1;
  public seFabrica = esFabricable;
  public tiposProducto: TipoProductoFabricacion[] = [];
  public tipoProductoSeleccionado = {} as TipoProductoFabricacion;
  public medidasDeProducto: MedidaProducto[] = [];
  public medidaProductoSeleccionada = {} as MedidaProducto;
  public modelosDeProducto: ModeloProducto[] = [];
  public modeloProductoSeleccionado = {} as ModeloProducto;
  public origenesDeProducto = origenProductoFabricado
  public origenProductoSeleccionado = {} as OrigenProductoFabricado;
  public longitudesDeProducto: number[] = [];
  public longitudProductoSeleccionada = 0;
  public unidades = 1;

  public hayTipoProductoSeleccionado = false;
  public hayMedidaSeleccionada = false;
  public hayModeloSeleccionado = false;
  public hayLongitudSeleccionada = false;
  public hayUnidadesSeleccionadas = true;
  public hayOrigenSeleccionado = false;

  public tiposMapper = tipoMapper;
  public tipoGenerico = tiposGenericos;

  public productoGenerado = ''
  public productoGeneradoTiposLength = 0;
  public productoGeneradoMedidasLength = 0;
  public productoGeneradoOrigenesLength = 0;
  public productoGeneradoModelosLength = 0;
  public productoGeneradoLongitudesLength = 0;
  public productoGeneradoUnidadesLength = 0;
  public idChapa = 0;
  public idPlegable = 0;

  public listaChapas: ChapaDesdeBD[] = [];
  public listaPlegables: ProdPlegable[] = [];
  public chapaSeleccionada: ChapaDesdeBD = {} as ChapaDesdeBD;
  public prodSeleccionado: ProdPlegable = {} as ProdPlegable;
  public hayChapaSeleccionada = false;
  public hayProdSeleccionado = false;
  public esFabricacion = false;
  public esPlegado = false;
  public cantidadPlegables = 1;
  
  public productesEnFabricacio: any = [];
  public procesandoPlegado = false;
  public procesandoFabricacion = false;

  public fabStatus = {
    PUNZONADO: 'Punzonado',
    PLEGADO: 'Plegado',
  }

  constructor(private apiService: ApiService, private router: Router, private _snackBar: MatSnackBar) { }

  public obtenerTiposProductoFabricacion() {
    this.apiService.obtenerTiposProductoFabricacion().subscribe((data: any) => {
      console.log(data.response);
      this.tiposProducto = data.response;
    });
  }

  public obtenerMedidasProducto(seFabrica: number, idProductoFabricacion: number,) {
    this.apiService.obtenerMedidasProducto(seFabrica, idProductoFabricacion).subscribe((data: any) => {
      console.log(data.response);
      this.medidasDeProducto = data.response;
    });
  }

  public obtenerModelosProducto(seFabrica: number, idProductoFabricacion: number, idMedida: number) {
    this.apiService.obtenerModelosProducto(seFabrica, idProductoFabricacion, idMedida).subscribe((data: any) => {
      console.log(data.response);
      this.modelosDeProducto = data.response;
    });
  }

  public obtenerLongitudesProducto(seFabrica: number, idProductoFabricacion: number, idMedida: number, idModelo: number) {
    this.apiService.obtenerLongitudesProducto(seFabrica, idProductoFabricacion, idMedida, idModelo).subscribe((data: any) => {
      console.log(data.response);
      this.longitudesDeProducto = data.response;
    });
  }

  public volverAOrigenes() {
    this.hayUnidadesSeleccionadas = false;
    this.unidades = 1;
    this.productoGenerado = this.productoGenerado.slice(0, this.productoGeneradoOrigenesLength);
  }

  public volverAModelos() {
    this.hayLongitudSeleccionada = false;
    this.hayUnidadesSeleccionadas = false;
    this.longitudProductoSeleccionada = 0;
    this.unidades = 1;
    this.productoGenerado = this.productoGenerado.slice(0, this.productoGeneradoModelosLength);
  }

  public volverAMedidas() {
    this.hayModeloSeleccionado = false;
    this.hayOrigenSeleccionado = false;
    this.modeloProductoSeleccionado = {} as ModeloProducto;
    this.origenProductoSeleccionado = {} as OrigenProductoFabricado;
    this.productoGenerado = this.productoGenerado.slice(0, this.productoGeneradoMedidasLength);
  }

  public volverATipos() {
    this.modelosDeProducto = [];
    this.hayMedidaSeleccionada = false;
    this.hayUnidadesSeleccionadas = false;
    this.hayOrigenSeleccionado = false;
    this.unidades = 1;
    this.medidaProductoSeleccionada = {} as MedidaProducto;
    this.origenProductoSeleccionado = {} as OrigenProductoFabricado;
    this.productoGenerado = this.productoGenerado.slice(0, this.productoGeneradoTiposLength);
  }

  public volver() {
    this.medidasDeProducto = [];
    this.hayTipoProductoSeleccionado = false;
    this.tipoProductoSeleccionado = {} as TipoProductoFabricacion;
    this.productoGenerado = '';
  }

  public volverAChapas() {
    this.esFabricacion = true;
    this.hayChapaSeleccionada = false;
    this.chapaSeleccionada = {} as ChapaDesdeBD;
    this.esPlegado = false;
    this.resetValues();
    this.router.navigate(['taller']);
  }

  public irAPlegado() {
    this.esPlegado = true;
    this.esFabricacion = false;
    this.router.navigate(['taller/plegado']);
  }

  public anadirProducto() {
    let producto
    if (this.hayChapaSeleccionada && this.esFabricacion) {
        producto = {
        tipo: this.tipoProductoSeleccionado ? this.cloneVariable(this.tipoProductoSeleccionado['IdProducteFabricacio']) : null,
        medida: this.medidaProductoSeleccionada['IdMidaProducte'] ? this.cloneVariable(this.medidaProductoSeleccionada['IdMidaProducte']) : null,
        modelo: this.modeloProductoSeleccionado['IdModel'] ? this.cloneVariable(this.modeloProductoSeleccionado['IdModel']) : null,
        origen: this.cloneVariable(this.origenProductoSeleccionado),
        longitud: this.cloneVariable(this.longitudProductoSeleccionada),
        unidades: this.cloneVariable(this.origenProductoSeleccionado).desc === 'RECORTE' ? this.cloneVariable(this.unidades) : this.cloneVariable(this.unidades) * this.cantidadDeChapas,
        label: this.cloneVariable(this.productoGenerado),
        status: this.fabStatus.PUNZONADO
      }

      // actualizar las unidades en la label antes de añadir el producto
      const guionIndex = producto['label'].indexOf('-');
      producto['label'] = producto['label'].slice(0, guionIndex) + (producto['longitud'] !== 0 ? producto['longitud'] : '') + (producto['origen']['desc'] === 'RECORTE' ? ' - RECORTE' : '') + ' - Unidades: ' + producto['unidades'];

      console.log('PRODUCTO:', producto);

      this.productesEnFabricacio.push(producto);
    }

    if (this.hayProdSeleccionado) {
      producto = {
        id: this.prodSeleccionado['IdProducte'],
        unidades: this.cantidadPlegables,
        label: this.prodSeleccionado['DescProducte'] + (this.prodSeleccionado['Longitud'] !== 0 ? ` - Longitud:${this.prodSeleccionado['Longitud']}` : '') + ' - Unidades: ' + this.cantidadPlegables,
        status: this.fabStatus.PLEGADO
      }

      const index = this.productesEnFabricacio.findIndex((elemento: any) => elemento.id == this.prodSeleccionado['IdProducte']);
      if (index > -1) {
        this.productesEnFabricacio[index].unidades += this.cantidadPlegables;
        this.productesEnFabricacio[index].label = this.prodSeleccionado['DescProducte'] + (this.prodSeleccionado['Longitud'] !== 0 ? ` - Longitud:${this.prodSeleccionado['Longitud']}` : '') + ' - Unidades: ' + this.productesEnFabricacio[index].unidades;
      } else {
        this.productesEnFabricacio.push(producto);
      }
    }
    
    this.resetValues();
  }

  public cloneVariable(variable: any) {
    return _.cloneDeep(variable);
  }

  public resetValues() {
  this.tipoProductoSeleccionado = {} as TipoProductoFabricacion;
  this.medidaProductoSeleccionada = {} as MedidaProducto;
  this.modeloProductoSeleccionado = {} as ModeloProducto;
  this.origenProductoSeleccionado = {} as OrigenProductoFabricado;
  this.longitudProductoSeleccionada = 0;
  this.unidades = 1;
  this.hayTipoProductoSeleccionado = false;
  this.hayMedidaSeleccionada = false;
  this.hayModeloSeleccionado = false;
  this.hayLongitudSeleccionada = false;
  this.hayUnidadesSeleccionadas = true;
  this.hayOrigenSeleccionado = false;
  this.productoGenerado = '';
  this.productoGeneradoTiposLength = 0;
  this.productoGeneradoMedidasLength = 0;
  this.productoGeneradoOrigenesLength = 0;
  this.productoGeneradoModelosLength = 0;
  this.productoGeneradoLongitudesLength = 0;
  this.productoGeneradoUnidadesLength = 0;
  this.medidasDeProducto = [];
  this.modelosDeProducto = [];
  this.longitudesDeProducto = [];

  }

  public cleanVaribales() {
  this.idTipoProducto = IdTipoProducto;
  this.cantidadDeChapas = 1;
  this.tiposProducto = [];
  this.tipoProductoSeleccionado = {} as TipoProductoFabricacion;
  this.medidasDeProducto = [];
  this.medidaProductoSeleccionada = {} as MedidaProducto;
  this.modelosDeProducto = [];
  this.modeloProductoSeleccionado = {} as ModeloProducto;
  this.origenesDeProducto = origenProductoFabricado
  this.origenProductoSeleccionado = {} as OrigenProductoFabricado;
  this.longitudesDeProducto = [];
  this.longitudProductoSeleccionada = 0;
  this.unidades = 1;

  this.hayTipoProductoSeleccionado = false;
  this.hayMedidaSeleccionada = false;
  this.hayModeloSeleccionado = false;
  this.hayLongitudSeleccionada = false;
  this.hayUnidadesSeleccionadas = true;
  this.hayOrigenSeleccionado = false;

  this.productoGenerado = ''
  this.productoGeneradoTiposLength = 0;
  this.productoGeneradoMedidasLength = 0;
  this.productoGeneradoOrigenesLength = 0;
  this.productoGeneradoModelosLength = 0;
  this.productoGeneradoLongitudesLength = 0;
  this.productoGeneradoUnidadesLength = 0;
  this.idChapa = 0;
  this.idPlegable = 0;

  this.listaChapas = [];
  this.listaPlegables = [];
  this.chapaSeleccionada = {} as ChapaDesdeBD;
  this.prodSeleccionado = {} as ProdPlegable;
  this.hayChapaSeleccionada = false;
  this.hayProdSeleccionado = false;
  this.esFabricacion = false;
  this.esPlegado = false;
  this.cantidadPlegables = 1;

  this.productesEnFabricacio = [];
  }

  public insertOrderFabrication() {
    console.log('ORDEN A INGRESAR: ', this.productesEnFabricacio, this.idChapa, this.cantidadDeChapas);
    this.procesandoFabricacion = true;
    this.apiService.insertOrderFabrication(this.productesEnFabricacio, this.idChapa, this.cantidadDeChapas).subscribe((data: any) => {
      if (data && data.response) {
        this.obtenerChapas();
        this.obtenerProductosAPlegar();
        this.idChapa = 0;
        this.productesEnFabricacio = [];
        this.cleanVaribales();
        this.procesandoFabricacion = false;
        this.router.navigate(['taller']);
        this._snackBar.open("Orden de elaboración generada correctamente", "x", {
          duration: 7000,
          panelClass: ['success-snackbar']
        });
      }
    });
  }

  public updateStockPlegados () {
    this.procesandoPlegado = true;
    this.apiService.updateStockPlegados(this.productesEnFabricacio).subscribe((data: any) => {
      if (data && data.response) {
        this.obtenerProductosAPlegar();
        this.productesEnFabricacio = [];
        this.prodSeleccionado = {} as ProdPlegable;
        this.chapaSeleccionada = {} as ChapaDesdeBD;
        this.esFabricacion = false;
        this.esPlegado = false;
        this.procesandoPlegado = false;
        this._snackBar.open("Productos plegados correctamente", "x", {
          duration: 7000,
          panelClass: ['success-snackbar']
        });
      }
    });
  }

  public obtenerChapas() {
    this.apiService.obtenerChapas().subscribe((data: any) => {
      console.log(data.response);
      this.listaChapas = data.response;
    });
  }

  public obtenerProductosAPlegar() {
    this.apiService.obtenerProductosAPlegar().subscribe((data: any) => {
      this.listaPlegables = data.response;
      console.log(this.listaPlegables);
    })
  }

  public deleteProduct(index: number) {
    if (this.productesEnFabricacio[index]['status'] === this.fabStatus.PLEGADO) {
      const prodABorrar = this.listaPlegables.find((prod: any) => prod['IdProducte'] === this.productesEnFabricacio[index]['id']);
      if (prodABorrar) prodABorrar['Punzonado'] += this.productesEnFabricacio[index]['unidades'];
    }

    this.productesEnFabricacio.splice(index, 1);
  }

  public hiddenBadge() {
    if (this.productesEnFabricacio.length <= 0 || (this.esPlegado && this.listaPlegables.length <= 0)) return true;
    return false;
  }

  public disableVolverAChapas() {
    if (this.productesEnFabricacio.length > 0 && this.productesEnFabricacio.some((prod: any) => prod['status'] === this.fabStatus.PUNZONADO)) return true;
    return false;
  }

  public disableConfirmarPlegado() {
    if (!this.hayProdSeleccionado || this.prodSeleccionado.Punzonado === 0) return true;
    return false;
    }
}