<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content class="mantenimiento container">
    <div class="tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="items" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="!element.editable" mat-icon-button
                        (click)="dialogEliminarUnCampo($event, element)"><mat-icon>deletes</mat-icon></button>
                </td>
            </ng-container>

            <!-- Nombre Column -->
            <ng-container matColumnDef={{data.cols[0]}}>
                <th mat-header-cell *matHeaderCellDef> {{data.cols[0]}} </th>
                <td mat-cell *matCellDef="let element"> {{element.labelPrincipal}} </td>
            </ng-container>


            <!-- Direccion Column -->
            <ng-container matColumnDef={{data.cols[1]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[1]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label2}} </td>
            </ng-container>

            <!-- Ciudad Column -->
            <ng-container matColumnDef={{data.cols[2]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[2]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label3}} </td>
            </ng-container>

            <!-- Telefon Column -->
            <ng-container matColumnDef={{data.cols[3]}}>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{data.cols[3]}} </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.label4}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnItem(row)"
                [ngClass]="{'row-highlighted':  itemSelected.idPrincipal == row.idPrincipal }"></tr>
        </table>
    </div>

    <form class="form flex-row-wrap flex-35-responsive" #formulario="ngForm" (ngSubmit)="guardarItem()">


        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> {{data.cols[0]}} </mat-label>
            <input [(ngModel)]="itemSelected.labelPrincipal" name="labelPrincipal" matInput type="text"
                (ngModelChange)="goTo()">
            <button class="limpiar" mat-button *ngIf="itemSelected.labelPrincipal" matSuffix mat-icon-button
                aria-label="Clear" (click)="resetItem()"><mat-icon>close</mat-icon>
            </button>
        </mat-form-field>


        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> {{data.cols[1]}} </mat-label>
            <input [(ngModel)]="itemSelected.label2" name="label2" matInput type="text">
        </mat-form-field>


        <app-autocomplete class="autocomplete" name="label3" ngDefaultControl class="flex-100"
            [idOpcionSeleccionada]="itemSelected.idlabel3" (cuandoSeSeleccioneOpcion)="itemSelected.idlabel3 = $event"
            label="{{data.cols[2]}}" [options]="ciudad" icon="settings"
            (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" [(ngModel)]="itemSelected.label3"
            (ngModelChange)="goTo()">
        </app-autocomplete>
        <!--
        <mat-form-field  appearance="outline" class="flex-100">
            <mat-label> {{data.cols[2]}} </mat-label>
            <input [(ngModel)]="itemSelected.label3" name="label3" matInput type="text">
        </mat-form-field>
-->
        <mat-form-field appearance="outline" class="flex-100">
            <mat-label> {{data.cols[3]}} </mat-label>
            <input [(ngModel)]="itemSelected.label4" name="label4" matInput type="text">
        </mat-form-field>



        <div class="LimpiarGuardar flex-row-wrap flex-100">
            <span class="flex-50-responsive">
                <button mat-raised-button type="submit">GUARDAR</button>
            </span>

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions-padding">
    <button color=" primary" mat-button mat-dialog-close mat-stroked-button>
        <mat-icon>close</mat-icon> Cerrar
    </button>
</mat-dialog-actions>