import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FabricacionService } from 'src/app/services/fabricacion.service';
import { type OrigenProductoFabricado } from 'src/app/types/fabricacion';

@Component({
  selector: 'app-seccion-origen',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './seccion-origen.component.html',
  styleUrl: './seccion-origen.component.css'
})
export class SeccionOrigenComponent {
  private agregarOrigen = '';

  constructor(public fabricacionService: FabricacionService) { }

  public onOrigenSelection(origen: OrigenProductoFabricado) {
    this.fabricacionService.hayOrigenSeleccionado = true;
    this.fabricacionService.origenProductoSeleccionado = origen;
    this.agregarOrigen = ` - Origen: ${this.fabricacionService.origenProductoSeleccionado.desc}`;
    this.fabricacionService.productoGenerado += this.agregarOrigen;
    this.fabricacionService.productoGeneradoOrigenesLength = this.fabricacionService.productoGenerado.length;
  }

  public disabledButton() {
    if (this.fabricacionService.hayOrigenSeleccionado ||
      !this.fabricacionService.hayTipoProductoSeleccionado ||
      this.fabricacionService.medidasDeProducto.length > 0 && !this.fabricacionService.hayMedidaSeleccionada) return true;
    return false;
  }

  public volverAtras() {
    if (this.fabricacionService.medidasDeProducto.length > 0) {
      this.fabricacionService.volverAMedidas();
      return
    }

    if (this.fabricacionService.medidasDeProducto.length === 0) {
      this.fabricacionService.volverATipos();
      return
    }
  }


  public disableVolverAtras() {
    if (!this.fabricacionService.hayOrigenSeleccionado || this.fabricacionService.unidades > 1) return true;
    return false;
  }
}
