import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router'
import { ApiService } from 'src/app/services/api.service';
import { TituloService } from 'src/app/services/titulo.service';
import { tiposCabeceraReport } from 'src/app/constants/comprasChapa.constant';
@Component({
  selector: 'app-report-albaranes-compra-chapa',
  standalone: false,
  templateUrl: './report-albaranes-compra-chapa.component.html',
  styleUrl: './report-albaranes-compra-chapa.component.css'
})
export class ReportAlbaranesCompraChapaComponent implements OnInit {
  @ViewChild('content', { static: false }) content!: ElementRef;
  public compra: any = {};
  public compraCompleta: any = {};
  public fechas = {} as any;
  public albaranes: any[] = [];
  public tiposCabecera = tiposCabeceraReport;

  public contadorLineas = 0;
  comentarioNComanda = '';

  public paginas: any[] = [];
  public freeSpaceCuentaLienas: number = 0;
  public templateHeight: number = 0;
  public lineHeight: number = 31.83;
  public linesPerPage: number = 39;

  public nPagina = 0;
  public idIdioma = 2;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private tituloService: TituloService,
  ) { }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.activatedRoute.queryParams.subscribe(data => {
      this.fechas = { fechaDesde: data['fechaDesde'],  fechaHasta: data['fechaHasta'] };
      this.apiService.obtenerAlbaranesCompraChapaPorFecha(this.fechas.fechaDesde, this.fechas.fechaHasta).subscribe(data => {
        this.albaranes = data.response;
        this.cuentaLineas();
      })
    })
  }

  private stringToHTML(str: any) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  public convertirAPdf() {
    //this.apiService.modificarDescripcionPdf(this.nFactura, this.datosFactura.descripcion).subscribe();
    setTimeout(() => {
      this.downloadPDF();
    }, 200);
  }

  downloadPDF() {
    window.scrollTo(0, 0);
    const doc = new jsPDF('p', 'pt', 'a4');
    Promise.all(Array.from(document.getElementsByClassName('pagina')).map((pagina) => {
      return html2canvas(pagina as HTMLElement, { scale: 2 })
    })).then((canvasList) => {
      canvasList.forEach((canvas, idx) => {
        const img = canvas.toDataURL('image/SVG');
        const bufferX = 15;
        const bufferY = 15;
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        idx !== 0 && doc.addPage();
        doc.addImage(img, 'SVG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      })
      doc.save(`albaranes_desde_${this.fechas.fechaDesde}_hasta_${this.fechas.fechaHasta}.pdf`);
      //  this.router.navigate(['facturas'], { queryParams: { facturaResaltada: this.nFactura } });
    })
  }


  private cuentaLineas() {
    let lineasPagina = 0;
    let pagina: any[] = [];
    let cantidadAlbaranes = this.albaranes.length;
    let contadorAlbaranes = 0
    this.albaranes.forEach(albaran => {      
      contadorAlbaranes = contadorAlbaranes + 1;
      let lineasAlbaran = 2 + (albaran.Entregas.length);
      lineasPagina = lineasPagina + lineasAlbaran;
      if (lineasPagina < 51) {
        pagina.push(albaran)
        lineasAlbaran = 0;
        if (contadorAlbaranes == cantidadAlbaranes) {
          ++this.nPagina;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            albaranes: pagina
          }
          this.paginas.push(element)
        }
      } else {
        const idInicio = (albaran.Entregas.length) - (lineasPagina - 51);
        const idFin = (albaran.Entregas.length);
        const albaranFinal = albaran.Entregas.slice(idInicio, idFin);
        
        ++this.nPagina;
        let element = {
          npagina: this.nPagina,
          ultimaPagina: false,
          albaranes: pagina
        }
        this.paginas.push(element);
        lineasPagina = albaranFinal.length + 4;
        pagina = [];
        let albaranFinalAGuardar = {
          NumAlbara: albaran.NumAlbara,
          IdAlbara: albaran.IdAlbara,
          DataAlbara: albaran.DataAlbara,
          Entregas: albaran.Entregas
        }
        pagina.push(albaranFinalAGuardar);
        if (contadorAlbaranes == cantidadAlbaranes) {
          ++this.nPagina;
          let element = {
            npagina: this.nPagina,
            ultimaPagina: false,
            albaranes: pagina
          }
          this.paginas.push(element)
        }
      }
    });
    console.log("PAGINAS: ", this.paginas);
  }

  /*private cuentaLineas(container: HTMLElement, maxLinesPerPage: number): HTMLElement[] {
    const lineHeight = parseFloat(getComputedStyle(container).lineHeight);
    let currentLineCount = 0;
    let currentPage: HTMLElement[] = [];
    const allPages: HTMLElement[][] = [currentPage];

    Array.from(container.children)
      .filter(child => child instanceof HTMLElement)
      .forEach((value: Element, _index, _array) => {
        const child = value as HTMLElement;
        const childHeight = child.offsetHeight;
        const childLines = Math.ceil(childHeight / lineHeight);

        if (currentLineCount + childLines > maxLinesPerPage) {
          currentPage = [];
          allPages.push(currentPage);
          currentLineCount = 0;
        }

        currentPage.push(child);
        currentLineCount += childLines;
      });

    return allPages.map((pageElements) => {
      const newPageContainer = document.createElement('div');
      newPageContainer.classList.add('pagina');
      pageElements.forEach(el => newPageContainer.appendChild(el));
      return newPageContainer;
    });
  }*/

  public getHeightTemplate() {
    if (this.templateHeight == 0) {
      setTimeout(() => {
        let template = document.getElementById('heightTemplate');
        if (template) {
          this.templateHeight = template.getBoundingClientRect().height;
          console.log("templateHeight: ", this.templateHeight);
        }
      }, 1000);
    }
    return false;
  }
}
