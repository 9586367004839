import { AfterViewInit, Component, Inject, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogPopUpComponent } from 'src/app/dialogs/dialog-pop-up/dialog-pop-up.component';
import { Factura } from '../../types/facturas';
import { ApiService } from '../../services/api.service';
import { IdLabelObject } from '../../types/global';
import { DateToString, StringToDate } from '../../services/dataFunction';
// Joan post-upgrade: Ho comento de moment per poder compilar després de migrar Angular de 12 a 15. De totes formes no veig que aquí s'utilitzi
//import { prepareEventListenerParameters } from '@angular/compiler/src/render3/view/template';
import { DOCUMENT } from '@angular/common';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.css']
})
export class FacturasComponent implements OnInit, AfterViewInit {

  public filtroFacturas: any = {
    selectedFechaInicio: '',
    selectedFechaFin: '',
    selectednFactura: '',
    selectednOferta: '',
    selectedidCliente: '',
    selectedidComercial: '',
    selectedPagado: '',
    selectedAdelanto: '',
    selectedAnulada: ''
  }

  public factura: any = {
    idFactura: "",
    nFactura: "",
    fechaFactura: "",
    nOferta: "",
    idCliente: "",
    nombreCliente: "",
    direccionFactura: "",
    idCiudad: "",
    nombreCiudad: "",
    nif: "",
    codigoPostal: "",
    cuenta: "",
    telefono: "",
    condicionesPago: "",
    formaPago: "",
    conceptoOtrosGastos: "",
    notas: "",
    descripcion: "",
    idComercial: "",
    nombreComercial: "",
    comercialPagado: false,
    fechaPagoComercial: "",
    qtyComisiones: "",
    importeLineas: 0,
    descuentoPP: 0,
    importeproductosOpcionales: 0,
    transporte: 0,
    otrosGastos: 0,
    importeFactura: 0,
    iva: 0,
    importeIva: 0,
    importeFacturaConIva: 0,
    pagoPrevisto: "",
    pagoReal: undefined,
    adelanto: false,
    anulada: false,
    tieneLineas: false
  }

  opcionesSiNo = [
    { nombre: "SI",
      valor: true
    },
    { nombre: "NO",
      valor: false
    }
  ]

  public listaFacturas: any[] = [];
  public esFacturaNueva: boolean = true;
  public facturaResaltada = null;
  public clienteABorrar: any;
  public ciudadesAutocomplete: IdLabelObject[] = [];
  public condicionesPagoAutocomplete: IdLabelObject[] = [];
  public clientesAutocomplete: IdLabelObject[] = [];
  public comercialesAutocomplete: IdLabelObject[] = [];
  public checkPagoReal: boolean = false;

  public dataSource!: MatTableDataSource<any>;
  public hayDatosEnTabla: boolean = true;
  private paramsUrl: any = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = ['buttons', 'nFactura', 'nOferta', 'nombreCliente', 'fechaFactura', 'adelanto', 'anulada', 'pagoPrevisto', 'pagoReal'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaFacturas);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    if (localStorage.getItem('filtroFacturas')) {
      this.filtroFacturas = JSON.parse(localStorage.getItem('filtroFacturas') || '{}');
      localStorage.removeItem('filtroFacturas');
      if (this.filtroFacturas.selectedFechaInicio) this.filtroFacturas.selectedFechaInicio = StringToDate(this.filtroFacturas.selectedFechaInicio);
      if (this.filtroFacturas.selectedFechaFin) this.filtroFacturas.selectedFechaFin = StringToDate(this.filtroFacturas.selectedFechaFin);  
    } 
 
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.facturaResaltada) {
        console.log("PaSO");
        this.facturaResaltada = params.facturaResaltada;
      }
      else {
        if (params.nuevaFactura === 'vacia') {
            this.anadirVacia();
        } 
        else if (params.nuevaFactura) {
          let filtroUnaOferta = {
            selectedFechaInicio: '',
            selectedFechaFin: '',
            selectednFactura: params.nuevaFactura,
            selectednOferta: '',
            selectedidCliente: '',
            selectedidComercial: '',
            selectedPagado: '',
            selectedAdelanto: '',
            selectedAnulada: ''      
          }
          console.log("llamada api obtener factura 2");
          this.apiService.obtenerFactura(filtroUnaOferta).subscribe( facturas => {
            this.factura = facturas.response[0];
            this.muestraUnaFactura(this.factura);
          })
        } else {
  //        this.envioDatosFiltro();
        }
      }
    })

    //this.resetFactura();
  //  this.envioDatosFiltro();
    this.apiService.obtenerCiudades().subscribe(data => {
      this.ciudadesAutocomplete = data.response.map(ciudad => ({ id: ciudad.idCiudad.toString(), label: ciudad.nombreCiutat }));
    });

    this.apiService.obtenerCondicionesDePago().subscribe(data => {
      this.condicionesPagoAutocomplete = data.response.map(cPago => ({ id: cPago.nCondicion.toString(), label: cPago.condicion}));
    })
    this.apiService.obtenerComerciales().subscribe(data => {
      this.comercialesAutocomplete = data.response.map(comercial => ({ id: comercial.idComercial.toString(), label: comercial.nombre }));
    })
    this.apiService.obtenerClientesCompleto({}).subscribe(data => {
      this.clientesAutocomplete = data.response.map((cliente: any) => ({ id: cliente.nCliente.toString(), label: cliente.empresa }));
    })
  }

  ngAfterViewInit() {
    this.envioDatosFiltro();
/*    this.dataSource = new MatTableDataSource<any>(this.listaFacturas);
    console.log(this.listaFacturas);
    let page = this.buscarIndiceEnOfertasFiltradas(this.facturaResaltada) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;*/
  }

/*  ngOnChanges(): void {
    this.envioDatosFiltro();
    this.dataSource = new MatTableDataSource<any>(this.listaFacturas);
    let page = this.buscarIndiceEnOfertasFiltradas(this.facturaResaltada) || 0;
    this.paginator.pageIndex = page;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
*/
  public envioDatosFiltro() {
    if (this.filtroFacturas.selectedFechaInicio) this.filtroFacturas.selectedFechaInicio = DateToString(this.filtroFacturas.selectedFechaInicio);
    if (this.filtroFacturas.selectedFechaFin) this.filtroFacturas.selectedFechaFin = DateToString(this.filtroFacturas.selectedFechaFin);
    
    console.log("llamada api obtener factura 3");
    this.apiService.obtenerFactura(this.filtroFacturas).subscribe(data => {
      if (this.filtroFacturas.selectedFechaInicio) this.filtroFacturas.selectedFechaInicio = StringToDate(this.filtroFacturas.selectedFechaInicio);
      if (this.filtroFacturas.selectedFechaFin) this.filtroFacturas.selectedFechaFin = StringToDate(this.filtroFacturas.selectedFechaFin);  
      this.listaFacturas = data.response;
      console.log(data.response);
      this.dataSource = new MatTableDataSource<any>(this.listaFacturas);
      let page = this.buscarIndiceEnOfertasFiltradas(this.facturaResaltada) || 0;
      this.paginator.pageIndex = page;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.facturaResaltada) {
        for (let i = 0; i < this.listaFacturas.length; i++) {
          if(this.listaFacturas[i]['nFactura'] == this.facturaResaltada) {
            this.muestraUnaFactura(this.listaFacturas[i]);
            break;
          }
        }
      }
    })
  }

  public modificarFactura(formulario: NgForm) {
    console.log(formulario.valid);
//    if (!formulario.valid) { return };
    console.log("1: ", this.factura);
    this.factura.fechaFactura = DateToString(this.factura.fechaFactura);
    if (this.factura.pagoPrevisto) {this.factura.pagoPrevisto = DateToString(this.factura.pagoPrevisto)};
    if (this.factura.pagoReal) {this.factura.pagoReal = DateToString(this.factura.pagoReal)};
    console.log("2: ", this.factura);
    this.apiService.modificarFactura(this.factura).subscribe(params => {
      this.factura.fechaFactura = StringToDate(this.factura.fechaFactura);
      if (this.factura.pagoPrevisto) {this.factura.pagoPrevisto = StringToDate(this.factura.pagoPrevisto)};
      if (this.factura.pagoReal) {this.factura.pagoReal = StringToDate(this.factura.pagoReal)};
      this.envioDatosFiltro();
    })
  }


  public crearFacturaNueva() {
    this.esFacturaNueva = true;
    this.resetFactura();
  }

  public anadirFactura() {
    this.guardarFiltroEnLocalstorage();
    if (this.facturaResaltada) {
      let params = {
        facturaResaltada: this.facturaResaltada
      }
      this.router.navigate(['seleccion-facturas'], { queryParams: params});
    } else {
      this.router.navigate(['seleccion-facturas']);
    }
//    this.router.navigate(['seleccion-facturas']);
  }

  public anadirVacia() {
    this.apiService.crearFacturaVacia().subscribe( dato => {
      let element = {
        nFactura: dato
      };
      this.facturaResaltada = element.nFactura;
      this.envioDatosFiltro();
      console.log("llamada api obtener factura 5");
      this.apiService.obtenerFactura(element.nFactura).subscribe( facturas => {
        this.factura = facturas.response  [0];
        this.muestraUnaFactura(this.factura);
      })
    });
  }

  public irADetalles() {
    let params = {
      nFactura: this.factura.nFactura,
      idFactura: this.factura.idFactura,
      nOferta: this.factura.nOferta,
      idCliente: this.factura.idCliente,
      nombreCliente: this.factura.nombreCliente
    }
    console.log("Tiene lineas: ", this.factura.tieneLineas);
    if (this.factura.tieneLineas) {
      this.router.navigate(['lineas-factura'], { queryParams: params });
    } else {
      this.router.navigate(['albaranes-pendientes-facturar'], { queryParams:  params });
    }
  }

  public muestraUnaFactura(element: any) {
    this.factura = { ...element };
    console.log(this.factura);
    if (this.factura.descripcion) {
      this.factura.descripcion = this.factura.descripcion.replace('<br>', '\n');
      this.factura.descripcion = this.factura.descripcion.replace(/<[^>]+>/g, '');
    }
    if (this.factura.pagoReal) {this.checkPagoReal = true} else { this.checkPagoReal = false};
    if (this.factura.adelanto === "NO") { this.factura.adelanto = false} else if (this.factura.adelanto === "SI") {this.factura.adelanto = true };
    if (this.factura.anulada === "NO") { this.factura.anulada = false} else if (this.factura.anulada === "SI") {this.factura.anulada = true }; 
    if (this.factura.pagoRel) { this.factura.pagado = true} else {this.factura.pagado = true }; 
    if (this.factura.comercialPagado === "SI") { this.factura.comercialPagado = true} else {this.factura.comercialPagado = false }; 
    this.esFacturaNueva = false;
    this.facturaResaltada = this.factura.nFactura;
  }


  private buscarIndiceEnOfertasFiltradas(facturaResaltada: any) {
    if (!facturaResaltada) return 0;
    let busqueda = facturaResaltada.toString();
    let indice: number;
    indice = this.listaFacturas.findIndex(factura => factura.nFactura.toString() === busqueda);
    return Math.trunc((indice - 1) / this.paginator.pageSize);
  }

  private guardarFiltroEnLocalstorage() {
    if (this.filtroFacturas.selectedFechaInicio) this.filtroFacturas.selectedFechaInicio = DateToString(this.filtroFacturas.selectedFechaInicio);
    if (this.filtroFacturas.selectedFechaFin) this.filtroFacturas.selectedFechaFin = DateToString(this.filtroFacturas.selectedFechaFin);
    if (localStorage.getItem('filtroFacturas')) { 
      localStorage.removeItem('filtroFacturas');
    }
    localStorage.setItem('filtroFacturas', JSON.stringify(this.filtroFacturas));
    if (this.filtroFacturas.selectedFechaInicio) this.filtroFacturas.selectedFechaInicio = StringToDate(this.filtroFacturas.selectedFechaInicio);
    if (this.filtroFacturas.selectedFechaFin) this.filtroFacturas.selectedFechaFin = StringToDate(this.filtroFacturas.selectedFechaFin);  
  }

  public imprimirFactura() {
   /*
    let params = {
      nFactura: this.factura.nFactura,
      idFactura: this.factura.idFactura,
      nOferta: this.factura.nOferta,
      idCliente: this.factura.idCliente,
      nombreCliente: this.factura.nombreCliente
    }
  */
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = '/report-factura?nFactura=' + this.factura.nFactura  + '&idFactura=' + this.factura.idFactura + '&nOferta=' +this.factura.nOferta + '&idCliente=' + this.factura.idCliente + '&nombreCliente=' + this.factura.nombreCliente;
    link.click();
    link.remove();
  }


  resetFactura() {
    this.factura = {
      idFactura: "",
      nFactura: "",
      fechaFactura: "",
      nOferta: "",
      idCliente: "",
      nombreCliente: "",
      direccionFactura: "",
      idCiudad: "",
      nombreCiudad: "",
      nif: "",
      codigoPostal: "",
      cuenta: "",
      telefono: "",
      condicionesPago: "",
      formaPago: "",
      conceptoOtrosGastos: "",
      notas: "",
      descripcion: "",
      idComercial: "",
      nombreComercial: "",
      comercialPagado: false,
      fechaPagoComercial: "",
      qtyComisiones: "",
      importeLineas: 0,
      descuentoPP: 0,
      importeproductosOpcionales: 0,
      transporte: 0,
      otrosGastos: 0,
      importeFactura: 0,
      iva: 0,
      importeIva: 0,
      importeFacturaConIva: 0,
      pagoPrevisto: "",
      pagoReal: "",
      adelanto: false,
      anulada: false
      };
    this.esFacturaNueva = true;
  }

  public resetFiltro() {
    this.filtroFacturas = {
      selectedFechaInicio: '',
      selectedFechaFin: '',
      selectednFactura: '',
      selectednOferta: '',
      selectedidCliente: '',
      selectedidComercial: '',
      selectedPagado: false,
      selectedAdelanto: false,
      selectedAnulada: false
          }
    this.facturaResaltada = null;
    this.router.navigate(['facturas']);
    this.resetFactura();
  }



}
