<div class="container flex-row-wrap">

    <div class="layout-tabla flex-layout flex-65-responsive">
        <table mat-table [dataSource]="dataSource" matSort
            class="mat-elevation-z8 tabla-boton-init standar-table-mecanoviga">

            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef>
                    <button class="button-create" mat-icon-button type="button" (click)="esCompraGeneradaNueva()">
                        <mat-icon>create_new_folder</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button type="button" (click)="dialogEliminarCompraGenerada(element)">
                        <mat-icon>deletes</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="nombreProducto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                <td mat-cell *matCellDef="let element" align="left"> {{element.nombreProducto}} </td>
            </ng-container>

            <ng-container matColumnDef="stockMinimo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Stock Mínimo </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.stockMinimo}} </td>
            </ng-container>

            <ng-container matColumnDef="stockActual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Stock Actual </th>
                <td mat-cell *matCellDef="let element" align="center" style="font-weight: 600;">
                    {{element.stockActual}} </td>
            </ng-container>

            <ng-container matColumnDef="Sin zincar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Sin zincar </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.stockActual - (element.prodZincado +
                    element.enZincador)}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Zincado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Zincado </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.prodZincado}} </td>
            </ng-container>

            <ng-container matColumnDef="Zincador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Zincador </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.enZincador}} </td>
            </ng-container>

            <ng-container matColumnDef="Zincador al cliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Zincador al cliente </th>
                <td mat-cell *matCellDef="let element" align="center" style="color: #ffb81d; font-weight: 600;">
                    {{element.zincadorAClient}} </td>
            </ng-container>

            <ng-container matColumnDef="materialComprado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Material Comprado </th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.materialComprado}} </td>
            </ng-container>

            <ng-container matColumnDef="pedNoComprados">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Ped. No Comprados</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.pedNoComprados}} </td>
            </ng-container>

            <ng-container matColumnDef="aComprar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> A Comprar</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.aComprar}} </td>
            </ng-container>

            <ng-container matColumnDef="peso">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="center p10"> Peso</th>
                <td mat-cell *matCellDef="let element" align="center"> {{element.peso | number:'0.2-2'}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="muestraUnaCompraGenerada(row)"
                [ngClass]="{'row-highlighted': compraGenerada.idProducto == row.idProducto}"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidden':true, 'no-hidden': listaComprasGeneradas.length > 0}"
            [pageSizeOptions]="[20]" showFirstLastButtons>
        </mat-paginator>
        <div class="noHayNadaEnTabla" *ngIf="listaComprasGeneradas.length == 0 && showSpinner === false">No hay
            productos</div>
        <mat-spinner *ngIf="showSpinner" style="margin-top: 50px;"></mat-spinner>
        <div class="noHayNadaEnTabla" *ngIf="showSpinner">BUSCANDO</div>

    </div>

    <form #formulario="ngForm" (ngSubmit)="guardarProducto(formulario)"
        class="form-derecho row-gap-10 pt-10 flex-35-responsive">

        <app-autocomplete class="autocomplete flex-100" name="producto" [disabled]="!crearCompraNueva" ngDefaultControl
            [idOpcionSeleccionada]="compraGenerada.idProducto"
            (cuandoSeSeleccioneOpcion)="compraGenerada.idProducto = $event" label="Producto"
            [options]="productosAutocomplete">
        </app-autocomplete>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Stock Mínimo</mat-label>
            <input disabled [(ngModel)]="compraGenerada.stockMinimo" type="number" name="stockMinimo" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Stock Actual</mat-label>
            <input disabled [(ngModel)]="compraGenerada.stockActual" type="number" name="stockActual" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Sin zincar </mat-label>
            <input disabled
                [value]="compraGenerada.stockActual - (compraGenerada.prodZincado + compraGenerada.enZincador)"
                type="number" name="sinZincar" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Zincado </mat-label>
            <input disabled [(ngModel)]="compraGenerada.prodZincado" type="number" name="prodZincado" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Zincador </mat-label>
            <input disabled [(ngModel)]="compraGenerada.enZincador" type="number" name="zincador" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Zincador a cliente </mat-label>
            <input disabled [(ngModel)]="compraGenerada.zincadorAClient" type="number" name="zincadorAClient" matInput>
        </mat-form-field>

        <mat-form-field class="flex-45-responsive" appearance="outline">
            <mat-label> Material Comprado</mat-label>
            <input disabled [(ngModel)]="compraGenerada.materialComprado" type="number" name="materialComprado"
                matInput>
        </mat-form-field>


        <mat-form-field class="flex-51-responsive" appearance="outline">
            <mat-label> Pedidos No Comprados</mat-label>
            <input disabled [(ngModel)]="compraGenerada.PedNoComprados" type="number" name="PedNoComprados" matInput>
        </mat-form-field>

        <mat-form-field class="flex-51-responsive" appearance="outline">
            <mat-label> A Comprar</mat-label>
            <input [disabled]="!compraGenerada.idProducto" [(ngModel)]="compraGenerada.aComprar" type="number" min="0"
                name="aComprar" matInput>
        </mat-form-field>

        <mat-form-field class="flex-51-responsive" appearance="outline">
            <mat-label> Peso (Kg)</mat-label>
            <input disabled [(ngModel)]="compraGenerada.peso" type="number" name="peso" matInput>
        </mat-form-field>
        <!--
        <span class="flex-70-responsive">
            <button class="button__buscar" type="button" mat-raised-button>Calcular Peso</button>
        </span>

    -->

        <span class="flex-48-responsive">
            <button [disabled]="!compraGenerada.idProducto" class="button__buscar" type="submit"
                mat-raised-button>Guardar</button>
        </span>

        <span class="flex-48-responsive">
            <button class="button__buscar" type="button" (click)="ComprarProductoGenerado()"
                mat-raised-button>Comprar</button>
        </span>

    </form>

</div>