<form (ngSubmit)="envioDatosFiltro()" #form="ngForm" class="criterios">
    <div class="criterios__container row-gap-10">

        <div class="criterios__input" style="width: 15em;">
            <mat-form-field appearance="outline">
                <mat-label>
                    Fecha Inicio
                </mat-label>
                <input matInput [max]="filtros.selectedFechaHasta" readonly='true'
                    [(ngModel)]="filtros.selectedFechaDesde" name="fechaInicio" [matDatepicker]="picker" disabled>
                <button mat-button *ngIf="filtros.selectedFechaDesde" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filtros.selectedFechaDesde=undefined">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="criterios__input" style="width: 15em;">
            <mat-form-field appearance="outline">
                <mat-label>
                    Fecha Fin
                </mat-label>
                <input matInput readonly='true' [(ngModel)]="filtros.selectedFechaHasta"
                    [min]="filtros.selectedFechaDesde" name="fechaFin" [matDatepicker]="picker2" disabled>
                <button mat-button *ngIf="filtros.selectedFechaHasta" matSuffix mat-icon-button aria-label="Clear"
                    (click)="filtros.selectedFechaHasta=undefined">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="criterios__input">
            <app-autocomplete class="autocomplete" style="width: 20em;" name="comercial" ngDefaultControl
                [idOpcionSeleccionada]="filtros.selectedComercial"
                (cuandoSeSeleccioneOpcion)="filtros.selectedComercial = $event" icon=""
                (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('comercial')" label="Comercial"
                [options]="comerciales">
            </app-autocomplete>
        </div>
        <div class="criterios__input" style="width: 12em;">
            <mat-checkbox [(ngModel)]="filtros.selectedChecked" name="checked">¿Asignada?</mat-checkbox>
        </div>
        <div class="criterios__input" style="width: 12em;">
            <mat-form-field appearance="outline">
                <mat-label>¿Cerrado?</mat-label>
                <mat-select [(ngModel)]="filtros.selectedCerrado" name="cerrado">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                        {{opcion.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="criterios__input">
            <app-autocomplete class="autocomplete" style="width: 22.6em;" name="tecnico" ngDefaultControl
                [idOpcionSeleccionada]="filtros.selectedTecnico"
                (cuandoSeSeleccioneOpcion)="filtros.selectedTecnico = $event" icon=""
                (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('tecnico')" label="Técnico" [options]="tecnicos">
            </app-autocomplete>
        </div>
        <div class="criterios__input">
            <app-autocomplete class="autocomplete" style="width: 35.3em;" name="cliente" ngDefaultControl
                [idOpcionSeleccionada]="filtros.selectedCliente"
                (cuandoSeSeleccioneOpcion)="filtros.selectedCliente = $event" icon=""
                (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('cliente')" label="Cliente" [options]="clientes">
            </app-autocomplete>
        </div>
        <div class="criterios__input">
            <app-autocomplete class="autocomplete" style="width: 20em;" name="provincia" ngDefaultControl
                [idOpcionSeleccionada]="filtros.selectedProvincia"
                (cuandoSeSeleccioneOpcion)="filtros.selectedProvincia = $event" icon=""
                (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('provincia')" label="Provincia"
                [options]="provincias">
            </app-autocomplete>
        </div>

        <div class="criterios__input">
            <app-autocomplete class="autocomplete" style="width: 20em;" name="ciudad" ngDefaultControl
                [idOpcionSeleccionada]="filtros.selectedCiudad"
                (cuandoSeSeleccioneOpcion)="filtros.selectedCiudad = $event" icon=""
                (cuandoSeCliqueEnIcono)="cuandoSeCliqueEnMantenimiento('ciudad')" label="Ciudad" [options]="ciudades">
            </app-autocomplete>
        </div>

        <div class="criterios__input" style="width: 20em;">
            <mat-form-field appearance="outline">
                <mat-label>Dirección obra</mat-label>
                <input matInput type="text" [(ngModel)]="filtros.selectedDireccionObra" name="direccionObra">
                <button mat-button *ngIf="filtros.selectedDireccionObra" matSuffix></button>
            </mat-form-field>
        </div>
        <div class="criterios__input" style="width: 12em;">
            <mat-form-field appearance="outline">
                <mat-label>¿Colaboración?</mat-label>
                <mat-select [(ngModel)]="filtros.selectedColaboracion" name="colaboracion">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opcion of opcionesSiNo" [value]="opcion.nombre">
                        {{opcion.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="criterios__input" style="width: 12em;">
            <mat-form-field appearance="outline">
                <mat-label>Nº oferta</mat-label>
                <input matInput type="number" [(ngModel)]="filtros.selectednOferta" name="nOferta">
                <button mat-button *ngIf="filtros.selectednOferta" matSuffix></button>
            </mat-form-field>
        </div>
        <div class="criterios__input" style="width: 10em;">
            <button type="submit" class="button__buscar" color="primary" mat-stroked-button>Buscar</button>
        </div>

    </div>
</form>