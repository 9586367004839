import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { AuthService } from 'src/app/services/auth-service';
import {
  mantenimiento1CondicionesPago, mantenimiento1FormaPago, mantenimiento1Patologia, mantenimiento1Idioma,
  mantenimiento1ModoEnvio, mantenimiento1Pais, mantenimiento1TipoSoporte, mantenimiento1Transportista,
  mantenimiento3Comercial, mantenimiento2TiposKit, mantenimiento2SubTiposKit, mantenimiento3Stock,
  mantenimiento2TipoProducto, mantenimiento1ValidezOferta, mantenimiento2Ciudad, mantenimiento2Provincia,
  mantenimiento2Producto, mantenimiento2ProductoOpc, mantenimiento2Kits, seleccionInformeVentas, mantenimiento1CuentaBancaria, mantenimiento4Proveedor, mantenimientoStockProduccion
} from 'src/app/services/llamadasMantenimiento';
import { TituloService } from 'src/app/services/titulo.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //public tituloPagina: string = "";

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public tituloService: TituloService
  ) { }
  ngOnInit() { 
    this.tituloService.enviarTitulo();
    const userRoles = localStorage.getItem('userRoles');
    if(userRoles) this.authService.userRoles = JSON.parse(userRoles);
  }

  public irAOfertas() {
    //  this.tituloPagina = 'OFERTAS';
    localStorage.removeItem('arrayOfertas');
    this.router.navigate(['ofertas']);
  }

  public irALaRuta(ruta: string, item: string) {
    if (localStorage.getItem('token')) {
      mantenimiento1CondicionesPago(this.dialog);
      this.router.navigate([ruta, item]);
    }
  }
  public openDialogCondicionesPago(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1CondicionesPago(this.dialog);
    }
  }
  public openDialogValidezOferta(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1ValidezOferta(this.dialog);
    }
  }
  public openDialogFormaPago(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1FormaPago(this.dialog);
    }
  }
  public openDialogPatologia(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1Patologia(this.dialog);
    }
  }
  public openDialogIdioma(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1Idioma(this.dialog);
    }
  }

  public openDialogPais(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1Pais(this.dialog);
    }
  }
  public openDialogModoEnvio(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1ModoEnvio(this.dialog);
    }
  }
  public openDialogTipoProducto(): void {
    if (localStorage.getItem('token')) {
      mantenimiento2TipoProducto(this.dialog);
    }
  }
  public openDialogTipoSoporte(): void {
    if (localStorage.getItem('token')) {
      mantenimiento1TipoSoporte(this.dialog);
    }
  }
  public openDialogTransportista() {
    if (localStorage.getItem('token')) {
      mantenimiento1Transportista(this.dialog);
    }
  }
  public openDialogCuentas() {
    if (localStorage.getItem('token')) {
      mantenimiento1CuentaBancaria(this.dialog);
    }
  }

  public openDialogCiudad() {
    if (localStorage.getItem('token')) {
      mantenimiento2Ciudad(this.dialog);
    }
  }
  public openDialogProvincia() {
    if (localStorage.getItem('token')) {
      mantenimiento2Provincia(this.dialog);
    }
  }

  public openDialogComercial() {
    if (localStorage.getItem('token')) {
      mantenimiento3Comercial(this.dialog);
    }
  }
  public openDialogTipoKit() {
    if (localStorage.getItem('token')) {
      mantenimiento2TiposKit(this.dialog);
    }
  }
  public openDialogProducto() {
    if (localStorage.getItem('token')) {
      mantenimiento2Producto(this.dialog);
    }
  }
  public openDialogSubtipoKit() {
    if (localStorage.getItem('token')) {
      mantenimiento2SubTiposKit(this.dialog);
    }
  }

  public openDialogProductoOpc() {
    if (localStorage.getItem('token')) {
      mantenimiento2ProductoOpc(this.dialog);
    }
  }

  public openDialogKits() {
    if (localStorage.getItem('token')) {
      mantenimiento2Kits(this.dialog);
    }
  }
  public openDialogStock() {
    if (localStorage.getItem('token')) {
      mantenimiento3Stock(this.dialog);
    }
  }

  public openDialogStockProduccion() {
    if (localStorage.getItem('token')) {
      mantenimientoStockProduccion(this.dialog);
    }
  }

  public logout() {
    //  this.tituloPagina = '';
    localStorage.removeItem('token');
    localStorage.removeItem('userRoles');
    this.router.navigate(['login']);
  }

  public openDialogInformeVentas() {
    //  this.tituloPagina = 'FACTURAS - INFORMES';
    if (localStorage.getItem('token')) {
      seleccionInformeVentas(this.dialog);
    }
  }
  public openDialogProveedor() {
    if (localStorage.getItem('token')) {
      mantenimiento4Proveedor(this.dialog);
    }
  }
}
