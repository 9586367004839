import { Component, OnInit, ViewChild } from '@angular/core';
import { IdLabelObject } from '../../../types/global';
import { ApiService } from '../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CompraGenerada } from '../../../types/compras';
import { DialogPopUpComponent } from '../../../dialogs/dialog-pop-up/dialog-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { TituloService } from 'src/app/services/titulo.service';


@Component({
  selector: 'app-generar-compra',
  templateUrl: './generar-compra.component.html',
  styleUrls: ['./generar-compra.component.css']
})
export class GenerarCompraComponent implements OnInit {

  public listaComprasGeneradas: any[] = [];
  public productosAutocomplete: IdLabelObject[] = [];
  public crearCompraNueva: boolean = false;
  public nCompra = "";
  private paramsUrl: any;
  public compraGeneradaABorrar: any;
  public showSpinner = false;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  public productoResaltado = null;
  public dataSource!: MatTableDataSource<CompraGenerada>;

  public compraGenerada: any = {
    idProducto: "",
    nombreProducto: "",
    stockMinimo: "",
    stockActual: "",
    prodZincado: "",
    zincadorCliente: "",
    materialComprado: "",
    stockZinc: "",
    pedNoComprados: "",
    aComprar: "",
    peso: ""
  }

  displayedColumns: string[] = ['buttons', 'nombreProducto', 'stockMinimo', 'stockActual', 'Sin zincar',
    'Zincado', 'Zincador', 'Zincador al cliente', 'materialComprado', 'pedNoComprados', 'aComprar', 'peso'];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private tituloService: TituloService
  ) {
    this.dataSource = new MatTableDataSource(this.listaComprasGeneradas);
  }

  ngOnInit(): void {
    this.tituloService.enviarTitulo();
    this.resetCompraGenerada();
    // obtener listaComprasGeneradas
    //    this.showSpinner = true;
    console.log("pre");
    /*  this.apiService.generarOrdenCompra().subscribe(data => {
        setTimeout(() => {
          this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
            this.listaComprasGeneradas = data.response;
            this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
            console.log(this.listaComprasGeneradas);
            this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.showSpinner = false;
          });
        }, 5000);
      })
    */
  }

  ngAfterViewInit(): void {
    //  obtener listaComprasGeneradas
    this.showSpinner = true;
    console.log("after");
    this.apiService.generarOrdenCompra().subscribe(data => {
      setTimeout(() => {
        this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
          this.listaComprasGeneradas = data.response;
          this.productosAutocomplete = data.response.map((producto: any) => ({ id: producto.idProducto.toString(), label: producto.nombreProducto }));
          console.log(this.listaComprasGeneradas);
          this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.showSpinner = false;
        });
      }, 1000);
    })
  }

  ngOnChanges(): void {
    //  obtener listaComprasGeneradas
    this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
      this.listaComprasGeneradas = data.response;
      this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public muestraUnaCompraGenerada(element: any) {
    this.crearCompraNueva = false;
    this.compraGenerada = { ...element };

  }

  public esCompraGeneradaNueva() {
    this.crearCompraNueva = true;
    this.resetCompraGenerada();
  }

  public guardarProducto(formulario: NgForm) {
    if (!this.compraGenerada.idProducto) {
      // Guardar nuevo producto en lista
      this.apiService.anadirOrdenCompraDetalle2(this.compraGenerada).subscribe(data => {
        this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
          this.listaComprasGeneradas = data.response;
          this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    } else {
      // Modificar producto en lista
      this.apiService.modificarOrdenCompraDetalle2(this.compraGenerada).subscribe(data => {
        this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
          this.listaComprasGeneradas = data.response;
          this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      });
    }
  }

  public ComprarProductoGenerado() {
    var msg = "";
    if (!this.miraNegativos(this.compraGenerada.aComprar)) {
      msg = 'El campo A Comprar tiene un número negativo';
    }
    else if (this.comprobarSiYaExiste(this.compraGenerada.idProducto) && this.crearCompraNueva) {
      msg = 'Este producto ya está dado de alta en esta compra';
    }
    if (msg != "") {
      this.openDialog("ALERTA", msg, false, "");
      return;
    }
    this.apiService.comprar(this.compraGenerada).subscribe(data => {
    })
    /*
    if (this.crearCompraNueva) {
      // guardar compra generada nueva
      this.apiService.anadirOrdenCompraDetalle(this.compraGenerada).subscribe(data => {
        // obtener listaComprasGeneradas
        this.apiService.generarOrdenCompra().subscribe(data => {
          this.apiService.obtenerOrdenCompraAutogenerada().subscribe(data => {
            this.crearCompraNueva = false;
            this.listaComprasGeneradas = data.response;
            this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        });
      });
    } else {
      // modificar compra generada
      this.apiService.modificarOrdenCompraDetalle(this.compraGenerada).subscribe(dades => {
        // obtener listaComprasGeneradas
        this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
          this.listaComprasGeneradas = data.response;
          this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

      });
    }
    */
  }

  public eliminarDetalleProducto() {
    this.apiService.borrarOrdenCompraDetalle(this.compraGeneradaABorrar).subscribe(dades => {
      this.crearCompraNueva = false;
      // obtener listaComprasGeneradas
      this.apiService.obtenerOrdenesCompraDetalle(this.nCompra).subscribe(data => {
        this.listaComprasGeneradas = data.response;
        this.dataSource = new MatTableDataSource<CompraGenerada>(this.listaComprasGeneradas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resetCompraGenerada();
      })
    })
  }

  public dialogEliminarCompraGenerada(compraGenerada: any) {
    this.compraGeneradaABorrar = compraGenerada;
    this.openDialog("ELIMINAR PRODUCTO", "¿Quiere eliminar el producto " + compraGenerada.nombreProducto + " de la lista de compras generadas", true, "eliminarCompraGenerada");
  }


  private openDialog(title: string, msg: string, cancelBtn: boolean, tipo: any) {
    const dialogRef = this.dialog.open(DialogPopUpComponent, {
      data: { title: title, msg: msg, cancelBtn: cancelBtn }
    });
    dialogRef.afterClosed().subscribe(result => {
      switch (tipo) {
        case 'eliminarCompraGenerada':
          if (result) this.eliminarDetalleProducto();
          break;
        default:
          break;
      }
    });
  }

  private comprobarSiYaExiste(idProducto: string) {
    let arrayComprobado;
    arrayComprobado = this.listaComprasGeneradas.find(item => {
      if (item.idProducto == idProducto) {
        return true;
      } else {
        return false;
      }
    });
    return arrayComprobado;
  }

  private miraNegativos(numero: number) {
    if (numero < 0) {
      return false
    } else {
      return true
    }
  }


  private resetCompraGenerada() {
    this.compraGenerada = {
      idProducto: "",
      nombreProducto: "",
      stockMinimo: "",
      stockActual: "",
      materialComprado: "",
      stockZinc: "",
      pedNoComprados: "",
      aComprar: "",
      peso: ""
    }

  }


}
